/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignUpUserDto
 */
export interface SignUpUserDto {
    /**
     * 
     * @type {string}
     * @memberof SignUpUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserDto
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserDto
     */
    affiliateCode: string;
}

export function SignUpUserDtoFromJSON(json: any): SignUpUserDto {
    return SignUpUserDtoFromJSONTyped(json, false);
}

export function SignUpUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignUpUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'fullName': json['fullName'],
        'affiliateCode': json['affiliateCode'],
    };
}

export function SignUpUserDtoToJSON(value?: SignUpUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'fullName': value.fullName,
        'affiliateCode': value.affiliateCode,
    };
}

