import React from 'react';
import { Wrapper } from './icon.styled';

export const Icon: React.FC<React.SVGProps<SVGSVGElement>> = ({ children, ...rest }) => {
    return (
        <Wrapper>
            <svg xmlns="http://www.w3.org/2000/svg" {...rest}>
                {children}
            </svg>
        </Wrapper>
    );
};
