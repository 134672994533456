/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SymbolGrowthRateDto
 */
export interface SymbolGrowthRateDto {
    /**
     * 
     * @type {number}
     * @memberof SymbolGrowthRateDto
     */
    calculatedProfitGrowthRate: number;
    /**
     * 
     * @type {number}
     * @memberof SymbolGrowthRateDto
     */
    expectedProfitGrowthRate: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SymbolGrowthRateDto
     */
    current: boolean;
    /**
     * 
     * @type {string}
     * @memberof SymbolGrowthRateDto
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof SymbolGrowthRateDto
     */
    netIncome: number;
}

export function SymbolGrowthRateDtoFromJSON(json: any): SymbolGrowthRateDto {
    return SymbolGrowthRateDtoFromJSONTyped(json, false);
}

export function SymbolGrowthRateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolGrowthRateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calculatedProfitGrowthRate': json['calculatedProfitGrowthRate'],
        'expectedProfitGrowthRate': json['expectedProfitGrowthRate'],
        'current': json['current'],
        'date': json['date'],
        'netIncome': json['netIncome'],
    };
}

export function SymbolGrowthRateDtoToJSON(value?: SymbolGrowthRateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calculatedProfitGrowthRate': value.calculatedProfitGrowthRate,
        'expectedProfitGrowthRate': value.expectedProfitGrowthRate,
        'current': value.current,
        'date': value.date,
        'netIncome': value.netIncome,
    };
}

