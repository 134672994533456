import { makeAutoObservable, runInAction } from 'mobx';
import { StatusMessageEnum } from '@app/common';
import moment, { Moment } from 'moment/moment';
import type { RootStore } from '../root-store';
import { formatApiDate } from '../../functions/format-api-date';
// eslint-disable-next-line
import { type SymbolDetailStore } from './symbol-detail-store';
import { SymbolCloseIntervalTypeEnum } from '../../enums/symbol-close-interval-type-enum';
import { SymbolCloseIntervalInterface } from '../../interfaces/symbol-close-interval-interface';
import { TabEnum } from '../../enums/tab-enum';
import { AreaChartMobxDto } from '../../mobx/dtos/statistic/area-chart-mobx-dto';
import { SymbolControllerGetSymbolHistoryByIdRequest } from '../../../defs/api';

export const STATISTIC_INTERVALS: SymbolCloseIntervalInterface[] = [
    {
        title: 'Celou dobu',
        date: moment().set({ year: 2010 }).startOf('year'),
        type: SymbolCloseIntervalTypeEnum.AllTime,
    },
    {
        title: 'Tento rok',
        date: moment().startOf('year'),
        type: SymbolCloseIntervalTypeEnum.ThisYearBeginning,
    },
    {
        title: 'Posledních 12 měsíců',
        date: moment().subtract(12, 'months').startOf('day'),
        type: SymbolCloseIntervalTypeEnum.LastTwelveMonths,
    },
    {
        title: 'Posledních 30 dní',
        date: moment().subtract(30, 'day').startOf('day'),
        type: SymbolCloseIntervalTypeEnum.LastThirtyDays,
    },
    {
        title: 'Tento měsíc',
        date: moment().startOf('month'),
        type: SymbolCloseIntervalTypeEnum.ThisMonthBeginning,
    },
];

export class SymbolDetailChartStore {
    rootStore: RootStore;

    symbolDetailStore: SymbolDetailStore;

    percentageCloses: AreaChartMobxDto | null = null;

    absoluteCloses: AreaChartMobxDto | null = null;

    filter: Map<string, boolean> = new Map<string, boolean>();

    type: SymbolCloseIntervalTypeEnum = SymbolCloseIntervalTypeEnum.LastTwelveMonths;

    tab = TabEnum.Absolute;

    showChart: boolean = false;

    loading = true;

    constructor(symbolDetailStore: SymbolDetailStore) {
        this.symbolDetailStore = symbolDetailStore;
        this.rootStore = symbolDetailStore.rootStore;

        makeAutoObservable(this, { rootStore: false, symbolDetailStore: false });
    }

    get noChartData() {
        return this.absoluteCloses && this.absoluteCloses?.data.length === 0;
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    setShowChart(fetched: boolean = true) {
        this.showChart = fetched;
    }

    setTab(tab: TabEnum) {
        this.tab = tab;
    }

    get date(): Moment | null {
        const interval = STATISTIC_INTERVALS.find((si) => si.type === this.type);
        if (!interval) {
            throw new Error(StatusMessageEnum.SelectedIntervalNotFound);
        }
        return interval.date;
    }

    get chartDateFormat(): string {
        if (this.type === SymbolCloseIntervalTypeEnum.LastThirtyDays) {
            return process.env.NEXT_PUBLIC_DATE_FORMAT as string;
        }
        return process.env.NEXT_PUBLIC_DATE_YEAR_MONTH_FORMAT as string;
    }

    async setType(exchangeId: string, symbol: string, type: SymbolCloseIntervalTypeEnum): Promise<void> {
        if (this.rootStore.authStore.userHasFreeTariff && type !== SymbolCloseIntervalTypeEnum.LastThirtyDays) {
            this.rootStore.premiumDialogStore.open();
        } else {
            this.type = type;
            await this.fetchCloseChart(exchangeId, symbol);
        }
    }

    async fetchCloseChart(exchangeId: string, symbol: string): Promise<void> {
        this.absoluteCloses = null;
        this.percentageCloses = null;

        const parameters: SymbolControllerGetSymbolHistoryByIdRequest = { exchangeId, symbol };

        if (this.date) {
            parameters.dateFrom = formatApiDate(this.date);
        }

        this.startLoading();

        try {
            const fetchedCloseProfit = await this.rootStore.loadWithDelay(() => {
                return this.rootStore.apiClient.symbolController.symbolControllerGetSymbolHistoryById(parameters);
            });
            if (fetchedCloseProfit) {
                runInAction(() => {
                    this.absoluteCloses = AreaChartMobxDto.create(fetchedCloseProfit.absoluteClose);
                    this.percentageCloses = AreaChartMobxDto.create(fetchedCloseProfit.percentageClose);

                    this.filterSelected();
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    filterSelected(id?: string): void {
        if (id) {
            if (this.rootStore.authStore.userHasFreeTariff) {
                this.rootStore.premiumDialogStore.open();
            } else {
                const filterValue = this.filter.get(id);
                runInAction(() => {
                    this.filter.set(id, !filterValue);
                });
            }
        } else {
            if (this.absoluteCloses) {
                this.filter.set(this.absoluteCloses.id, true);
            }
            if (this.percentageCloses) {
                this.filter.set(this.percentageCloses.id, true);
            }
        }

        runInAction(() => {
            this.setShowChart(true);
        });
    }
}
