/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ShareTypeEnum {
    Disabled = 'Disabled',
    All = 'All'
}

export function ShareTypeEnumFromJSON(json: any): ShareTypeEnum {
    return ShareTypeEnumFromJSONTyped(json, false);
}

export function ShareTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareTypeEnum {
    return json as ShareTypeEnum;
}

export function ShareTypeEnumToJSON(value?: ShareTypeEnum | null): any {
    return value as any;
}

