/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EcomailUsersCountDto
 */
export interface EcomailUsersCountDto {
    /**
     * 
     * @type {number}
     * @memberof EcomailUsersCountDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof EcomailUsersCountDto
     */
    deleted: number;
    /**
     * 
     * @type {number}
     * @memberof EcomailUsersCountDto
     */
    premium: number;
    /**
     * 
     * @type {number}
     * @memberof EcomailUsersCountDto
     */
    free: number;
}

export function EcomailUsersCountDtoFromJSON(json: any): EcomailUsersCountDto {
    return EcomailUsersCountDtoFromJSONTyped(json, false);
}

export function EcomailUsersCountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EcomailUsersCountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'deleted': json['deleted'],
        'premium': json['premium'],
        'free': json['free'],
    };
}

export function EcomailUsersCountDtoToJSON(value?: EcomailUsersCountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'deleted': value.deleted,
        'premium': value.premium,
        'free': value.free,
    };
}

