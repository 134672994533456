/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PriceAlertDirectionEnum,
    PriceAlertDirectionEnumFromJSON,
    PriceAlertDirectionEnumFromJSONTyped,
    PriceAlertDirectionEnumToJSON,
} from './PriceAlertDirectionEnum';

/**
 * 
 * @export
 * @interface CreateSymbolPriceAlertDto
 */
export interface CreateSymbolPriceAlertDto {
    /**
     * 
     * @type {PriceAlertDirectionEnum}
     * @memberof CreateSymbolPriceAlertDto
     */
    direction: PriceAlertDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateSymbolPriceAlertDto
     */
    symbolId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSymbolPriceAlertDto
     */
    currentPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSymbolPriceAlertDto
     */
    targetPrice: number;
}

export function CreateSymbolPriceAlertDtoFromJSON(json: any): CreateSymbolPriceAlertDto {
    return CreateSymbolPriceAlertDtoFromJSONTyped(json, false);
}

export function CreateSymbolPriceAlertDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSymbolPriceAlertDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'direction': PriceAlertDirectionEnumFromJSON(json['direction']),
        'symbolId': json['symbolId'],
        'currentPrice': json['currentPrice'],
        'targetPrice': json['targetPrice'],
    };
}

export function CreateSymbolPriceAlertDtoToJSON(value?: CreateSymbolPriceAlertDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'direction': PriceAlertDirectionEnumToJSON(value.direction),
        'symbolId': value.symbolId,
        'currentPrice': value.currentPrice,
        'targetPrice': value.targetPrice,
    };
}

