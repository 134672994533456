/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorEnum,
    ColorEnumFromJSON,
    ColorEnumFromJSONTyped,
    ColorEnumToJSON,
} from './ColorEnum';
import {
    CurrencyEntity,
    CurrencyEntityFromJSON,
    CurrencyEntityFromJSONTyped,
    CurrencyEntityToJSON,
} from './CurrencyEntity';
import {
    IconNameEnum,
    IconNameEnumFromJSON,
    IconNameEnumFromJSONTyped,
    IconNameEnumToJSON,
} from './IconNameEnum';
import {
    PortfolioIntegrationEntity,
    PortfolioIntegrationEntityFromJSON,
    PortfolioIntegrationEntityFromJSONTyped,
    PortfolioIntegrationEntityToJSON,
} from './PortfolioIntegrationEntity';
import {
    ShareTypeEnum,
    ShareTypeEnumFromJSON,
    ShareTypeEnumFromJSONTyped,
    ShareTypeEnumToJSON,
} from './ShareTypeEnum';
import {
    UserEntity,
    UserEntityFromJSON,
    UserEntityFromJSONTyped,
    UserEntityToJSON,
} from './UserEntity';

/**
 * 
 * @export
 * @interface PortfolioEntity
 */
export interface PortfolioEntity {
    /**
     * 
     * @type {ShareTypeEnum}
     * @memberof PortfolioEntity
     */
    shareType: ShareTypeEnum;
    /**
     * 
     * @type {IconNameEnum}
     * @memberof PortfolioEntity
     */
    iconName: IconNameEnum;
    /**
     * 
     * @type {ColorEnum}
     * @memberof PortfolioEntity
     */
    colorName: ColorEnum;
    /**
     * 
     * @type {CurrencyEntity}
     * @memberof PortfolioEntity
     */
    currency: CurrencyEntity;
    /**
     * 
     * @type {PortfolioIntegrationEntity}
     * @memberof PortfolioEntity
     */
    integration: PortfolioIntegrationEntity;
    /**
     * 
     * @type {UserEntity}
     * @memberof PortfolioEntity
     */
    user: UserEntity;
    /**
     * 
     * @type {string}
     * @memberof PortfolioEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioEntity
     */
    shareSlug: string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioEntity
     */
    dividends: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioEntity
     */
    dividendsNextYearPrediction: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioEntity
     */
    dividendsYield: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioEntity
     */
    dividendsYieldOnCost: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioEntity
     */
    numberOfTrades: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioEntity
     */
    numberOfAssets: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioEntity
     */
    currencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioEntity
     */
    userId: string;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioEntity
     */
    selected: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioEntity
     */
    isRecalculating: boolean;
    /**
     * 
     * @type {string}
     * @memberof PortfolioEntity
     */
    portfolioHistoryCurrencyCode: string;
    /**
     * 
     * @type {Date}
     * @memberof PortfolioEntity
     */
    lastUpdated: Date;
    /**
     * 
     * @type {Date}
     * @memberof PortfolioEntity
     */
    recalculationStartDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PortfolioEntity
     */
    recalculationEndDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioEntity
     */
    deleted: boolean;
}

export function PortfolioEntityFromJSON(json: any): PortfolioEntity {
    return PortfolioEntityFromJSONTyped(json, false);
}

export function PortfolioEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shareType': ShareTypeEnumFromJSON(json['shareType']),
        'iconName': IconNameEnumFromJSON(json['iconName']),
        'colorName': ColorEnumFromJSON(json['colorName']),
        'currency': CurrencyEntityFromJSON(json['currency']),
        'integration': PortfolioIntegrationEntityFromJSON(json['integration']),
        'user': UserEntityFromJSON(json['user']),
        'id': json['id'],
        'name': json['name'],
        'shareSlug': json['shareSlug'],
        'dividends': json['dividends'],
        'dividendsNextYearPrediction': json['dividendsNextYearPrediction'],
        'dividendsYield': json['dividendsYield'],
        'dividendsYieldOnCost': json['dividendsYieldOnCost'],
        'numberOfTrades': json['numberOfTrades'],
        'numberOfAssets': json['numberOfAssets'],
        'currencyCode': json['currencyCode'],
        'userId': json['userId'],
        'selected': json['selected'],
        'isRecalculating': json['isRecalculating'],
        'portfolioHistoryCurrencyCode': json['portfolioHistoryCurrencyCode'],
        'lastUpdated': (new Date(json['lastUpdated'])),
        'recalculationStartDate': (new Date(json['recalculationStartDate'])),
        'recalculationEndDate': (json['recalculationEndDate'] === null ? null : new Date(json['recalculationEndDate'])),
        'deleted': json['deleted'],
    };
}

export function PortfolioEntityToJSON(value?: PortfolioEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shareType': ShareTypeEnumToJSON(value.shareType),
        'iconName': IconNameEnumToJSON(value.iconName),
        'colorName': ColorEnumToJSON(value.colorName),
        'currency': CurrencyEntityToJSON(value.currency),
        'integration': PortfolioIntegrationEntityToJSON(value.integration),
        'user': UserEntityToJSON(value.user),
        'id': value.id,
        'name': value.name,
        'shareSlug': value.shareSlug,
        'dividends': value.dividends,
        'dividendsNextYearPrediction': value.dividendsNextYearPrediction,
        'dividendsYield': value.dividendsYield,
        'dividendsYieldOnCost': value.dividendsYieldOnCost,
        'numberOfTrades': value.numberOfTrades,
        'numberOfAssets': value.numberOfAssets,
        'currencyCode': value.currencyCode,
        'userId': value.userId,
        'selected': value.selected,
        'isRecalculating': value.isRecalculating,
        'portfolioHistoryCurrencyCode': value.portfolioHistoryCurrencyCode,
        'lastUpdated': (value.lastUpdated.toISOString()),
        'recalculationStartDate': (value.recalculationStartDate.toISOString()),
        'recalculationEndDate': (value.recalculationEndDate === null ? null : value.recalculationEndDate.toISOString()),
        'deleted': value.deleted,
    };
}

