export const createBreakpoints = () => {
    const sizes = {
        zero: 0,
        xs: 320,
        vs: 360,
        sm: 480,
        md: 600,
        lg: 769,
        vl: 1024,
        xl: 1240,
        xxl: 1420,
        fullhd: 1920,
    } as const;

    const breakpoints = {
        zero: {
            value: sizes.zero,
            min: `(min-width: ${sizes.zero}px)`,
            max: `(max-width: ${sizes.zero}px)`,
        },
        xs: {
            value: sizes.xs,
            min: `(min-width: ${sizes.xs}px)`,
            max: `(max-width: ${sizes.xs - 1}px)`,
        },
        vs: {
            value: sizes.vs,
            min: `(min-width: ${sizes.vs}px)`,
            max: `(max-width: ${sizes.vs - 1}px)`,
        },
        sm: {
            value: sizes.sm,
            min: `(min-width: ${sizes.sm}px)`,
            max: `(max-width: ${sizes.sm - 1}px)`,
        },
        md: {
            value: sizes.md,
            min: `(min-width: ${sizes.md}px)`,
            max: `(max-width: ${sizes.md - 1}px)`,
        },
        lg: {
            value: sizes.lg,
            min: `(min-width: ${sizes.lg}px)`,
            max: `(max-width: ${sizes.lg - 1}px)`,
        },
        vl: {
            value: sizes.vl,
            min: `(min-width: ${sizes.vl}px)`,
            max: `(max-width: ${sizes.vl - 1}px)`,
        },
        xl: {
            value: sizes.xl,
            min: `(min-width: ${sizes.xl}px)`,
            max: `(max-width: ${sizes.xl - 1}px)`,
        },
        xxl: {
            value: sizes.xxl,
            min: `(min-width: ${sizes.xxl}px)`,
            max: `(max-width: ${sizes.xxl - 1}px)`,
        },
        fullhd: {
            value: sizes.fullhd,
            min: `(min-width: ${sizes.fullhd}px)`,
            max: `(max-width: ${sizes.fullhd - 1}px)`,
        },
    };

    return breakpoints;
};
