import React from 'react';
import { Wrapper } from './container.styled';

interface ContainerPropsInterface {
    children: React.ReactNode;
}

export const Container: React.FC<ContainerPropsInterface> = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};
