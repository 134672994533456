import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from '../../root-store';
import { WaitingUserMobxDto } from '../../../mobx/dtos/admin/user/waiting-user-mobx-dto';
import { UserControllerGetWaitingRequest } from '../../../../defs/api';

export type WaitingUsersStoreHydration = {
    waitingUsers: WaitingUserMobxDto[];
    totalWaitingUsersCount: number;
};

export class WaitingUsersStore {
    rootStore: RootStore;

    loading = false;

    totalWaitingUsersCount = 0;

    waitingUsers: WaitingUserMobxDto[] = [];

    waitingUsersPage = 1;

    limit = Number(process.env.NEXT_PUBLIC_PAGE_LIMIT);

    filterPhrase = '';

    userDeleteInProgress = false;

    order = 0;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    async activateUsers(): Promise<void> {
        try {
            this.setIsLoading(true);
            const userIds: string[] = Array.from(
                this.waitingUsers.filter((user) => user.isSelected).map((selUser) => selUser.id || ''),
            );

            const res = await this.rootStore.apiClient.userController.userControllerActivate({
                userActivateDto: { userIds },
            });
            if (res) {
                runInAction(() => {
                    this.fetchWaitingUserList();
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.setIsLoading(false);
        }
    }

    async fetchWaitingUserList(): Promise<void> {
        try {
            this.setIsLoading(true);
            const query: UserControllerGetWaitingRequest = {
                limit: this.limit,
                offset: (this.waitingUsersPage - 1) * this.limit,
                phrase: this.filterPhrase,
            };

            const fetchedWaitingUsers = await this.rootStore.apiClient.userController.userControllerGetWaiting(query);

            if (fetchedWaitingUsers) {
                runInAction(() => {
                    this.waitingUsers = WaitingUserMobxDto.createFromArray(fetchedWaitingUsers.items);
                    this.totalWaitingUsersCount = fetchedWaitingUsers.total;
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.setIsLoading(false);
        }
    }

    setIsLoading(loading: boolean): void {
        this.loading = loading;
    }

    setUserDeleteInProgress(userDeleteInProgress: boolean): void {
        this.userDeleteInProgress = userDeleteInProgress;
    }

    async setWaitingPage(page: number): Promise<void> {
        this.waitingUsersPage = page;
        await this.fetchWaitingUserList();
    }

    get waitingPagesCount(): number {
        return Math.ceil(this.totalWaitingUsersCount / this.limit);
    }

    get hasWaitingUsers(): boolean {
        return this.waitingUsers.length > 0;
    }

    setAllUsersSelection(checked: boolean): void {
        this.waitingUsers.forEach((user) => {
            user.setSelected(checked);
        });
    }

    get isPageSelected(): boolean {
        return this.waitingUsers.filter((user) => !user.isSelected).length < 1;
    }

    get selectedUsersCount(): number {
        return this.waitingUsers.filter((u) => u.isSelected).length;
    }

    setPhraseFilter(phrase: string) {
        this.filterPhrase = phrase;
        this.fetchWaitingUserList();
    }

    switchUserSelected(user: WaitingUserMobxDto) {
        this.waitingUsers.find((waitingUser) => waitingUser.id === user.id)?.switchSelected();
    }

    async deleteUser(userId: string): Promise<void> {
        this.setUserDeleteInProgress(true);
        try {
            const res = await this.rootStore.apiClient.userController.userControllerDeleteUser({ userId });
            if (res) {
                await this.fetchWaitingUserList();
            }
        } finally {
            // errors handle in component
            this.setUserDeleteInProgress(false);
        }
    }

    async getOrder(): Promise<void> {
        try {
            this.setIsLoading(true);

            const order = await this.rootStore.apiClient.userController.userControllerGetWaitingOrder();
            if (order) {
                runInAction(() => {
                    this.order = order;
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.setIsLoading(false);
        }
    }
}
