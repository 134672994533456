/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TradeListPaginatedDto,
    TradeListPaginatedDtoFromJSON,
    TradeListPaginatedDtoFromJSONTyped,
    TradeListPaginatedDtoToJSON,
} from './TradeListPaginatedDto';
import {
    TradeOverviewDto,
    TradeOverviewDtoFromJSON,
    TradeOverviewDtoFromJSONTyped,
    TradeOverviewDtoToJSON,
} from './TradeOverviewDto';

/**
 * 
 * @export
 * @interface TradeListOverviewDto
 */
export interface TradeListOverviewDto {
    /**
     * 
     * @type {TradeListPaginatedDto}
     * @memberof TradeListOverviewDto
     */
    tradeListSortDto: TradeListPaginatedDto;
    /**
     * 
     * @type {TradeOverviewDto}
     * @memberof TradeListOverviewDto
     */
    overview: TradeOverviewDto;
}

export function TradeListOverviewDtoFromJSON(json: any): TradeListOverviewDto {
    return TradeListOverviewDtoFromJSONTyped(json, false);
}

export function TradeListOverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeListOverviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tradeListSortDto': TradeListPaginatedDtoFromJSON(json['tradeListSortDto']),
        'overview': TradeOverviewDtoFromJSON(json['overview']),
    };
}

export function TradeListOverviewDtoToJSON(value?: TradeListOverviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tradeListSortDto': TradeListPaginatedDtoToJSON(value.tradeListSortDto),
        'overview': TradeOverviewDtoToJSON(value.overview),
    };
}

