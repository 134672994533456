import Link from 'next/link';
import React from 'react';

import { ButtonControl, ButtonControlPropsInterface } from './button-control';

export type ButtonPropsInterface = ButtonControlPropsInterface & {
    nativeLink?: boolean;
    disableResponsive?: boolean;
    target?: string;
};

export const Button: React.FC<ButtonPropsInterface> = ({
    href,
    nativeLink = false,
    children,
    variant = 'default',
    size = 'medium',
    variation = 'filled',
    loading,
    form,
    disabled,
    icon,
    iconPosition = 'right',
    iconGap = 'medium',
    id,
    onClick,
    block,
    type = 'submit',
    gradient = false,
    shadow = false,
    hideLabelUnder,
    hideLabelOver,
    disableResponsive,
    as,
    target,
}) => {
    const ButtonStyled = (
        <ButtonControl
            variant={variant}
            size={size}
            loading={loading}
            disabled={disabled}
            id={id}
            icon={icon}
            iconPosition={iconPosition}
            iconGap={iconGap}
            onClick={onClick}
            block={block}
            form={form}
            variation={variation}
            type={type}
            gradient={variant === 'primary' ? true : gradient}
            shadow={variant === 'primary' ? true : shadow}
            hideLabelUnder={hideLabelUnder}
            hideLabelOver={hideLabelOver}
            disableResponsive={disableResponsive}
            as={as}
        >
            {children}
        </ButtonControl>
    );

    if (!href) {
        return ButtonStyled;
    }

    if (nativeLink) {
        return (
            <a href={href} target={target}>
                {ButtonStyled}
            </a>
        );
    }

    return (
        <Link href={href} passHref>
            <a target={target}>{ButtonStyled}</a>
        </Link>
    );
};
