import { makeAutoObservable } from 'mobx';
import type { RootStore } from './root-store';
import { CookieService } from '../services/cookie-service';

export class PrivateStore {
    rootStore: RootStore;

    isPrivateModeEnabled: boolean = false;

    private readonly cookieService: CookieService;

    constructor(rootStore: RootStore, cookieService: CookieService) {
        this.rootStore = rootStore;
        this.cookieService = cookieService;
        this.isPrivateModeEnabled = this.cookieService.isPrivateModeEnabled();

        makeAutoObservable(this);
    }

    togglePrivateModeEnabled(): void {
        this.isPrivateModeEnabled = !this.isPrivateModeEnabled;
        this.cookieService.togglePrivateModeEnabled();
    }
}
