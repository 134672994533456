import { CookieService } from './services/cookie-service';
import { isStringArray } from '../utils/typeguards/is-string-array';
import { JsonError } from '../errors/json-error';

export interface IParameters {
    [key: string]: string[] | string | boolean | number | object;
}

export interface IApiOptions<R> {
    method?: string;
    query?: IParameters;
    headers?: HeadersInit;
    data?: R | Record<string, unknown> | FormData;
    signal?: AbortSignal;
}

export class Api {
    host: string;

    cookieService: CookieService;

    constructor(host: string, cookieService: CookieService) {
        this.host = host;
        this.cookieService = cookieService;
    }

    public async fetch<T, R = void>(endpoint: string, options: IApiOptions<R> = {}): Promise<T | null> {
        const url = `/${endpoint}`;
        const fullUrl = `${this.host}${this.generateUrl(url, options.query)}`;
        const method = options.method ?? 'GET';

        // eslint-disable-next-line no-console
        console.log(`${method} ${fullUrl}`);

        const isClient = typeof window !== 'undefined';
        const body = isClient && options.data instanceof FormData ? options.data : JSON.stringify(options.data);
        const ops: RequestInit = {
            method,
            headers: { ...this.getHeaders<R>(options), ...options.headers },
            body,
            signal: options.signal,
        };
        const data = await fetch(fullUrl, ops);
        if (data.status === 204) {
            return null;
        }

        let json;

        try {
            json = await data.json();
        } catch (e) {
            throw new JsonError();
        }

        if (data.status !== 200 && data.status !== 201 && data.status !== 202) {
            if (json && json.message) {
                throw new Error(json.message);
            }
            throw new Error(await data.text());
        }

        return json;
    }

    private getHeaders<R>(options: IApiOptions<R>): HeadersInit {
        const headers: HeadersInit = {};

        if (this.cookieService.getJwtToken()) {
            headers.Authorization = `Bearer ${this.cookieService.getJwtToken()}`;
        }

        if (typeof window === 'undefined' || !(options.data instanceof FormData)) {
            headers['Content-Type'] = 'application/json';
        }
        headers['Access-Control-Allow-Origin'] = '*';
        headers['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
        headers['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token';

        return headers;
    }

    private generateUrl(url: string, query: IParameters = {}): string {
        if (Object.keys(query).length > 0) {
            const joinChar = url.indexOf('?') === -1 ? '?' : '&';
            return `${url}${joinChar}${this.getQueryParams(query)}`;
        }
        return url;
    }

    private getQueryParams(query: IParameters): string {
        return Object.keys(query)
            .map((k) => {
                const queryObject = query[k];
                if (isStringArray(queryObject)) {
                    return queryObject
                        .map((queryParam: string) => {
                            return `${encodeURIComponent(k)}=${encodeURIComponent(queryParam)}`;
                        })
                        .join('&');
                }
                if (typeof queryObject === 'object') {
                    return Object.entries(queryObject)
                        .map((entry) => {
                            if (entry[0]) {
                                return `${encodeURIComponent(k)}[${encodeURIComponent(entry[0])}]=${encodeURIComponent(
                                    entry[1] || '',
                                )}`;
                            }
                            return null;
                        })
                        .join('&');
                }
                return `${encodeURIComponent(k)}=${encodeURIComponent(queryObject)}`;
            })
            .join('&');
    }
}
