import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from '../root-store';
import type { StatisticStore } from './statistic-store';
import { formatApiDate } from '../../functions/format-api-date';
import { TabEnumAllocations } from '../../enums/tab-enum-allocations';
import { isEnumValue } from '../../../utils/typeguards/is-enum-value';
import { UnrealizedAllocationsOverviewMobxDto } from '../../mobx/dtos/statistic/unrealized-allocations-overview-mobx-dto';
import { UnrealizedAllocationMobxDto } from '../../mobx/dtos/statistic/unrealized-allocation-mobx-dto';
import { RealizedAllocationMobxDto } from '../../mobx/dtos/statistic/realized-allocation-mobx-dto';
import { RankingsAllocationMobxDto } from '../../mobx/dtos/statistic/rankings-allocation-mobx-dto';
import { PieChartMobxDto } from '../../mobx/dtos/statistic/pie-chart-mobx-dto';
import { OrderEnum, StatisticControllerGetRatiosRequest } from '../../../defs/api';

export const UNREALIZED_ALLOCATIONS_LIMIT = 5;
export const REALIZED_ALLOCATIONS_LIMIT = 5;
export const REALIZED_ALLOCATIONS_LIMIT_FREE = 3;

export type SortModel = {
    weight?: OrderEnum | '';
    invested?: OrderEnum | '';
    value?: OrderEnum | '';
    currencyImpactValue?: OrderEnum | '';
    profitValue?: OrderEnum | '';
};

export type RatiosStoreHydration = {
    symbolTypeRatio: PieChartMobxDto;
    currencyRatio: PieChartMobxDto;
    allocationTypeRatio: PieChartMobxDto;
    allAllocations: UnrealizedAllocationMobxDto[];
};

const DEFAULT_SORT_MODEL: SortModel = {
    weight: OrderEnum.Desc,
};

export class RatiosStore {
    rootStore: RootStore;

    statisticStore: StatisticStore;

    symbolTypeRatio: PieChartMobxDto | null = null;

    currencyRatio: PieChartMobxDto | null = null;

    sectorRatio: PieChartMobxDto | null = null;

    countryRatio: PieChartMobxDto | null = null;

    allocationTypeRatio: PieChartMobxDto | null = null;

    allUnrealizedAllocations: UnrealizedAllocationMobxDto[] = [];

    allRealizedAllocations: RealizedAllocationMobxDto[] = [];

    rankingsAllocations: RankingsAllocationMobxDto = { gainers: [], losers: [] };

    unrealizedAllocationsOverview: UnrealizedAllocationsOverviewMobxDto | null = null;

    isUnrealizedAllocationsExpanded: boolean = true;

    isRealizedAllocationsExpanded: boolean = true;

    currentTab: TabEnumAllocations = TabEnumAllocations.Unrealized;

    loading = true;

    sortModel: SortModel = {};

    constructor(statisticStore: StatisticStore) {
        this.statisticStore = statisticStore;
        this.rootStore = statisticStore.rootStore;
        this.sortModel = DEFAULT_SORT_MODEL;

        makeAutoObservable(this);
    }

    get hasRatios() {
        return (
            (this.symbolTypeRatio && this.symbolTypeRatio.data.length > 0) ||
            (this.currencyRatio && this.currencyRatio.data.length > 0) ||
            (this.sectorRatio && this.sectorRatio.data.length > 0) ||
            (this.countryRatio && this.countryRatio.data.length > 0) ||
            (this.allocationTypeRatio && this.allocationTypeRatio.data.length > 0)
        );
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async fetchRatios(): Promise<void> {
        const portfolioId = this.rootStore.portfolioStore.selectedPortfolio.id;

        const parameters: StatisticControllerGetRatiosRequest = {
            portfolioId,
        };

        if (this.statisticStore.dateFrom) {
            parameters.dateFrom = formatApiDate(this.statisticStore.dateFrom);
        }

        if (this.statisticStore.dateTo) {
            parameters.dateTo = formatApiDate(this.statisticStore.dateTo);
        }

        this.startLoading();

        try {
            const fetchedRatios = await this.rootStore.loadWithDelay(() => {
                const isSharedPortfolio = this.rootStore.portfolioStore.sharedPortfolio !== null;
                return isSharedPortfolio
                    ? this.rootStore.apiClient.statisticsController.sharedStatisticControllerGetRatios(parameters)
                    : this.rootStore.apiClient.statisticsController.statisticControllerGetRatios(parameters);
            });

            if (fetchedRatios) {
                runInAction(() => {
                    if (!this.hasRatios) {
                        this.symbolTypeRatio = PieChartMobxDto.create(fetchedRatios.symbolTypeRatio);
                        this.currencyRatio = PieChartMobxDto.create(fetchedRatios.currencyRatio);
                        this.sectorRatio = PieChartMobxDto.create(fetchedRatios.sectorRatio);
                        this.countryRatio = PieChartMobxDto.create(fetchedRatios.countryRatio);
                        this.allocationTypeRatio = PieChartMobxDto.create(fetchedRatios.allocationTypeRatio);
                    }
                    this.allUnrealizedAllocations = UnrealizedAllocationMobxDto.createFromArray(
                        fetchedRatios.allocations,
                    );
                    this.allRealizedAllocations = RealizedAllocationMobxDto.createFromArray(
                        fetchedRatios.realizedAllocations,
                    );
                    this.rankingsAllocations = {
                        gainers: UnrealizedAllocationMobxDto.createFromArray(fetchedRatios.gainersRanking),
                        losers: UnrealizedAllocationMobxDto.createFromArray(fetchedRatios.losersRanking),
                    };

                    this.unrealizedAllocationsOverview = UnrealizedAllocationsOverviewMobxDto.create(
                        fetchedRatios.unrealizedAllocationsOverview,
                    );
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    toggleAllocationsExpanded(): void {
        if (this.currentTab === TabEnumAllocations.Unrealized) {
            this.isUnrealizedAllocationsExpanded = !this.isUnrealizedAllocationsExpanded;
        }

        if (this.currentTab === TabEnumAllocations.Realized) {
            this.isRealizedAllocationsExpanded = !this.isRealizedAllocationsExpanded;
        }
    }

    get allUnrealizedAllocationsCount(): number {
        return this.allUnrealizedAllocations.length;
    }

    get allRealizedAllocationsCount(): number {
        return this.allRealizedAllocations.length;
    }

    get unrealizedAllocations(): UnrealizedAllocationMobxDto[] {
        return this.sortedUnrealizedAllocations();
    }

    get realizedAllocations(): RealizedAllocationMobxDto[] {
        return this.sortedRealizedAllocations();
    }

    get canDisplayMore(): boolean {
        if (this.currentTab === TabEnumAllocations.Unrealized) {
            return this.allUnrealizedAllocationsCount > UNREALIZED_ALLOCATIONS_LIMIT;
        }

        if (this.currentTab === TabEnumAllocations.Realized) {
            return (
                this.allRealizedAllocationsCount >
                (this.rootStore.authStore.userHasFreeTariff
                    ? REALIZED_ALLOCATIONS_LIMIT_FREE
                    : REALIZED_ALLOCATIONS_LIMIT)
            );
        }
        return false;
    }

    setTab(tab: TabEnumAllocations) {
        this.currentTab = tab;
    }

    setSortModel(sortModel: { field: keyof SortModel; method: OrderEnum | null }): void {
        this.sortModel = {};
        if (sortModel.method) {
            this.sortModel[sortModel.field] = sortModel.method;
        } else {
            this.sortModel = DEFAULT_SORT_MODEL;
        }
    }

    sortedUnrealizedAllocations(): UnrealizedAllocationMobxDto[] {
        return (
            this.isUnrealizedAllocationsExpanded
                ? [...this.allUnrealizedAllocations].slice(0, UNREALIZED_ALLOCATIONS_LIMIT)
                : [...this.allUnrealizedAllocations]
        ).sort((a, b) => {
            return (
                Object.entries(this.sortModel)
                    .map((sortColumn) => {
                        if (sortColumn[0] in a && isEnumValue(OrderEnum, sortColumn[1])) {
                            if (sortColumn[1] === OrderEnum.Desc) {
                                return a[sortColumn[0] as keyof UnrealizedAllocationMobxDto] <
                                    b[sortColumn[0] as keyof UnrealizedAllocationMobxDto]
                                    ? 1
                                    : -1;
                            }
                            return a[sortColumn[0] as keyof UnrealizedAllocationMobxDto] <
                                b[sortColumn[0] as keyof UnrealizedAllocationMobxDto]
                                ? -1
                                : 1;
                        }
                        return 1;
                    })
                    .pop() || 1
            );
        });
    }

    sortedRealizedAllocations(): RealizedAllocationMobxDto[] {
        return (
            this.isRealizedAllocationsExpanded
                ? [...this.allRealizedAllocations].slice(
                      0,
                      this.rootStore.authStore.userHasFreeTariff
                          ? REALIZED_ALLOCATIONS_LIMIT_FREE
                          : REALIZED_ALLOCATIONS_LIMIT,
                  )
                : [...this.allRealizedAllocations]
        ).sort((a, b) => {
            return (
                Object.entries(this.sortModel)
                    .map((sortColumn) => {
                        if (sortColumn[0] in a && isEnumValue(OrderEnum, sortColumn[1])) {
                            if (sortColumn[1] === OrderEnum.Desc) {
                                return a[sortColumn[0] as keyof RealizedAllocationMobxDto] <
                                    b[sortColumn[0] as keyof RealizedAllocationMobxDto]
                                    ? 1
                                    : -1;
                            }
                            return a[sortColumn[0] as keyof RealizedAllocationMobxDto] <
                                b[sortColumn[0] as keyof RealizedAllocationMobxDto]
                                ? -1
                                : 1;
                        }
                        return 1;
                    })
                    .pop() || 1
            );
        });
    }

    getFieldSortMethod(field: keyof SortModel): OrderEnum | undefined | '' {
        if (this.sortModel && this.sortModel[field]) {
            return this.sortModel[field];
        }
        return undefined;
    }

    get isExpanded(): boolean {
        if (this.currentTab === TabEnumAllocations.Unrealized) {
            return this.isUnrealizedAllocationsExpanded;
        }

        if (this.currentTab === TabEnumAllocations.Realized) {
            return this.isRealizedAllocationsExpanded;
        }
        return false;
    }
}
