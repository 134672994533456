/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PieChartDataDto,
    PieChartDataDtoFromJSON,
    PieChartDataDtoFromJSONTyped,
    PieChartDataDtoToJSON,
} from './PieChartDataDto';

/**
 * 
 * @export
 * @interface PieChartDto
 */
export interface PieChartDto {
    /**
     * 
     * @type {Array<PieChartDataDto>}
     * @memberof PieChartDto
     */
    data: Array<PieChartDataDto>;
}

export function PieChartDtoFromJSON(json: any): PieChartDto {
    return PieChartDtoFromJSONTyped(json, false);
}

export function PieChartDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PieChartDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(PieChartDataDtoFromJSON)),
    };
}

export function PieChartDtoToJSON(value?: PieChartDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(PieChartDataDtoToJSON)),
    };
}

