import styled, { css, DefaultTheme } from 'styled-components';
import { BreakpointSizeType } from '../../../../../theme/type/breakpoints-type';
import { ButtonSizeType, ButtonVariantType, ButtonVariationType } from '../../../../../theme/type/button-type';
import { GapSizeType } from '../../../../../theme/type/gap-type';

const getResponsiveIconWrapperSize = (size: object, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        if (index === 0) {
            return `
                @media ${theme.breakpoints[item[0] as BreakpointSizeType].max} {
                    width: ${theme.button.size[item[1] as ButtonSizeType].icon.wrapper};
                    height: ${theme.button.size[item[1] as ButtonSizeType].icon.wrapper};

                    svg {
                        width: ${theme.button.size[item[1] as ButtonSizeType].icon.size} !important;
                        height: ${theme.button.size[item[1] as ButtonSizeType].icon.size} !important;
                    }
                }

                @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                    width: ${theme.button.size[item[1] as ButtonSizeType].icon.wrapper};
                    height: ${theme.button.size[item[1] as ButtonSizeType].icon.wrapper};

                    svg {
                        width: ${theme.button.size[item[1] as ButtonSizeType].icon.size} !important;
                        height: ${theme.button.size[item[1] as ButtonSizeType].icon.size} !important;
                    }
                }
            `;
        }
        return `
            @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                width: ${theme.button.size[item[1] as ButtonSizeType].icon.wrapper};
                height: ${theme.button.size[item[1] as ButtonSizeType].icon.wrapper};

                svg {
                    width: ${theme.button.size[item[1] as ButtonSizeType].icon.size} !important;
                    height: ${theme.button.size[item[1] as ButtonSizeType].icon.size} !important;
                }
            }
        `;
    });
};

export const Wrapper = styled.span.withConfig({
    shouldForwardProp: (prop) =>
        !['variant', 'size', 'hideLabelUnder', 'hideLabelOver', 'variation', 'gap', 'position'].includes(prop),
})<{
    variant: ButtonVariantType;
    size: ButtonSizeType | object;
    hideLabelUnder?: BreakpointSizeType;
    hideLabelOver?: BreakpointSizeType;
    variation: ButtonVariationType;
    gap: GapSizeType;
    position?: 'left' | 'right';
}>`
    ${({ theme, variant, variation, size, hideLabelUnder, hideLabelOver, position, gap, onClick }) => css`
        display: inline-flex;
        position: relative;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        color: ${theme.button.variant[variant][variation].base.icon.color};
        background: transparent;
        cursor: ${onClick ? 'pointer' : 'unset'};

        & > * {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        svg {
            align-items: center;
            display: inline-flex;
            height: inherit;
            stroke-width: 0;
            transition: color ${theme.base.transition};
            width: inherit;
            fill: transparent;
            margin: unset;
        }

        ${typeof size === 'string' &&
        css`
            width: ${theme.button.size[size].icon.wrapper};
            height: ${theme.button.size[size].icon.wrapper};

            svg {
                width: ${theme.button.size[size].icon.size} !important;
                height: ${theme.button.size[size].icon.size} !important;
            }
        `}

        ${typeof size === 'object' &&
        css`
            ${getResponsiveIconWrapperSize(size, theme)};
        `}

        ${!hideLabelUnder &&
        !hideLabelOver &&
        css`
            background: ${theme.button.variant[variant][variation].base.icon.background};
        `}

        ${hideLabelUnder &&
        css`
            @media ${theme.breakpoints[hideLabelUnder].min} {
                background: ${theme.button.variant[variant][variation].base.icon.background};

                ${typeof size === 'string' &&
                css`
                    svg {
                        width: ${theme.button.size[size].icon.size} !important;
                        height: ${theme.button.size[size].icon.size} !important;
                    }
                `}
            }

            @media ${theme.breakpoints[hideLabelUnder].max} {
                ${typeof size === 'string' &&
                css`
                    svg {
                        width: calc(${theme.button.size[size].icon.size} + 0.4rem) !important;
                        height: calc(${theme.button.size[size].icon.size} + 0.4rem) !important;
                    }
                `}
            }
        `}

        ${hideLabelOver &&
        css`
            @media ${theme.breakpoints[hideLabelOver].max} {
                background: ${theme.button.variant[variant][variation].base.icon.background};

                ${typeof size === 'string' &&
                css`
                    svg {
                        width: ${theme.button.size[size].icon.size} !important;
                        height: ${theme.button.size[size].icon.size} !important;
                    }
                `}
            }

            @media ${theme.breakpoints[hideLabelOver].min} {
                ${typeof size === 'string' &&
                css`
                    svg {
                        width: calc(${theme.button.size[size].icon.size} + 0.4rem) !important;
                        height: calc(${theme.button.size[size].icon.size} + 0.4rem) !important;
                    }
                `}
            }
        `}


        ${position === 'right' &&
        css`
            ${!hideLabelUnder &&
            !hideLabelOver &&
            css`
                margin-left: ${theme.gap[gap]};
            `}

            ${hideLabelUnder &&
            css`
                @media ${theme.breakpoints[hideLabelUnder].min} {
                    margin-left: ${theme.gap[gap]};
                }
            `}

            ${hideLabelOver &&
            css`
                @media ${theme.breakpoints[hideLabelOver].max} {
                    margin-left: ${theme.gap[gap]};
                }
            `}
        `}

        ${position === 'left' &&
        css`
            order: -1;

            ${!hideLabelUnder &&
            !hideLabelOver &&
            css`
                margin-right: ${theme.gap[gap]};
            `}

            ${hideLabelUnder &&
            css`
                @media ${theme.breakpoints[hideLabelUnder].min} {
                    margin-right: ${theme.gap[gap]};
                }
            `}

            ${hideLabelOver &&
            css`
                @media ${theme.breakpoints[hideLabelOver].max} {
                    margin-right: ${theme.gap[gap]};
                }
            `}
        `}
    `}
`;
