import { makeAutoObservable } from 'mobx';
import { SymbolDividendDto, SymbolTypeEnum } from '../../../../defs/api';

export class DividendMobxDto implements SymbolDividendDto {
    public id: string;

    public symbolId: string = '';

    public symbolCode: string = '';

    public symbolCurrency: string = '';

    public symbolName: string = '';

    public symbolType: SymbolTypeEnum = SymbolTypeEnum.Cs;

    public currency: string = '';

    public recordDate?: Date = new Date();

    public paymentDate?: Date = new Date();

    public date: Date = new Date();

    public exchangeId: string = '';

    public exchangeName: string = '';

    public region: string = '';

    public quantity: number = 0;

    public amount: number = 0;

    public amountInSymbolCurrency: number = 0;

    public amountPerUnit: number = 0;

    public amountPerUnitInSymbolCurrency: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolDividendDto): DividendMobxDto {
        const mobxDto = new DividendMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: SymbolDividendDto[]): DividendMobxDto[] {
        return dtos.map((dto) => DividendMobxDto.create(dto));
    }
}
