/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DividendCalendarMonthDto
 */
export interface DividendCalendarMonthDto {
    /**
     * 
     * @type {string}
     * @memberof DividendCalendarMonthDto
     */
    month: string;
    /**
     * 
     * @type {number}
     * @memberof DividendCalendarMonthDto
     */
    amount: number;
    /**
     * 
     * @type {boolean}
     * @memberof DividendCalendarMonthDto
     */
    isFutureDividend: boolean;
}

export function DividendCalendarMonthDtoFromJSON(json: any): DividendCalendarMonthDto {
    return DividendCalendarMonthDtoFromJSONTyped(json, false);
}

export function DividendCalendarMonthDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DividendCalendarMonthDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': json['month'],
        'amount': json['amount'],
        'isFutureDividend': json['isFutureDividend'],
    };
}

export function DividendCalendarMonthDtoToJSON(value?: DividendCalendarMonthDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'amount': value.amount,
        'isFutureDividend': value.isFutureDividend,
    };
}

