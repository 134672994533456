/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaitingUserDto
 */
export interface WaitingUserDto {
    /**
     * 
     * @type {number}
     * @memberof WaitingUserDto
     */
    daysSinceRegistration: number;
    /**
     * 
     * @type {string}
     * @memberof WaitingUserDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WaitingUserDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof WaitingUserDto
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof WaitingUserDto
     */
    registrationDate: Date;
}

export function WaitingUserDtoFromJSON(json: any): WaitingUserDto {
    return WaitingUserDtoFromJSONTyped(json, false);
}

export function WaitingUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaitingUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'daysSinceRegistration': json['daysSinceRegistration'],
        'id': json['id'],
        'fullName': json['fullName'],
        'email': json['email'],
        'registrationDate': (new Date(json['registrationDate'])),
    };
}

export function WaitingUserDtoToJSON(value?: WaitingUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'daysSinceRegistration': value.daysSinceRegistration,
        'id': value.id,
        'fullName': value.fullName,
        'email': value.email,
        'registrationDate': (value.registrationDate.toISOString()),
    };
}

