/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LeaderboardSymbolDto,
    LeaderboardSymbolDtoFromJSON,
    LeaderboardSymbolDtoFromJSONTyped,
    LeaderboardSymbolDtoToJSON,
} from './LeaderboardSymbolDto';

/**
 * 
 * @export
 * @interface LeaderboardDto
 */
export interface LeaderboardDto {
    /**
     * 
     * @type {Array<LeaderboardSymbolDto>}
     * @memberof LeaderboardDto
     */
    mostPurchasedSymbols: Array<LeaderboardSymbolDto>;
    /**
     * 
     * @type {Array<LeaderboardSymbolDto>}
     * @memberof LeaderboardDto
     */
    trendingSymbols: Array<LeaderboardSymbolDto>;
    /**
     * 
     * @type {Array<LeaderboardSymbolDto>}
     * @memberof LeaderboardDto
     */
    mostSoldSymbols: Array<LeaderboardSymbolDto>;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardDto
     */
    totalPortfoliosValue: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardDto
     */
    totalPortfoliosValueGrowth: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardDto
     */
    totalInvestmentValueGrowth: number;
}

export function LeaderboardDtoFromJSON(json: any): LeaderboardDto {
    return LeaderboardDtoFromJSONTyped(json, false);
}

export function LeaderboardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mostPurchasedSymbols': ((json['mostPurchasedSymbols'] as Array<any>).map(LeaderboardSymbolDtoFromJSON)),
        'trendingSymbols': ((json['trendingSymbols'] as Array<any>).map(LeaderboardSymbolDtoFromJSON)),
        'mostSoldSymbols': ((json['mostSoldSymbols'] as Array<any>).map(LeaderboardSymbolDtoFromJSON)),
        'totalPortfoliosValue': json['totalPortfoliosValue'],
        'totalPortfoliosValueGrowth': json['totalPortfoliosValueGrowth'],
        'totalInvestmentValueGrowth': json['totalInvestmentValueGrowth'],
    };
}

export function LeaderboardDtoToJSON(value?: LeaderboardDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mostPurchasedSymbols': ((value.mostPurchasedSymbols as Array<any>).map(LeaderboardSymbolDtoToJSON)),
        'trendingSymbols': ((value.trendingSymbols as Array<any>).map(LeaderboardSymbolDtoToJSON)),
        'mostSoldSymbols': ((value.mostSoldSymbols as Array<any>).map(LeaderboardSymbolDtoToJSON)),
        'totalPortfoliosValue': value.totalPortfoliosValue,
        'totalPortfoliosValueGrowth': value.totalPortfoliosValueGrowth,
        'totalInvestmentValueGrowth': value.totalInvestmentValueGrowth,
    };
}

