import { makeAutoObservable } from 'mobx';
import { LeaderboardRankingDto } from '../../../../defs/api';

export class LeaderboardRankingMobxDto implements LeaderboardRankingDto {
    ranking = 0;

    numberOfUsers = 0;

    value = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: LeaderboardRankingDto): LeaderboardRankingMobxDto {
        const mobxDto = new LeaderboardRankingMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: LeaderboardRankingDto[]): LeaderboardRankingMobxDto[] {
        return dtos.map((dto) => LeaderboardRankingMobxDto.create(dto));
    }
}
