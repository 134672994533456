/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserRoleEnum {
    User = 'user',
    Admin = 'admin'
}

export function UserRoleEnumFromJSON(json: any): UserRoleEnum {
    return UserRoleEnumFromJSONTyped(json, false);
}

export function UserRoleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRoleEnum {
    return json as UserRoleEnum;
}

export function UserRoleEnumToJSON(value?: UserRoleEnum | null): any {
    return value as any;
}

