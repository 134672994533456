/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FirstLeaderboardDateDto
 */
export interface FirstLeaderboardDateDto {
    /**
     * 
     * @type {number}
     * @memberof FirstLeaderboardDateDto
     */
    month: number;
    /**
     * 
     * @type {number}
     * @memberof FirstLeaderboardDateDto
     */
    year: number;
}

export function FirstLeaderboardDateDtoFromJSON(json: any): FirstLeaderboardDateDto {
    return FirstLeaderboardDateDtoFromJSONTyped(json, false);
}

export function FirstLeaderboardDateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirstLeaderboardDateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': json['month'],
        'year': json['year'],
    };
}

export function FirstLeaderboardDateDtoToJSON(value?: FirstLeaderboardDateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'year': value.year,
    };
}

