import { makeAutoObservable, runInAction } from 'mobx';
import { BarDatum } from '@nivo/bar';
import moment from 'moment';
import type { RootStore } from '../root-store';
import { InvestmentsMobxDto } from '../../mobx/dtos/investments/investments-mobx-dto';

export type InvestmentsStoreHydration = {
    investments: InvestmentsMobxDto;

    withSells: boolean;
};

export class InvestmentsStore {
    rootStore: RootStore;

    investments: InvestmentsMobxDto | null = null;

    withSells = false;

    loading = true;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this);
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    get chartData(): BarDatum[] {
        const chartData: BarDatum[] = [];

        if (!this.investments) {
            return [];
        }

        this.investments.years.forEach((investment) => {
            investment.months.forEach((month) => {
                const currentDate = moment()
                    .set('year', investment.year)
                    .set('month', month.month - 1);

                chartData.push({
                    date: currentDate.format(process.env.NEXT_PUBLIC_API_DATE_FORMAT),
                    invested: this.withSells ? month.amountWithSell : month.amountWithoutSell,
                });
            });
        });

        return chartData;
    }

    async fetchInvestments(): Promise<void> {
        this.startLoading();
        try {
            const investments = await this.rootStore.loadWithDelay(() => {
                return this.rootStore.apiClient.investmentController.investmentControllerGetInvestmentsByMonths();
            });
            if (investments) {
                runInAction(() => {
                    this.investments = InvestmentsMobxDto.create(investments);
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
