import { makeAutoObservable } from 'mobx';
import { LeaderboardSymbolMobxDto } from './leaderboard-symbol-mobx-dto';
import { LeaderboardDto } from '../../../../defs/api';

export class LeaderboardMobxDto implements LeaderboardDto {
    mostPurchasedSymbols: LeaderboardSymbolMobxDto[] = [];

    trendingSymbols: LeaderboardSymbolMobxDto[] = [];

    mostSoldSymbols: LeaderboardSymbolMobxDto[] = [];

    totalPortfoliosValue: number = 0;

    totalPortfoliosValueGrowth: number = 0;

    totalInvestmentValueGrowth: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: LeaderboardDto): LeaderboardMobxDto {
        const mobxDto = new LeaderboardMobxDto();
        return Object.assign(mobxDto, dto, {
            mostPurchasedSymbols: LeaderboardSymbolMobxDto.createFromArray(dto.mostPurchasedSymbols),
            trendingSymbols: LeaderboardSymbolMobxDto.createFromArray(dto.trendingSymbols),
            mostSoldSymbols: LeaderboardSymbolMobxDto.createFromArray(dto.mostSoldSymbols),
        });
    }

    static createFromArray(dtos: LeaderboardDto[]): LeaderboardMobxDto[] {
        return dtos.map((dto) => LeaderboardMobxDto.create(dto));
    }
}
