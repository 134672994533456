import { makeAutoObservable } from 'mobx';
import { CurrencyEntity, PortfolioEntity, TradeListDto, TradeTypeEnum, SymbolTypeEnum } from '../../../../defs/api';
import { SymbolWithExchangeMobxDto } from '../symbol/symbol-with-exchange-mobx-dto';

export class TradeListMobxDto implements TradeListDto {
    id: string = '';

    symbol: SymbolWithExchangeMobxDto = new SymbolWithExchangeMobxDto();

    date: Date = new Date();

    type: TradeTypeEnum = TradeTypeEnum.Buy;

    amount = 0;

    unitPrice = 0;

    fees = 0;

    note = '';

    currency: CurrencyEntity;

    tickerId: string | null = null;

    portfolioId: string = '';

    symbolCode: string = '';

    symbolName: string = '';

    symbolType: SymbolTypeEnum = SymbolTypeEnum.Cs;

    exchangeId: string = '';

    exchangeName: string = '';

    isSelected: boolean = false;

    value: number = 0;

    referenceId: string = '';

    portfolio: PortfolioEntity;

    constructor(trade?: Partial<TradeListMobxDto>) {
        if (trade) {
            Object.assign(this, trade);
        }

        makeAutoObservable(this);
    }

    static create(dto: TradeListDto): TradeListMobxDto {
        const mobxDto = new TradeListMobxDto();
        return Object.assign(mobxDto, dto, { symbol: SymbolWithExchangeMobxDto.create(dto.symbol) });
    }

    static createFromArray(dtos: TradeListDto[]): TradeListMobxDto[] {
        return dtos.map((dto) => TradeListMobxDto.create(dto));
    }

    public switchSelected(): void {
        this.isSelected = !this.isSelected;
    }

    public setSelected(selected: boolean): void {
        this.isSelected = selected;
    }
}
