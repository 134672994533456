/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SymbolValueDto
 */
export interface SymbolValueDto {
    /**
     * 
     * @type {string}
     * @memberof SymbolValueDto
     */
    symbol: string;
    /**
     * 
     * @type {number}
     * @memberof SymbolValueDto
     */
    value: number;
}

export function SymbolValueDtoFromJSON(json: any): SymbolValueDto {
    return SymbolValueDtoFromJSONTyped(json, false);
}

export function SymbolValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolValueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'symbol': json['symbol'],
        'value': json['value'],
    };
}

export function SymbolValueDtoToJSON(value?: SymbolValueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'symbol': value.symbol,
        'value': value.value,
    };
}

