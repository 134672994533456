import styled, { keyframes } from 'styled-components';

const breathAnimation = () => keyframes`
        0% {
            fill: #8E91AF;
        }
        50% {
            fill: #231D61;
        }
        100% {
            fill: #8E91AF;
        }
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
`;

export const Svg = styled.svg`
    width: 3rem;
`;

export const First = styled.path`
    animation: ${breathAnimation} 1.8s;
    animation-iteration-count: infinite;
`;

export const Second = styled.rect`
    animation: ${breathAnimation} 1.8s;
    animation-delay: 0.25s;
    animation-iteration-count: infinite;
`;

export const Third = styled.rect`
    animation: ${breathAnimation} 1.8s;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
`;
