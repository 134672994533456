/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolEntity,
    SymbolEntityFromJSON,
    SymbolEntityFromJSONTyped,
    SymbolEntityToJSON,
} from './SymbolEntity';

/**
 * 
 * @export
 * @interface SymbolFundamentalDataHighlightsEntity
 */
export interface SymbolFundamentalDataHighlightsEntity {
    /**
     * 
     * @type {string}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    id: string;
    /**
     * 
     * @type {SymbolEntity}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    symbol: SymbolEntity;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    marketCapitalization: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    peRatio: number | null;
    /**
     * earnings before interest, taxes, depreciation, amortization; ebit + depreciationAndAmortization.
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    ebitda: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    epsEstimateCurrentYear: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    dividend: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    dividendYield: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    dividendPayoutRatio: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    margin: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    marginTTM: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    eps: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    roe: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    evEbitda: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    dividendYears: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataHighlightsEntity
     */
    continuousDividendYears: number | null;
}

export function SymbolFundamentalDataHighlightsEntityFromJSON(json: any): SymbolFundamentalDataHighlightsEntity {
    return SymbolFundamentalDataHighlightsEntityFromJSONTyped(json, false);
}

export function SymbolFundamentalDataHighlightsEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolFundamentalDataHighlightsEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'symbol': SymbolEntityFromJSON(json['symbol']),
        'marketCapitalization': json['marketCapitalization'],
        'peRatio': json['peRatio'],
        'ebitda': json['ebitda'],
        'epsEstimateCurrentYear': json['epsEstimateCurrentYear'],
        'dividend': json['dividend'],
        'dividendYield': json['dividendYield'],
        'dividendPayoutRatio': json['dividendPayoutRatio'],
        'margin': json['margin'],
        'marginTTM': json['marginTTM'],
        'eps': json['eps'],
        'roe': json['roe'],
        'evEbitda': json['evEbitda'],
        'dividendYears': json['dividendYears'],
        'continuousDividendYears': json['continuousDividendYears'],
    };
}

export function SymbolFundamentalDataHighlightsEntityToJSON(value?: SymbolFundamentalDataHighlightsEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'symbol': SymbolEntityToJSON(value.symbol),
        'marketCapitalization': value.marketCapitalization,
        'peRatio': value.peRatio,
        'ebitda': value.ebitda,
        'epsEstimateCurrentYear': value.epsEstimateCurrentYear,
        'dividend': value.dividend,
        'dividendYield': value.dividendYield,
        'dividendPayoutRatio': value.dividendPayoutRatio,
        'margin': value.margin,
        'marginTTM': value.marginTTM,
        'eps': value.eps,
        'roe': value.roe,
        'evEbitda': value.evEbitda,
        'dividendYears': value.dividendYears,
        'continuousDividendYears': value.continuousDividendYears,
    };
}

