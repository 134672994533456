import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import cz from './cz.json';
import en from './en.json';

const resources = {
    cz: {
        translation: cz,
    },
    en: {
        translation: en,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'cz',
    fallbackLng: 'cz',
    interpolation: {
        escapeValue: false,
    },
    react: {
        transKeepBasicHtmlNodesFor: ['br', 'strong'],
        useSuspense: false,
    },
});

export default i18n;
