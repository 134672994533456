/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AreaChartDataDto
 */
export interface AreaChartDataDto {
    /**
     * 
     * @type {Date}
     * @memberof AreaChartDataDto
     */
    x: Date;
    /**
     * 
     * @type {number}
     * @memberof AreaChartDataDto
     */
    y: number;
}

export function AreaChartDataDtoFromJSON(json: any): AreaChartDataDto {
    return AreaChartDataDtoFromJSONTyped(json, false);
}

export function AreaChartDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaChartDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'x': (new Date(json['x'])),
        'y': json['y'],
    };
}

export function AreaChartDataDtoToJSON(value?: AreaChartDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'x': (value.x.toISOString()),
        'y': value.y,
    };
}

