import i18n from 'i18next';

export class SymbolCurrencyNotFoundError extends Error {
    constructor(symbol: string, currencyCodes: string[]) {
        super(
            i18n.t('errors.symbolCurrencyNotFound', {
                symbol,
                currencyCodes: currencyCodes.join(', '),
            }),
        );
    }
}
