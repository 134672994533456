import React from 'react';

import { Wrapper } from './button-icon.styled';
import { GapSizeType } from '../../../../../theme/type/gap-type';
import { ButtonSizeType, ButtonVariantType, ButtonVariationType } from '../../../../../theme/type/button-type';
import { BreakpointSizeType } from '../../../../../theme/type/breakpoints-type';

interface ButtonIconPropsInterface {
    icon?: React.ReactNode;
    size: ButtonSizeType | object;
    hideLabelUnder?: BreakpointSizeType;
    hideLabelOver?: BreakpointSizeType;
    variation?: ButtonVariationType;
    variant?: ButtonVariantType;
    gap?: GapSizeType;
    position?: 'left' | 'right';
    onClick?: (event: React.MouseEvent<HTMLAnchorElement & HTMLButtonElement & HTMLDivElement>) => void;
    onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
    onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const ButtonIcon: React.FC<ButtonIconPropsInterface> = ({
    icon,
    variant = 'default',
    size,
    variation = 'filled',
    gap = 'medium',
    hideLabelUnder,
    hideLabelOver,
    position,
    onClick,
    onMouseEnter,
    onMouseLeave,
}) => {
    return (
        <Wrapper
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            size={size}
            variant={variant}
            variation={variation}
            gap={gap}
            hideLabelUnder={hideLabelUnder}
            hideLabelOver={hideLabelOver}
            position={position}
        >
            {icon}
        </Wrapper>
    );
};
