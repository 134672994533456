import { makeAutoObservable } from 'mobx';
import { TradeDto, TradeTypeEnum } from '../../../../defs/api';

export class TradeMobxDto implements TradeDto {
    public date: Date = new Date();

    public type: TradeTypeEnum = TradeTypeEnum.Buy;

    public amount: number = 0;

    public unitPrice: number = 0;

    public fees: number = 0;

    public note: string = '';

    public currencyCode: string = '';

    public symbolId: string = '';

    public symbolCode: string = '';

    public symbolName: string = '';

    public symbolType: string = '';

    public exchangeId: string = '';

    public exchangeName: string = '';

    public portfolioId: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: TradeDto): TradeMobxDto {
        const mobxDto = new TradeMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: TradeDto[]): TradeMobxDto[] {
        return dtos.map((dto) => TradeMobxDto.create(dto));
    }
}
