/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ThemeEnum,
    ThemeEnumFromJSON,
    ThemeEnumFromJSONTyped,
    ThemeEnumToJSON,
} from './ThemeEnum';

/**
 * 
 * @export
 * @interface SetThemeDto
 */
export interface SetThemeDto {
    /**
     * 
     * @type {ThemeEnum}
     * @memberof SetThemeDto
     */
    theme: ThemeEnum;
}

export function SetThemeDtoFromJSON(json: any): SetThemeDto {
    return SetThemeDtoFromJSONTyped(json, false);
}

export function SetThemeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetThemeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'theme': ThemeEnumFromJSON(json['theme']),
    };
}

export function SetThemeDtoToJSON(value?: SetThemeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'theme': ThemeEnumToJSON(value.theme),
    };
}

