/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MailOverviewPeriodEnum {
    Disabled = 'disabled',
    Weekly = 'weekly',
    Monthly = 'monthly'
}

export function MailOverviewPeriodEnumFromJSON(json: any): MailOverviewPeriodEnum {
    return MailOverviewPeriodEnumFromJSONTyped(json, false);
}

export function MailOverviewPeriodEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailOverviewPeriodEnum {
    return json as MailOverviewPeriodEnum;
}

export function MailOverviewPeriodEnumToJSON(value?: MailOverviewPeriodEnum | null): any {
    return value as any;
}

