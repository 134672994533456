/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RedirectUrlDto,
    RedirectUrlDtoFromJSON,
    RedirectUrlDtoFromJSONTyped,
    RedirectUrlDtoToJSON,
} from './RedirectUrlDto';

/**
 * 
 * @export
 * @interface SwitchSubscriptionPriceResponseDto
 */
export interface SwitchSubscriptionPriceResponseDto {
    /**
     * 
     * @type {RedirectUrlDto}
     * @memberof SwitchSubscriptionPriceResponseDto
     */
    session?: RedirectUrlDto;
}

export function SwitchSubscriptionPriceResponseDtoFromJSON(json: any): SwitchSubscriptionPriceResponseDto {
    return SwitchSubscriptionPriceResponseDtoFromJSONTyped(json, false);
}

export function SwitchSubscriptionPriceResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwitchSubscriptionPriceResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'session': !exists(json, 'session') ? undefined : RedirectUrlDtoFromJSON(json['session']),
    };
}

export function SwitchSubscriptionPriceResponseDtoToJSON(value?: SwitchSubscriptionPriceResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session': RedirectUrlDtoToJSON(value.session),
    };
}

