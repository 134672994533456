/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateSymbolAlternativeDto,
    CreateSymbolAlternativeDtoFromJSON,
    CreateSymbolAlternativeDtoToJSON,
    CreatedSymbolAlternativeDto,
    CreatedSymbolAlternativeDtoFromJSON,
    CreatedSymbolAlternativeDtoToJSON,
    PaginatedSymbolAlternativeDto,
    PaginatedSymbolAlternativeDtoFromJSON,
    PaginatedSymbolAlternativeDtoToJSON,
} from '../models';

export interface SymbolAlternativeControllerCreateRequest {
    createSymbolAlternativeDto: CreateSymbolAlternativeDto;
}

export interface SymbolAlternativeControllerDeleteRequest {
    symbolId: string;
}

export interface SymbolAlternativeControllerGetListRequest {
    limit: number;
    offset: number;
    phrase?: string;
}

/**
 * 
 */
export class SymbolAlternativesApi extends runtime.BaseAPI {

    /**
     * Create symbol alternative
     */
    async symbolAlternativeControllerCreateRaw(requestParameters: SymbolAlternativeControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreatedSymbolAlternativeDto>> {
        if (requestParameters.createSymbolAlternativeDto === null || requestParameters.createSymbolAlternativeDto === undefined) {
            throw new runtime.RequiredError('createSymbolAlternativeDto','Required parameter requestParameters.createSymbolAlternativeDto was null or undefined when calling symbolAlternativeControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbol-alternatives`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSymbolAlternativeDtoToJSON(requestParameters.createSymbolAlternativeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatedSymbolAlternativeDtoFromJSON(jsonValue));
    }

    /**
     * Create symbol alternative
     */
    async symbolAlternativeControllerCreate(requestParameters: SymbolAlternativeControllerCreateRequest, initOverrides?: RequestInit): Promise<CreatedSymbolAlternativeDto> {
        const response = await this.symbolAlternativeControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete symbol alternative by id
     */
    async symbolAlternativeControllerDeleteRaw(requestParameters: SymbolAlternativeControllerDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.symbolId === null || requestParameters.symbolId === undefined) {
            throw new runtime.RequiredError('symbolId','Required parameter requestParameters.symbolId was null or undefined when calling symbolAlternativeControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbol-alternatives/{symbolId}`.replace(`{${"symbolId"}}`, encodeURIComponent(String(requestParameters.symbolId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete symbol alternative by id
     */
    async symbolAlternativeControllerDelete(requestParameters: SymbolAlternativeControllerDeleteRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.symbolAlternativeControllerDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get symbol alternatives by phrase
     */
    async symbolAlternativeControllerGetListRaw(requestParameters: SymbolAlternativeControllerGetListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedSymbolAlternativeDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling symbolAlternativeControllerGetList.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling symbolAlternativeControllerGetList.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.phrase !== undefined) {
            queryParameters['phrase'] = requestParameters.phrase;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbol-alternatives`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedSymbolAlternativeDtoFromJSON(jsonValue));
    }

    /**
     * Get symbol alternatives by phrase
     */
    async symbolAlternativeControllerGetList(requestParameters: SymbolAlternativeControllerGetListRequest, initOverrides?: RequestInit): Promise<PaginatedSymbolAlternativeDto> {
        const response = await this.symbolAlternativeControllerGetListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
