/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortfolioListItemDto
 */
export interface PortfolioListItemDto {
    /**
     * 
     * @type {string}
     * @memberof PortfolioListItemDto
     */
    portfolioId: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioListItemDto
     */
    portfolioName: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioListItemDto
     */
    userFullName: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioListItemDto
     */
    userEmail: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioListItemDto
     */
    portfolioCurrencyCode: string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioListItemDto
     */
    tradesCount: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioListItemDto
     */
    historyCount: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioListItemDto
     */
    correctHistoryCount: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioListItemDto
     */
    calculationTimeSeconds: number;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioListItemDto
     */
    selected: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PortfolioListItemDto
     */
    lastUpdated: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioListItemDto
     */
    isRecalculating: boolean;
}

export function PortfolioListItemDtoFromJSON(json: any): PortfolioListItemDto {
    return PortfolioListItemDtoFromJSONTyped(json, false);
}

export function PortfolioListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioListItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'portfolioId': json['portfolioId'],
        'portfolioName': json['portfolioName'],
        'userFullName': json['userFullName'],
        'userEmail': json['userEmail'],
        'portfolioCurrencyCode': json['portfolioCurrencyCode'],
        'tradesCount': json['tradesCount'],
        'historyCount': json['historyCount'],
        'correctHistoryCount': json['correctHistoryCount'],
        'calculationTimeSeconds': json['calculationTimeSeconds'],
        'selected': json['selected'],
        'lastUpdated': (new Date(json['lastUpdated'])),
        'isRecalculating': json['isRecalculating'],
    };
}

export function PortfolioListItemDtoToJSON(value?: PortfolioListItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'portfolioId': value.portfolioId,
        'portfolioName': value.portfolioName,
        'userFullName': value.userFullName,
        'userEmail': value.userEmail,
        'portfolioCurrencyCode': value.portfolioCurrencyCode,
        'tradesCount': value.tradesCount,
        'historyCount': value.historyCount,
        'correctHistoryCount': value.correctHistoryCount,
        'calculationTimeSeconds': value.calculationTimeSeconds,
        'selected': value.selected,
        'lastUpdated': (value.lastUpdated.toISOString()),
        'isRecalculating': value.isRecalculating,
    };
}

