/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolGrowthRateDto,
    SymbolGrowthRateDtoFromJSON,
    SymbolGrowthRateDtoFromJSONTyped,
    SymbolGrowthRateDtoToJSON,
} from './SymbolGrowthRateDto';

/**
 * 
 * @export
 * @interface SymbolGrowthRateWithHighlightsDto
 */
export interface SymbolGrowthRateWithHighlightsDto {
    /**
     * 
     * @type {Array<SymbolGrowthRateDto>}
     * @memberof SymbolGrowthRateWithHighlightsDto
     */
    growthRates: Array<SymbolGrowthRateDto>;
    /**
     * 
     * @type {number}
     * @memberof SymbolGrowthRateWithHighlightsDto
     */
    peRatio: number | null;
    /**
     * 
     * @type {number}
     * @memberof SymbolGrowthRateWithHighlightsDto
     */
    eps: number | null;
}

export function SymbolGrowthRateWithHighlightsDtoFromJSON(json: any): SymbolGrowthRateWithHighlightsDto {
    return SymbolGrowthRateWithHighlightsDtoFromJSONTyped(json, false);
}

export function SymbolGrowthRateWithHighlightsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolGrowthRateWithHighlightsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'growthRates': ((json['growthRates'] as Array<any>).map(SymbolGrowthRateDtoFromJSON)),
        'peRatio': json['peRatio'],
        'eps': json['eps'],
    };
}

export function SymbolGrowthRateWithHighlightsDtoToJSON(value?: SymbolGrowthRateWithHighlightsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'growthRates': ((value.growthRates as Array<any>).map(SymbolGrowthRateDtoToJSON)),
        'peRatio': value.peRatio,
        'eps': value.eps,
    };
}

