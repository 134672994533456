/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TradeImportResponseDto
 */
export interface TradeImportResponseDto {
    /**
     * 
     * @type {string}
     * @memberof TradeImportResponseDto
     */
    error?: string;
    /**
     * 
     * @type {number}
     * @memberof TradeImportResponseDto
     */
    totalRowsCount: number;
    /**
     * 
     * @type {number}
     * @memberof TradeImportResponseDto
     */
    skippedRowsCount: number;
    /**
     * 
     * @type {number}
     * @memberof TradeImportResponseDto
     */
    invalidRowDates: number;
    /**
     * 
     * @type {boolean}
     * @memberof TradeImportResponseDto
     */
    invalidFormat?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TradeImportResponseDto
     */
    importedRowsCount: number;
    /**
     * 
     * @type {number}
     * @memberof TradeImportResponseDto
     */
    existingTradesCount: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TradeImportResponseDto
     */
    notFoundSymbols: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TradeImportResponseDto
     */
    notFoundCurrencies: Array<string>;
}

export function TradeImportResponseDtoFromJSON(json: any): TradeImportResponseDto {
    return TradeImportResponseDtoFromJSONTyped(json, false);
}

export function TradeImportResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeImportResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : json['error'],
        'totalRowsCount': json['totalRowsCount'],
        'skippedRowsCount': json['skippedRowsCount'],
        'invalidRowDates': json['invalidRowDates'],
        'invalidFormat': !exists(json, 'invalidFormat') ? undefined : json['invalidFormat'],
        'importedRowsCount': json['importedRowsCount'],
        'existingTradesCount': json['existingTradesCount'],
        'notFoundSymbols': json['notFoundSymbols'],
        'notFoundCurrencies': json['notFoundCurrencies'],
    };
}

export function TradeImportResponseDtoToJSON(value?: TradeImportResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': value.error,
        'totalRowsCount': value.totalRowsCount,
        'skippedRowsCount': value.skippedRowsCount,
        'invalidRowDates': value.invalidRowDates,
        'invalidFormat': value.invalidFormat,
        'importedRowsCount': value.importedRowsCount,
        'existingTradesCount': value.existingTradesCount,
        'notFoundSymbols': value.notFoundSymbols,
        'notFoundCurrencies': value.notFoundCurrencies,
    };
}

