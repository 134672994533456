/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProfitYearDto,
    ProfitYearDtoFromJSON,
    ProfitYearDtoFromJSONTyped,
    ProfitYearDtoToJSON,
} from './ProfitYearDto';

/**
 * 
 * @export
 * @interface ProfitDto
 */
export interface ProfitDto {
    /**
     * 
     * @type {Array<ProfitYearDto>}
     * @memberof ProfitDto
     */
    years: Array<ProfitYearDto>;
    /**
     * 
     * @type {number}
     * @memberof ProfitDto
     */
    numberOfMonths: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitDto
     */
    totalProfit: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitDto
     */
    averageProfit: number;
}

export function ProfitDtoFromJSON(json: any): ProfitDto {
    return ProfitDtoFromJSONTyped(json, false);
}

export function ProfitDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfitDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'years': ((json['years'] as Array<any>).map(ProfitYearDtoFromJSON)),
        'numberOfMonths': json['numberOfMonths'],
        'totalProfit': json['totalProfit'],
        'averageProfit': json['averageProfit'],
    };
}

export function ProfitDtoToJSON(value?: ProfitDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'years': ((value.years as Array<any>).map(ProfitYearDtoToJSON)),
        'numberOfMonths': value.numberOfMonths,
        'totalProfit': value.totalProfit,
        'averageProfit': value.averageProfit,
    };
}

