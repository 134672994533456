/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExchangeSourceEnum {
    Coinapi = 'Coinapi',
    IexCloud = 'IexCloud',
    Eod = 'Eod'
}

export function ExchangeSourceEnumFromJSON(json: any): ExchangeSourceEnum {
    return ExchangeSourceEnumFromJSONTyped(json, false);
}

export function ExchangeSourceEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeSourceEnum {
    return json as ExchangeSourceEnum;
}

export function ExchangeSourceEnumToJSON(value?: ExchangeSourceEnum | null): any {
    return value as any;
}

