/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OverviewDto
 */
export interface OverviewDto {
    /**
     * 
     * @type {string}
     * @memberof OverviewDto
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof OverviewDto
     */
    lastUpdated: string;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    portfolioValue: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    unrealizedProfit: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    todayUnrealizedProfit: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    unrealizedProfitPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    todayUnrealizedProfitPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    realizedProfit: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    realizedProfitPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    invested: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    investmentPeriod: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    averageInvestment: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    dividends: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    dividendsNextYearPrediction: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    dividendsYield: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    dividendsYieldOnCost: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    fees: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    feesPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    rateImpact: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewDto
     */
    totalPortfolioValue: number;
    /**
     * 
     * @type {boolean}
     * @memberof OverviewDto
     */
    isRecalculating: boolean;
}

export function OverviewDtoFromJSON(json: any): OverviewDto {
    return OverviewDtoFromJSONTyped(json, false);
}

export function OverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateFrom': json['dateFrom'],
        'lastUpdated': json['lastUpdated'],
        'portfolioValue': json['portfolioValue'],
        'unrealizedProfit': json['unrealizedProfit'],
        'todayUnrealizedProfit': json['todayUnrealizedProfit'],
        'unrealizedProfitPercentage': json['unrealizedProfitPercentage'],
        'todayUnrealizedProfitPercentage': json['todayUnrealizedProfitPercentage'],
        'realizedProfit': json['realizedProfit'],
        'realizedProfitPercentage': json['realizedProfitPercentage'],
        'invested': json['invested'],
        'investmentPeriod': json['investmentPeriod'],
        'averageInvestment': json['averageInvestment'],
        'dividends': json['dividends'],
        'dividendsNextYearPrediction': json['dividendsNextYearPrediction'],
        'dividendsYield': json['dividendsYield'],
        'dividendsYieldOnCost': json['dividendsYieldOnCost'],
        'fees': json['fees'],
        'feesPercentage': json['feesPercentage'],
        'rateImpact': json['rateImpact'],
        'totalPortfolioValue': json['totalPortfolioValue'],
        'isRecalculating': json['isRecalculating'],
    };
}

export function OverviewDtoToJSON(value?: OverviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateFrom': value.dateFrom,
        'lastUpdated': value.lastUpdated,
        'portfolioValue': value.portfolioValue,
        'unrealizedProfit': value.unrealizedProfit,
        'todayUnrealizedProfit': value.todayUnrealizedProfit,
        'unrealizedProfitPercentage': value.unrealizedProfitPercentage,
        'todayUnrealizedProfitPercentage': value.todayUnrealizedProfitPercentage,
        'realizedProfit': value.realizedProfit,
        'realizedProfitPercentage': value.realizedProfitPercentage,
        'invested': value.invested,
        'investmentPeriod': value.investmentPeriod,
        'averageInvestment': value.averageInvestment,
        'dividends': value.dividends,
        'dividendsNextYearPrediction': value.dividendsNextYearPrediction,
        'dividendsYield': value.dividendsYield,
        'dividendsYieldOnCost': value.dividendsYieldOnCost,
        'fees': value.fees,
        'feesPercentage': value.feesPercentage,
        'rateImpact': value.rateImpact,
        'totalPortfolioValue': value.totalPortfolioValue,
        'isRecalculating': value.isRecalculating,
    };
}

