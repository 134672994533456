import { makeAutoObservable } from 'mobx';
import { CurrencyDto } from '../../../../defs/api';

export class CurrencyMobxDto implements CurrencyDto {
    public code = '';

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: CurrencyDto): CurrencyMobxDto {
        const mobxDto = new CurrencyMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: CurrencyDto[]): CurrencyMobxDto[] {
        return dtos.map((dto) => CurrencyMobxDto.create(dto));
    }
}
