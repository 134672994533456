/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProfitMonthDto,
    ProfitMonthDtoFromJSON,
    ProfitMonthDtoFromJSONTyped,
    ProfitMonthDtoToJSON,
} from './ProfitMonthDto';

/**
 * 
 * @export
 * @interface ProfitYearDto
 */
export interface ProfitYearDto {
    /**
     * 
     * @type {number}
     * @memberof ProfitYearDto
     */
    year: number;
    /**
     * 
     * @type {Array<ProfitMonthDto>}
     * @memberof ProfitYearDto
     */
    months: Array<ProfitMonthDto>;
    /**
     * 
     * @type {ProfitMonthDto}
     * @memberof ProfitYearDto
     */
    total: ProfitMonthDto;
}

export function ProfitYearDtoFromJSON(json: any): ProfitYearDto {
    return ProfitYearDtoFromJSONTyped(json, false);
}

export function ProfitYearDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfitYearDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'months': ((json['months'] as Array<any>).map(ProfitMonthDtoFromJSON)),
        'total': ProfitMonthDtoFromJSON(json['total']),
    };
}

export function ProfitYearDtoToJSON(value?: ProfitYearDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'months': ((value.months as Array<any>).map(ProfitMonthDtoToJSON)),
        'total': ProfitMonthDtoToJSON(value.total),
    };
}

