import { DefaultTheme } from 'styled-components';

export const createBase = () => {
    const base: DefaultTheme['base'] = {
        fontFace: 'Satoshi',
        fontWeight: '500',
        containerWidth: '160rem',
        transition: '0.2s ease-in-out',
        layoutWidth: '100%',
        barHeight: '4rem',
    };
    return base;
};

export const createShadow = () => {
    const shadow: DefaultTheme['shadow'] = {
        small: '0 0.1rem 0.2rem rgba(0, 0, 0, 0.06)',
        medium: '0 0.2rem 0.4rem rgba(0, 0, 0, 0.06)',
        large: '0 0.4rem 0.8rem rgba(0, 0, 0, 0.09)',
    };
    return shadow;
};

export const createRadius = () => {
    const radius: DefaultTheme['radius'] = {
        small: '0.4rem',
        medium: '0.8rem',
        large: '1.2rem',
        xlarge: '2rem',
        circle: '9999px',
    };
    return radius;
};

export const createGap = () => {
    const gap: DefaultTheme['gap'] = {
        none: '0',
        xxsmall: '0.5rem',
        xsmall: '1rem',
        vsmall: '1.5rem',
        small: '2rem',
        medium: '3rem',
        large: '4rem',
        vlarge: '6rem',
        xlarge: '8rem',
        xxlarge: '10rem',
    };
    return gap;
};
