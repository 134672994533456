/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LookupKeyEnum,
    LookupKeyEnumFromJSON,
    LookupKeyEnumFromJSONTyped,
    LookupKeyEnumToJSON,
} from './LookupKeyEnum';

/**
 * 
 * @export
 * @interface InterestedInSubscriptionDto
 */
export interface InterestedInSubscriptionDto {
    /**
     * 
     * @type {LookupKeyEnum}
     * @memberof InterestedInSubscriptionDto
     */
    interestedInSubscription?: LookupKeyEnum;
}

export function InterestedInSubscriptionDtoFromJSON(json: any): InterestedInSubscriptionDto {
    return InterestedInSubscriptionDtoFromJSONTyped(json, false);
}

export function InterestedInSubscriptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterestedInSubscriptionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interestedInSubscription': !exists(json, 'interestedInSubscription') ? undefined : LookupKeyEnumFromJSON(json['interestedInSubscription']),
    };
}

export function InterestedInSubscriptionDtoToJSON(value?: InterestedInSubscriptionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interestedInSubscription': LookupKeyEnumToJSON(value.interestedInSubscription),
    };
}

