import { makeObservable, observable } from 'mobx';
import { PortfolioTradeDto, SymbolTypeEnum, TradeTypeEnum } from '../../../../defs/api';

export class PortfolioTradeMobxDto implements PortfolioTradeDto {
    public unitPrice: number = 0;

    public symbolId: string = '';

    public symbol: string = '';

    public symbolName: string = '';

    public symbolCurrency: string = '';

    public symbolRate: number = 0;

    public tradeCurrency: string = '';

    public portfolioCurrency: string = '';

    public date: Date = new Date();

    public amount: number = 0;

    public totalPricePortfolioCurrency: number = 0;

    public symbolType: SymbolTypeEnum = SymbolTypeEnum.Cs;

    public type: TradeTypeEnum = TradeTypeEnum.Buy;

    public tradeSymbolId: string = '';

    public exchange: string = '';

    public fees: number = 0;

    public feesPortfolioCurrency: number = 0;

    public rawAmount: number = 0;

    public note: string = '';

    constructor() {
        makeObservable(this, {
            unitPrice: observable,
            symbolId: observable,
            symbol: observable,
            symbolName: observable,
            symbolCurrency: observable,
            symbolRate: observable,
            tradeCurrency: observable,
            portfolioCurrency: observable,
            date: observable,
            amount: observable,
            totalPricePortfolioCurrency: observable,
            symbolType: observable,
            type: observable,
            tradeSymbolId: observable,
            exchange: observable,
            fees: observable,
            feesPortfolioCurrency: observable,
            rawAmount: observable,
            note: observable,
        });
    }

    static create(dto: PortfolioTradeDto): PortfolioTradeMobxDto {
        const mobxDto = new PortfolioTradeMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: PortfolioTradeDto[]): PortfolioTradeMobxDto[] {
        return dtos.map((dto) => PortfolioTradeMobxDto.create(dto));
    }
}
