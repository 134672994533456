/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeaderboardRankingDto
 */
export interface LeaderboardRankingDto {
    /**
     * 
     * @type {number}
     * @memberof LeaderboardRankingDto
     */
    ranking: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardRankingDto
     */
    numberOfUsers: number;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardRankingDto
     */
    value: number;
}

export function LeaderboardRankingDtoFromJSON(json: any): LeaderboardRankingDto {
    return LeaderboardRankingDtoFromJSONTyped(json, false);
}

export function LeaderboardRankingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardRankingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ranking': json['ranking'],
        'numberOfUsers': json['numberOfUsers'],
        'value': json['value'],
    };
}

export function LeaderboardRankingDtoToJSON(value?: LeaderboardRankingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ranking': value.ranking,
        'numberOfUsers': value.numberOfUsers,
        'value': value.value,
    };
}

