/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserTypeEnum {
    Credentials = 'credentials',
    Google = 'google',
    Apple = 'apple',
    Twitter = 'twitter'
}

export function UserTypeEnumFromJSON(json: any): UserTypeEnum {
    return UserTypeEnumFromJSONTyped(json, false);
}

export function UserTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserTypeEnum {
    return json as UserTypeEnum;
}

export function UserTypeEnumToJSON(value?: UserTypeEnum | null): any {
    return value as any;
}

