import { makeAutoObservable } from 'mobx';
import { SymbolValueDto } from '../../../../defs/api';

export class SymbolValueMobxDto implements SymbolValueDto {
    symbol: string;

    value: number;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolValueDto): SymbolValueMobxDto {
        const mobxDto = new SymbolValueMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: SymbolValueDto[]): SymbolValueMobxDto[] {
        return dtos.map((dto) => SymbolValueMobxDto.create(dto));
    }
}
