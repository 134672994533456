import { makeAutoObservable } from 'mobx';
import { PortfolioListItemDto } from '../../../../../defs/api';

export class PortfolioListItemMobxDto implements PortfolioListItemDto {
    public portfolioId: string = '';

    public portfolioName: string = '';

    public userFullName: string = '';

    public userEmail: string = '';

    public portfolioCurrencyCode: string = '';

    public lastUpdated: Date = new Date();

    public calculationTimeSeconds: number = 0;

    public selected: boolean = false;

    public tradesCount: number = 0;

    public historyCount: number = 0;

    public correctHistoryCount: number = 0;

    public isSelected: boolean = false;

    public isRecalculating: boolean = false;

    constructor() {
        makeAutoObservable(this, {});
    }

    static create(dto: PortfolioListItemDto): PortfolioListItemMobxDto {
        const mobxDto = new PortfolioListItemMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: PortfolioListItemDto[]): PortfolioListItemMobxDto[] {
        return dtos.map((dto) => PortfolioListItemMobxDto.create(dto));
    }

    public switchSelected(): void {
        this.isSelected = !this.isSelected;
    }

    public setSelected(selected: boolean): void {
        this.isSelected = selected;
    }
}
