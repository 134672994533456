import { makeAutoObservable } from 'mobx';
import type { RootStore } from './root-store';

export class PortfolioValueCalculationInfoDialogStore {
    rootStore: RootStore;

    opened: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    open(): void {
        this.opened = true;
    }

    close(): void {
        this.opened = false;
    }
}
