/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StornoReasonEnum {
    HighPrice = 'highPrice',
    DoNotUsePremiumFeatures = 'doNotUsePremiumFeatures',
    UsingAlternativeService = 'usingAlternativeService',
    DoNotInvest = 'doNotInvest',
    Other = 'other'
}

export function StornoReasonEnumFromJSON(json: any): StornoReasonEnum {
    return StornoReasonEnumFromJSONTyped(json, false);
}

export function StornoReasonEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StornoReasonEnum {
    return json as StornoReasonEnum;
}

export function StornoReasonEnumToJSON(value?: StornoReasonEnum | null): any {
    return value as any;
}

