/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DividendListDto,
    DividendListDtoFromJSON,
    DividendListDtoToJSON,
    PaginatedSymbolDividendDto,
    PaginatedSymbolDividendDtoFromJSON,
    PaginatedSymbolDividendDtoToJSON,
    SymbolDividendDto,
    SymbolDividendDtoFromJSON,
    SymbolDividendDtoToJSON,
} from '../models';

export interface SymbolDividendControllerGetBySymbolIdRequest {
    symbolId: string;
}

export interface SymbolDividendControllerGetListRequest {
    year?: number;
}

export interface SymbolDividendControllerGetPaginatedListRequest {
    limit: number;
    offset: number;
}

/**
 * 
 */
export class SymbolDividendsApi extends runtime.BaseAPI {

    /**
     * Get dividends by symbol
     */
    async symbolDividendControllerGetBySymbolIdRaw(requestParameters: SymbolDividendControllerGetBySymbolIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SymbolDividendDto>>> {
        if (requestParameters.symbolId === null || requestParameters.symbolId === undefined) {
            throw new runtime.RequiredError('symbolId','Required parameter requestParameters.symbolId was null or undefined when calling symbolDividendControllerGetBySymbolId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbol-dividends/{symbolId}`.replace(`{${"symbolId"}}`, encodeURIComponent(String(requestParameters.symbolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SymbolDividendDtoFromJSON));
    }

    /**
     * Get dividends by symbol
     */
    async symbolDividendControllerGetBySymbolId(requestParameters: SymbolDividendControllerGetBySymbolIdRequest, initOverrides?: RequestInit): Promise<Array<SymbolDividendDto>> {
        const response = await this.symbolDividendControllerGetBySymbolIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get dividends
     */
    async symbolDividendControllerGetListRaw(requestParameters: SymbolDividendControllerGetListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DividendListDto>> {
        const queryParameters: any = {};

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbol-dividends`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DividendListDtoFromJSON(jsonValue));
    }

    /**
     * Get dividends
     */
    async symbolDividendControllerGetList(requestParameters: SymbolDividendControllerGetListRequest = {}, initOverrides?: RequestInit): Promise<DividendListDto> {
        const response = await this.symbolDividendControllerGetListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get paginated dividends
     */
    async symbolDividendControllerGetPaginatedListRaw(requestParameters: SymbolDividendControllerGetPaginatedListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedSymbolDividendDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling symbolDividendControllerGetPaginatedList.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling symbolDividendControllerGetPaginatedList.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbol-dividends/paginated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedSymbolDividendDtoFromJSON(jsonValue));
    }

    /**
     * Get paginated dividends
     */
    async symbolDividendControllerGetPaginatedList(requestParameters: SymbolDividendControllerGetPaginatedListRequest, initOverrides?: RequestInit): Promise<PaginatedSymbolDividendDto> {
        const response = await this.symbolDividendControllerGetPaginatedListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
