import { makeAutoObservable } from 'mobx';
import { ProfitYearMobxDto } from './profit-year-mobx-dto';
import { ProfitDto } from '../../../../defs/api';

export class ProfitMobxDto implements ProfitDto {
    years: ProfitYearMobxDto[] = [];

    numberOfMonths: number = 0;

    totalProfit: number = 0;

    averageProfit: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: ProfitDto): ProfitMobxDto {
        const mobxDto = new ProfitMobxDto();
        return Object.assign(mobxDto, dto, { years: ProfitYearMobxDto.createFromArray(dto.years) });
    }

    static createFromArray(dtos: ProfitDto[]): ProfitMobxDto[] {
        return dtos.map((dto) => ProfitMobxDto.create(dto));
    }
}
