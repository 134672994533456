import styled, { css, DefaultTheme } from 'styled-components';
import { Wrapper as Icon } from './icon/button-icon.styled';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';
import { ButtonSizeType, ButtonVariantType, ButtonVariationType } from '../../../../theme/type/button-type';

const getResponsivePadding = (size: object, variation: ButtonVariationType, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        if (index === 0) {
            return `
                @media ${theme.breakpoints[item[0] as BreakpointSizeType].max} {
                    padding: ${
                        variation === 'filled'
                            ? theme.button.size[item[1] as ButtonSizeType].padding
                            : `${theme.button.size[item[1] as ButtonSizeType].padding}`
                    };
                }
                @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                    padding: ${
                        variation === 'filled'
                            ? theme.button.size[item[1] as ButtonSizeType].padding
                            : `${theme.button.size[item[1] as ButtonSizeType].padding}`
                    };
                }
            `;
        }

        return `
            @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                padding: ${
                    variation === 'filled'
                        ? theme.button.size[item[1] as ButtonSizeType].padding
                        : `${theme.button.size[item[1] as ButtonSizeType].padding}`
                };
            }
        `;
    });
};

const getResponsiveLabel = (size: object, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        if (index === 0) {
            return `
                @media ${theme.breakpoints[item[0] as BreakpointSizeType].max} {
                    font-size: ${theme.button.size[item[1] as ButtonSizeType].fontSize};
                    line-height: ${theme.button.size[item[1] as ButtonSizeType].lineHeight};
                    padding: ${theme.button.size[item[1] as ButtonSizeType].labelPadding};
                    height: ${theme.button.size[item[1] as ButtonSizeType].icon.wrapper};
                }
                @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                    font-size: ${theme.button.size[item[1] as ButtonSizeType].fontSize};
                    line-height: ${theme.button.size[item[1] as ButtonSizeType].lineHeight};
                    padding: ${theme.button.size[item[1] as ButtonSizeType].labelPadding};
                    height: ${theme.button.size[item[1] as ButtonSizeType].icon.wrapper};
                }
            `;
        }

        return `
                @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                    font-size: ${theme.button.size[item[1] as ButtonSizeType].fontSize};
                    line-height: ${theme.button.size[item[1] as ButtonSizeType].lineHeight};
                    padding: ${theme.button.size[item[1] as ButtonSizeType].labelPadding};
                    height: ${theme.button.size[item[1] as ButtonSizeType].icon.wrapper};
                }
            `;
    });
};

export const Wrapper = styled.button.withConfig({
    shouldForwardProp: (prop) =>
        ![
            'block',
            'variant',
            'icon',
            'variation',
            'gradient',
            'shadow',
            'size',
            'hideLabelUnder',
            'hideLabelOver',
            'disableResponsive',
        ].includes(prop),
})<{
    block?: boolean;
    variant: ButtonVariantType;
    disabled?: boolean;
    icon?: boolean;
    variation: ButtonVariationType;
    gradient?: boolean;
    shadow?: boolean;
    size: ButtonSizeType | object;
    hideLabelUnder?: BreakpointSizeType;
    hideLabelOver?: BreakpointSizeType;
    disableResponsive?: boolean;
}>`
    ${({
        theme,
        block,
        variant,
        icon,
        variation,
        gradient,
        shadow,
        size,
        hideLabelUnder,
        hideLabelOver,
        disableResponsive,
    }) => css`
        align-items: center;
        cursor: pointer;
        border-radius: 20rem;
        display: inline-flex;
        font-family: ${theme.base.fontFace};
        font-weight: 700;
        justify-content: ${icon ? 'space-between' : 'center'};
        width: ${block ? '100%' : 'unset'};
        outline: none;
        position: relative;
        text-decoration: none;
        transition: all ${theme.base.transition};
        background-color: ${theme.button.variant[variant][variation].base.background};
        border: 0.1rem solid ${theme.button.variant[variant][variation].base.border};
        color: ${theme.button.variant[variant][variation].base.color};
        white-space: nowrap;

        ${typeof size === 'string' &&
        css`
            padding: ${variation === 'filled' || disableResponsive
                ? theme.button.size[size].padding
                : theme.button.size[size].padding};
        `}

        ${typeof size === 'object' &&
        css`
            ${getResponsivePadding(size, variation, theme)};
        `}

        ${gradient &&
        variation === 'filled' &&
        css`
            background-image: linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
            border-color: transparent;
            box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.25);
        `}

        ${shadow &&
        variation === 'filled' &&
        css`
            box-shadow: ${theme.button.variant[variant][variation].base.shadow};
        `}

        &:active {
            background-color: ${theme.button.variant[variant][variation].active.background};
            border-color: ${theme.button.variant[variant][variation].active.border};
            color: ${theme.button.variant[variant][variation].active.color};
        }

        &:hover {
            background-color: ${theme.button.variant[variant][variation].hover.background};
            border-color: ${theme.button.variant[variant][variation].hover.border};
            color: ${theme.button.variant[variant][variation].hover.color};

            ${!hideLabelUnder &&
            !hideLabelOver &&
            css`
                ${Icon} {
                    background-color: ${theme.button.variant[variant][variation].hover.icon.background};
                }
            `}
        }

        &:disabled {
            background-color: ${theme.button.variant[variant][variation].disabled.background};
            border-color: ${theme.button.variant[variant][variation].disabled.border};
            color: ${theme.button.variant[variant][variation].disabled.color};
            cursor: not-allowed;
            opacity: 0.6;
        }
    `}
`;

export const Label = styled.span.withConfig({
    shouldForwardProp: (prop) => !['size', 'hideLabelUnder', 'hideLabelOver'].includes(prop),
})<{
    size: ButtonSizeType | object;
    hideLabelUnder?: BreakpointSizeType;
    hideLabelOver?: BreakpointSizeType;
}>`
    ${({ theme, size, hideLabelUnder, hideLabelOver }) => css`
        display: flex;
        align-items: center;

        ${typeof size === 'string' &&
        css`
            font-size: ${theme.button.size[size].fontSize};
            line-height: ${theme.button.size[size].lineHeight};
            padding: ${theme.button.size[size].labelPadding};
            height: ${theme.button.size[size].icon.wrapper};
        `}

        ${typeof size === 'object' &&
        css`
            ${getResponsiveLabel(size, theme)};
        `}

        ${hideLabelUnder &&
        css`
            @media ${theme.breakpoints[hideLabelUnder].max} {
                display: none;
            }
        `}

        ${hideLabelOver &&
        css`
            @media ${theme.breakpoints[hideLabelOver].min} {
                display: none;
            }
        `}
    `}
`;
