import { makeAutoObservable } from 'mobx';
import { IconNameEnum, ColorEnum } from '@app/common';
import { PortfolioDto } from '../../../../defs/api';
import { CurrencyMobxDto } from '../currency/currency-mobx-dto';

export class PortfolioMobxDto implements PortfolioDto {
    public id: string = '';

    public name: string = '';

    public iconName: IconNameEnum = IconNameEnum.WalletFilled;

    public colorName: ColorEnum = ColorEnum.Blue;

    public currency: CurrencyMobxDto = new CurrencyMobxDto();

    public userId: string | null = null;

    public selected = false;

    public portfolioValue = 0;

    public invested = 0;

    public realizedPortfolioValue = 0;

    public realizedInvested = 0;

    public realizedProfit = 0;

    public realizedProfitPercentage = 0;

    public unrealizedProfit = 0;

    public unrealizedProfitPercentage = 0;

    public numberOfTrades = 0;

    public numberOfAssets = 0;

    public isRecalculating = false;

    public portfolioHistoryCurrencyCode = '';

    public processed = false;

    public currencyImpactUnrealizedValue: number = 0;

    public currencyImpactRealizedValue: number = 0;

    public todayUnrealizedProfit: number = 0;

    public todayUnrealizedProfitPercentage: number = 0;

    public dividends: number = 0;

    public dividendsNextYearPrediction: number = 0;

    public dividendsYield: number = 0;

    public dividendsYieldOnCost: number = 0;

    public lastUpdated: Date = new Date();

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: PortfolioDto): PortfolioMobxDto {
        const mobxDto = new PortfolioMobxDto();
        return Object.assign(mobxDto, dto, { currency: CurrencyMobxDto.create(dto.currency) });
    }

    static createFromArray(dtos: PortfolioDto[]): PortfolioMobxDto[] {
        return dtos.map((dto) => PortfolioMobxDto.create(dto));
    }

    setSelected(selected: boolean) {
        this.selected = selected;
    }

    setIsRecalculating(isRecalculating: boolean) {
        this.isRecalculating = isRecalculating;
    }
}
