import { makeAutoObservable } from 'mobx';
import { SymbolAlternativeDto } from '../../../../defs/api';

export class SymbolAlternativeMobxDto implements SymbolAlternativeDto {
    public id: string = '';

    public alternativeSymbol: string = '';

    public alternativeName: string = '';

    public symbol: string = '';

    public name: number = 0;

    public validFrom: Date = new Date();

    constructor() {
        makeAutoObservable(this, {});
    }

    static create(dto: SymbolAlternativeDto): SymbolAlternativeMobxDto {
        const mobxDto = new SymbolAlternativeMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: SymbolAlternativeDto[]): SymbolAlternativeMobxDto[] {
        return dtos.map((dto) => SymbolAlternativeMobxDto.create(dto));
    }
}
