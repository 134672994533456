import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    background: url('/images/intro/background.jpg') no-repeat;
    background-size: cover;
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const Header = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: center;
        padding: ${theme.gap.xlarge} 0;
    `,
);

export const Content = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;
