/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EcomailUsersCountDto,
    EcomailUsersCountDtoFromJSON,
    EcomailUsersCountDtoToJSON,
    InterestedInSubscriptionDto,
    InterestedInSubscriptionDtoFromJSON,
    InterestedInSubscriptionDtoToJSON,
    MailOverviewPeriodDto,
    MailOverviewPeriodDtoFromJSON,
    MailOverviewPeriodDtoToJSON,
    NicknameAlreadyTakenDto,
    NicknameAlreadyTakenDtoFromJSON,
    NicknameAlreadyTakenDtoToJSON,
    PaginatedActiveUserDto,
    PaginatedActiveUserDtoFromJSON,
    PaginatedActiveUserDtoToJSON,
    PaginatedNotConfirmedUserDto,
    PaginatedNotConfirmedUserDtoFromJSON,
    PaginatedNotConfirmedUserDtoToJSON,
    PaginatedWaitingUserDto,
    PaginatedWaitingUserDtoFromJSON,
    PaginatedWaitingUserDtoToJSON,
    ProfileFormValuesDto,
    ProfileFormValuesDtoFromJSON,
    ProfileFormValuesDtoToJSON,
    SetLayoutDto,
    SetLayoutDtoFromJSON,
    SetLayoutDtoToJSON,
    SetNewsletterDto,
    SetNewsletterDtoFromJSON,
    SetNewsletterDtoToJSON,
    SetThemeDto,
    SetThemeDtoFromJSON,
    SetThemeDtoToJSON,
    UpdateStornoReasonDto,
    UpdateStornoReasonDtoFromJSON,
    UpdateStornoReasonDtoToJSON,
    UserActivateDto,
    UserActivateDtoFromJSON,
    UserActivateDtoToJSON,
} from '../models';

export interface UserControllerActivateRequest {
    userActivateDto: UserActivateDto;
}

export interface UserControllerDeleteUserRequest {
    userId: string;
}

export interface UserControllerGetActiveRequest {
    limit: number;
    offset: number;
    subscription?: string;
    phrase?: string;
}

export interface UserControllerGetNotConfirmedRequest {
    limit: number;
    offset: number;
    phrase?: string;
}

export interface UserControllerGetWaitingRequest {
    limit: number;
    offset: number;
    phrase?: string;
}

export interface UserControllerIsNicknameAlreadyTakenRequest {
    nickname: string;
}

export interface UserControllerSetLayoutRequest {
    setLayoutDto: SetLayoutDto;
}

export interface UserControllerSetNewsletterRequest {
    setNewsletterDto: SetNewsletterDto;
}

export interface UserControllerSetThemeRequest {
    setThemeDto: SetThemeDto;
}

export interface UserControllerUpdateInterestedInSubscriptionRequest {
    interestedInSubscriptionDto: InterestedInSubscriptionDto;
}

export interface UserControllerUpdateMailOverviewPeriodRequest {
    mailOverviewPeriodDto: MailOverviewPeriodDto;
}

export interface UserControllerUpdateProfileRequest {
    profileFormValuesDto: ProfileFormValuesDto;
}

export interface UserControllerUpdateStornoReasonRequest {
    updateStornoReasonDto: UpdateStornoReasonDto;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Activate selected users on waitlist and send them email
     */
    async userControllerActivateRaw(requestParameters: UserControllerActivateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.userActivateDto === null || requestParameters.userActivateDto === undefined) {
            throw new runtime.RequiredError('userActivateDto','Required parameter requestParameters.userActivateDto was null or undefined when calling userControllerActivate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/activate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserActivateDtoToJSON(requestParameters.userActivateDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Activate selected users on waitlist and send them email
     */
    async userControllerActivate(requestParameters: UserControllerActivateRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.userControllerActivateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete my account
     */
    async userControllerDeleteAccountRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/my-account`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete my account
     */
    async userControllerDeleteAccount(initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.userControllerDeleteAccountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Delete user by id
     */
    async userControllerDeleteUserRaw(requestParameters: UserControllerDeleteUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userControllerDeleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete user by id
     */
    async userControllerDeleteUser(requestParameters: UserControllerDeleteUserRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.userControllerDeleteUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get active users
     */
    async userControllerGetActiveRaw(requestParameters: UserControllerGetActiveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedActiveUserDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling userControllerGetActive.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling userControllerGetActive.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.subscription !== undefined) {
            queryParameters['subscription'] = requestParameters.subscription;
        }

        if (requestParameters.phrase !== undefined) {
            queryParameters['phrase'] = requestParameters.phrase;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedActiveUserDtoFromJSON(jsonValue));
    }

    /**
     * Get active users
     */
    async userControllerGetActive(requestParameters: UserControllerGetActiveRequest, initOverrides?: RequestInit): Promise<PaginatedActiveUserDto> {
        const response = await this.userControllerGetActiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get ecomail users count
     */
    async userControllerGetEcomailUsersCountRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<EcomailUsersCountDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/ecomail-users-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EcomailUsersCountDtoFromJSON(jsonValue));
    }

    /**
     * Get ecomail users count
     */
    async userControllerGetEcomailUsersCount(initOverrides?: RequestInit): Promise<EcomailUsersCountDto> {
        const response = await this.userControllerGetEcomailUsersCountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get mail overview period
     */
    async userControllerGetMailOverviewPeriodRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<MailOverviewPeriodDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/mail-overview-period`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailOverviewPeriodDtoFromJSON(jsonValue));
    }

    /**
     * Get mail overview period
     */
    async userControllerGetMailOverviewPeriod(initOverrides?: RequestInit): Promise<MailOverviewPeriodDto> {
        const response = await this.userControllerGetMailOverviewPeriodRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get users emails that do not match with ecomail
     */
    async userControllerGetNonMatchingEcomailUsersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/non-matching-ecomail-users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get users emails that do not match with ecomail
     */
    async userControllerGetNonMatchingEcomailUsers(initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.userControllerGetNonMatchingEcomailUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get not confirmed users
     */
    async userControllerGetNotConfirmedRaw(requestParameters: UserControllerGetNotConfirmedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedNotConfirmedUserDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling userControllerGetNotConfirmed.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling userControllerGetNotConfirmed.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.phrase !== undefined) {
            queryParameters['phrase'] = requestParameters.phrase;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/not-confirmed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNotConfirmedUserDtoFromJSON(jsonValue));
    }

    /**
     * Get not confirmed users
     */
    async userControllerGetNotConfirmed(requestParameters: UserControllerGetNotConfirmedRequest, initOverrides?: RequestInit): Promise<PaginatedNotConfirmedUserDto> {
        const response = await this.userControllerGetNotConfirmedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get rounded count of users
     */
    async userControllerGetRoundedCountRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/rounded-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get rounded count of users
     */
    async userControllerGetRoundedCount(initOverrides?: RequestInit): Promise<number> {
        const response = await this.userControllerGetRoundedCountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get users on waiting list
     */
    async userControllerGetWaitingRaw(requestParameters: UserControllerGetWaitingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedWaitingUserDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling userControllerGetWaiting.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling userControllerGetWaiting.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.phrase !== undefined) {
            queryParameters['phrase'] = requestParameters.phrase;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/waiting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedWaitingUserDtoFromJSON(jsonValue));
    }

    /**
     * Get users on waiting list
     */
    async userControllerGetWaiting(requestParameters: UserControllerGetWaitingRequest, initOverrides?: RequestInit): Promise<PaginatedWaitingUserDto> {
        const response = await this.userControllerGetWaitingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get waiting list order
     */
    async userControllerGetWaitingOrderRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/waiting-order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get waiting list order
     */
    async userControllerGetWaitingOrder(initOverrides?: RequestInit): Promise<number> {
        const response = await this.userControllerGetWaitingOrderRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get if user has storno discount
     */
    async userControllerHadStornoDiscountRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/storno-discount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get if user has storno discount
     */
    async userControllerHadStornoDiscount(initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.userControllerHadStornoDiscountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get mail overview period
     */
    async userControllerIsNicknameAlreadyTakenRaw(requestParameters: UserControllerIsNicknameAlreadyTakenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NicknameAlreadyTakenDto>> {
        if (requestParameters.nickname === null || requestParameters.nickname === undefined) {
            throw new runtime.RequiredError('nickname','Required parameter requestParameters.nickname was null or undefined when calling userControllerIsNicknameAlreadyTaken.');
        }

        const queryParameters: any = {};

        if (requestParameters.nickname !== undefined) {
            queryParameters['nickname'] = requestParameters.nickname;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/nickname-already-taken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NicknameAlreadyTakenDtoFromJSON(jsonValue));
    }

    /**
     * Get mail overview period
     */
    async userControllerIsNicknameAlreadyTaken(requestParameters: UserControllerIsNicknameAlreadyTakenRequest, initOverrides?: RequestInit): Promise<NicknameAlreadyTakenDto> {
        const response = await this.userControllerIsNicknameAlreadyTakenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set layout for current user
     */
    async userControllerSetLayoutRaw(requestParameters: UserControllerSetLayoutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.setLayoutDto === null || requestParameters.setLayoutDto === undefined) {
            throw new runtime.RequiredError('setLayoutDto','Required parameter requestParameters.setLayoutDto was null or undefined when calling userControllerSetLayout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/set-layout`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetLayoutDtoToJSON(requestParameters.setLayoutDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Set layout for current user
     */
    async userControllerSetLayout(requestParameters: UserControllerSetLayoutRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.userControllerSetLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set newsletter for current user
     */
    async userControllerSetNewsletterRaw(requestParameters: UserControllerSetNewsletterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.setNewsletterDto === null || requestParameters.setNewsletterDto === undefined) {
            throw new runtime.RequiredError('setNewsletterDto','Required parameter requestParameters.setNewsletterDto was null or undefined when calling userControllerSetNewsletter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/set-newsletter`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetNewsletterDtoToJSON(requestParameters.setNewsletterDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Set newsletter for current user
     */
    async userControllerSetNewsletter(requestParameters: UserControllerSetNewsletterRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.userControllerSetNewsletterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set theme for current user
     */
    async userControllerSetThemeRaw(requestParameters: UserControllerSetThemeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.setThemeDto === null || requestParameters.setThemeDto === undefined) {
            throw new runtime.RequiredError('setThemeDto','Required parameter requestParameters.setThemeDto was null or undefined when calling userControllerSetTheme.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/set-theme`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetThemeDtoToJSON(requestParameters.setThemeDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Set theme for current user
     */
    async userControllerSetTheme(requestParameters: UserControllerSetThemeRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.userControllerSetThemeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update interested in subscription for current user
     */
    async userControllerUpdateInterestedInSubscriptionRaw(requestParameters: UserControllerUpdateInterestedInSubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.interestedInSubscriptionDto === null || requestParameters.interestedInSubscriptionDto === undefined) {
            throw new runtime.RequiredError('interestedInSubscriptionDto','Required parameter requestParameters.interestedInSubscriptionDto was null or undefined when calling userControllerUpdateInterestedInSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/interested-in-subscription`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InterestedInSubscriptionDtoToJSON(requestParameters.interestedInSubscriptionDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update interested in subscription for current user
     */
    async userControllerUpdateInterestedInSubscription(requestParameters: UserControllerUpdateInterestedInSubscriptionRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.userControllerUpdateInterestedInSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update mail overview period
     */
    async userControllerUpdateMailOverviewPeriodRaw(requestParameters: UserControllerUpdateMailOverviewPeriodRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.mailOverviewPeriodDto === null || requestParameters.mailOverviewPeriodDto === undefined) {
            throw new runtime.RequiredError('mailOverviewPeriodDto','Required parameter requestParameters.mailOverviewPeriodDto was null or undefined when calling userControllerUpdateMailOverviewPeriod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/mail-overview-period`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MailOverviewPeriodDtoToJSON(requestParameters.mailOverviewPeriodDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update mail overview period
     */
    async userControllerUpdateMailOverviewPeriod(requestParameters: UserControllerUpdateMailOverviewPeriodRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.userControllerUpdateMailOverviewPeriodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update profile for current user
     */
    async userControllerUpdateProfileRaw(requestParameters: UserControllerUpdateProfileRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.profileFormValuesDto === null || requestParameters.profileFormValuesDto === undefined) {
            throw new runtime.RequiredError('profileFormValuesDto','Required parameter requestParameters.profileFormValuesDto was null or undefined when calling userControllerUpdateProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/update-profile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileFormValuesDtoToJSON(requestParameters.profileFormValuesDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update profile for current user
     */
    async userControllerUpdateProfile(requestParameters: UserControllerUpdateProfileRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.userControllerUpdateProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set storno reason for current user
     */
    async userControllerUpdateStornoReasonRaw(requestParameters: UserControllerUpdateStornoReasonRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.updateStornoReasonDto === null || requestParameters.updateStornoReasonDto === undefined) {
            throw new runtime.RequiredError('updateStornoReasonDto','Required parameter requestParameters.updateStornoReasonDto was null or undefined when calling userControllerUpdateStornoReason.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/storno-reason`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStornoReasonDtoToJSON(requestParameters.updateStornoReasonDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Set storno reason for current user
     */
    async userControllerUpdateStornoReason(requestParameters: UserControllerUpdateStornoReasonRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.userControllerUpdateStornoReasonRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
