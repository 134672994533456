/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailVerificationDto
 */
export interface EmailVerificationDto {
    /**
     * 
     * @type {string}
     * @memberof EmailVerificationDto
     */
    email: string;
}

export function EmailVerificationDtoFromJSON(json: any): EmailVerificationDto {
    return EmailVerificationDtoFromJSONTyped(json, false);
}

export function EmailVerificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailVerificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function EmailVerificationDtoToJSON(value?: EmailVerificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}

