import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
import { WatchlistDialogStore } from './watchlist-dialog-store';
import { WatchlistMobxDto } from '../mobx/dtos/watchlist/watchlist-mobx-dto';
import { WatchlistControllerGetListRequest } from '../../defs/api';
import { SymbolMobxDto } from '../mobx/dtos/symbol/symbol-mobx-dto';
import { WatchlistSymbolMobxDto } from '../mobx/dtos/watchlist/watchlist-symbol-mobx-dto';

export type WatchlistStoreHydration = {
    watchlists: WatchlistMobxDto[];
    symbols: SymbolMobxDto[];
};

export class WatchlistStore {
    rootStore: RootStore;

    watchlistDialogStore: WatchlistDialogStore;

    watchlists: WatchlistMobxDto[] = [];

    selectedWatchlist: WatchlistMobxDto | null = null;

    loading = false;

    symbols: WatchlistSymbolMobxDto[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.watchlistDialogStore = new WatchlistDialogStore(this);
        makeAutoObservable(this, {
            rootStore: false,
            watchlistDialogStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async fetchSymbolWatchlists(symbolId: string): Promise<void> {
        try {
            this.startLoading();
            const query: WatchlistControllerGetListRequest = { symbolId };

            const fetchedWatchlists = await this.rootStore.apiClient.watchlistsController.watchlistControllerGetList(
                query,
            );
            if (fetchedWatchlists) {
                runInAction(() => {
                    this.rootStore.symbolsStore.symbolDetailStore.setSymbolWatchlists(
                        WatchlistMobxDto.createFromArray(fetchedWatchlists),
                    );
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async fetchWatchlists(): Promise<void> {
        try {
            this.startLoading();

            const fetchedWatchlists = await this.rootStore.apiClient.watchlistsController.watchlistControllerGetList();
            if (fetchedWatchlists) {
                runInAction(() => {
                    this.watchlists = WatchlistMobxDto.createFromArray(fetchedWatchlists);
                    if (!this.selectedWatchlist && this.watchlists[0]) {
                        // eslint-disable-next-line prefer-destructuring
                        this.selectDefaultWatchlist();
                    }
                    if (this.selectedWatchlist) {
                        this.fetchSymbols();
                    }
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async addSymbolToWatchlist(symbolId: string | null, watchlistId: string | null): Promise<void> {
        if (symbolId && watchlistId) {
            try {
                this.startLoading();

                await this.rootStore.apiClient.watchlistsController.watchlistControllerAddSymbol({
                    id: watchlistId,
                    symbolId,
                });
                await this.fetchSymbolWatchlists(symbolId);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            } finally {
                this.stopLoading();
            }
        }
    }

    async deleteSymbolFromWatchlist(symbolId: string | null, watchlistId: string | null): Promise<void> {
        if (symbolId && watchlistId) {
            try {
                this.startLoading();

                const res = await this.rootStore.apiClient.watchlistsController.watchlistControllerDeleteSymbol({
                    id: watchlistId,
                    symbolId,
                });
                if (res) {
                    await this.fetchSymbolWatchlists(symbolId);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            } finally {
                this.stopLoading();
            }
        }
    }

    async addSymbol(symbolId: string | null): Promise<void> {
        if (symbolId && this.selectedWatchlist) {
            try {
                this.startLoading();

                await this.rootStore.apiClient.watchlistsController.watchlistControllerAddSymbol({
                    id: this.selectedWatchlist.id,
                    symbolId,
                });

                await this.fetchWatchlists();
                await this.rootStore.symbolsStore.fetchSymbols();
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            } finally {
                this.stopLoading();
            }
        }
    }

    async fetchSymbols() {
        if (this.selectedWatchlist?.id) {
            this.rootStore.symbolsStore.fetchWatchlistSymbols(this.selectedWatchlist.id);
        }
    }

    async selectWatchlist(watchlist: WatchlistMobxDto | string) {
        if (typeof watchlist === 'object') {
            this.selectedWatchlist = watchlist;
        }

        if (typeof watchlist === 'string') {
            this.selectedWatchlist = this.watchlists.filter((w) => w.id === watchlist).at(0) || null;
        }
        await this.fetchSymbols();
    }

    setSymbols(symbols: WatchlistSymbolMobxDto[]) {
        this.symbols = [...symbols];
        if (symbols.length < 1 && this.rootStore.symbolsStore.watchlistSymbolPage > 1) {
            this.rootStore.symbolsStore.setPrevWatchlistSymbolPage();
        }
    }

    get hasWatchlists() {
        return this.watchlists.length > 0;
    }

    get hasSymbols() {
        return this.symbols.length > 0;
    }

    selectDefaultWatchlist() {
        if (this.watchlists.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.selectedWatchlist = this.watchlists[0];
        }
    }

    getWatchlistById(id: string) {
        return this.watchlists.filter((w) => w.id === id).at(0);
    }
}
