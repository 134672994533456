import { makeAutoObservable } from 'mobx';
import { SymbolWithExchangeMobxDto } from '../symbol-with-exchange-mobx-dto';
import { SymbolFundamentalDataCashFlowDto } from '../../../../../defs/api';

export class SymbolDetailCashFlowMobxDto implements SymbolFundamentalDataCashFlowDto {
    public id: string = '';

    public symbol: SymbolWithExchangeMobxDto = new SymbolWithExchangeMobxDto();

    public date: string = '';

    public netIncome: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolFundamentalDataCashFlowDto): SymbolDetailCashFlowMobxDto {
        const mobxDto = new SymbolDetailCashFlowMobxDto();
        return Object.assign(mobxDto, dto, { symbol: SymbolWithExchangeMobxDto.create(dto.symbol) });
    }

    static createFromArray(dtos: SymbolFundamentalDataCashFlowDto[]): SymbolDetailCashFlowMobxDto[] {
        return dtos.map((dto) => SymbolDetailCashFlowMobxDto.create(dto));
    }
}
