/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TradeTypeEnum,
    TradeTypeEnumFromJSON,
    TradeTypeEnumFromJSONTyped,
    TradeTypeEnumToJSON,
} from './TradeTypeEnum';

/**
 * 
 * @export
 * @interface TradeDto
 */
export interface TradeDto {
    /**
     * 
     * @type {TradeTypeEnum}
     * @memberof TradeDto
     */
    type: TradeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeDto
     */
    currencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDto
     */
    symbolId: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDto
     */
    symbolCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDto
     */
    symbolName?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDto
     */
    symbolType?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDto
     */
    exchangeId?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDto
     */
    exchangeName?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDto
     */
    portfolioId: string;
    /**
     * 
     * @type {Date}
     * @memberof TradeDto
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof TradeDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof TradeDto
     */
    unitPrice: number;
    /**
     * 
     * @type {number}
     * @memberof TradeDto
     */
    fees: number;
    /**
     * 
     * @type {string}
     * @memberof TradeDto
     */
    note: string | null;
}

export function TradeDtoFromJSON(json: any): TradeDto {
    return TradeDtoFromJSONTyped(json, false);
}

export function TradeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': TradeTypeEnumFromJSON(json['type']),
        'currencyCode': json['currencyCode'],
        'symbolId': json['symbolId'],
        'symbolCode': !exists(json, 'symbolCode') ? undefined : json['symbolCode'],
        'symbolName': !exists(json, 'symbolName') ? undefined : json['symbolName'],
        'symbolType': !exists(json, 'symbolType') ? undefined : json['symbolType'],
        'exchangeId': !exists(json, 'exchangeId') ? undefined : json['exchangeId'],
        'exchangeName': !exists(json, 'exchangeName') ? undefined : json['exchangeName'],
        'portfolioId': json['portfolioId'],
        'date': (new Date(json['date'])),
        'amount': json['amount'],
        'unitPrice': json['unitPrice'],
        'fees': json['fees'],
        'note': json['note'],
    };
}

export function TradeDtoToJSON(value?: TradeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': TradeTypeEnumToJSON(value.type),
        'currencyCode': value.currencyCode,
        'symbolId': value.symbolId,
        'symbolCode': value.symbolCode,
        'symbolName': value.symbolName,
        'symbolType': value.symbolType,
        'exchangeId': value.exchangeId,
        'exchangeName': value.exchangeName,
        'portfolioId': value.portfolioId,
        'date': (value.date.toISOString()),
        'amount': value.amount,
        'unitPrice': value.unitPrice,
        'fees': value.fees,
        'note': value.note,
    };
}

