/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TradeExportDto,
    TradeExportDtoFromJSON,
    TradeExportDtoToJSON,
} from '../models';

/**
 * 
 */
export class TradesExportApi extends runtime.BaseAPI {

    /**
     * Export monery portfolio
     */
    async tradeExportControllerExportMoneryRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TradeExportDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trades-export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeExportDtoFromJSON(jsonValue));
    }

    /**
     * Export monery portfolio
     */
    async tradeExportControllerExportMonery(initOverrides?: RequestInit): Promise<TradeExportDto> {
        const response = await this.tradeExportControllerExportMoneryRaw(initOverrides);
        return await response.value();
    }

}
