/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LayoutEnum {
    Horizontal = 'horizontal',
    Vertical = 'vertical'
}

export function LayoutEnumFromJSON(json: any): LayoutEnum {
    return LayoutEnumFromJSONTyped(json, false);
}

export function LayoutEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LayoutEnum {
    return json as LayoutEnum;
}

export function LayoutEnumToJSON(value?: LayoutEnum | null): any {
    return value as any;
}

