/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolDividendDto,
    SymbolDividendDtoFromJSON,
    SymbolDividendDtoFromJSONTyped,
    SymbolDividendDtoToJSON,
} from './SymbolDividendDto';

/**
 * 
 * @export
 * @interface PaginatedSymbolDividendDto
 */
export interface PaginatedSymbolDividendDto {
    /**
     * 
     * @type {number}
     * @memberof PaginatedSymbolDividendDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedSymbolDividendDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedSymbolDividendDto
     */
    offset: number;
    /**
     * 
     * @type {Array<SymbolDividendDto>}
     * @memberof PaginatedSymbolDividendDto
     */
    items: Array<SymbolDividendDto>;
}

export function PaginatedSymbolDividendDtoFromJSON(json: any): PaginatedSymbolDividendDto {
    return PaginatedSymbolDividendDtoFromJSONTyped(json, false);
}

export function PaginatedSymbolDividendDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedSymbolDividendDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'limit': json['limit'],
        'offset': json['offset'],
        'items': ((json['items'] as Array<any>).map(SymbolDividendDtoFromJSON)),
    };
}

export function PaginatedSymbolDividendDtoToJSON(value?: PaginatedSymbolDividendDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'limit': value.limit,
        'offset': value.offset,
        'items': ((value.items as Array<any>).map(SymbolDividendDtoToJSON)),
    };
}

