import React, { useState } from 'react';
import { enableStaticRendering } from 'mobx-react-lite';

import { StatusMessageEnum } from '@app/common';
import { Api } from '../models/api';
import { RootStore, RootStoreHydration } from '../models/stores/root-store';
import { CookieService } from '../models/services/cookie-service';
import { LoadingPage } from '../components/app/loading/page/loading-page';
import ApiClient from '../models/api-client';

enableStaticRendering(typeof window === 'undefined');

declare global {
    interface Window {
        clientRootStore?: RootStore;
    }
}

const RootStoreContext = React.createContext<RootStore>({} as RootStore);

export const useRootStore = (): RootStore => {
    const context = React.useContext(RootStoreContext);
    if (context === undefined) {
        throw new Error(StatusMessageEnum.UseStoreMustBeInProvider);
    }

    return context;
};

interface RootStoreProviderProps {
    host: string;
    cookieService: CookieService;
    initialData?: RootStoreHydration;
    children: React.ReactNode;
    isSSR?: boolean;
}

export function initializeRootStore(
    host: string,
    cookieService: CookieService,
    initialData?: RootStoreHydration,
): RootStore {
    const isServer = typeof window === 'undefined';

    // check if we already declare store (client Store), otherwise create one
    const rootStoreInstance =
        !isServer && window.clientRootStore
            ? window.clientRootStore
            : new RootStore(
                  host,
                  new Api(host, cookieService),
                  new ApiClient(cookieService, {
                      BASE: host.replace('/api', ''),
                      WITH_CREDENTIALS: true,
                  }),
              );

    rootStoreInstance.cookieService = cookieService;

    if (initialData) {
        rootStoreInstance.rehydrate(initialData);
    }

    if (isServer) {
        // Create a store on every server request
        return rootStoreInstance;
    }

    // Otherwise it's client, remember this store and return
    if (!window.clientRootStore) {
        window.clientRootStore = rootStoreInstance;
    }

    return rootStoreInstance;
}

export const RootStoreProvider: React.FC<RootStoreProviderProps> = ({
    host,
    cookieService,
    initialData,
    children,
    isSSR,
}: RootStoreProviderProps): React.ReactElement => {
    const [store, setStore] = React.useState<RootStore | null>(initializeRootStore(host, cookieService));
    const [initData, setInitData] = React.useState<RootStoreHydration | undefined>(initialData);
    const [hasPortfolios, setHasPortfolios] = useState(false);

    const userId = initialData?.authStore?.userProfile?.id;

    const hasNoUserData = !initialData?.authStore?.userProfile && cookieService.hasJwtToken();

    // V1)
    React.useEffect(() => {
        setInitData(initialData);
        const rootStoreInstance = initializeRootStore(host, cookieService);
        if (initialData && isSSR) {
            rootStoreInstance.rehydrate(initialData);
        }
        setStore(rootStoreInstance);
    }, [host, cookieService, initialData, userId, initializeRootStore]);

    // We need to have portfolio available almost everywhere before rendering anything - so we must load it and block rendering
    // until it is loaded we display spinner/skeleton etc.
    React.useEffect(() => {
        if (!isSSR && store) {
            store.authStore.fetchUserProfile().then(() => {
                store.portfolioStore.fetchPortfolioList().then((done) => {
                    if (done) {
                        setHasPortfolios(true);
                    }
                });
            });
        }
    }, [isSSR, hasNoUserData, store]);

    if (!store || (initData !== initialData && isSSR)) {
        return <></>;
    }

    if (!hasPortfolios && !isSSR) {
        return <LoadingPage />;
    }

    return <RootStoreContext.Provider value={store}>{children}</RootStoreContext.Provider>;
};
