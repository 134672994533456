/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LookupKeyEnum,
    LookupKeyEnumFromJSON,
    LookupKeyEnumFromJSONTyped,
    LookupKeyEnumToJSON,
} from './LookupKeyEnum';

/**
 * 
 * @export
 * @interface SubscriptionPriceLookupKeyDto
 */
export interface SubscriptionPriceLookupKeyDto {
    /**
     * 
     * @type {LookupKeyEnum}
     * @memberof SubscriptionPriceLookupKeyDto
     */
    lookupKey: LookupKeyEnum;
}

export function SubscriptionPriceLookupKeyDtoFromJSON(json: any): SubscriptionPriceLookupKeyDto {
    return SubscriptionPriceLookupKeyDtoFromJSONTyped(json, false);
}

export function SubscriptionPriceLookupKeyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionPriceLookupKeyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lookupKey': LookupKeyEnumFromJSON(json['lookupKey']),
    };
}

export function SubscriptionPriceLookupKeyDtoToJSON(value?: SubscriptionPriceLookupKeyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lookupKey': LookupKeyEnumToJSON(value.lookupKey),
    };
}

