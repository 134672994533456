/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TradeWithSymbolDto,
    TradeWithSymbolDtoFromJSON,
    TradeWithSymbolDtoFromJSONTyped,
    TradeWithSymbolDtoToJSON,
} from './TradeWithSymbolDto';

/**
 * 
 * @export
 * @interface CreatedTradeDto
 */
export interface CreatedTradeDto {
    /**
     * 
     * @type {TradeWithSymbolDto}
     * @memberof CreatedTradeDto
     */
    trade?: TradeWithSymbolDto;
    /**
     * 
     * @type {string}
     * @memberof CreatedTradeDto
     */
    symbol?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatedTradeDto
     */
    currenciesForSymbol?: Array<string>;
}

export function CreatedTradeDtoFromJSON(json: any): CreatedTradeDto {
    return CreatedTradeDtoFromJSONTyped(json, false);
}

export function CreatedTradeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatedTradeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trade': !exists(json, 'trade') ? undefined : TradeWithSymbolDtoFromJSON(json['trade']),
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
        'currenciesForSymbol': !exists(json, 'currenciesForSymbol') ? undefined : json['currenciesForSymbol'],
    };
}

export function CreatedTradeDtoToJSON(value?: CreatedTradeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trade': TradeWithSymbolDtoToJSON(value.trade),
        'symbol': value.symbol,
        'currenciesForSymbol': value.currenciesForSymbol,
    };
}

