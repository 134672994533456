/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DividendCalendarDto,
    DividendCalendarDtoFromJSON,
    DividendCalendarDtoFromJSONTyped,
    DividendCalendarDtoToJSON,
} from './DividendCalendarDto';
import {
    DividendCalendarMonthDto,
    DividendCalendarMonthDtoFromJSON,
    DividendCalendarMonthDtoFromJSONTyped,
    DividendCalendarMonthDtoToJSON,
} from './DividendCalendarMonthDto';
import {
    SymbolDividendCalendarDto,
    SymbolDividendCalendarDtoFromJSON,
    SymbolDividendCalendarDtoFromJSONTyped,
    SymbolDividendCalendarDtoToJSON,
} from './SymbolDividendCalendarDto';
import {
    SymbolDividendChartDto,
    SymbolDividendChartDtoFromJSON,
    SymbolDividendChartDtoFromJSONTyped,
    SymbolDividendChartDtoToJSON,
} from './SymbolDividendChartDto';
import {
    SymbolDividendDto,
    SymbolDividendDtoFromJSON,
    SymbolDividendDtoFromJSONTyped,
    SymbolDividendDtoToJSON,
} from './SymbolDividendDto';

/**
 * 
 * @export
 * @interface DividendListDto
 */
export interface DividendListDto {
    /**
     * 
     * @type {Array<DividendCalendarDto>}
     * @memberof DividendListDto
     */
    calendar: Array<DividendCalendarDto>;
    /**
     * 
     * @type {Array<SymbolDividendDto>}
     * @memberof DividendListDto
     */
    upcomingDividends: Array<SymbolDividendDto>;
    /**
     * 
     * @type {Array<SymbolDividendChartDto>}
     * @memberof DividendListDto
     */
    chart: Array<SymbolDividendChartDto>;
    /**
     * 
     * @type {Array<SymbolDividendCalendarDto>}
     * @memberof DividendListDto
     */
    symbolCalendar: Array<SymbolDividendCalendarDto>;
    /**
     * 
     * @type {Array<DividendCalendarMonthDto>}
     * @memberof DividendListDto
     */
    symbolCalendarTotal: Array<DividendCalendarMonthDto>;
}

export function DividendListDtoFromJSON(json: any): DividendListDto {
    return DividendListDtoFromJSONTyped(json, false);
}

export function DividendListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DividendListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calendar': ((json['calendar'] as Array<any>).map(DividendCalendarDtoFromJSON)),
        'upcomingDividends': ((json['upcomingDividends'] as Array<any>).map(SymbolDividendDtoFromJSON)),
        'chart': ((json['chart'] as Array<any>).map(SymbolDividendChartDtoFromJSON)),
        'symbolCalendar': ((json['symbolCalendar'] as Array<any>).map(SymbolDividendCalendarDtoFromJSON)),
        'symbolCalendarTotal': ((json['symbolCalendarTotal'] as Array<any>).map(DividendCalendarMonthDtoFromJSON)),
    };
}

export function DividendListDtoToJSON(value?: DividendListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calendar': ((value.calendar as Array<any>).map(DividendCalendarDtoToJSON)),
        'upcomingDividends': ((value.upcomingDividends as Array<any>).map(SymbolDividendDtoToJSON)),
        'chart': ((value.chart as Array<any>).map(SymbolDividendChartDtoToJSON)),
        'symbolCalendar': ((value.symbolCalendar as Array<any>).map(SymbolDividendCalendarDtoToJSON)),
        'symbolCalendarTotal': ((value.symbolCalendarTotal as Array<any>).map(DividendCalendarMonthDtoToJSON)),
    };
}

