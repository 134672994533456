import { makeAutoObservable } from 'mobx';
import { SymbolGrowthRateDto } from '../../../../defs/api';

export class SymbolGrowthRateMobxDto implements SymbolGrowthRateDto {
    public date: string = '';

    public netIncome: number = 0;

    public expectedProfitGrowthRate: number = 0;

    public calculatedProfitGrowthRate: number = 0;

    public current: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolGrowthRateDto): SymbolGrowthRateMobxDto {
        const mobxDto = new SymbolGrowthRateMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: SymbolGrowthRateDto[]): SymbolGrowthRateMobxDto[] {
        return dtos.map((dto) => SymbolGrowthRateMobxDto.create(dto));
    }
}
