import { makeAutoObservable } from 'mobx';
import { SymbolDetailMobxDto } from './symbol-detail-mobx-dto';
import { ProfitMonthDto } from '../../../../defs/api';

export class ProfitMonthMobxDto implements ProfitMonthDto {
    month: number = 0;

    profitPercentage: number = 0;

    profitPerformance: number = 0;

    profitAbsolute: number = 0;

    bestEarner: SymbolDetailMobxDto = new SymbolDetailMobxDto();

    worstEarner: SymbolDetailMobxDto = new SymbolDetailMobxDto();

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: ProfitMonthDto): ProfitMonthMobxDto {
        const mobxDto = new ProfitMonthMobxDto();
        return Object.assign(mobxDto, dto, {
            bestEarner: SymbolDetailMobxDto.create(dto.bestEarner),
            worstEarner: SymbolDetailMobxDto.create(dto.worstEarner),
        });
    }

    static createFromArray(dtos: ProfitMonthDto[]): ProfitMonthMobxDto[] {
        return dtos.map((dto) => ProfitMonthMobxDto.create(dto));
    }
}
