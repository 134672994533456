import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { color } from '../colors';

export const createAvatar = (t: ThemeEnum) => {
    const avatar: DefaultTheme['avatar'] = {
        size: {
            xsmall: {
                icon: '1.2rem',
                wrapper: '3.0rem',
            },
            small: {
                icon: '1.4rem',
                wrapper: '3.2rem',
            },
            medium: {
                icon: '1.8rem',
                wrapper: '4.0rem',
            },
            large: {
                icon: '2.2rem',
                wrapper: '4.8rem',
            },
        },
        variant: {
            default: {
                background: color[t].light.gray,
                color: color[t].base.text,
            },
            primary: {
                background: color[t].base.primary,
                color: color[t].base.white,
            },
            secondary: {
                background: color[t].base.secondary,
                color: color[t].base.white,
            },
            positive: {
                background: color[t].base.positive,
                color: color[t].base.white,
            },
            negative: {
                background: color[t].base.negative,
                color: color[t].base.white,
            },
        },
    };
    return avatar;
};
