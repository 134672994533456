import { makeAutoObservable, runInAction } from 'mobx';
import { ErrorStatusEnum, StatusMessageEnum } from '@app/common';
import type { RootStore } from './root-store';
import { AuthDialogStore } from './auth-dialog-store';
import { ImpersonationDialogStore } from './impersonation-dialog-store';
import { UserProfileMobxDto } from '../mobx/dtos/user/user-profile-mobx-dto';
import { LoginUserMobxDto } from '../mobx/dtos/auth/login-user-mobx-dto';
import { SubscriptionEnum } from '../../defs/api';
import { SignUpUserMobxDto } from '../mobx/dtos/auth/sign-up-user-mobx-dto';
import { ForgottenPasswordMobxDto } from '../mobx/dtos/auth/forgotten-password-mobx-dto';

export type AuthStoreHydration = {
    userProfile: UserProfileMobxDto | null;
};

export class AuthStore {
    rootStore: RootStore;

    authDialogStore: AuthDialogStore;

    impersonationDialogStore: ImpersonationDialogStore;

    userProfile: UserProfileMobxDto | null = null;

    loading = false;

    registrationDone = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.authDialogStore = new AuthDialogStore(this);
        this.impersonationDialogStore = new ImpersonationDialogStore(this);
        makeAutoObservable(this, {
            rootStore: false,
            authDialogStore: false,
            impersonationDialogStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    hydrate(): AuthStoreHydration {
        return {
            userProfile: this.userProfile ? JSON.parse(JSON.stringify(this.userProfile)) : null,
        };
    }

    rehydrate(data: AuthStoreHydration): void {
        if (data.userProfile) {
            this.userProfile = UserProfileMobxDto.create(data.userProfile);
        }
    }

    async login(user: LoginUserMobxDto): Promise<void> {
        this.authDialogStore.setLoading(true);

        const res = await this.rootStore.apiClient.authController.authControllerLogin({ loginUserDto: user });
        if (res && res.accessToken) {
            this.rootStore.cookieService.setJwtToken(res.accessToken, user.rememberMe);
        }
    }

    get userHasFreeTariff(): boolean {
        return this.userProfile?.subscription === SubscriptionEnum.Free;
    }

    get userHasPremiumTariff(): boolean {
        return this.userProfile?.subscription === SubscriptionEnum.Premium;
    }

    logout() {
        if (this.rootStore.cookieService.hasJwtImpersonateToken()) {
            this.rootStore.cookieService.setImpersonateJwtToken(null);
            window.location.reload();
        } else {
            this.rootStore.cookieService.setJwtToken(null);
            this.userProfile = null;
            this.rootStore.alertStore.clearAllMessages();
        }
    }

    async signUp(user: SignUpUserMobxDto): Promise<void> {
        this.authDialogStore.setLoading(true);

        try {
            await this.rootStore.apiClient.authController.authControllerSignUp({ signUpUserDto: user });
            runInAction(() => {
                this.registrationDone = true;
            });
        } catch (e) {
            this.authDialogStore.setLoading(false);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.authDialogStore.setLoading(false);
        }
    }

    async sendResetPasswordEmail(data: ForgottenPasswordMobxDto): Promise<void> {
        this.authDialogStore.setLoading(true);
        try {
            const res = await this.rootStore.apiClient.authController.authControllerSendResetPasswordEmail({
                resetPasswordTokenPayloadDto: data,
            });
            this.authDialogStore.setLoading(false);
            if (!res) {
                throw new Error(ErrorStatusEnum.EmailNotFound);
            }

            this.authDialogStore.setResetPasswordActive();
        } catch (e) {
            this.authDialogStore.setLoading(false);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            throw e;
        }
    }

    async resetPassword(token: string, password: string): Promise<void> {
        this.authDialogStore.setLoading(true);
        try {
            const res = await this.rootStore.apiClient.authController.authControllerResetPassword({
                resetPasswordDto: {
                    token,
                    password,
                },
            });
            if (!res) {
                throw new Error();
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.authDialogStore.setLoading(false);
        }
    }

    async fetchUserProfile(): Promise<void> {
        this.startLoading();
        try {
            const userProfile = await this.rootStore.apiClient.authController.authControllerGetProfile();
            if (userProfile) {
                runInAction(() => {
                    this.userProfile = UserProfileMobxDto.create(userProfile);
                    this.rootStore.themeStore.init();
                    this.rootStore.userStore.init();
                    this.rootStore.statisticStore.init();
                });
            }
        } catch (e) {
            // no-op
        } finally {
            this.stopLoading();
        }
    }

    async sendVerificationEmail(email: string): Promise<void> {
        this.authDialogStore.setLoading(true);
        try {
            const res = await this.rootStore.apiClient.authController.authControllerSendVerificationLink({
                emailVerificationDto: { email },
            });
            if (!res) {
                throw new Error();
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.authDialogStore.setLoading(false);
        }
    }

    async confirmEmail(accessToken: string): Promise<void> {
        this.authDialogStore.confirmLoading = true;
        this.authDialogStore.setLoginActive(true);
        try {
            const res = await this.rootStore.apiClient.authController.authControllerConfirmEmail({
                accessTokenDto: { accessToken },
            });

            if (res) {
                runInAction(() => {
                    this.authDialogStore.confirmDone = true;
                });
            }
        } catch (e) {
            if (e.message === StatusMessageEnum.EmailAlreadyConfirmed) {
                runInAction(() => {
                    this.authDialogStore.confirmDone = true;
                });
            } else {
                this.rootStore.alertStore.setErrorMessageByStatus(e.message);
            }
        } finally {
            runInAction(() => {
                this.authDialogStore.confirmLoading = false;
            });
        }
    }

    async impersonation(email: string): Promise<void> {
        this.impersonationDialogStore.setLoading(true);
        try {
            const res = await this.rootStore.apiClient.authController.authControllerImpersonation({ email });
            if (res && res.accessToken) {
                this.rootStore.cookieService.setImpersonateJwtToken(res.accessToken);
                window.location.reload();
            }
        } finally {
            // errors handle in component
            this.impersonationDialogStore.setLoading(false);
        }
    }

    async deleteAccount(): Promise<void> {
        this.startLoading();
        try {
            const res = await this.rootStore.apiClient.userController.userControllerDeleteAccount();
            if (res) {
                this.logout();
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
