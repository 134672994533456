import React from 'react';
import { useTranslation } from 'react-i18next';
import Head from 'next/head';
import { LayoutError } from '../blocks/web/layout/error/layout-error';
import { PageError } from '../blocks/web/page-error/page-error';

const Maintenance: React.FC = () => {
    const { t } = useTranslation();
    return (
        <LayoutError>
            <Head>
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://monery.io`} />
                <meta property="og:image" content={`https://monery.io/images/social-covers/monery.jpg`} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={`https://monery.io`} />
                <meta property="twitter:image" content={`https://monery.io/images/social-covers/monery.jpg`} />
                <title>
                    {t('pages.error.maintenance.meta.title')}
                    {t('meta.title.suffix')}
                </title>
                <meta name="description" content={t('pages.error.maintenance.meta.description')} />
                <meta property="og:title" content={t('pages.error.maintenance.meta.title')} />
                <meta property="og:description" content={t('pages.error.maintenance.meta.description')} />
                <meta property="twitter:title" content={t('pages.error.maintenance.meta.title')} />
                <meta property="twitter:description" content={t('pages.error.maintenance.meta.description')} />
            </Head>
            <PageError
                type={t('pages.error.maintenance.title')}
                description={t('pages.error.maintenance.description')}
            />
        </LayoutError>
    );
};

export default Maintenance;
