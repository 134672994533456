/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TradeTypeEnum,
    TradeTypeEnumFromJSON,
    TradeTypeEnumFromJSONTyped,
    TradeTypeEnumToJSON,
} from './TradeTypeEnum';

/**
 * 
 * @export
 * @interface CreateTradeDto
 */
export interface CreateTradeDto {
    /**
     * 
     * @type {TradeTypeEnum}
     * @memberof CreateTradeDto
     */
    type: TradeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTradeDto
     */
    currencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTradeDto
     */
    symbolId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTradeDto
     */
    portfolioId: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateTradeDto
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof CreateTradeDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTradeDto
     */
    unitPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTradeDto
     */
    fees: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTradeDto
     */
    note: string | null;
}

export function CreateTradeDtoFromJSON(json: any): CreateTradeDto {
    return CreateTradeDtoFromJSONTyped(json, false);
}

export function CreateTradeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTradeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': TradeTypeEnumFromJSON(json['type']),
        'currencyCode': json['currencyCode'],
        'symbolId': json['symbolId'],
        'portfolioId': json['portfolioId'],
        'date': (new Date(json['date'])),
        'amount': json['amount'],
        'unitPrice': json['unitPrice'],
        'fees': json['fees'],
        'note': json['note'],
    };
}

export function CreateTradeDtoToJSON(value?: CreateTradeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': TradeTypeEnumToJSON(value.type),
        'currencyCode': value.currencyCode,
        'symbolId': value.symbolId,
        'portfolioId': value.portfolioId,
        'date': (value.date.toISOString()),
        'amount': value.amount,
        'unitPrice': value.unitPrice,
        'fees': value.fees,
        'note': value.note,
    };
}

