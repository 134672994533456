/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WatchlistSymbolDto
 */
export interface WatchlistSymbolDto {
    /**
     * 
     * @type {string}
     * @memberof WatchlistSymbolDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WatchlistSymbolDto
     */
    symbol: string;
    /**
     * 
     * @type {string}
     * @memberof WatchlistSymbolDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WatchlistSymbolDto
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof WatchlistSymbolDto
     */
    exchangeId: string;
    /**
     * 
     * @type {string}
     * @memberof WatchlistSymbolDto
     */
    exchangeName: string;
    /**
     * 
     * @type {number}
     * @memberof WatchlistSymbolDto
     */
    marketCapitalization: number | null;
}

export function WatchlistSymbolDtoFromJSON(json: any): WatchlistSymbolDto {
    return WatchlistSymbolDtoFromJSONTyped(json, false);
}

export function WatchlistSymbolDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WatchlistSymbolDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'symbol': json['symbol'],
        'name': json['name'],
        'currency': json['currency'],
        'exchangeId': json['exchangeId'],
        'exchangeName': json['exchangeName'],
        'marketCapitalization': json['marketCapitalization'],
    };
}

export function WatchlistSymbolDtoToJSON(value?: WatchlistSymbolDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'symbol': value.symbol,
        'name': value.name,
        'currency': value.currency,
        'exchangeId': value.exchangeId,
        'exchangeName': value.exchangeName,
        'marketCapitalization': value.marketCapitalization,
    };
}

