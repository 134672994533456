/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetPasswordTokenPayloadDto
 */
export interface ResetPasswordTokenPayloadDto {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordTokenPayloadDto
     */
    email: string;
}

export function ResetPasswordTokenPayloadDtoFromJSON(json: any): ResetPasswordTokenPayloadDto {
    return ResetPasswordTokenPayloadDtoFromJSONTyped(json, false);
}

export function ResetPasswordTokenPayloadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordTokenPayloadDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function ResetPasswordTokenPayloadDtoToJSON(value?: ResetPasswordTokenPayloadDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}

