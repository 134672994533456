/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SymbolDetailDto
 */
export interface SymbolDetailDto {
    /**
     * 
     * @type {string}
     * @memberof SymbolDetailDto
     */
    symbol: string;
    /**
     * 
     * @type {number}
     * @memberof SymbolDetailDto
     */
    valueChangeAbsolute: number;
    /**
     * 
     * @type {number}
     * @memberof SymbolDetailDto
     */
    valueChangePercentage: number;
}

export function SymbolDetailDtoFromJSON(json: any): SymbolDetailDto {
    return SymbolDetailDtoFromJSONTyped(json, false);
}

export function SymbolDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'symbol': json['symbol'],
        'valueChangeAbsolute': json['valueChangeAbsolute'],
        'valueChangePercentage': json['valueChangePercentage'],
    };
}

export function SymbolDetailDtoToJSON(value?: SymbolDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'symbol': value.symbol,
        'valueChangeAbsolute': value.valueChangeAbsolute,
        'valueChangePercentage': value.valueChangePercentage,
    };
}

