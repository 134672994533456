import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { color } from '../colors';
import { getOpacity } from '../utils/get-rgb';

export const createAllocationsProgressBar = (t: ThemeEnum) => {
    const allocationsProgressbar: DefaultTheme['allocationsProgressBar'] = {
        base: {
            radius: '1.2rem',
            padding: '1.5rem',
        },
        variant: {
            default: {
                background: getOpacity(color[t].base.gray),
                bar: getOpacity(color[t].base.gray, 0.24),
            },
            primary: {
                background: getOpacity(color[t].light.primary),
                bar: getOpacity(color[t].light.primary, 0.64),
            },
            positive: {
                background: getOpacity(color[t].base.positive),
                bar: getOpacity(color[t].base.positive, 0.24),
            },
            negative: {
                background: getOpacity(color[t].base.negative),
                bar: getOpacity(color[t].base.negative, 0.24),
            },
        },
    };
    return allocationsProgressbar;
};
