import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    text-align: center;
`;

export const Type = styled.div(
    ({ theme }) => css`
        font-size: 5rem;
        color: ${theme.palette.color.white.main};
        line-height: 1;
        font-weight: 800;
        margin-bottom: ${theme.gap.xlarge};

        @media ${theme.breakpoints.sm.min} {
            font-size: 8rem;
        }

        @media ${theme.breakpoints.md.min} {
            font-size: 11rem;
        }

        @media ${theme.breakpoints.vl.min} {
            font-size: 16rem;
        }

        @media ${theme.breakpoints.xl.min} {
            font-size: 20rem;
        }

        @media ${theme.breakpoints.xxl.min} {
            font-size: 24.5rem;
        }
    `,
);

export const Description = styled.div(
    ({ theme }) => css`
        color: ${theme.palette.color.white.main};
        text-align: center;
        font-size: ${theme.text.size.large.fontSize};
        line-height: ${theme.text.size.large.lineHeight};
        font-weight: 500;
        max-width: 50rem;
        margin: 0 auto ${theme.gap.xlarge};

        @media ${theme.breakpoints.md.min} {
            font-size: ${theme.text.size.xxlarge.fontSize};
            line-height: ${theme.text.size.xxlarge.lineHeight};
        }
    `,
);
