import { makeAutoObservable } from 'mobx';
import { PieChartDataMobxDto } from './pie-chart-data-mobx-dto';
import { PieChartDto } from '../../../../defs/api';

export class PieChartMobxDto implements PieChartDto {
    data: PieChartDataMobxDto[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: PieChartDto): PieChartMobxDto {
        const mobxDto = new PieChartMobxDto();
        return Object.assign(mobxDto, dto, {
            data: PieChartDataMobxDto.createFromArray(dto.data),
        });
    }

    static createFromArray(dtos: PieChartDto[]): PieChartMobxDto[] {
        return dtos.map((dto) => PieChartMobxDto.create(dto));
    }
}
