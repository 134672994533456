import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
import { LeaderboardMobxDto } from '../mobx/dtos/leaderboard/leaderboard-mobx-dto';
import { IndividualRankingsMobxDto } from '../mobx/dtos/leaderboard/individual-rankings-mobx-dto';

export class LeaderboardStore {
    rootStore: RootStore;

    loading = false;

    leaderboard: LeaderboardMobxDto | null = null;

    individualRankings: IndividualRankingsMobxDto | null = null;

    month: number = 0;

    firstMonth: number = 0;

    firstYear: number = 0;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async fetchLeaderboard(month: number, year: number): Promise<void> {
        this.startLoading();
        try {
            const query = {
                month,
                year,
            };

            const [leaderboardDto, individualRankingsDto] = await Promise.all([
                this.rootStore.apiClient.leaderboardController.leaderboardControllerGetLeaderboard(query),
                this.rootStore.apiClient.leaderboardController.leaderboardControllerGetIndividualRankings(query),
            ]);

            runInAction(() => {
                this.month = month;
                this.leaderboard = LeaderboardMobxDto.create(leaderboardDto);
                this.individualRankings = IndividualRankingsMobxDto.create(individualRankingsDto);
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async fetchFirstDate(): Promise<void> {
        this.startLoading();
        try {
            const firstDate =
                await this.rootStore.apiClient.leaderboardController.leaderboardControllerGetFirstLeaderboardDate();

            runInAction(() => {
                this.firstMonth = firstDate ? firstDate.month : new Date().getMonth() + 1;
                this.firstYear = firstDate ? firstDate.year : new Date().getFullYear();
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
