import { makeAutoObservable } from 'mobx';
import { WaitingUserDto } from '../../../../../defs/api';

export class WaitingUserMobxDto implements WaitingUserDto {
    public id: string = '';

    public email: string = '';

    public fullName: string = '';

    public registrationDate: Date = new Date();

    public daysSinceRegistration: number = 0;

    public isSelected: boolean = false;

    constructor() {
        makeAutoObservable(this, {});
    }

    static create(dto: WaitingUserDto): WaitingUserMobxDto {
        const mobxDto = new WaitingUserMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: WaitingUserDto[]): WaitingUserMobxDto[] {
        return dtos.map((dto) => WaitingUserMobxDto.create(dto));
    }

    public switchSelected(): void {
        this.isSelected = !this.isSelected;
    }

    public setSelected(selected: boolean): void {
        this.isSelected = selected;
    }
}
