import React from 'react';
import { DefaultTheme, ThemeProvider as StyledComponentThemeProvider } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { StatusMessageEnum } from '@app/common';
import { createTheme } from '../theme/theme';
import { useRootStore } from './root-store-provider';

const ThemeContext = React.createContext<DefaultTheme>({} as DefaultTheme);

export const useTheme = (): DefaultTheme => {
    const context = React.useContext(ThemeContext);
    if (context === undefined) {
        throw new Error(StatusMessageEnum.UseStoreMustBeInProvider);
    }

    return context;
};

interface ThemeProviderProps {
    children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = observer(
    ({ children }: ThemeProviderProps): React.ReactElement => {
        const rootStore = useRootStore();
        const theme: DefaultTheme = createTheme(rootStore.themeStore.theme);

        return <StyledComponentThemeProvider theme={theme}>{children}</StyledComponentThemeProvider>;
    },
);
