/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface SymbolLogoControllerGetRequest {
    symbol: string;
    type?: string;
}

/**
 * 
 */
export class LogoApi extends runtime.BaseAPI {

    /**
     * Get symbol logo
     */
    async symbolLogoControllerGetRaw(requestParameters: SymbolLogoControllerGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.symbol === null || requestParameters.symbol === undefined) {
            throw new runtime.RequiredError('symbol','Required parameter requestParameters.symbol was null or undefined when calling symbolLogoControllerGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/logo/{symbol}`.replace(`{${"symbol"}}`, encodeURIComponent(String(requestParameters.symbol))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get symbol logo
     */
    async symbolLogoControllerGet(requestParameters: SymbolLogoControllerGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.symbolLogoControllerGetRaw(requestParameters, initOverrides);
    }

}
