import React from 'react';
import { Label, Wrapper } from './button.styled';
import { ButtonIcon } from './icon/button-icon';
import { IconSpinner } from '../../icons/spinner/spinner';
import { GapSizeType } from '../../../../theme/type/gap-type';
import { ButtonSizeType, ButtonVariantType, ButtonVariationType } from '../../../../theme/type/button-type';
import { BreakpointSizeType } from '../../../../theme/type/breakpoints-type';

export interface ButtonControlPropsInterface {
    children?: JSX.Element | Array<JSX.Element> | string;
    href?: string;
    as?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    loading?: boolean;
    id?: string;
    form?: string;
    button?: string;
    variation?: ButtonVariationType;
    size?: ButtonSizeType | object;
    variant?: ButtonVariantType;
    block?: boolean;
    disabled?: boolean;
    icon?: React.ReactNode;
    gradient?: boolean;
    shadow?: boolean;
    hideLabelUnder?: BreakpointSizeType;
    hideLabelOver?: BreakpointSizeType;
    iconPosition?: 'left' | 'right';
    iconGap?: GapSizeType;
    disableResponsive?: boolean;
    type?: 'button' | 'submit' | 'reset' | undefined;
}
export const ButtonControl: React.FC<ButtonControlPropsInterface> = ({
    children,
    loading,
    variant = 'default',
    size = 'medium',
    variation = 'filled',
    disabled,
    form,
    icon,
    iconPosition,
    iconGap = 'medium',
    id,
    onClick,
    gradient,
    shadow,
    block,
    type,
    hideLabelUnder,
    hideLabelOver,
    disableResponsive = false,
}) => {
    return (
        <Wrapper
            disabled={disabled}
            id={id}
            onClick={onClick}
            block={block}
            variant={variant}
            type={type}
            gradient={gradient}
            shadow={shadow}
            variation={variation}
            icon={!!icon}
            form={form}
            size={size}
            hideLabelUnder={hideLabelUnder}
            hideLabelOver={hideLabelOver}
            disableResponsive={disableResponsive}
        >
            {children && (
                <Label size={size} hideLabelUnder={hideLabelUnder} hideLabelOver={hideLabelOver}>
                    {children}
                </Label>
            )}
            {icon && (
                <ButtonIcon
                    position={iconPosition}
                    icon={loading ? <IconSpinner /> : icon}
                    size={size}
                    gap={iconGap}
                    variant={variant}
                    variation={variation}
                    hideLabelUnder={hideLabelUnder}
                    hideLabelOver={hideLabelOver}
                />
            )}
            {!icon && (
                <>
                    {loading && (
                        <ButtonIcon
                            position={iconPosition}
                            icon={<IconSpinner />}
                            gap="none"
                            size={size}
                            variant={variant}
                            variation={variation}
                            hideLabelUnder={hideLabelUnder}
                            hideLabelOver={hideLabelOver}
                        />
                    )}
                </>
            )}
        </Wrapper>
    );
};
