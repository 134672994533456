/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OverviewDto,
    OverviewDtoFromJSON,
    OverviewDtoToJSON,
    ProfitChartDto,
    ProfitChartDtoFromJSON,
    ProfitChartDtoToJSON,
    RatiosDto,
    RatiosDtoFromJSON,
    RatiosDtoToJSON,
} from '../models';

export interface SharedStatisticControllerGetOverviewRequest {
    portfolioId: string;
    calculateFees: boolean;
    dateFrom?: string;
}

export interface SharedStatisticControllerGetProfitChartsRequest {
    portfolioId: string;
    dateFrom?: string;
    dateTo?: string;
}

export interface SharedStatisticControllerGetRatiosRequest {
    portfolioId: string;
    dateFrom?: string;
    dateTo?: string;
}

export interface StatisticControllerGetOverviewRequest {
    portfolioId: string;
    calculateFees: boolean;
    dateFrom?: string;
}

export interface StatisticControllerGetProfitChartsRequest {
    portfolioId: string;
    dateFrom?: string;
    dateTo?: string;
}

export interface StatisticControllerGetRatiosRequest {
    portfolioId: string;
    dateFrom?: string;
    dateTo?: string;
}

/**
 * 
 */
export class StatisticsApi extends runtime.BaseAPI {

    /**
     * Get overview
     */
    async sharedStatisticControllerGetOverviewRaw(requestParameters: SharedStatisticControllerGetOverviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OverviewDto>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling sharedStatisticControllerGetOverview.');
        }

        if (requestParameters.calculateFees === null || requestParameters.calculateFees === undefined) {
            throw new runtime.RequiredError('calculateFees','Required parameter requestParameters.calculateFees was null or undefined when calling sharedStatisticControllerGetOverview.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        if (requestParameters.calculateFees !== undefined) {
            queryParameters['calculateFees'] = requestParameters.calculateFees;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/statistics/shared/{portfolioId}/overview`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OverviewDtoFromJSON(jsonValue));
    }

    /**
     * Get overview
     */
    async sharedStatisticControllerGetOverview(requestParameters: SharedStatisticControllerGetOverviewRequest, initOverrides?: RequestInit): Promise<OverviewDto> {
        const response = await this.sharedStatisticControllerGetOverviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get profit area chart data
     */
    async sharedStatisticControllerGetProfitChartsRaw(requestParameters: SharedStatisticControllerGetProfitChartsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfitChartDto>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling sharedStatisticControllerGetProfitCharts.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/statistics/shared/{portfolioId}/profit-charts`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfitChartDtoFromJSON(jsonValue));
    }

    /**
     * Get profit area chart data
     */
    async sharedStatisticControllerGetProfitCharts(requestParameters: SharedStatisticControllerGetProfitChartsRequest, initOverrides?: RequestInit): Promise<ProfitChartDto> {
        const response = await this.sharedStatisticControllerGetProfitChartsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all ratios chart data
     */
    async sharedStatisticControllerGetRatiosRaw(requestParameters: SharedStatisticControllerGetRatiosRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RatiosDto>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling sharedStatisticControllerGetRatios.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/statistics/shared/{portfolioId}/ratios`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RatiosDtoFromJSON(jsonValue));
    }

    /**
     * Get all ratios chart data
     */
    async sharedStatisticControllerGetRatios(requestParameters: SharedStatisticControllerGetRatiosRequest, initOverrides?: RequestInit): Promise<RatiosDto> {
        const response = await this.sharedStatisticControllerGetRatiosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get overview
     */
    async statisticControllerGetOverviewRaw(requestParameters: StatisticControllerGetOverviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OverviewDto>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling statisticControllerGetOverview.');
        }

        if (requestParameters.calculateFees === null || requestParameters.calculateFees === undefined) {
            throw new runtime.RequiredError('calculateFees','Required parameter requestParameters.calculateFees was null or undefined when calling statisticControllerGetOverview.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        if (requestParameters.calculateFees !== undefined) {
            queryParameters['calculateFees'] = requestParameters.calculateFees;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/statistics/{portfolioId}/overview`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OverviewDtoFromJSON(jsonValue));
    }

    /**
     * Get overview
     */
    async statisticControllerGetOverview(requestParameters: StatisticControllerGetOverviewRequest, initOverrides?: RequestInit): Promise<OverviewDto> {
        const response = await this.statisticControllerGetOverviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get profit area chart data
     */
    async statisticControllerGetProfitChartsRaw(requestParameters: StatisticControllerGetProfitChartsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfitChartDto>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling statisticControllerGetProfitCharts.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/statistics/{portfolioId}/profit-charts`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfitChartDtoFromJSON(jsonValue));
    }

    /**
     * Get profit area chart data
     */
    async statisticControllerGetProfitCharts(requestParameters: StatisticControllerGetProfitChartsRequest, initOverrides?: RequestInit): Promise<ProfitChartDto> {
        const response = await this.statisticControllerGetProfitChartsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all ratios chart data
     */
    async statisticControllerGetRatiosRaw(requestParameters: StatisticControllerGetRatiosRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RatiosDto>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling statisticControllerGetRatios.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/statistics/{portfolioId}/ratios`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RatiosDtoFromJSON(jsonValue));
    }

    /**
     * Get all ratios chart data
     */
    async statisticControllerGetRatios(requestParameters: StatisticControllerGetRatiosRequest, initOverrides?: RequestInit): Promise<RatiosDto> {
        const response = await this.statisticControllerGetRatiosRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
