/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IconNameEnum {
    WalletFilled = 'WalletFilled',
    ProfitsFilled = 'ProfitsFilled',
    TradesFilled = 'TradesFilled',
    DividendsFilled = 'DividendsFilled',
    HeartFilled = 'HeartFilled',
    ChartBarFilled = 'ChartBarFilled'
}

export function IconNameEnumFromJSON(json: any): IconNameEnum {
    return IconNameEnumFromJSONTyped(json, false);
}

export function IconNameEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): IconNameEnum {
    return json as IconNameEnum;
}

export function IconNameEnumToJSON(value?: IconNameEnum | null): any {
    return value as any;
}

