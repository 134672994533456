import { makeAutoObservable } from 'mobx';
import type { RootStore } from './root-store';

export class PremiumDialogStore {
    rootStore: RootStore;

    period: 'yearly' | 'monthly' = 'yearly';

    opened: boolean = false;

    type: 'auto' | 'click' = 'click';

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    setPeriod(period: 'yearly' | 'monthly'): void {
        this.period = period;
    }

    open(type: 'auto' | 'click' = 'click'): void {
        this.opened = true;
        this.type = type;
    }

    openAfterLoginForFreeSubscription(): void {
        // if (this.rootStore.authStore.userHasFreeTariff) {
        //     const today = format(new Date(), 'yyyy-MM-dd');
        //     const key = `premiumDialogOpened${today}`;
        //     if (window.localStorage.getItem(key) === null) {
        //         this.open('auto');
        //         window.localStorage.setItem(key, 'true');
        //     }
        // }
    }

    openAfterRouteChange(): void {
        if (this.rootStore.authStore.userHasFreeTariff) {
            setTimeout(() => {
                if (!this.rootStore.cookieService.hasPremiumDialogOpenProtection()) {
                    this.open('auto');
                    this.rootStore.cookieService.setPremiumDialogOpenProtection(30 * 60);
                }
            }, 2000);
        }
    }

    close(): void {
        this.opened = false;
    }
}
