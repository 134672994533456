/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonthDto
 */
export interface MonthDto {
    /**
     * 
     * @type {number}
     * @memberof MonthDto
     */
    month: number;
    /**
     * 
     * @type {number}
     * @memberof MonthDto
     */
    amountWithoutSell: number;
    /**
     * 
     * @type {number}
     * @memberof MonthDto
     */
    amountWithSell: number;
}

export function MonthDtoFromJSON(json: any): MonthDto {
    return MonthDtoFromJSONTyped(json, false);
}

export function MonthDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': json['month'],
        'amountWithoutSell': json['amountWithoutSell'],
        'amountWithSell': json['amountWithSell'],
    };
}

export function MonthDtoToJSON(value?: MonthDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'amountWithoutSell': value.amountWithoutSell,
        'amountWithSell': value.amountWithSell,
    };
}

