import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { color } from '../colors';
import { getOpacity } from '../utils/get-rgb';

export const createButton = (t: ThemeEnum) => {
    const button: DefaultTheme['button'] = {
        size: {
            xsmall: {
                fontSize: '1.2rem',
                lineHeight: '0.6rem',
                padding: '0.2rem',
                labelPadding: '0rem 0.8rem',
                icon: {
                    size: '1.0rem',
                    wrapper: '1.8rem',
                },
            },
            vsmall: {
                fontSize: '1.2rem',
                lineHeight: '1.0rem',
                padding: '0.4rem',
                labelPadding: '0rem 0.8rem',
                icon: {
                    size: '1.2rem',
                    wrapper: '2.2rem',
                },
            },
            small: {
                fontSize: '1.2rem',
                lineHeight: '1.2rem',
                padding: '0.5rem',
                labelPadding: '0rem 1rem',
                icon: {
                    size: '1.4rem',
                    wrapper: '2.6rem',
                },
            },
            medium: {
                fontSize: '1.4rem',
                lineHeight: '1.4rem',
                padding: '0.9rem',
                labelPadding: '0rem 1.2rem',
                icon: {
                    size: '1.4rem',
                    wrapper: '3rem',
                },
            },
            large: {
                fontSize: '1.6rem',
                lineHeight: '1.6rem',
                padding: '0.9rem',
                labelPadding: '0rem 1.7rem',
                icon: {
                    size: '1.6rem',
                    wrapper: '3.6rem',
                },
            },
            vlarge: {
                fontSize: '2rem',
                lineHeight: '2rem',
                padding: '0.9rem',
                labelPadding: '0rem 2.7rem',
                icon: {
                    size: '2.0rem',
                    wrapper: '4.8rem',
                },
            },
        },
        variant: {
            default: {
                filled: {
                    base: {
                        background: getOpacity(color[t].base.gray, 0.3),
                        border: 'transparent',
                        color: color[t].dark.gray,
                        shadow: '0px 4px 8px rgba(142, 145, 175, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.text,
                            background: getOpacity(color[t].base.gray, 0.24),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.gray, 0.8),
                        border: 'transparent',
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(142, 145, 175, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.gray, 0.95),
                        },
                    },
                    active: {
                        background: getOpacity(color[t].base.gray, 0.3),
                        border: 'transparent',
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(142, 145, 175, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.secondary,
                            background: getOpacity(color[t].darken.gray, 0.18),
                        },
                    },
                    disabled: {
                        background: getOpacity(color[t].base.gray, 0.1),
                        border: 'transparent',
                        color: color[t].dark.gray,
                        shadow: '0px 4px 8px rgba(142, 145, 175, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.secondary,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                outlined: {
                    base: {
                        background: 'transparent',
                        border: getOpacity(color[t].base.gray, 0.3),
                        color: color[t].dark.gray,
                        shadow: '0px 4px 8px rgba(142, 145, 175, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.text,
                            background: getOpacity(color[t].darken.gray, 0.18),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.gray, 0.8),
                        border: 'transparent',
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(142, 145, 175, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.gray, 0.95),
                        },
                    },
                    active: {
                        background: getOpacity(color[t].base.gray, 0.3),
                        border: 'transparent',
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(142, 145, 175, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.secondary,
                            background: getOpacity(color[t].darken.gray, 0.18),
                        },
                    },
                    disabled: {
                        background: getOpacity(color[t].base.gray, 0.2),
                        border: 'transparent',
                        color: getOpacity(color[t].base.text, 0.4),
                        shadow: '0px 4px 8px rgba(142, 145, 175, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.gray,
                            background: getOpacity(color[t].base.gray, 0.12),
                        },
                    },
                },
                transparent: {
                    base: {
                        background: 'transparent',
                        border: 'transparent',
                        color: color[t].dark.gray,
                        shadow: '0px 4px 8px rgba(142, 145, 175, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.text,
                            background: getOpacity(color[t].darken.gray, 0.18),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.gray, 0.8),
                        border: 'transparent',
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(142, 145, 175, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.gray, 0.95),
                        },
                    },
                    active: {
                        background: getOpacity(color[t].base.gray, 0.3),
                        border: 'transparent',
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(142, 145, 175, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.secondary,
                            background: getOpacity(color[t].darken.gray, 0.18),
                        },
                    },
                    disabled: {
                        background: getOpacity(color[t].base.gray, 0.1),
                        border: 'transparent',
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(142, 145, 175, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.secondary,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
            },
            primary: {
                filled: {
                    base: {
                        background: color[t].base.primary,
                        border: color[t].base.primary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.primary,
                        border: color[t].darken.primary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.primary,
                        border: color[t].darken.primary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].light.primary,
                        border: color[t].light.primary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                outlined: {
                    base: {
                        background: 'transparent',
                        border: getOpacity(color[t].base.primary, 0.36),
                        color: color[t].base.primary,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.primary,
                            background: getOpacity(color[t].base.primary, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.primary,
                        border: color[t].darken.primary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.primary,
                        border: color[t].darken.primary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.primary,
                        border: color[t].darken.primary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                transparent: {
                    base: {
                        background: 'transparent',
                        border: 'transparent',
                        color: color[t].base.primary,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.primary,
                            background: getOpacity(color[t].base.primary, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.primary,
                        border: color[t].darken.primary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.primary,
                        border: color[t].darken.primary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.primary,
                        border: color[t].darken.primary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
            },
            secondary: {
                filled: {
                    base: {
                        background: color[t].base.secondary,
                        border: color[t].base.secondary,
                        color: color[t].base.textInverse,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.secondary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.secondary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.secondary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                outlined: {
                    base: {
                        background: 'transparent',
                        border: getOpacity(color[t].darken.secondary, 0.36),
                        color: color[t].base.secondary,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.secondary,
                            background: getOpacity(color[t].base.secondary, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.secondary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.secondary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.secondary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                transparent: {
                    base: {
                        background: 'transparent',
                        border: 'transparent',
                        color: color[t].base.secondary,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.secondary,
                            background: getOpacity(color[t].base.secondary, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.secondary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.secondary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.secondary,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
            },
            positive: {
                filled: {
                    base: {
                        background: color[t].base.positive,
                        border: color[t].base.positive,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(21, 164, 129, 0.51), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.positive,
                        border: color[t].darken.positive,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(21, 164, 129, 0.51), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.positive,
                        border: color[t].darken.positive,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(21, 164, 129, 0.51), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.positive,
                        border: color[t].darken.positive,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(21, 164, 129, 0.51), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                outlined: {
                    base: {
                        background: 'transparent',
                        border: getOpacity(color[t].base.positive, 0.36),
                        color: color[t].base.positive,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.positive,
                            background: getOpacity(color[t].base.positive, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.positive,
                        border: color[t].darken.positive,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.positive,
                        border: color[t].darken.positive,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: 'transparent',
                        border: getOpacity(color[t].base.positive, 0.3),
                        color: color[t].base.positive,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.positive,
                            background: getOpacity(color[t].base.positive, 0.12),
                        },
                    },
                },
                transparent: {
                    base: {
                        background: 'transparent',
                        border: 'transparent',
                        color: color[t].base.positive,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.positive,
                            background: getOpacity(color[t].base.positive, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.positive,
                        border: color[t].darken.positive,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.positive,
                        border: color[t].darken.positive,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.positive,
                        border: color[t].darken.positive,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
            },
            purple: {
                filled: {
                    base: {
                        background: color[t].base.purple,
                        border: color[t].base.purple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.purple,
                        border: color[t].darken.purple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.purple,
                        border: color[t].darken.purple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.purple,
                        border: color[t].darken.purple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                outlined: {
                    base: {
                        background: 'transparent',
                        border: getOpacity(color[t].base.purple, 0.36),
                        color: color[t].base.purple,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.purple,
                            background: getOpacity(color[t].base.purple, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.purple,
                        border: color[t].darken.purple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.purple,
                        border: color[t].darken.purple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.purple,
                        border: color[t].darken.purple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                transparent: {
                    base: {
                        background: 'transparent',
                        border: 'transparent',
                        color: color[t].base.purple,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.purple,
                            background: getOpacity(color[t].base.purple, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.purple,
                        border: color[t].darken.purple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.purple,
                        border: color[t].darken.purple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.purple,
                        border: color[t].darken.purple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
            },
            negative: {
                filled: {
                    base: {
                        background: color[t].base.negative,
                        border: color[t].base.negative,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.negative,
                        border: color[t].darken.negative,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.negative,
                        border: color[t].darken.negative,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.negative,
                        border: color[t].darken.negative,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                outlined: {
                    base: {
                        background: 'transparent',
                        border: getOpacity(color[t].base.negative, 0.36),
                        color: color[t].base.negative,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.negative,
                            background: getOpacity(color[t].base.negative, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.negative,
                        border: color[t].darken.negative,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.negative,
                        border: color[t].darken.negative,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.negative,
                        border: color[t].darken.negative,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                transparent: {
                    base: {
                        background: 'transparent',
                        border: 'transparent',
                        color: color[t].base.negative,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.negative,
                            background: getOpacity(color[t].base.negative, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.negative,
                        border: color[t].darken.negative,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.negative,
                        border: color[t].darken.negative,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.negative,
                        border: color[t].darken.negative,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
            },
            white: {
                filled: {
                    base: {
                        background: color[t].base.white,
                        border: color[t].base.white,
                        color: color[t].base.dark,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.black,
                            background: getOpacity(color[t].base.black, 0.12),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.white, 0.8),
                        border: 'transparent',
                        color: color[t].base.dark,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.black,
                            background: getOpacity(color[t].base.black, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.white,
                        border: color[t].darken.white,
                        color: color[t].base.black,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.black,
                            background: getOpacity(color[t].base.black, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.white,
                        border: color[t].darken.white,
                        color: color[t].base.black,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.black,
                            background: getOpacity(color[t].base.black, 0.12),
                        },
                    },
                },
                outlined: {
                    base: {
                        background: 'transparent',
                        border: getOpacity(color[t].base.white, 0.36),
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.white,
                        border: color[t].darken.white,
                        color: color[t].base.dark,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.secondary,
                            background: getOpacity(color[t].base.secondary, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.white,
                        border: color[t].darken.white,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.white,
                        border: color[t].darken.white,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                transparent: {
                    base: {
                        background: 'transparent',
                        border: 'transparent',
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.secondary,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.white,
                        border: color[t].darken.white,
                        color: color[t].base.secondary,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.secondary,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.white,
                        border: color[t].darken.white,
                        color: color[t].base.secondary,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.secondary,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.white,
                        border: color[t].darken.white,
                        color: color[t].base.secondary,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.secondary,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
            },
            google: {
                filled: {
                    base: {
                        background: color[t].base.google,
                        border: color[t].base.google,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.google,
                        border: color[t].darken.google,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.google,
                        border: color[t].darken.google,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.google,
                        border: color[t].darken.google,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                outlined: {
                    base: {
                        background: 'transparent',
                        border: getOpacity(color[t].base.google, 0.36),
                        color: color[t].base.google,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.google,
                            background: getOpacity(color[t].base.google, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.google,
                        border: color[t].darken.google,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.google,
                        border: color[t].darken.google,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.google,
                        border: color[t].darken.google,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                transparent: {
                    base: {
                        background: 'transparent',
                        border: 'transparent',
                        color: color[t].base.google,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.google,
                            background: getOpacity(color[t].base.google, 0.12),
                        },
                    },
                    hover: {
                        background: color[t].darken.google,
                        border: color[t].darken.google,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.google,
                        border: color[t].darken.google,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.google,
                        border: color[t].darken.google,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
            },
            apple: {
                filled: {
                    base: {
                        background: color[t].base.apple,
                        border: color[t].base.apple,
                        color: color[t].base.light,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.light,
                            background: getOpacity(color[t].base.light, 0.12),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.apple, 0.6),
                        border: 'transparent',
                        color: color[t].base.light,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.light,
                            background: getOpacity(color[t].base.light, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.apple,
                        border: color[t].darken.apple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.apple,
                        border: color[t].darken.apple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                outlined: {
                    base: {
                        background: 'transparent',
                        border: getOpacity(color[t].base.apple, 0.36),
                        color: color[t].base.apple,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.apple,
                            background: getOpacity(color[t].base.apple, 0.12),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.apple, 0.6),
                        border: 'transparent',
                        color: color[t].base.light,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.light,
                            background: getOpacity(color[t].base.light, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.apple,
                        border: color[t].darken.apple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.apple,
                        border: color[t].darken.apple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                transparent: {
                    base: {
                        background: 'transparent',
                        border: 'transparent',
                        color: color[t].base.apple,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.apple,
                            background: getOpacity(color[t].base.apple, 0.12),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.apple, 0.6),
                        border: 'transparent',
                        color: color[t].base.light,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.light,
                            background: getOpacity(color[t].base.light, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.apple,
                        border: color[t].darken.apple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.apple,
                        border: color[t].darken.apple,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
            },
            facebook: {
                filled: {
                    base: {
                        background: color[t].base.facebook,
                        border: color[t].base.facebook,
                        color: color[t].base.light,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.light,
                            background: getOpacity(color[t].base.light, 0.12),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.facebook, 0.6),
                        border: 'transparent',
                        color: color[t].base.light,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.light,
                            background: getOpacity(color[t].base.light, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.facebook,
                        border: color[t].darken.facebook,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.facebook,
                        border: color[t].darken.facebook,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                outlined: {
                    base: {
                        background: 'transparent',
                        border: getOpacity(color[t].base.facebook, 0.36),
                        color: color[t].base.facebook,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.facebook,
                            background: getOpacity(color[t].base.facebook, 0.12),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.facebook, 0.6),
                        border: 'transparent',
                        color: color[t].base.light,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.light,
                            background: getOpacity(color[t].base.light, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.facebook,
                        border: color[t].darken.facebook,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.facebook,
                        border: color[t].darken.facebook,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                transparent: {
                    base: {
                        background: 'transparent',
                        border: 'transparent',
                        color: color[t].base.facebook,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.facebook,
                            background: getOpacity(color[t].base.facebook, 0.12),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.facebook, 0.6),
                        border: 'transparent',
                        color: color[t].base.light,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.light,
                            background: getOpacity(color[t].base.light, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.facebook,
                        border: color[t].darken.facebook,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.facebook,
                        border: color[t].darken.facebook,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
            },
            instagram: {
                filled: {
                    base: {
                        background: color[t].base.instagram,
                        border: color[t].base.instagram,
                        color: color[t].base.light,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.light,
                            background: getOpacity(color[t].base.light, 0.12),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.instagram, 0.6),
                        border: 'transparent',
                        color: color[t].base.light,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.light,
                            background: getOpacity(color[t].base.light, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.instagram,
                        border: color[t].darken.instagram,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.instagram,
                        border: color[t].darken.instagram,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                outlined: {
                    base: {
                        background: 'transparent',
                        border: getOpacity(color[t].base.instagram, 0.36),
                        color: color[t].base.instagram,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.instagram,
                            background: getOpacity(color[t].base.instagram, 0.12),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.instagram, 0.6),
                        border: 'transparent',
                        color: color[t].base.light,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.light,
                            background: getOpacity(color[t].base.light, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.instagram,
                        border: color[t].darken.instagram,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.instagram,
                        border: color[t].darken.instagram,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
                transparent: {
                    base: {
                        background: 'transparent',
                        border: 'transparent',
                        color: color[t].base.instagram,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.instagram,
                            background: getOpacity(color[t].base.instagram, 0.12),
                        },
                    },
                    hover: {
                        background: getOpacity(color[t].base.instagram, 0.6),
                        border: 'transparent',
                        color: color[t].base.light,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.light,
                            background: getOpacity(color[t].base.light, 0.12),
                        },
                    },
                    active: {
                        background: color[t].darken.instagram,
                        border: color[t].darken.instagram,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                    disabled: {
                        background: color[t].darken.instagram,
                        border: color[t].darken.instagram,
                        color: color[t].base.white,
                        shadow: '0px 4px 8px rgba(0, 110, 204, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.25)',
                        icon: {
                            color: color[t].base.white,
                            background: getOpacity(color[t].base.white, 0.12),
                        },
                    },
                },
            },
        },
    };
    return button;
};
