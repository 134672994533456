/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolAlternativeDto,
    SymbolAlternativeDtoFromJSON,
    SymbolAlternativeDtoFromJSONTyped,
    SymbolAlternativeDtoToJSON,
} from './SymbolAlternativeDto';

/**
 * 
 * @export
 * @interface PaginatedSymbolAlternativeDto
 */
export interface PaginatedSymbolAlternativeDto {
    /**
     * 
     * @type {number}
     * @memberof PaginatedSymbolAlternativeDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedSymbolAlternativeDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedSymbolAlternativeDto
     */
    offset: number;
    /**
     * 
     * @type {Array<SymbolAlternativeDto>}
     * @memberof PaginatedSymbolAlternativeDto
     */
    items: Array<SymbolAlternativeDto>;
}

export function PaginatedSymbolAlternativeDtoFromJSON(json: any): PaginatedSymbolAlternativeDto {
    return PaginatedSymbolAlternativeDtoFromJSONTyped(json, false);
}

export function PaginatedSymbolAlternativeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedSymbolAlternativeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'limit': json['limit'],
        'offset': json['offset'],
        'items': ((json['items'] as Array<any>).map(SymbolAlternativeDtoFromJSON)),
    };
}

export function PaginatedSymbolAlternativeDtoToJSON(value?: PaginatedSymbolAlternativeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'limit': value.limit,
        'offset': value.offset,
        'items': ((value.items as Array<any>).map(SymbolAlternativeDtoToJSON)),
    };
}

