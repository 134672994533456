import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
// eslint-disable-next-line import/no-cycle
import { SymbolAlternativesStore } from './symbol-alternatives-store';
import { SymbolAlternativeMobxDto } from '../mobx/dtos/symbol-alternative/symbol-alternative-mobx-dto';
import { SymbolMobxDto } from '../mobx/dtos/symbol/symbol-mobx-dto';
import { CreateSymbolAlternativeMobxDto } from '../mobx/dtos/symbol-alternative/create-symbol-alternative-mobx-dto';

export class SymbolAlternativesDialogStore {
    rootStore: RootStore;

    symbolAlternativesStore: SymbolAlternativesStore;

    alternativeSymbol: SymbolAlternativeMobxDto | null = null;

    createDialogOpened = false;

    symbolSearchPhrase = '';

    symbols: SymbolMobxDto[] = [];

    loading = false;

    loadingSearch = false;

    createOrUpdateErrorMessage: string | null = null;

    private delayTimer: NodeJS.Timeout;

    private abortController: AbortController | null = null;

    constructor(symbolAlternativesStore: SymbolAlternativesStore) {
        this.rootStore = symbolAlternativesStore.rootStore;
        this.symbolAlternativesStore = symbolAlternativesStore;

        makeAutoObservable(this, {
            rootStore: false,
            symbolAlternativesStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async create(alternativeSymbol: CreateSymbolAlternativeMobxDto): Promise<void> {
        runInAction(() => {
            this.createOrUpdateErrorMessage = null;
        });

        try {
            this.startLoading();
            const result =
                await this.rootStore.apiClient.symbolAlternativesController.symbolAlternativeControllerCreate({
                    createSymbolAlternativeDto: alternativeSymbol,
                });

            if (result) {
                await this.symbolAlternativesStore.fetchSymbolAlternatives();
                this.closeCreateDialog();
            }
        } catch (e) {
            runInAction(() => {
                this.createOrUpdateErrorMessage = e.message;
            });
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    openCreateDialog(): void {
        this.alternativeSymbol = new SymbolAlternativeMobxDto();
        this.createDialogOpened = true;
    }

    closeCreateDialog(): void {
        this.createDialogOpened = false;
    }

    setSymbolSearchPhrase(phrase: string): void {
        this.symbolSearchPhrase = phrase;
    }

    async triggerSearch(symbol?: SymbolMobxDto): Promise<void> {
        this.loadingSearch = true;
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
            await this.fetchSymbols(symbol);
            runInAction(() => {
                this.loadingSearch = false;
            });
        }, 500);
    }

    async fetchSymbols(symbol: SymbolMobxDto | null = null): Promise<void> {
        this.startLoading();
        await this.rootStore.symbolsStore.fetchSymbols();
        try {
            if (this.abortController) {
                this.abortController.abort();
            }

            this.abortController = new AbortController();
            const { signal } = this.abortController;

            const symbols = await this.rootStore.apiClient.symbolController.symbolControllerGetList(
                {
                    phrase: this.symbolSearchPhrase,
                    limit: 6,
                },
                {
                    signal,
                },
            );
            runInAction(() => {
                const newSymbols =
                    symbols?.map((s) => ({
                        ...s,
                        marketCapitalizationBn: null,
                    })) || [];

                if (symbol && !newSymbols.find((s) => s.id === symbol.id)) {
                    this.symbols = [symbol, ...newSymbols];
                } else {
                    this.symbols = newSymbols;
                }
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
