import { makeAutoObservable } from 'mobx';
import { AreaChartDataMobxDto } from './area-chart-data-mobx-dto';
import { AreaChartDto } from '../../../../defs/api';

export class AreaChartMobxDto implements AreaChartDto {
    id: string;

    color: string;

    data: AreaChartDataMobxDto[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: AreaChartDto): AreaChartMobxDto {
        const mobxDto = new AreaChartMobxDto();
        return Object.assign(mobxDto, dto, {
            data: AreaChartDataMobxDto.createFromArray(dto.data),
        });
    }

    static createFromArray(dtos: AreaChartDto[]): AreaChartMobxDto[] {
        return dtos.map((dto) => AreaChartMobxDto.create(dto));
    }
}
