/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolDetailDto,
    SymbolDetailDtoFromJSON,
    SymbolDetailDtoFromJSONTyped,
    SymbolDetailDtoToJSON,
} from './SymbolDetailDto';

/**
 * 
 * @export
 * @interface ProfitMonthDto
 */
export interface ProfitMonthDto {
    /**
     * 
     * @type {number}
     * @memberof ProfitMonthDto
     */
    month: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitMonthDto
     */
    profitPerformance: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitMonthDto
     */
    profitAbsolute: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitMonthDto
     */
    profitPercentage: number;
    /**
     * 
     * @type {SymbolDetailDto}
     * @memberof ProfitMonthDto
     */
    bestEarner: SymbolDetailDto;
    /**
     * 
     * @type {SymbolDetailDto}
     * @memberof ProfitMonthDto
     */
    worstEarner: SymbolDetailDto;
}

export function ProfitMonthDtoFromJSON(json: any): ProfitMonthDto {
    return ProfitMonthDtoFromJSONTyped(json, false);
}

export function ProfitMonthDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfitMonthDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': json['month'],
        'profitPerformance': json['profitPerformance'],
        'profitAbsolute': json['profitAbsolute'],
        'profitPercentage': json['profitPercentage'],
        'bestEarner': SymbolDetailDtoFromJSON(json['bestEarner']),
        'worstEarner': SymbolDetailDtoFromJSON(json['worstEarner']),
    };
}

export function ProfitMonthDtoToJSON(value?: ProfitMonthDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'profitPerformance': value.profitPerformance,
        'profitAbsolute': value.profitAbsolute,
        'profitPercentage': value.profitPercentage,
        'bestEarner': SymbolDetailDtoToJSON(value.bestEarner),
        'worstEarner': SymbolDetailDtoToJSON(value.worstEarner),
    };
}

