/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonthDto,
    MonthDtoFromJSON,
    MonthDtoFromJSONTyped,
    MonthDtoToJSON,
} from './MonthDto';

/**
 * 
 * @export
 * @interface YearDto
 */
export interface YearDto {
    /**
     * 
     * @type {number}
     * @memberof YearDto
     */
    year: number;
    /**
     * 
     * @type {Array<MonthDto>}
     * @memberof YearDto
     */
    months: Array<MonthDto>;
}

export function YearDtoFromJSON(json: any): YearDto {
    return YearDtoFromJSONTyped(json, false);
}

export function YearDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): YearDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'months': ((json['months'] as Array<any>).map(MonthDtoFromJSON)),
    };
}

export function YearDtoToJSON(value?: YearDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'months': ((value.months as Array<any>).map(MonthDtoToJSON)),
    };
}

