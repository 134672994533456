/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LayoutEnum,
    LayoutEnumFromJSON,
    LayoutEnumFromJSONTyped,
    LayoutEnumToJSON,
} from './LayoutEnum';
import {
    LookupKeyEnum,
    LookupKeyEnumFromJSON,
    LookupKeyEnumFromJSONTyped,
    LookupKeyEnumToJSON,
} from './LookupKeyEnum';
import {
    MailOverviewPeriodEnum,
    MailOverviewPeriodEnumFromJSON,
    MailOverviewPeriodEnumFromJSONTyped,
    MailOverviewPeriodEnumToJSON,
} from './MailOverviewPeriodEnum';
import {
    StornoReasonEnum,
    StornoReasonEnumFromJSON,
    StornoReasonEnumFromJSONTyped,
    StornoReasonEnumToJSON,
} from './StornoReasonEnum';
import {
    SubscriptionEnum,
    SubscriptionEnumFromJSON,
    SubscriptionEnumFromJSONTyped,
    SubscriptionEnumToJSON,
} from './SubscriptionEnum';
import {
    ThemeEnum,
    ThemeEnumFromJSON,
    ThemeEnumFromJSONTyped,
    ThemeEnumToJSON,
} from './ThemeEnum';
import {
    UserRoleEnum,
    UserRoleEnumFromJSON,
    UserRoleEnumFromJSONTyped,
    UserRoleEnumToJSON,
} from './UserRoleEnum';
import {
    UserTypeEnum,
    UserTypeEnumFromJSON,
    UserTypeEnumFromJSONTyped,
    UserTypeEnumToJSON,
} from './UserTypeEnum';

/**
 * 
 * @export
 * @interface UserEntity
 */
export interface UserEntity {
    /**
     * 
     * @type {UserRoleEnum}
     * @memberof UserEntity
     */
    role: UserRoleEnum;
    /**
     * 
     * @type {UserTypeEnum}
     * @memberof UserEntity
     */
    type: UserTypeEnum;
    /**
     * 
     * @type {ThemeEnum}
     * @memberof UserEntity
     */
    theme: ThemeEnum;
    /**
     * 
     * @type {LayoutEnum}
     * @memberof UserEntity
     */
    layout: LayoutEnum;
    /**
     * 
     * @type {SubscriptionEnum}
     * @memberof UserEntity
     */
    subscription: SubscriptionEnum;
    /**
     * 
     * @type {LookupKeyEnum}
     * @memberof UserEntity
     */
    lookupKey: LookupKeyEnum;
    /**
     * 
     * @type {StornoReasonEnum}
     * @memberof UserEntity
     */
    stornoReason: StornoReasonEnum;
    /**
     * 
     * @type {LookupKeyEnum}
     * @memberof UserEntity
     */
    interestedInSubscription: LookupKeyEnum;
    /**
     * 
     * @type {MailOverviewPeriodEnum}
     * @memberof UserEntity
     */
    mailOverviewPeriod: MailOverviewPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserEntity
     */
    isEmailVerified: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserEntity
     */
    isOnWaitingList: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    affiliateCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    nickname: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserEntity
     */
    newsletter: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    stripeCustomerId: string;
    /**
     * 
     * @type {Date}
     * @memberof UserEntity
     */
    subscriptionEndDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserEntity
     */
    hadTrialing: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserEntity
     */
    registrationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserEntity
     */
    lastLoginDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserEntity
     */
    activationDate: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    stornoReasonDescription: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserEntity
     */
    hadStornoDiscount: boolean;
}

export function UserEntityFromJSON(json: any): UserEntity {
    return UserEntityFromJSONTyped(json, false);
}

export function UserEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': UserRoleEnumFromJSON(json['role']),
        'type': UserTypeEnumFromJSON(json['type']),
        'theme': ThemeEnumFromJSON(json['theme']),
        'layout': LayoutEnumFromJSON(json['layout']),
        'subscription': SubscriptionEnumFromJSON(json['subscription']),
        'lookupKey': LookupKeyEnumFromJSON(json['lookupKey']),
        'stornoReason': StornoReasonEnumFromJSON(json['stornoReason']),
        'interestedInSubscription': LookupKeyEnumFromJSON(json['interestedInSubscription']),
        'mailOverviewPeriod': MailOverviewPeriodEnumFromJSON(json['mailOverviewPeriod']),
        'id': json['id'],
        'email': json['email'],
        'isEmailVerified': json['isEmailVerified'],
        'isOnWaitingList': json['isOnWaitingList'],
        'password': json['password'],
        'affiliateCode': json['affiliateCode'],
        'fullName': json['fullName'],
        'nickname': json['nickname'],
        'newsletter': json['newsletter'],
        'stripeCustomerId': json['stripeCustomerId'],
        'subscriptionEndDate': (json['subscriptionEndDate'] === null ? null : new Date(json['subscriptionEndDate'])),
        'hadTrialing': json['hadTrialing'],
        'registrationDate': (new Date(json['registrationDate'])),
        'lastLoginDate': (new Date(json['lastLoginDate'])),
        'activationDate': (json['activationDate'] === null ? null : new Date(json['activationDate'])),
        'stornoReasonDescription': json['stornoReasonDescription'],
        'hadStornoDiscount': json['hadStornoDiscount'],
    };
}

export function UserEntityToJSON(value?: UserEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': UserRoleEnumToJSON(value.role),
        'type': UserTypeEnumToJSON(value.type),
        'theme': ThemeEnumToJSON(value.theme),
        'layout': LayoutEnumToJSON(value.layout),
        'subscription': SubscriptionEnumToJSON(value.subscription),
        'lookupKey': LookupKeyEnumToJSON(value.lookupKey),
        'stornoReason': StornoReasonEnumToJSON(value.stornoReason),
        'interestedInSubscription': LookupKeyEnumToJSON(value.interestedInSubscription),
        'mailOverviewPeriod': MailOverviewPeriodEnumToJSON(value.mailOverviewPeriod),
        'id': value.id,
        'email': value.email,
        'isEmailVerified': value.isEmailVerified,
        'isOnWaitingList': value.isOnWaitingList,
        'password': value.password,
        'affiliateCode': value.affiliateCode,
        'fullName': value.fullName,
        'nickname': value.nickname,
        'newsletter': value.newsletter,
        'stripeCustomerId': value.stripeCustomerId,
        'subscriptionEndDate': (value.subscriptionEndDate === null ? null : value.subscriptionEndDate.toISOString()),
        'hadTrialing': value.hadTrialing,
        'registrationDate': (value.registrationDate.toISOString()),
        'lastLoginDate': (value.lastLoginDate.toISOString()),
        'activationDate': (value.activationDate === null ? null : value.activationDate.toISOString()),
        'stornoReasonDescription': value.stornoReasonDescription,
        'hadStornoDiscount': value.hadStornoDiscount,
    };
}

