import { makeAutoObservable } from 'mobx';
import { AreaChartDataDto } from '../../../../defs/api';

export class AreaChartDataMobxDto implements AreaChartDataDto {
    x: Date;

    y: number;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: AreaChartDataDto): AreaChartDataMobxDto {
        const mobxDto = new AreaChartDataMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: AreaChartDataDto[]): AreaChartDataMobxDto[] {
        return dtos.map((dto) => AreaChartDataMobxDto.create(dto));
    }
}
