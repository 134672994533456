/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolWithExchangeDto,
    SymbolWithExchangeDtoFromJSON,
    SymbolWithExchangeDtoFromJSONTyped,
    SymbolWithExchangeDtoToJSON,
} from './SymbolWithExchangeDto';

/**
 * 
 * @export
 * @interface SymbolFundamentalDataCashFlowDto
 */
export interface SymbolFundamentalDataCashFlowDto {
    /**
     * 
     * @type {SymbolWithExchangeDto}
     * @memberof SymbolFundamentalDataCashFlowDto
     */
    symbol: SymbolWithExchangeDto;
    /**
     * 
     * @type {string}
     * @memberof SymbolFundamentalDataCashFlowDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolFundamentalDataCashFlowDto
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataCashFlowDto
     */
    netIncome: number;
}

export function SymbolFundamentalDataCashFlowDtoFromJSON(json: any): SymbolFundamentalDataCashFlowDto {
    return SymbolFundamentalDataCashFlowDtoFromJSONTyped(json, false);
}

export function SymbolFundamentalDataCashFlowDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolFundamentalDataCashFlowDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'symbol': SymbolWithExchangeDtoFromJSON(json['symbol']),
        'id': json['id'],
        'date': json['date'],
        'netIncome': json['netIncome'],
    };
}

export function SymbolFundamentalDataCashFlowDtoToJSON(value?: SymbolFundamentalDataCashFlowDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'symbol': SymbolWithExchangeDtoToJSON(value.symbol),
        'id': value.id,
        'date': value.date,
        'netIncome': value.netIncome,
    };
}

