/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AreaChartDataDto,
    AreaChartDataDtoFromJSON,
    AreaChartDataDtoFromJSONTyped,
    AreaChartDataDtoToJSON,
} from './AreaChartDataDto';

/**
 * 
 * @export
 * @interface AreaChartDto
 */
export interface AreaChartDto {
    /**
     * 
     * @type {string}
     * @memberof AreaChartDto
     */
    id: string;
    /**
     * 
     * @type {Array<AreaChartDataDto>}
     * @memberof AreaChartDto
     */
    data: Array<AreaChartDataDto>;
}

export function AreaChartDtoFromJSON(json: any): AreaChartDto {
    return AreaChartDtoFromJSONTyped(json, false);
}

export function AreaChartDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaChartDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'data': ((json['data'] as Array<any>).map(AreaChartDataDtoFromJSON)),
    };
}

export function AreaChartDtoToJSON(value?: AreaChartDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'data': ((value.data as Array<any>).map(AreaChartDataDtoToJSON)),
    };
}

