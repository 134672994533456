import { makeAutoObservable } from 'mobx';
import { RealizedTransactionsSummaryDto } from '../../../../../defs/api';

export class RealizedTransactionsSummaryMobxDto implements RealizedTransactionsSummaryDto {
    public quantity: number = 0;

    public overallValue: number = 0;

    public overallGain: number = 0;

    public limitPercentage: number = 0;

    public fees: number = 0;

    public limit: number = 0;

    public overallValueInCzk: number = 0;

    public overallGainInCzk: number = 0;

    public overallFeesInCzk: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: RealizedTransactionsSummaryDto): RealizedTransactionsSummaryMobxDto {
        const mobxDto = new RealizedTransactionsSummaryMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: RealizedTransactionsSummaryDto[]): RealizedTransactionsSummaryMobxDto[] {
        return dtos.map((dto) => RealizedTransactionsSummaryMobxDto.create(dto));
    }
}
