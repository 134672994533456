/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorEnum,
    ColorEnumFromJSON,
    ColorEnumFromJSONTyped,
    ColorEnumToJSON,
} from './ColorEnum';
import {
    IconNameEnum,
    IconNameEnumFromJSON,
    IconNameEnumFromJSONTyped,
    IconNameEnumToJSON,
} from './IconNameEnum';
import {
    UserEntity,
    UserEntityFromJSON,
    UserEntityFromJSONTyped,
    UserEntityToJSON,
} from './UserEntity';

/**
 * 
 * @export
 * @interface WatchlistEntity
 */
export interface WatchlistEntity {
    /**
     * 
     * @type {IconNameEnum}
     * @memberof WatchlistEntity
     */
    iconName: IconNameEnum;
    /**
     * 
     * @type {ColorEnum}
     * @memberof WatchlistEntity
     */
    colorName: ColorEnum;
    /**
     * 
     * @type {UserEntity}
     * @memberof WatchlistEntity
     */
    user: UserEntity;
    /**
     * 
     * @type {string}
     * @memberof WatchlistEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WatchlistEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WatchlistEntity
     */
    userId: string;
}

export function WatchlistEntityFromJSON(json: any): WatchlistEntity {
    return WatchlistEntityFromJSONTyped(json, false);
}

export function WatchlistEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): WatchlistEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iconName': IconNameEnumFromJSON(json['iconName']),
        'colorName': ColorEnumFromJSON(json['colorName']),
        'user': UserEntityFromJSON(json['user']),
        'id': json['id'],
        'name': json['name'],
        'userId': json['userId'],
    };
}

export function WatchlistEntityToJSON(value?: WatchlistEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iconName': IconNameEnumToJSON(value.iconName),
        'colorName': ColorEnumToJSON(value.colorName),
        'user': UserEntityToJSON(value.user),
        'id': value.id,
        'name': value.name,
        'userId': value.userId,
    };
}

