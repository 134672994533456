import { makeAutoObservable } from 'mobx';
import { InvestmentBySymbolDto } from '../../../../../defs/api';

export class SymbolDetailInvestmentMobxDto implements InvestmentBySymbolDto {
    public investmentValue: number = 0;

    public amount: number = 0;

    public value: number = 0;

    public averageBuyPrice: number = 0;

    public averageBuyPriceInSymbolCurrency: number = 0;

    public profit: number = 0;

    public profitPercentage: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: InvestmentBySymbolDto): SymbolDetailInvestmentMobxDto {
        const mobxDto = new SymbolDetailInvestmentMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: InvestmentBySymbolDto[]): SymbolDetailInvestmentMobxDto[] {
        return dtos.map((dto) => SymbolDetailInvestmentMobxDto.create(dto));
    }
}
