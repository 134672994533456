import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { createRadius } from './main-create';
import { color } from '../colors';

export const createForm = (t: ThemeEnum) => {
    const radius = createRadius();
    const form: DefaultTheme['form'] = {
        field: {
            borderColor: color[t].base.border,
            backgroundColor: color[t].base.lighten,
            borderWidth: '0.1rem',
            radius: radius.medium,
        },
    };
    return form;
};
