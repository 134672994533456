import { makeAutoObservable } from 'mobx';
import { LeaderboardRankingMobxDto } from './leaderboard-ranking-mobx-dto';
import { IndividualRankingsDto } from '../../../../defs/api';

export class IndividualRankingsMobxDto implements IndividualRankingsDto {
    mostInvestedRanking: LeaderboardRankingMobxDto = new LeaderboardRankingMobxDto();

    activityRanking: LeaderboardRankingMobxDto = new LeaderboardRankingMobxDto();

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: IndividualRankingsDto): IndividualRankingsMobxDto {
        const mobxDto = new IndividualRankingsMobxDto();
        return Object.assign(mobxDto, dto, {
            mostInvestedRanking: LeaderboardRankingMobxDto.create(dto.mostInvestedRanking),
            activityRanking: LeaderboardRankingMobxDto.create(dto.activityRanking),
        });
    }

    static createFromArray(dtos: IndividualRankingsDto[]): IndividualRankingsMobxDto[] {
        return dtos.map((dto) => IndividualRankingsMobxDto.create(dto));
    }
}
