import { ThemeEnum } from '@app/common';
import { DefaultTheme } from 'styled-components';
import { color } from '../colors';
import { getOpacity } from '../utils/get-rgb';

export const createModal = (t: ThemeEnum) => {
    const modal: DefaultTheme['modal'] = {
        overlay: {
            background: getOpacity(color[t].base.overlay, 0.5),
        },
        window: {
            background: color[t].base.lighten,
        },
        close: {
            base: {
                color: color[t].lighten.gray,
            },
            hover: {
                color: color[t].base.gray,
            },
        },
    };
    return modal;
};
