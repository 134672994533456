import { makeAutoObservable } from 'mobx';
import { SymbolFundamentalDataHighlightsDto } from '../../../../../defs/api';
import { SymbolWithExchangeMobxDto } from '../symbol-with-exchange-mobx-dto';

export class SymbolDetailHighlightMobxDto implements SymbolFundamentalDataHighlightsDto {
    public id: string = '';

    public marketCapitalization: number = 0;

    public peRatio: number = 0;

    public ebitda: number = 0;

    public epsEstimateCurrentYear: number = 0;

    public dividendYield: number = 0;

    public dividend: number = 0;

    public dividendPayoutRatio: number = 0;

    public margin: number = 0;

    public marginTTM: number = 0;

    public eps: number = 0;

    public roe: number = 0;

    public evEbitda: number = 0;

    public dividendYears: number = 0;

    public continuousDividendYears: number = 0;

    public symbol: SymbolWithExchangeMobxDto = new SymbolWithExchangeMobxDto();

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolFundamentalDataHighlightsDto): SymbolDetailHighlightMobxDto {
        const mobxDto = new SymbolDetailHighlightMobxDto();
        return Object.assign(mobxDto, dto, { symbol: SymbolWithExchangeMobxDto.create(dto.symbol) });
    }

    static createFromArray(dtos: SymbolFundamentalDataHighlightsDto[]): SymbolDetailHighlightMobxDto[] {
        return dtos.map((dto) => SymbolDetailHighlightMobxDto.create(dto));
    }
}
