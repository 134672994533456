import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { color } from '../colors';
import { getOpacity } from '../utils/get-rgb';

export const createCard = (t: ThemeEnum) => {
    const card: DefaultTheme['card'] = {
        variant: {
            default: {
                background: color[t].base.light,
                color: color[t].base.text,
                border: getOpacity(color[t].base.gray),
                title: {
                    color: color[t].base.textMuted,
                },
                icon: {
                    color: color[t].base.gray,
                    hover: color[t].base.text,
                },
            },
            dark: {
                background: color[t].base.dark,
                color: color[t].base.white,
                border: getOpacity(color[t].base.gray),
                title: {
                    color: color[t].base.textMuted,
                },
                icon: {
                    color: color[t].base.gray,
                    hover: color[t].base.text,
                },
            },
            darkInverse: {
                background: color[t].base.light,
                color: color[t].base.dark,
                border: getOpacity(color[t].base.gray),
                title: {
                    color: color[t].base.textMuted,
                },
                icon: {
                    color: color[t].base.gray,
                    hover: color[t].base.text,
                },
            },
        },
    };
    return card;
};
