import { makeAutoObservable } from 'mobx';
import { WatchlistSymbolDto } from '../../../../defs/api';

export class WatchlistSymbolMobxDto implements WatchlistSymbolDto {
    id: string = '';

    symbol: string = '';

    name: string = '';

    exchangeName: string = '';

    exchangeId: string = '';

    currency: string = '';

    marketCapitalization: number | null = null;

    constructor() {
        makeAutoObservable(this, {});
    }

    static create(dto: WatchlistSymbolDto): WatchlistSymbolMobxDto {
        const mobxDto = new WatchlistSymbolMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: WatchlistSymbolDto[]): WatchlistSymbolMobxDto[] {
        return dtos.map((dto) => WatchlistSymbolMobxDto.create(dto));
    }

    get marketCapitalizationBn() {
        if (this.marketCapitalization) {
            return this.marketCapitalization / 1000;
        }
        return null;
    }
}
