/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RealizedDividendsSummaryDto
 */
export interface RealizedDividendsSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof RealizedDividendsSummaryDto
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedDividendsSummaryDto
     */
    overallValue: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedDividendsSummaryDto
     */
    overallForeignValue: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedDividendsSummaryDto
     */
    limitPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedDividendsSummaryDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedDividendsSummaryDto
     */
    overallValueInCzk: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedDividendsSummaryDto
     */
    overallForeignValueInCzk: number | null;
}

export function RealizedDividendsSummaryDtoFromJSON(json: any): RealizedDividendsSummaryDto {
    return RealizedDividendsSummaryDtoFromJSONTyped(json, false);
}

export function RealizedDividendsSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RealizedDividendsSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quantity': json['quantity'],
        'overallValue': json['overallValue'],
        'overallForeignValue': json['overallForeignValue'],
        'limitPercentage': json['limitPercentage'],
        'limit': json['limit'],
        'overallValueInCzk': json['overallValueInCzk'],
        'overallForeignValueInCzk': json['overallForeignValueInCzk'],
    };
}

export function RealizedDividendsSummaryDtoToJSON(value?: RealizedDividendsSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quantity': value.quantity,
        'overallValue': value.overallValue,
        'overallForeignValue': value.overallForeignValue,
        'limitPercentage': value.limitPercentage,
        'limit': value.limit,
        'overallValueInCzk': value.overallValueInCzk,
        'overallForeignValueInCzk': value.overallForeignValueInCzk,
    };
}

