/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrencyEntity,
    CurrencyEntityFromJSON,
    CurrencyEntityFromJSONTyped,
    CurrencyEntityToJSON,
} from './CurrencyEntity';
import {
    PortfolioEntity,
    PortfolioEntityFromJSON,
    PortfolioEntityFromJSONTyped,
    PortfolioEntityToJSON,
} from './PortfolioEntity';
import {
    SymbolEntity,
    SymbolEntityFromJSON,
    SymbolEntityFromJSONTyped,
    SymbolEntityToJSON,
} from './SymbolEntity';
import {
    TradeTypeEnum,
    TradeTypeEnumFromJSON,
    TradeTypeEnumFromJSONTyped,
    TradeTypeEnumToJSON,
} from './TradeTypeEnum';

/**
 * 
 * @export
 * @interface TradeEntity
 */
export interface TradeEntity {
    /**
     * 
     * @type {TradeTypeEnum}
     * @memberof TradeEntity
     */
    type: TradeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeEntity
     */
    id: string;
    /**
     * 
     * @type {CurrencyEntity}
     * @memberof TradeEntity
     */
    currency: CurrencyEntity;
    /**
     * 
     * @type {SymbolEntity}
     * @memberof TradeEntity
     */
    symbol: SymbolEntity;
    /**
     * 
     * @type {PortfolioEntity}
     * @memberof TradeEntity
     */
    portfolio: PortfolioEntity;
    /**
     * 
     * @type {Date}
     * @memberof TradeEntity
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof TradeEntity
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof TradeEntity
     */
    unitPrice: number;
    /**
     * 
     * @type {number}
     * @memberof TradeEntity
     */
    fees: number;
    /**
     * 
     * @type {string}
     * @memberof TradeEntity
     */
    note: string | null;
    /**
     * 
     * @type {string}
     * @memberof TradeEntity
     */
    referenceId: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeEntity
     */
    processed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeEntity
     */
    deleted: boolean;
}

export function TradeEntityFromJSON(json: any): TradeEntity {
    return TradeEntityFromJSONTyped(json, false);
}

export function TradeEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': TradeTypeEnumFromJSON(json['type']),
        'id': json['id'],
        'currency': CurrencyEntityFromJSON(json['currency']),
        'symbol': SymbolEntityFromJSON(json['symbol']),
        'portfolio': PortfolioEntityFromJSON(json['portfolio']),
        'date': (new Date(json['date'])),
        'amount': json['amount'],
        'unitPrice': json['unitPrice'],
        'fees': json['fees'],
        'note': json['note'],
        'referenceId': json['referenceId'],
        'processed': json['processed'],
        'deleted': json['deleted'],
    };
}

export function TradeEntityToJSON(value?: TradeEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': TradeTypeEnumToJSON(value.type),
        'id': value.id,
        'currency': CurrencyEntityToJSON(value.currency),
        'symbol': SymbolEntityToJSON(value.symbol),
        'portfolio': PortfolioEntityToJSON(value.portfolio),
        'date': (value.date.toISOString()),
        'amount': value.amount,
        'unitPrice': value.unitPrice,
        'fees': value.fees,
        'note': value.note,
        'referenceId': value.referenceId,
        'processed': value.processed,
        'deleted': value.deleted,
    };
}

