import { makeAutoObservable } from 'mobx';
import { AdminStatisticsDto } from '../../../../../defs/api';

export class AdminStatisticsMobxDto implements AdminStatisticsDto {
    public enteredDate = new Date();

    public count = 0;

    public logged = 0;

    public canceled = 0;

    public premium = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: AdminStatisticsDto): AdminStatisticsMobxDto {
        const mobxDto = new AdminStatisticsMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: AdminStatisticsDto[]): AdminStatisticsMobxDto[] {
        return dtos.map((dto) => AdminStatisticsMobxDto.create(dto));
    }
}
