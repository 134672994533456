/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorEnum,
    ColorEnumFromJSON,
    ColorEnumFromJSONTyped,
    ColorEnumToJSON,
} from './ColorEnum';
import {
    IconNameEnum,
    IconNameEnumFromJSON,
    IconNameEnumFromJSONTyped,
    IconNameEnumToJSON,
} from './IconNameEnum';

/**
 * 
 * @export
 * @interface UpdatePortfolioDto
 */
export interface UpdatePortfolioDto {
    /**
     * 
     * @type {IconNameEnum}
     * @memberof UpdatePortfolioDto
     */
    iconName: IconNameEnum;
    /**
     * 
     * @type {ColorEnum}
     * @memberof UpdatePortfolioDto
     */
    colorName: ColorEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioDto
     */
    currencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePortfolioDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePortfolioDto
     */
    isRecalculating: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePortfolioDto
     */
    selected: boolean;
}

export function UpdatePortfolioDtoFromJSON(json: any): UpdatePortfolioDto {
    return UpdatePortfolioDtoFromJSONTyped(json, false);
}

export function UpdatePortfolioDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePortfolioDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iconName': IconNameEnumFromJSON(json['iconName']),
        'colorName': ColorEnumFromJSON(json['colorName']),
        'currencyCode': json['currencyCode'],
        'id': json['id'],
        'name': json['name'],
        'isRecalculating': json['isRecalculating'],
        'selected': json['selected'],
    };
}

export function UpdatePortfolioDtoToJSON(value?: UpdatePortfolioDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iconName': IconNameEnumToJSON(value.iconName),
        'colorName': ColorEnumToJSON(value.colorName),
        'currencyCode': value.currencyCode,
        'id': value.id,
        'name': value.name,
        'isRecalculating': value.isRecalculating,
        'selected': value.selected,
    };
}

