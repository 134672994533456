/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SymbolAlternativeDto
 */
export interface SymbolAlternativeDto {
    /**
     * 
     * @type {string}
     * @memberof SymbolAlternativeDto
     */
    symbol: string;
    /**
     * 
     * @type {number}
     * @memberof SymbolAlternativeDto
     */
    name: number;
    /**
     * 
     * @type {string}
     * @memberof SymbolAlternativeDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolAlternativeDto
     */
    alternativeSymbol: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolAlternativeDto
     */
    alternativeName: string;
    /**
     * 
     * @type {Date}
     * @memberof SymbolAlternativeDto
     */
    validFrom: Date;
}

export function SymbolAlternativeDtoFromJSON(json: any): SymbolAlternativeDto {
    return SymbolAlternativeDtoFromJSONTyped(json, false);
}

export function SymbolAlternativeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolAlternativeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'symbol': json['symbol'],
        'name': json['name'],
        'id': json['id'],
        'alternativeSymbol': json['alternativeSymbol'],
        'alternativeName': json['alternativeName'],
        'validFrom': (new Date(json['validFrom'])),
    };
}

export function SymbolAlternativeDtoToJSON(value?: SymbolAlternativeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'symbol': value.symbol,
        'name': value.name,
        'id': value.id,
        'alternativeSymbol': value.alternativeSymbol,
        'alternativeName': value.alternativeName,
        'validFrom': (value.validFrom.toISOString()),
    };
}

