import { ThemeEnum } from '@app/common';
import { DefaultTheme } from 'styled-components';
import { color } from '../colors';

export const createSwitch = (t: ThemeEnum) => {
    const switchCheckbox: DefaultTheme['switchCheckbox'] = {
        primary: {
            background: {
                off: color[t].base.gray,
                on: color[t].gradient.base.primary,
            },
            icon: {
                off: color[t].base.gray,
                on: color[t].base.primary,
            },
        },
        negative: {
            background: {
                off: color[t].base.negative,
                on: color[t].gradient.base.negative,
            },
            icon: {
                off: color[t].lighten.negative,
                on: color[t].lighten.negative,
            },
        },
        positive: {
            background: {
                off: color[t].base.positive,
                on: color[t].gradient.base.positive,
            },
            icon: {
                off: color[t].base.text,
                on: color[t].base.white,
            },
        },
    };
    return switchCheckbox;
};
