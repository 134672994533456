import { makeAutoObservable } from 'mobx';
import { SymbolDividendChartDto } from '../../../../../defs/api';
import { SymbolValueMobxDto } from '../symbol-value-mobx-dto';

export class SymbolDividendChartMobxDto implements SymbolDividendChartDto {
    timePeriod: string;

    symbols: SymbolValueMobxDto[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolDividendChartDto): SymbolDividendChartMobxDto {
        const mobxDto = new SymbolDividendChartMobxDto();
        return Object.assign(mobxDto, dto, { symbols: SymbolValueMobxDto.createFromArray(dto.symbols) });
    }

    static createFromArray(dtos: SymbolDividendChartDto[]): SymbolDividendChartMobxDto[] {
        return dtos.map((dto) => SymbolDividendChartMobxDto.create(dto));
    }
}
