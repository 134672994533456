import React from 'react';
import { Wrapper, Header, Content, Inner } from './layout-error.styled';
import { Container } from '../../../../components/web/container/container';
import { Logo } from '../../../../components/app/logo/logo';

interface LayoutErrorPropsInterface {
    children: React.ReactNode;
}

export const LayoutError: React.FC<LayoutErrorPropsInterface> = ({ children }) => {
    return (
        <Wrapper>
            <Container>
                <Inner>
                    <Header>
                        <Logo white />
                    </Header>
                    <Content>{children}</Content>
                </Inner>
            </Container>
        </Wrapper>
    );
};
