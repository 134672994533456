import { makeAutoObservable } from 'mobx';
import { IconNameEnum, ColorEnum } from '@app/common';
import { WatchlistDto } from '../../../../defs/api';

export class WatchlistMobxDto implements WatchlistDto {
    id: string = '';

    name: string = '';

    iconName: IconNameEnum = IconNameEnum.WalletFilled;

    colorName: ColorEnum = ColorEnum.Blue;

    userId: string = '';

    constructor() {
        makeAutoObservable(this, {});
    }

    static create(dto: WatchlistDto): WatchlistMobxDto {
        const mobxDto = new WatchlistMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: WatchlistDto[]): WatchlistMobxDto[] {
        return dtos.map((dto) => WatchlistMobxDto.create(dto));
    }
}
