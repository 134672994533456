import styled from 'styled-components';

export const Wrapper = styled.i`
    align-items: center;
    display: inline-flex;
    position: relative;
    transition: unset;
    justify-content: center;
    width: 1.6rem;
    height: 1.6rem;

    img {
        height: auto !important;
    }

    svg {
        align-items: center;
        display: inline-flex;
        height: inherit;
        stroke-width: 0;
        transition: unset;
        width: inherit;
        fill: transparent;
        margin: unset;
    }
`;
