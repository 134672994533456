import { makeAutoObservable } from 'mobx';
import { PortfolioIntegrationDto } from '../../../../defs/api';

export class PortfolioIntegrationMobxDto implements PortfolioIntegrationDto {
    trading212Error: string | null = null;

    trading212Token: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: PortfolioIntegrationDto): PortfolioIntegrationMobxDto {
        const mobxDto = new PortfolioIntegrationMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: PortfolioIntegrationDto[]): PortfolioIntegrationMobxDto[] {
        return dtos.map((dto) => PortfolioIntegrationMobxDto.create(dto));
    }

    setTrading212Token(trading212Token: string) {
        this.trading212Token = trading212Token;
    }
}
