/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TradeListDto,
    TradeListDtoFromJSON,
    TradeListDtoFromJSONTyped,
    TradeListDtoToJSON,
} from './TradeListDto';

/**
 * 
 * @export
 * @interface TradeListPaginatedDto
 */
export interface TradeListPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof TradeListPaginatedDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof TradeListPaginatedDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof TradeListPaginatedDto
     */
    offset: number;
    /**
     * 
     * @type {Array<TradeListDto>}
     * @memberof TradeListPaginatedDto
     */
    items: Array<TradeListDto>;
}

export function TradeListPaginatedDtoFromJSON(json: any): TradeListPaginatedDto {
    return TradeListPaginatedDtoFromJSONTyped(json, false);
}

export function TradeListPaginatedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeListPaginatedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'limit': json['limit'],
        'offset': json['offset'],
        'items': ((json['items'] as Array<any>).map(TradeListDtoFromJSON)),
    };
}

export function TradeListPaginatedDtoToJSON(value?: TradeListPaginatedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'limit': value.limit,
        'offset': value.offset,
        'items': ((value.items as Array<any>).map(TradeListDtoToJSON)),
    };
}

