import Cookies from 'universal-cookie';
import moment from 'moment';
import { routes } from '../../config/routes';
import { LookupKeyEnum } from '../../defs/api';

export class CookieService {
    cookie?: string;

    jwtKey = 'jwt';

    jwtImpersonateKey = 'jwt_impersonate';

    privateModeEnabledKey = 'private_mode_enabled';

    premiumDialogOpenProtectionKey = 'premium_dialog_open_protection';

    bannerYearlyOpenProtectionKey = 'yearly_banner_open_protection';

    interestedInSubscriptionKey = 'interested_in_subscription';

    calculateFeesKey = 'calculate_fees';

    constructor(cookie?: string) {
        this.cookie = cookie;
    }

    isPrivateModeEnabled(): boolean {
        const cookies = new Cookies(this.cookie);
        return cookies.get(this.privateModeEnabledKey) === 'true';
    }

    togglePrivateModeEnabled(): void {
        const cookies = new Cookies(this.cookie);
        if (this.isPrivateModeEnabled()) {
            cookies.remove(this.privateModeEnabledKey, { path: '/' });
        } else {
            cookies.set(this.privateModeEnabledKey, 'true', {
                path: '/',
                expires: moment().add(1, 'year').toDate(),
            });
        }
    }

    setRedirectUrl(redirect: string) {
        if (redirect !== routes.app.index) {
            window.localStorage.setItem('redirectUrl', redirect);
        }
    }

    loadRedirectUrl() {
        const redirectUrl = window.localStorage.getItem('redirectUrl');
        if (redirectUrl) {
            window.location.replace(redirectUrl);
            window.localStorage.removeItem('redirectUrl');
        }
    }

    hasJwtImpersonateToken(): boolean {
        return this.getJwtImpersonateToken() !== undefined;
    }

    getJwtImpersonateToken(): string | undefined {
        const cookies = new Cookies(this.cookie);
        return cookies.get(this.jwtImpersonateKey);
    }

    hasJwtToken(): boolean {
        return this.getJwtToken() !== undefined;
    }

    getJwtToken(): string | undefined {
        const cookies = new Cookies(this.cookie);

        const impersonationToken = this.getJwtImpersonateToken();

        return impersonationToken || cookies.get(this.jwtKey);
    }

    setJwtToken(accessToken: string | null, rememberMe?: boolean): void {
        const cookies = new Cookies(this.cookie);

        if (accessToken) {
            cookies.set(this.jwtKey, accessToken, {
                path: '/',
                expires: rememberMe ? moment().add(1, 'year').toDate() : undefined,
            });
        } else {
            cookies.remove(this.jwtKey, { path: '/' });
        }
    }

    setImpersonateJwtToken(accessToken: string | null): void {
        const cookies = new Cookies(this.cookie);

        if (accessToken) {
            cookies.set(this.jwtImpersonateKey, accessToken, { path: '/' });
        } else {
            cookies.remove(this.jwtImpersonateKey, { path: '/' });
        }
    }

    setPremiumDialogOpenProtection(seconds: number): void {
        const cookies = new Cookies(this.cookie);
        cookies.set(this.premiumDialogOpenProtectionKey, 'true', { path: '/', maxAge: seconds });
    }

    hasPremiumDialogOpenProtection(): boolean {
        const cookies = new Cookies(this.cookie);
        return cookies.get(this.premiumDialogOpenProtectionKey) === 'true';
    }

    setBannerYearlyOpenProtection(seconds = 60 * 60 * 24 * 30): void {
        const cookies = new Cookies(this.cookie);
        cookies.set(this.bannerYearlyOpenProtectionKey, 'true', {
            path: '/',
            maxAge: seconds,
        });
    }

    hasBannerYearlyOpenProtection(): boolean {
        const cookies = new Cookies(this.cookie);
        return cookies.get(this.bannerYearlyOpenProtectionKey) === 'true';
    }

    setInterestedInSubscription(subscription: LookupKeyEnum | null): void {
        const cookies = new Cookies(this.cookie);

        if (subscription) {
            cookies.set(this.interestedInSubscriptionKey, subscription, { path: '/' });
        } else {
            cookies.remove(this.interestedInSubscriptionKey, { path: '/' });
        }
    }

    getInterestedInSubscription(): LookupKeyEnum | null {
        const cookies = new Cookies(this.cookie);
        const interestedInSubscription = cookies.get(this.interestedInSubscriptionKey);

        if (!interestedInSubscription) {
            return null;
        }

        return interestedInSubscription as LookupKeyEnum;
    }

    setCalculateFees(calculateFees: boolean): void {
        const cookies = new Cookies(this.cookie);
        cookies.set(this.calculateFeesKey, calculateFees, { path: '/' });
    }

    getCalculateFees(): boolean {
        const cookies = new Cookies(this.cookie);
        const calculateFees = cookies.get(this.calculateFeesKey);
        return calculateFees === 'true';
    }
}
