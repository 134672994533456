import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { color } from '../colors';

export const createSpinner = (t: ThemeEnum) => {
    const spinner: DefaultTheme['spinner'] = {
        default: {
            start: color[t].base.textMuted,
            end: color[t].base.textMuted,
            duration: '1s',
        },
        logo: {
            start: color[t].base.textMuted,
            end: color[t].base.text,
            duration: '1.8s',
        },
    };
    return spinner;
};
