import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { color } from '../colors';

export const createHeadline = (t: ThemeEnum) => {
    const headline: DefaultTheme['headline'] = {
        base: {
            color: color[t].base.text,
        },
        size: {
            h1: {
                fontSize: '3.2rem',
                lineHeight: '4.0rem',
                mobile: {
                    fontSize: '2.6rem',
                    lineHeight: '3.4rem',
                },
            },
            h2: {
                fontSize: '2.4rem',
                lineHeight: '3.0rem',
                mobile: {
                    fontSize: '2.0rem',
                    lineHeight: '2.6rem',
                },
            },
            h3: {
                fontSize: '1.8rem',
                lineHeight: '2.2rem',
                mobile: {
                    fontSize: '1.6rem',
                    lineHeight: '2.0rem',
                },
            },
            h4: {
                fontSize: '1.6rem',
                lineHeight: '2.0rem',
                mobile: {
                    fontSize: '1.6rem',
                    lineHeight: '2.0rem',
                },
            },
            h5: {
                fontSize: '1.4rem',
                lineHeight: '1.8rem',
                mobile: {
                    fontSize: '1.4rem',
                    lineHeight: '1.8rem',
                },
            },
        },
    };
    return headline;
};
