import { makeAutoObservable } from 'mobx';
import { PieChartDataDto } from '../../../../defs/api';

export class PieChartDataMobxDto implements PieChartDataDto {
    id: string = '';

    name: string = '';

    value: number = 0;

    absoluteValue: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: PieChartDataDto): PieChartDataMobxDto {
        const mobxDto = new PieChartDataMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: PieChartDataDto[]): PieChartDataMobxDto[] {
        return dtos.map((dto) => PieChartDataMobxDto.create(dto));
    }
}
