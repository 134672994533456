import { ThemeEnum } from '@app/common';
import { DefaultTheme } from 'styled-components';
import { color } from '../colors';
import { getOpacity } from '../utils/get-rgb';

export const createPalette = (t: ThemeEnum) => {
    const palette: DefaultTheme['palette'] = {
        common: {
            link: {
                main: color[t].base.dark,
                hover: color[t].base.primary,
                active: color[t].base.dark,
            },
            border: color[t].base.border,
            divider: getOpacity(color[t].base.gray),
            text: color[t].base.text,
            textMuted: color[t].base.textMuted,
            textInverse: color[t].base.textInverse,
            note: color[t].base.note,
        },
        gradient: {
            primary: {
                main: color[t].gradient.base.primary,
                text: color[t].base.white,
            },
            negative: {
                main: color[t].gradient.base.negative,
                text: color[t].base.white,
            },
            positive: {
                main: color[t].gradient.base.positive,
                text: color[t].base.white,
            },
        },
        color: {
            default: {
                main: color[t].base.gray,
                dark: color[t].dark.gray,
                darken: color[t].darken.gray,
                light: color[t].light.gray,
                lighten: color[t].lighten.gray,
                opacity: {
                    low: getOpacity(color[t].base.gray, 0.5),
                    medium: getOpacity(color[t].base.gray, 0.24),
                    high: getOpacity(color[t].base.gray, 0.16),
                },
                text: color[t].base.white,
            },
            black: {
                main: color[t].base.black,
                dark: color[t].dark.black,
                darken: color[t].darken.black,
                light: color[t].light.black,
                lighten: color[t].lighten.black,
                opacity: {
                    low: getOpacity(color[t].base.dark, 0.5),
                    medium: getOpacity(color[t].base.dark, 0.24),
                    high: getOpacity(color[t].base.dark),
                },
                text: color[t].base.white,
            },
            white: {
                main: color[t].base.white,
                dark: color[t].dark.white,
                darken: color[t].darken.white,
                light: color[t].light.white,
                lighten: color[t].lighten.white,
                opacity: {
                    low: getOpacity(color[t].base.white, 0.5),
                    medium: getOpacity(color[t].base.white, 0.24),
                    high: getOpacity(color[t].base.white),
                },
                text: color[t].base.dark,
            },
            note: {
                main: color[t].base.note,
                dark: color[t].dark.note,
                darken: color[t].darken.note,
                light: color[t].light.note,
                lighten: color[t].lighten.note,
                opacity: {
                    low: getOpacity(color[t].base.note, 0.5),
                    medium: getOpacity(color[t].base.note, 0.24),
                    high: getOpacity(color[t].base.note),
                },
                text: color[t].base.dark,
            },
            gray: {
                main: color[t].base.gray,
                dark: color[t].dark.gray,
                darken: color[t].darken.gray,
                light: color[t].light.gray,
                lighten: color[t].lighten.gray,
                opacity: {
                    low: getOpacity(color[t].base.gray, 0.5),
                    medium: getOpacity(color[t].base.gray, 0.24),
                    high: getOpacity(color[t].base.gray),
                },
                text: color[t].base.dark,
            },
            primary: {
                main: color[t].base.primary,
                dark: color[t].dark.primary,
                darken: color[t].darken.primary,
                light: color[t].light.primary,
                lighten: color[t].lighten.primary,
                opacity: {
                    low: getOpacity(color[t].base.primary, 0.5),
                    medium: getOpacity(color[t].base.primary, 0.24),
                    high: getOpacity(color[t].base.primary),
                },
                text: color[t].base.white,
            },
            secondary: {
                main: color[t].base.secondary,
                dark: color[t].dark.secondary,
                darken: color[t].darken.secondary,
                light: color[t].light.secondary,
                lighten: color[t].lighten.secondary,
                opacity: {
                    low: getOpacity(color[t].base.secondary, 0.5),
                    medium: getOpacity(color[t].base.secondary, 0.24),
                    high: getOpacity(color[t].base.secondary),
                },
                text: color[t].base.white,
            },
            positive: {
                main: color[t].base.positive,
                dark: color[t].dark.positive,
                darken: color[t].darken.positive,
                light: color[t].light.positive,
                lighten: color[t].lighten.positive,
                opacity: {
                    low: getOpacity(color[t].base.positive, 0.5),
                    medium: getOpacity(color[t].base.positive, 0.24),
                    high: getOpacity(color[t].base.positive),
                },
                text: color[t].base.white,
            },
            negative: {
                main: color[t].base.negative,
                dark: color[t].dark.negative,
                darken: color[t].darken.negative,
                light: color[t].light.negative,
                lighten: color[t].lighten.negative,
                opacity: {
                    low: getOpacity(color[t].base.negative, 0.5),
                    medium: getOpacity(color[t].base.negative, 0.24),
                    high: getOpacity(color[t].base.negative),
                },
                text: color[t].base.white,
            },
            warning: {
                main: color[t].base.warning,
                dark: color[t].dark.warning,
                darken: color[t].darken.warning,
                light: color[t].light.warning,
                lighten: color[t].lighten.warning,
                opacity: {
                    low: getOpacity(color[t].base.warning, 0.5),
                    medium: getOpacity(color[t].base.warning, 0.24),
                    high: getOpacity(color[t].base.warning),
                },
                text: color[t].base.white,
            },
            blue: {
                main: color[t].base.blue,
                dark: color[t].dark.blue,
                darken: color[t].darken.blue,
                light: color[t].light.blue,
                lighten: color[t].lighten.blue,
                opacity: {
                    low: getOpacity(color[t].base.blue, 0.5),
                    medium: getOpacity(color[t].base.blue, 0.24),
                    high: getOpacity(color[t].base.blue),
                },
                text: color[t].base.white,
            },
            purple: {
                main: color[t].base.purple,
                dark: color[t].dark.purple,
                darken: color[t].darken.purple,
                light: color[t].light.purple,
                lighten: color[t].lighten.purple,
                opacity: {
                    low: getOpacity(color[t].base.purple, 0.5),
                    medium: getOpacity(color[t].base.purple, 0.24),
                    high: getOpacity(color[t].base.purple),
                },
                text: color[t].base.white,
            },
            green: {
                main: color[t].base.green,
                dark: color[t].dark.green,
                darken: color[t].darken.green,
                light: color[t].light.green,
                lighten: color[t].lighten.green,
                opacity: {
                    low: getOpacity(color[t].base.green, 0.5),
                    medium: getOpacity(color[t].base.green, 0.24),
                    high: getOpacity(color[t].base.green),
                },
                text: color[t].base.white,
            },
            google: {
                main: color[t].base.google,
                dark: color[t].dark.google,
                darken: color[t].darken.google,
                light: color[t].light.google,
                lighten: color[t].lighten.google,
                opacity: {
                    low: getOpacity(color[t].base.google, 0.5),
                    medium: getOpacity(color[t].base.google, 0.24),
                    high: getOpacity(color[t].base.google),
                },
                text: color[t].base.white,
            },
            apple: {
                main: color[t].base.apple,
                dark: color[t].dark.apple,
                darken: color[t].darken.apple,
                light: color[t].light.apple,
                lighten: color[t].lighten.apple,
                opacity: {
                    low: getOpacity(color[t].base.apple, 0.5),
                    medium: getOpacity(color[t].base.apple, 0.24),
                    high: getOpacity(color[t].base.apple),
                },
                text: color[t].base.white,
            },
            bitcoin: {
                main: color[t].base.bitcoin,
                dark: color[t].dark.bitcoin,
                darken: color[t].darken.bitcoin,
                light: color[t].light.bitcoin,
                lighten: color[t].lighten.bitcoin,
                opacity: {
                    low: getOpacity(color[t].base.bitcoin, 0.5),
                    medium: getOpacity(color[t].base.bitcoin, 0.24),
                    high: getOpacity(color[t].base.bitcoin),
                },
                text: color[t].base.white,
            },
        },
        background: {
            body: color[t].base.lighten,
            header: color[t].base.light,
        },
    };
    return palette;
};
