/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreatePortfolioDto,
    CreatePortfolioDtoFromJSON,
    CreatePortfolioDtoToJSON,
    IsRecalculatingDto,
    IsRecalculatingDtoFromJSON,
    IsRecalculatingDtoToJSON,
    PaginatedPortfolioListDto,
    PaginatedPortfolioListDtoFromJSON,
    PaginatedPortfolioListDtoToJSON,
    PortfolioDto,
    PortfolioDtoFromJSON,
    PortfolioDtoToJSON,
    PortfolioOverviewDto,
    PortfolioOverviewDtoFromJSON,
    PortfolioOverviewDtoToJSON,
    PortfolioShareInformationDto,
    PortfolioShareInformationDtoFromJSON,
    PortfolioShareInformationDtoToJSON,
    UpdatePortfolioDto,
    UpdatePortfolioDtoFromJSON,
    UpdatePortfolioDtoToJSON,
} from '../models';

export interface PortfolioControllerCreateRequest {
    createPortfolioDto: CreatePortfolioDto;
}

export interface PortfolioControllerDeleteRequest {
    id: string;
}

export interface PortfolioControllerGetAllRequest {
    limit: number;
    offset: number;
    phrase?: string;
    withProblem?: boolean;
}

export interface PortfolioControllerGetShareInformationRequest {
    portfolioId: string;
}

export interface PortfolioControllerIsRecalculatingRequest {
    id: string;
}

export interface PortfolioControllerSetSelectedRequest {
    id: string;
}

export interface PortfolioControllerUpdateRequest {
    id: string;
    updatePortfolioDto: UpdatePortfolioDto;
}

export interface PortfolioControllerUpdateShareInformationRequest {
    portfolioId: string;
    portfolioShareInformationDto: PortfolioShareInformationDto;
}

export interface SharedPortfolioControllerSharedRequest {
    shareSlug: string;
}

/**
 * 
 */
export class PortfoliosApi extends runtime.BaseAPI {

    /**
     * Create portfolio
     */
    async portfolioControllerCreateRaw(requestParameters: PortfolioControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PortfolioDto>> {
        if (requestParameters.createPortfolioDto === null || requestParameters.createPortfolioDto === undefined) {
            throw new runtime.RequiredError('createPortfolioDto','Required parameter requestParameters.createPortfolioDto was null or undefined when calling portfolioControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/portfolios`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePortfolioDtoToJSON(requestParameters.createPortfolioDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioDtoFromJSON(jsonValue));
    }

    /**
     * Create portfolio
     */
    async portfolioControllerCreate(requestParameters: PortfolioControllerCreateRequest, initOverrides?: RequestInit): Promise<PortfolioDto> {
        const response = await this.portfolioControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove portfolio
     */
    async portfolioControllerDeleteRaw(requestParameters: PortfolioControllerDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling portfolioControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/portfolios/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Remove portfolio
     */
    async portfolioControllerDelete(requestParameters: PortfolioControllerDeleteRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.portfolioControllerDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all portfolio list for admin
     */
    async portfolioControllerGetAllRaw(requestParameters: PortfolioControllerGetAllRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaginatedPortfolioListDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling portfolioControllerGetAll.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling portfolioControllerGetAll.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.phrase !== undefined) {
            queryParameters['phrase'] = requestParameters.phrase;
        }

        if (requestParameters.withProblem !== undefined) {
            queryParameters['withProblem'] = requestParameters.withProblem;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/portfolios/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPortfolioListDtoFromJSON(jsonValue));
    }

    /**
     * Get all portfolio list for admin
     */
    async portfolioControllerGetAll(requestParameters: PortfolioControllerGetAllRequest, initOverrides?: RequestInit): Promise<PaginatedPortfolioListDto> {
        const response = await this.portfolioControllerGetAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get array of portfolios
     */
    async portfolioControllerGetListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PortfolioDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/portfolios`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PortfolioDtoFromJSON));
    }

    /**
     * Get array of portfolios
     */
    async portfolioControllerGetList(initOverrides?: RequestInit): Promise<Array<PortfolioDto>> {
        const response = await this.portfolioControllerGetListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get portfolios overview
     */
    async portfolioControllerGetOverviewRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PortfolioOverviewDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/portfolios/overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioOverviewDtoFromJSON(jsonValue));
    }

    /**
     * Get portfolios overview
     */
    async portfolioControllerGetOverview(initOverrides?: RequestInit): Promise<PortfolioOverviewDto> {
        const response = await this.portfolioControllerGetOverviewRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get share information
     */
    async portfolioControllerGetShareInformationRaw(requestParameters: PortfolioControllerGetShareInformationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PortfolioShareInformationDto>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling portfolioControllerGetShareInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/portfolios/share-information/{portfolioId}`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioShareInformationDtoFromJSON(jsonValue));
    }

    /**
     * Get share information
     */
    async portfolioControllerGetShareInformation(requestParameters: PortfolioControllerGetShareInformationRequest, initOverrides?: RequestInit): Promise<PortfolioShareInformationDto> {
        const response = await this.portfolioControllerGetShareInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Checks if portfolio is still recalculating.
     */
    async portfolioControllerIsRecalculatingRaw(requestParameters: PortfolioControllerIsRecalculatingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IsRecalculatingDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling portfolioControllerIsRecalculating.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/portfolios/{id}/is-recalculating`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IsRecalculatingDtoFromJSON(jsonValue));
    }

    /**
     * Checks if portfolio is still recalculating.
     */
    async portfolioControllerIsRecalculating(requestParameters: PortfolioControllerIsRecalculatingRequest, initOverrides?: RequestInit): Promise<IsRecalculatingDto> {
        const response = await this.portfolioControllerIsRecalculatingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set portfolio as selected
     */
    async portfolioControllerSetSelectedRaw(requestParameters: PortfolioControllerSetSelectedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PortfolioDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling portfolioControllerSetSelected.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/portfolios/{id}/select`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioDtoFromJSON(jsonValue));
    }

    /**
     * Set portfolio as selected
     */
    async portfolioControllerSetSelected(requestParameters: PortfolioControllerSetSelectedRequest, initOverrides?: RequestInit): Promise<PortfolioDto> {
        const response = await this.portfolioControllerSetSelectedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update portfolio
     */
    async portfolioControllerUpdateRaw(requestParameters: PortfolioControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PortfolioDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling portfolioControllerUpdate.');
        }

        if (requestParameters.updatePortfolioDto === null || requestParameters.updatePortfolioDto === undefined) {
            throw new runtime.RequiredError('updatePortfolioDto','Required parameter requestParameters.updatePortfolioDto was null or undefined when calling portfolioControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/portfolios/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePortfolioDtoToJSON(requestParameters.updatePortfolioDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioDtoFromJSON(jsonValue));
    }

    /**
     * Update portfolio
     */
    async portfolioControllerUpdate(requestParameters: PortfolioControllerUpdateRequest, initOverrides?: RequestInit): Promise<PortfolioDto> {
        const response = await this.portfolioControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update share information
     */
    async portfolioControllerUpdateShareInformationRaw(requestParameters: PortfolioControllerUpdateShareInformationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.portfolioId === null || requestParameters.portfolioId === undefined) {
            throw new runtime.RequiredError('portfolioId','Required parameter requestParameters.portfolioId was null or undefined when calling portfolioControllerUpdateShareInformation.');
        }

        if (requestParameters.portfolioShareInformationDto === null || requestParameters.portfolioShareInformationDto === undefined) {
            throw new runtime.RequiredError('portfolioShareInformationDto','Required parameter requestParameters.portfolioShareInformationDto was null or undefined when calling portfolioControllerUpdateShareInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/portfolios/share-information/{portfolioId}`.replace(`{${"portfolioId"}}`, encodeURIComponent(String(requestParameters.portfolioId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PortfolioShareInformationDtoToJSON(requestParameters.portfolioShareInformationDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update share information
     */
    async portfolioControllerUpdateShareInformation(requestParameters: PortfolioControllerUpdateShareInformationRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.portfolioControllerUpdateShareInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get shared portfolio
     */
    async sharedPortfolioControllerSharedRaw(requestParameters: SharedPortfolioControllerSharedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PortfolioDto>> {
        if (requestParameters.shareSlug === null || requestParameters.shareSlug === undefined) {
            throw new runtime.RequiredError('shareSlug','Required parameter requestParameters.shareSlug was null or undefined when calling sharedPortfolioControllerShared.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/portfolios/shared/{shareSlug}`.replace(`{${"shareSlug"}}`, encodeURIComponent(String(requestParameters.shareSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioDtoFromJSON(jsonValue));
    }

    /**
     * Get shared portfolio
     */
    async sharedPortfolioControllerShared(requestParameters: SharedPortfolioControllerSharedRequest, initOverrides?: RequestInit): Promise<PortfolioDto> {
        const response = await this.sharedPortfolioControllerSharedRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
