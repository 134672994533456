/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AreaChartDto,
    AreaChartDtoFromJSON,
    AreaChartDtoFromJSONTyped,
    AreaChartDtoToJSON,
} from './AreaChartDto';

/**
 * 
 * @export
 * @interface ProfitChartDto
 */
export interface ProfitChartDto {
    /**
     * 
     * @type {Array<AreaChartDto>}
     * @memberof ProfitChartDto
     */
    absoluteProfits: Array<AreaChartDto>;
    /**
     * 
     * @type {Array<AreaChartDto>}
     * @memberof ProfitChartDto
     */
    percentageProfits: Array<AreaChartDto>;
}

export function ProfitChartDtoFromJSON(json: any): ProfitChartDto {
    return ProfitChartDtoFromJSONTyped(json, false);
}

export function ProfitChartDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfitChartDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'absoluteProfits': ((json['absoluteProfits'] as Array<any>).map(AreaChartDtoFromJSON)),
        'percentageProfits': ((json['percentageProfits'] as Array<any>).map(AreaChartDtoFromJSON)),
    };
}

export function ProfitChartDtoToJSON(value?: ProfitChartDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'absoluteProfits': ((value.absoluteProfits as Array<any>).map(AreaChartDtoToJSON)),
        'percentageProfits': ((value.percentageProfits as Array<any>).map(AreaChartDtoToJSON)),
    };
}

