/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PortfolioEntity,
    PortfolioEntityFromJSON,
    PortfolioEntityFromJSONTyped,
    PortfolioEntityToJSON,
} from './PortfolioEntity';

/**
 * 
 * @export
 * @interface PortfolioIntegrationEntity
 */
export interface PortfolioIntegrationEntity {
    /**
     * 
     * @type {PortfolioEntity}
     * @memberof PortfolioIntegrationEntity
     */
    portfolio: PortfolioEntity;
    /**
     * 
     * @type {string}
     * @memberof PortfolioIntegrationEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioIntegrationEntity
     */
    portfolioId: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioIntegrationEntity
     */
    trading212Token: string | null;
    /**
     * 
     * @type {string}
     * @memberof PortfolioIntegrationEntity
     */
    trading212Error: string | null;
}

export function PortfolioIntegrationEntityFromJSON(json: any): PortfolioIntegrationEntity {
    return PortfolioIntegrationEntityFromJSONTyped(json, false);
}

export function PortfolioIntegrationEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioIntegrationEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'portfolio': PortfolioEntityFromJSON(json['portfolio']),
        'id': json['id'],
        'portfolioId': json['portfolioId'],
        'trading212Token': json['trading212Token'],
        'trading212Error': json['trading212Error'],
    };
}

export function PortfolioIntegrationEntityToJSON(value?: PortfolioIntegrationEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'portfolio': PortfolioEntityToJSON(value.portfolio),
        'id': value.id,
        'portfolioId': value.portfolioId,
        'trading212Token': value.trading212Token,
        'trading212Error': value.trading212Error,
    };
}

