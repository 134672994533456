import { makeAutoObservable } from 'mobx';
import { RealizedDividendsSummaryDto } from '../../../../../defs/api';

export class RealizedDividendsSummaryMobxDto implements RealizedDividendsSummaryDto {
    public quantity: number = 0;

    public overallValue: number = 0;

    public overallForeignValue: number = 0;

    public limitPercentage: number = 0;

    public limit: number = 0;

    public overallValueInCzk: number = 0;

    public overallForeignValueInCzk: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: RealizedDividendsSummaryDto): RealizedDividendsSummaryMobxDto {
        const mobxDto = new RealizedDividendsSummaryMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: RealizedDividendsSummaryDto[]): RealizedDividendsSummaryMobxDto[] {
        return dtos.map((dto) => RealizedDividendsSummaryMobxDto.create(dto));
    }
}
