/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TradeTypeEnum {
    Buy = 'buy',
    Sell = 'sell'
}

export function TradeTypeEnumFromJSON(json: any): TradeTypeEnum {
    return TradeTypeEnumFromJSONTyped(json, false);
}

export function TradeTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeTypeEnum {
    return json as TradeTypeEnum;
}

export function TradeTypeEnumToJSON(value?: TradeTypeEnum | null): any {
    return value as any;
}

