import { makeAutoObservable } from 'mobx';
import { PriceAlertDirectionEnum, SymbolPriceAlertDto } from '../../../../defs/api';

export class SymbolPriceAlertMobxDto implements SymbolPriceAlertDto {
    id: string = '';

    currentPrice: number = 0;

    targetPrice: number = 0;

    symbolId: string = '';

    direction: PriceAlertDirectionEnum = PriceAlertDirectionEnum.Up;

    email?: string;

    fullName?: string;

    symbol?: string;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolPriceAlertDto): SymbolPriceAlertMobxDto {
        const mobxDto = new SymbolPriceAlertMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: SymbolPriceAlertDto[]): SymbolPriceAlertMobxDto[] {
        return dtos.map((dto) => SymbolPriceAlertMobxDto.create(dto));
    }
}
