import { makeAutoObservable } from 'mobx';
import { PortfolioOverviewDto } from '../../../../defs/api';

export class PortfolioOverviewMobxDto implements PortfolioOverviewDto {
    public portfolioValue: number;

    public invested: number;

    public unrealizedProfit: number;

    public realizedProfit: number;

    public currency: string;

    public dividends: number;

    public rateImpact: number;

    public fees: number;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: PortfolioOverviewDto): PortfolioOverviewMobxDto {
        const mobxDto = new PortfolioOverviewMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: PortfolioOverviewDto[]): PortfolioOverviewMobxDto[] {
        return dtos.map((dto) => PortfolioOverviewMobxDto.create(dto));
    }
}
