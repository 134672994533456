import { darken } from 'polished';
import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { getOpacity } from '../utils/get-rgb';
import { color } from '../colors';

export const createIconButton = (t: ThemeEnum) => {
    const iconButton: DefaultTheme['iconButton'] = {
        size: {
            xsmall: {
                icon: '1.2rem',
                wrapper: '3.0rem',
            },
            vsmall: {
                icon: '1.4rem',
                wrapper: '3.2rem',
            },
            small: {
                icon: '1.6rem',
                wrapper: '3.6rem',
            },
            medium: {
                icon: '1.8rem',
                wrapper: '4.0rem',
            },
            large: {
                icon: '2.4rem',
                wrapper: '4.8rem',
            },
            vlarge: {
                icon: '2.8rem',
                wrapper: '5.2rem',
            },
            xlarge: {
                icon: '3.8rem',
                wrapper: '6.2rem',
            },
        },
        variant: {
            default: {
                filled: {
                    base: {
                        background: getOpacity(color[t].base.gray, 0.3),
                        border: 'transparent',
                        color: color[t].base.text,
                    },
                    hover: {
                        background: color[t].darken.gray,
                        border: color[t].darken.gray,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].dark.gray,
                        border: color[t].dark.gray,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: getOpacity(color[t].base.gray, 0.1),
                        border: 'transparent',
                        color: getOpacity(color[t].base.text, 0.2),
                    },
                },
                outlined: {
                    base: {
                        background: 'transparent',
                        border: color[t].light.gray,
                        color: color[t].base.text,
                    },
                    hover: {
                        background: color[t].darken.gray,
                        border: color[t].darken.gray,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].darken.gray,
                        border: color[t].darken.gray,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: color[t].darken.gray,
                        border: color[t].darken.gray,
                        color: color[t].base.white,
                    },
                },
            },
            primary: {
                filled: {
                    base: {
                        background: color[t].base.primary,
                        border: color[t].base.primary,
                        color: color[t].base.white,
                    },
                    hover: {
                        background: color[t].darken.primary,
                        border: color[t].darken.primary,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].dark.primary,
                        border: color[t].dark.primary,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: getOpacity(color[t].base.gray, 0.1),
                        border: 'transparent',
                        color: getOpacity(color[t].base.text, 0.2),
                    },
                },
                outlined: {
                    base: {
                        background: color[t].light.primary,
                        border: color[t].light.primary,
                        color: color[t].base.text,
                    },
                    hover: {
                        background: color[t].darken.primary,
                        border: color[t].darken.primary,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].darken.primary,
                        border: color[t].darken.primary,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: color[t].darken.primary,
                        border: color[t].darken.gray,
                        color: color[t].base.white,
                    },
                },
            },
            secondary: {
                filled: {
                    base: {
                        background: color[t].base.secondary,
                        border: color[t].base.secondary,
                        color: color[t].base.textInverse,
                    },
                    hover: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.secondary,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].dark.secondary,
                        border: color[t].dark.secondary,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: getOpacity(color[t].base.gray, 0.1),
                        border: 'transparent',
                        color: getOpacity(color[t].base.text, 0.2),
                    },
                },
                outlined: {
                    base: {
                        background: color[t].light.secondary,
                        border: color[t].light.secondary,
                        color: color[t].base.text,
                    },
                    hover: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.secondary,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.secondary,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: color[t].darken.secondary,
                        border: color[t].darken.gray,
                        color: color[t].base.white,
                    },
                },
            },
            warning: {
                filled: {
                    base: {
                        background: color[t].base.warning,
                        border: color[t].base.warning,
                        color: color[t].base.white,
                    },
                    hover: {
                        background: color[t].darken.warning,
                        border: color[t].darken.warning,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].dark.warning,
                        border: color[t].dark.warning,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: getOpacity(color[t].base.gray, 0.1),
                        border: 'transparent',
                        color: getOpacity(color[t].base.text, 0.2),
                    },
                },
                outlined: {
                    base: {
                        background: color[t].light.warning,
                        border: color[t].light.warning,
                        color: color[t].base.text,
                    },
                    hover: {
                        background: color[t].darken.warning,
                        border: color[t].darken.warning,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].darken.warning,
                        border: color[t].darken.warning,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: color[t].darken.warning,
                        border: color[t].darken.gray,
                        color: color[t].base.white,
                    },
                },
            },
            positive: {
                filled: {
                    base: {
                        background: color[t].base.positive,
                        border: color[t].base.positive,
                        color: color[t].base.white,
                    },
                    hover: {
                        background: color[t].darken.positive,
                        border: color[t].darken.positive,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].dark.positive,
                        border: color[t].dark.positive,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: getOpacity(color[t].base.gray, 0.1),
                        border: 'transparent',
                        color: getOpacity(color[t].base.text, 0.2),
                    },
                },
                outlined: {
                    base: {
                        background: color[t].light.positive,
                        border: color[t].light.positive,
                        color: color[t].base.text,
                    },
                    hover: {
                        background: color[t].darken.positive,
                        border: color[t].darken.positive,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].darken.positive,
                        border: color[t].darken.positive,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: color[t].darken.positive,
                        border: color[t].darken.gray,
                        color: color[t].base.white,
                    },
                },
            },
            negative: {
                filled: {
                    base: {
                        background: color[t].base.negative,
                        border: color[t].base.negative,
                        color: color[t].base.white,
                    },
                    hover: {
                        background: color[t].darken.negative,
                        border: color[t].darken.negative,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].dark.negative,
                        border: color[t].dark.negative,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: getOpacity(color[t].base.gray, 0.1),
                        border: 'transparent',
                        color: getOpacity(color[t].base.text, 0.2),
                    },
                },
                outlined: {
                    base: {
                        background: color[t].light.negative,
                        border: color[t].light.negative,
                        color: color[t].base.text,
                    },
                    hover: {
                        background: color[t].darken.negative,
                        border: color[t].darken.negative,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].darken.negative,
                        border: color[t].darken.negative,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: color[t].darken.negative,
                        border: color[t].darken.gray,
                        color: color[t].base.white,
                    },
                },
            },
            note: {
                filled: {
                    base: {
                        background: color[t].base.note,
                        border: color[t].base.note,
                        color: darken(0.5, color[t].base.note),
                    },
                    hover: {
                        background: color[t].darken.note,
                        border: color[t].darken.note,
                        color: darken(0.5, color[t].base.note),
                    },
                    active: {
                        background: color[t].dark.note,
                        border: color[t].dark.note,
                        color: darken(0.5, color[t].base.note),
                    },
                    disabled: {
                        background: getOpacity(color[t].base.gray, 0.1),
                        border: 'transparent',
                        color: getOpacity(color[t].base.text, 0.2),
                    },
                },
                outlined: {
                    base: {
                        background: color[t].light.note,
                        border: color[t].light.note,
                        color: color[t].base.text,
                    },
                    hover: {
                        background: color[t].darken.note,
                        border: color[t].darken.note,
                        color: color[t].base.white,
                    },
                    active: {
                        background: color[t].darken.note,
                        border: color[t].darken.note,
                        color: color[t].base.white,
                    },
                    disabled: {
                        background: color[t].darken.note,
                        border: color[t].darken.gray,
                        color: color[t].base.white,
                    },
                },
            },
        },
    };
    return iconButton;
};
