/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LeaderboardRankingDto,
    LeaderboardRankingDtoFromJSON,
    LeaderboardRankingDtoFromJSONTyped,
    LeaderboardRankingDtoToJSON,
} from './LeaderboardRankingDto';

/**
 * 
 * @export
 * @interface IndividualRankingsDto
 */
export interface IndividualRankingsDto {
    /**
     * 
     * @type {LeaderboardRankingDto}
     * @memberof IndividualRankingsDto
     */
    mostInvestedRanking: LeaderboardRankingDto;
    /**
     * 
     * @type {LeaderboardRankingDto}
     * @memberof IndividualRankingsDto
     */
    activityRanking: LeaderboardRankingDto;
}

export function IndividualRankingsDtoFromJSON(json: any): IndividualRankingsDto {
    return IndividualRankingsDtoFromJSONTyped(json, false);
}

export function IndividualRankingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndividualRankingsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mostInvestedRanking': LeaderboardRankingDtoFromJSON(json['mostInvestedRanking']),
        'activityRanking': LeaderboardRankingDtoFromJSON(json['activityRanking']),
    };
}

export function IndividualRankingsDtoToJSON(value?: IndividualRankingsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mostInvestedRanking': LeaderboardRankingDtoToJSON(value.mostInvestedRanking),
        'activityRanking': LeaderboardRankingDtoToJSON(value.activityRanking),
    };
}

