/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateWatchlistDto,
    CreateWatchlistDtoFromJSON,
    CreateWatchlistDtoToJSON,
    UpdateWatchlistDto,
    UpdateWatchlistDtoFromJSON,
    UpdateWatchlistDtoToJSON,
    WatchlistDto,
    WatchlistDtoFromJSON,
    WatchlistDtoToJSON,
    WatchlistListDto,
    WatchlistListDtoFromJSON,
    WatchlistListDtoToJSON,
} from '../models';

export interface WatchlistControllerAddSymbolRequest {
    id: string;
    symbolId: string;
}

export interface WatchlistControllerCreateRequest {
    createWatchlistDto: CreateWatchlistDto;
}

export interface WatchlistControllerDeleteRequest {
    id: string;
}

export interface WatchlistControllerDeleteSymbolRequest {
    id: string;
    symbolId: string;
}

export interface WatchlistControllerGetListRequest {
    symbolId?: string;
}

export interface WatchlistControllerUpdateRequest {
    id: string;
    updateWatchlistDto: UpdateWatchlistDto;
}

/**
 * 
 */
export class WatchlistsApi extends runtime.BaseAPI {

    /**
     * Add watchlist symbol
     */
    async watchlistControllerAddSymbolRaw(requestParameters: WatchlistControllerAddSymbolRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WatchlistDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling watchlistControllerAddSymbol.');
        }

        if (requestParameters.symbolId === null || requestParameters.symbolId === undefined) {
            throw new runtime.RequiredError('symbolId','Required parameter requestParameters.symbolId was null or undefined when calling watchlistControllerAddSymbol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/watchlist/{id}/symbol/{symbolId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"symbolId"}}`, encodeURIComponent(String(requestParameters.symbolId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WatchlistDtoFromJSON(jsonValue));
    }

    /**
     * Add watchlist symbol
     */
    async watchlistControllerAddSymbol(requestParameters: WatchlistControllerAddSymbolRequest, initOverrides?: RequestInit): Promise<WatchlistDto> {
        const response = await this.watchlistControllerAddSymbolRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create watchlist
     */
    async watchlistControllerCreateRaw(requestParameters: WatchlistControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WatchlistDto>> {
        if (requestParameters.createWatchlistDto === null || requestParameters.createWatchlistDto === undefined) {
            throw new runtime.RequiredError('createWatchlistDto','Required parameter requestParameters.createWatchlistDto was null or undefined when calling watchlistControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/watchlist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWatchlistDtoToJSON(requestParameters.createWatchlistDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WatchlistDtoFromJSON(jsonValue));
    }

    /**
     * Create watchlist
     */
    async watchlistControllerCreate(requestParameters: WatchlistControllerCreateRequest, initOverrides?: RequestInit): Promise<WatchlistDto> {
        const response = await this.watchlistControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove watchlist
     */
    async watchlistControllerDeleteRaw(requestParameters: WatchlistControllerDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling watchlistControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/watchlist/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Remove watchlist
     */
    async watchlistControllerDelete(requestParameters: WatchlistControllerDeleteRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.watchlistControllerDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove watchlist symbol
     */
    async watchlistControllerDeleteSymbolRaw(requestParameters: WatchlistControllerDeleteSymbolRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling watchlistControllerDeleteSymbol.');
        }

        if (requestParameters.symbolId === null || requestParameters.symbolId === undefined) {
            throw new runtime.RequiredError('symbolId','Required parameter requestParameters.symbolId was null or undefined when calling watchlistControllerDeleteSymbol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/watchlist/{id}/symbol/{symbolId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"symbolId"}}`, encodeURIComponent(String(requestParameters.symbolId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Remove watchlist symbol
     */
    async watchlistControllerDeleteSymbol(requestParameters: WatchlistControllerDeleteSymbolRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.watchlistControllerDeleteSymbolRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get watchlists
     */
    async watchlistControllerGetListRaw(requestParameters: WatchlistControllerGetListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<WatchlistListDto>>> {
        const queryParameters: any = {};

        if (requestParameters.symbolId !== undefined) {
            queryParameters['symbolId'] = requestParameters.symbolId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/watchlist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WatchlistListDtoFromJSON));
    }

    /**
     * Get watchlists
     */
    async watchlistControllerGetList(requestParameters: WatchlistControllerGetListRequest = {}, initOverrides?: RequestInit): Promise<Array<WatchlistListDto>> {
        const response = await this.watchlistControllerGetListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update watchlist
     */
    async watchlistControllerUpdateRaw(requestParameters: WatchlistControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WatchlistDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling watchlistControllerUpdate.');
        }

        if (requestParameters.updateWatchlistDto === null || requestParameters.updateWatchlistDto === undefined) {
            throw new runtime.RequiredError('updateWatchlistDto','Required parameter requestParameters.updateWatchlistDto was null or undefined when calling watchlistControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/watchlist/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWatchlistDtoToJSON(requestParameters.updateWatchlistDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WatchlistDtoFromJSON(jsonValue));
    }

    /**
     * Update watchlist
     */
    async watchlistControllerUpdate(requestParameters: WatchlistControllerUpdateRequest, initOverrides?: RequestInit): Promise<WatchlistDto> {
        const response = await this.watchlistControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
