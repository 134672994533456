import { makeAutoObservable } from 'mobx';
import { YearDto } from '../../../../defs/api';
import { MonthMobxDto } from './month-mobx-dto';

export class YearMobxDto implements YearDto {
    year: number = 0;

    months: MonthMobxDto[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    get totalWithSell(): number {
        return this.months.reduce((p1, p2) => p1 + p2.amountWithSell, 0);
    }

    get totalWithoutSell(): number {
        return this.months.reduce((p1, p2) => p1 + p2.amountWithoutSell, 0);
    }

    static create(dto: YearDto): YearMobxDto {
        const mobxDto = new YearMobxDto();
        return Object.assign(mobxDto, dto, { months: MonthMobxDto.createFromArray(dto.months) });
    }

    static createFromArray(dtos: YearDto[]): YearMobxDto[] {
        return dtos.map((dto) => YearMobxDto.create(dto));
    }
}
