/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RealizedTransactionsSummaryDto
 */
export interface RealizedTransactionsSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof RealizedTransactionsSummaryDto
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedTransactionsSummaryDto
     */
    overallValue: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedTransactionsSummaryDto
     */
    overallGain: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedTransactionsSummaryDto
     */
    limitPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedTransactionsSummaryDto
     */
    fees: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedTransactionsSummaryDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof RealizedTransactionsSummaryDto
     */
    overallValueInCzk: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedTransactionsSummaryDto
     */
    overallGainInCzk: number | null;
    /**
     * 
     * @type {number}
     * @memberof RealizedTransactionsSummaryDto
     */
    overallFeesInCzk: number | null;
}

export function RealizedTransactionsSummaryDtoFromJSON(json: any): RealizedTransactionsSummaryDto {
    return RealizedTransactionsSummaryDtoFromJSONTyped(json, false);
}

export function RealizedTransactionsSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RealizedTransactionsSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quantity': json['quantity'],
        'overallValue': json['overallValue'],
        'overallGain': json['overallGain'],
        'limitPercentage': json['limitPercentage'],
        'fees': json['fees'],
        'limit': json['limit'],
        'overallValueInCzk': json['overallValueInCzk'],
        'overallGainInCzk': json['overallGainInCzk'],
        'overallFeesInCzk': json['overallFeesInCzk'],
    };
}

export function RealizedTransactionsSummaryDtoToJSON(value?: RealizedTransactionsSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quantity': value.quantity,
        'overallValue': value.overallValue,
        'overallGain': value.overallGain,
        'limitPercentage': value.limitPercentage,
        'fees': value.fees,
        'limit': value.limit,
        'overallValueInCzk': value.overallValueInCzk,
        'overallGainInCzk': value.overallGainInCzk,
        'overallFeesInCzk': value.overallFeesInCzk,
    };
}

