import { ThemeEnum } from '@app/common';
import { DefaultTheme } from 'styled-components';
import { color } from '../colors';

export const createDatepicker = (t: ThemeEnum) => {
    const datepicker: DefaultTheme['datepicker'] = {
        base: {
            open: {
                background: color[t].light.gray,
                color: color[t].base.text,
            },
            close: {
                background: color[t].light.negative,
                color: color[t].base.white,
            },
        },
        hover: {
            open: {
                background: color[t].darken.gray,
                color: color[t].base.white,
            },
            close: {
                background: color[t].base.negative,
                color: color[t].base.white,
            },
        },
    };
    return datepicker;
};
