/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TemplateContentDto,
    TemplateContentDtoFromJSON,
    TemplateContentDtoToJSON,
} from '../models';

export interface MailControllerGetTemplateContentRequest {
    templateName: string;
    variables?: string;
}

/**
 * 
 */
export class MailsApi extends runtime.BaseAPI {

    /**
     * Get template content
     */
    async mailControllerGetTemplateContentRaw(requestParameters: MailControllerGetTemplateContentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TemplateContentDto>> {
        if (requestParameters.templateName === null || requestParameters.templateName === undefined) {
            throw new runtime.RequiredError('templateName','Required parameter requestParameters.templateName was null or undefined when calling mailControllerGetTemplateContent.');
        }

        const queryParameters: any = {};

        if (requestParameters.variables !== undefined) {
            queryParameters['variables'] = requestParameters.variables;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mails/{templateName}`.replace(`{${"templateName"}}`, encodeURIComponent(String(requestParameters.templateName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateContentDtoFromJSON(jsonValue));
    }

    /**
     * Get template content
     */
    async mailControllerGetTemplateContent(requestParameters: MailControllerGetTemplateContentRequest, initOverrides?: RequestInit): Promise<TemplateContentDto> {
        const response = await this.mailControllerGetTemplateContentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Synchronize users to Ecomail
     */
    async mailControllerSynchronizeUsersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mails/synchronize-users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Synchronize users to Ecomail
     */
    async mailControllerSynchronizeUsers(initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.mailControllerSynchronizeUsersRaw(initOverrides);
        return await response.value();
    }

}
