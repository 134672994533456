/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Trading212IntegrationDto
 */
export interface Trading212IntegrationDto {
    /**
     * 
     * @type {string}
     * @memberof Trading212IntegrationDto
     */
    trading212Token: string | null;
    /**
     * 
     * @type {string}
     * @memberof Trading212IntegrationDto
     */
    trading212Error: string | null;
}

export function Trading212IntegrationDtoFromJSON(json: any): Trading212IntegrationDto {
    return Trading212IntegrationDtoFromJSONTyped(json, false);
}

export function Trading212IntegrationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Trading212IntegrationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trading212Token': json['trading212Token'],
        'trading212Error': json['trading212Error'],
    };
}

export function Trading212IntegrationDtoToJSON(value?: Trading212IntegrationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trading212Token': value.trading212Token,
        'trading212Error': value.trading212Error,
    };
}

