/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolTypeEnum,
    SymbolTypeEnumFromJSON,
    SymbolTypeEnumFromJSONTyped,
    SymbolTypeEnumToJSON,
} from './SymbolTypeEnum';

/**
 * 
 * @export
 * @interface SymbolDividendDto
 */
export interface SymbolDividendDto {
    /**
     * 
     * @type {SymbolTypeEnum}
     * @memberof SymbolDividendDto
     */
    symbolType: SymbolTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendDto
     */
    exchangeId: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendDto
     */
    exchangeName: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendDto
     */
    symbolId: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendDto
     */
    symbolCode: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendDto
     */
    symbolName: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendDto
     */
    symbolCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendDto
     */
    region: string;
    /**
     * 
     * @type {number}
     * @memberof SymbolDividendDto
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof SymbolDividendDto
     */
    amountInSymbolCurrency: number;
    /**
     * 
     * @type {number}
     * @memberof SymbolDividendDto
     */
    amountPerUnit: number;
    /**
     * 
     * @type {number}
     * @memberof SymbolDividendDto
     */
    amountPerUnitInSymbolCurrency: number;
    /**
     * 
     * @type {Date}
     * @memberof SymbolDividendDto
     */
    recordDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SymbolDividendDto
     */
    paymentDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SymbolDividendDto
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendDto
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof SymbolDividendDto
     */
    amount: number;
}

export function SymbolDividendDtoFromJSON(json: any): SymbolDividendDto {
    return SymbolDividendDtoFromJSONTyped(json, false);
}

export function SymbolDividendDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolDividendDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'symbolType': SymbolTypeEnumFromJSON(json['symbolType']),
        'exchangeId': json['exchangeId'],
        'exchangeName': json['exchangeName'],
        'symbolId': json['symbolId'],
        'symbolCode': json['symbolCode'],
        'symbolName': json['symbolName'],
        'symbolCurrency': json['symbolCurrency'],
        'region': json['region'],
        'quantity': json['quantity'],
        'amountInSymbolCurrency': json['amountInSymbolCurrency'],
        'amountPerUnit': json['amountPerUnit'],
        'amountPerUnitInSymbolCurrency': json['amountPerUnitInSymbolCurrency'],
        'recordDate': !exists(json, 'recordDate') ? undefined : (new Date(json['recordDate'])),
        'paymentDate': !exists(json, 'paymentDate') ? undefined : (new Date(json['paymentDate'])),
        'date': (new Date(json['date'])),
        'currency': json['currency'],
        'amount': json['amount'],
    };
}

export function SymbolDividendDtoToJSON(value?: SymbolDividendDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'symbolType': SymbolTypeEnumToJSON(value.symbolType),
        'exchangeId': value.exchangeId,
        'exchangeName': value.exchangeName,
        'symbolId': value.symbolId,
        'symbolCode': value.symbolCode,
        'symbolName': value.symbolName,
        'symbolCurrency': value.symbolCurrency,
        'region': value.region,
        'quantity': value.quantity,
        'amountInSymbolCurrency': value.amountInSymbolCurrency,
        'amountPerUnit': value.amountPerUnit,
        'amountPerUnitInSymbolCurrency': value.amountPerUnitInSymbolCurrency,
        'recordDate': value.recordDate === undefined ? undefined : (value.recordDate.toISOString()),
        'paymentDate': value.paymentDate === undefined ? undefined : (value.paymentDate.toISOString()),
        'date': (value.date.toISOString()),
        'currency': value.currency,
        'amount': value.amount,
    };
}

