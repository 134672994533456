import { ThemeEnum } from '@app/common';
import { DefaultTheme } from 'styled-components';
import { color } from '../colors';

export const createAlert = (t: ThemeEnum) => {
    const alert: DefaultTheme['alert'] = {
        success: {
            background: color[t].light.success,
            color: color[t].dark.success,
        },
        error: {
            background: color[t].light.error,
            color: color[t].dark.error,
        },
        warning: {
            background: color[t].light.warning,
            color: color[t].darken.warning,
        },
        info: {
            background: color[t].light.info,
            color: color[t].dark.info,
        },
    };
    return alert;
};
