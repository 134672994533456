import { makeAutoObservable, runInAction } from 'mobx';
import { BarDatum } from '@nivo/bar';
import type { RootStore } from './root-store';
import { DividendMobxDto } from '../mobx/dtos/dividend/dividend-mobx-dto';
import { DividendCalendarMobxDto } from '../mobx/dtos/dividend/dividend-calendar-mobx-dto';
import { SymbolDividendChartMobxDto } from '../mobx/dtos/symbol/symbol-dividend/symbol-dividend-chart-mobx-dto';
import { SymbolDividendCalendarMobxDto } from '../mobx/dtos/symbol/symbol-dividend/symbol-dividend-calendar-mobx-dto';
import { DividendCalendarMonthMobxDto } from '../mobx/dtos/dividend/dividend-calendar-month-mobx-dto';
import {
    SymbolDividendControllerGetListRequest,
    SymbolDividendControllerGetPaginatedListRequest,
} from '../../defs/api';

export type DividendsStoreHydration = {
    allDividends: DividendMobxDto[];
    upcomingDividends: DividendMobxDto[];
    calendar: DividendCalendarMobxDto[];
    chart: SymbolDividendChartMobxDto[];
    symbolCalendar: SymbolDividendCalendarMobxDto[];
    symbolCalendarTotal: DividendCalendarMonthMobxDto[];
    totalDividendsCount: number;
};

export class DividendsStore {
    rootStore: RootStore;

    calendar: DividendCalendarMobxDto[] = [];

    allDividends: DividendMobxDto[] = [];

    upcomingDividends: DividendMobxDto[] = [];

    selectedYear: number | null = null;

    chart: SymbolDividendChartMobxDto[] = [];

    symbolCalendar: SymbolDividendCalendarMobxDto[] = [];

    symbolCalendarTotal: DividendCalendarMonthMobxDto[] = [];

    totalDividendsCount = 0;

    page = 1;

    limit = Number(process.env.NEXT_PUBLIC_PAGE_LIMIT);

    chartSymbols = new Set<string>();

    loading = true;

    paginatedLoading = true;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    startPaginatedLoading(): void {
        this.paginatedLoading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    stopPaginatedLoading(): void {
        this.paginatedLoading = false;
    }

    async fetchDividends(): Promise<void> {
        const query: SymbolDividendControllerGetListRequest = {};
        if (this.selectedYear) {
            query.year = this.selectedYear;
        }
        try {
            this.startLoading();
            const fetchedDividends = await this.rootStore.loadWithDelay(() => {
                return this.rootStore.apiClient.symbolDividendsController.symbolDividendControllerGetList(query);
            });
            if (fetchedDividends) {
                runInAction(() => {
                    this.upcomingDividends = DividendMobxDto.createFromArray(fetchedDividends.upcomingDividends);
                    this.calendar = DividendCalendarMobxDto.createFromArray(fetchedDividends.calendar);
                    this.chart = SymbolDividendChartMobxDto.createFromArray(fetchedDividends.chart);
                    this.symbolCalendar = SymbolDividendCalendarMobxDto.createFromArray(
                        fetchedDividends.symbolCalendar,
                    );
                    this.symbolCalendarTotal = DividendCalendarMonthMobxDto.createFromArray(
                        fetchedDividends.symbolCalendarTotal,
                    );
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async fetchPaginatedDividends(): Promise<void> {
        const query: SymbolDividendControllerGetPaginatedListRequest = {
            limit: this.limit,
            offset: (this.page - 1) * this.limit,
        };
        try {
            this.startPaginatedLoading();
            const fetchedDividends = await this.rootStore.loadWithDelay(() => {
                return this.rootStore.apiClient.symbolDividendsController.symbolDividendControllerGetPaginatedList(
                    query,
                );
            });

            runInAction(() => {
                this.allDividends = DividendMobxDto.createFromArray(fetchedDividends.items);
                this.totalDividendsCount = fetchedDividends?.total || 0;
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopPaginatedLoading();
        }
    }

    setSelectedYear(year: number | null) {
        runInAction(() => {
            this.selectedYear = year;
        });
    }

    get dividendSymbolsChartData(): BarDatum[] {
        const chartData: BarDatum[] = [];
        this.chart.forEach((data) => {
            const obj: BarDatum = {
                quarter: data.timePeriod,
            };

            data.symbols.forEach((s) => {
                runInAction(() => {
                    this.chartSymbols.add(s.symbol);
                });

                obj[s.symbol as keyof BarDatum] = s.value;
            });
            chartData.push(obj);
        });
        return chartData;
    }

    get dividendSymbols(): string[] {
        return Array.from(this.chartSymbols);
    }

    get hasDividends(): boolean {
        return this.allDividends.length > 0 || this.calendar.length > 0;
    }

    async setPage(page: number): Promise<void> {
        this.page = page;
        await this.fetchPaginatedDividends();
    }

    get pagesCount(): number {
        let pagesCount = 0;
        const pageLimit = Number(process.env.NEXT_PUBLIC_PAGE_LIMIT);
        if (!pageLimit) {
            throw new Error('Env constant NEXT_PUBLIC_PAGE_LIMIT is undefined.');
        }
        pagesCount = Math.ceil(this.totalDividendsCount / pageLimit);

        return pagesCount;
    }

    get totalAmountByCurrentYear(): number {
        return this.symbolCalendarTotal.reduce((p1, p2) => p1 + p2.amount, 0);
    }
}
