import { makeObservable, observable } from 'mobx';
import { RealizedBuyTradeDto, SymbolTypeEnum, TradeTypeEnum } from '../../../../defs/api';

export class RealizedBuyTradeMobxDto implements RealizedBuyTradeDto {
    public unitPrice: number = 0;

    public symbolId: string = '';

    public symbol: string = '';

    public symbolName: string = '';

    public symbolCurrency: string = '';

    public symbolRate: number = 0;

    public tradeCurrency: string = '';

    public portfolioCurrency: string = '';

    public date: Date = new Date();

    public amount: number = 0;

    public totalPricePortfolioCurrency: number = 0;

    public tradeSymbolId: string = '';

    public exchange: string = '';

    public fees: number = 0;

    public feesPortfolioCurrency: number = 0;

    public rawAmount: number = 0;

    public note: string = '';

    public sellDate: Date = new Date();

    public sellTotalPricePortfolioCurrency: number = 0;

    public sellFeesPortfolioCurrency: number = 0;

    public symbolType: SymbolTypeEnum = SymbolTypeEnum.Cs;

    public type: TradeTypeEnum = TradeTypeEnum.Buy;

    constructor() {
        makeObservable(this, {
            sellDate: observable,
            sellTotalPricePortfolioCurrency: observable,
            sellFeesPortfolioCurrency: observable,
        });
    }

    static create(dto: RealizedBuyTradeDto): RealizedBuyTradeMobxDto {
        const mobxDto = new RealizedBuyTradeMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: RealizedBuyTradeDto[]): RealizedBuyTradeMobxDto[] {
        return dtos.map((dto) => RealizedBuyTradeMobxDto.create(dto));
    }
}
