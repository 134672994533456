/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubscriptionEnum,
    SubscriptionEnumFromJSON,
    SubscriptionEnumFromJSONTyped,
    SubscriptionEnumToJSON,
} from './SubscriptionEnum';

/**
 * 
 * @export
 * @interface ActiveUserDto
 */
export interface ActiveUserDto {
    /**
     * 
     * @type {SubscriptionEnum}
     * @memberof ActiveUserDto
     */
    subscription: SubscriptionEnum;
    /**
     * 
     * @type {number}
     * @memberof ActiveUserDto
     */
    portfoliosCount: number;
    /**
     * 
     * @type {number}
     * @memberof ActiveUserDto
     */
    tradesCount: number;
    /**
     * 
     * @type {number}
     * @memberof ActiveUserDto
     */
    assetsCount: number;
    /**
     * 
     * @type {string}
     * @memberof ActiveUserDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveUserDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveUserDto
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof ActiveUserDto
     */
    registrationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActiveUserDto
     */
    lastLoginDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ActiveUserDto
     */
    stripeCustomerId: string;
}

export function ActiveUserDtoFromJSON(json: any): ActiveUserDto {
    return ActiveUserDtoFromJSONTyped(json, false);
}

export function ActiveUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscription': SubscriptionEnumFromJSON(json['subscription']),
        'portfoliosCount': json['portfoliosCount'],
        'tradesCount': json['tradesCount'],
        'assetsCount': json['assetsCount'],
        'id': json['id'],
        'fullName': json['fullName'],
        'email': json['email'],
        'registrationDate': (new Date(json['registrationDate'])),
        'lastLoginDate': (new Date(json['lastLoginDate'])),
        'stripeCustomerId': json['stripeCustomerId'],
    };
}

export function ActiveUserDtoToJSON(value?: ActiveUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscription': SubscriptionEnumToJSON(value.subscription),
        'portfoliosCount': value.portfoliosCount,
        'tradesCount': value.tradesCount,
        'assetsCount': value.assetsCount,
        'id': value.id,
        'fullName': value.fullName,
        'email': value.email,
        'registrationDate': (value.registrationDate.toISOString()),
        'lastLoginDate': (value.lastLoginDate.toISOString()),
        'stripeCustomerId': value.stripeCustomerId,
    };
}

