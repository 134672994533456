/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TaxReportDto,
    TaxReportDtoFromJSON,
    TaxReportDtoToJSON,
} from '../models';

export interface TaxReportControllerGetTaxReportRequest {
    taxReportType?: string;
}

/**
 * 
 */
export class TaxReportApi extends runtime.BaseAPI {

    /**
     * Get detailed tax report
     */
    async taxReportControllerGetTaxReportRaw(requestParameters: TaxReportControllerGetTaxReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TaxReportDto>>> {
        const queryParameters: any = {};

        if (requestParameters.taxReportType !== undefined) {
            queryParameters['taxReportType'] = requestParameters.taxReportType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tax-report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaxReportDtoFromJSON));
    }

    /**
     * Get detailed tax report
     */
    async taxReportControllerGetTaxReport(requestParameters: TaxReportControllerGetTaxReportRequest = {}, initOverrides?: RequestInit): Promise<Array<TaxReportDto>> {
        const response = await this.taxReportControllerGetTaxReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
