/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortfolioOverviewDto
 */
export interface PortfolioOverviewDto {
    /**
     * 
     * @type {number}
     * @memberof PortfolioOverviewDto
     */
    portfolioValue: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioOverviewDto
     */
    invested: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioOverviewDto
     */
    unrealizedProfit: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioOverviewDto
     */
    realizedProfit: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioOverviewDto
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioOverviewDto
     */
    dividends: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioOverviewDto
     */
    rateImpact: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioOverviewDto
     */
    fees: number;
}

export function PortfolioOverviewDtoFromJSON(json: any): PortfolioOverviewDto {
    return PortfolioOverviewDtoFromJSONTyped(json, false);
}

export function PortfolioOverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioOverviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'portfolioValue': json['portfolioValue'],
        'invested': json['invested'],
        'unrealizedProfit': json['unrealizedProfit'],
        'realizedProfit': json['realizedProfit'],
        'currency': json['currency'],
        'dividends': json['dividends'],
        'rateImpact': json['rateImpact'],
        'fees': json['fees'],
    };
}

export function PortfolioOverviewDtoToJSON(value?: PortfolioOverviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'portfolioValue': value.portfolioValue,
        'invested': value.invested,
        'unrealizedProfit': value.unrealizedProfit,
        'realizedProfit': value.realizedProfit,
        'currency': value.currency,
        'dividends': value.dividends,
        'rateImpact': value.rateImpact,
        'fees': value.fees,
    };
}

