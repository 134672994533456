import { makeAutoObservable } from 'mobx';
import type { RootStore } from './root-store';
import type { AuthStore } from './auth-store';
import { AlertStore } from './alert-store';
import { LookupKeyEnum } from '../../defs/api';

export class AuthDialogStore {
    rootStore: RootStore;

    authStore: AuthStore;

    alertStore: AlertStore;

    activeModal: 'login' | 'forgottenPassword' | 'signUp' | 'emailConfirmation' | 'resetPassword' | null = null;

    loading = false;

    confirmLoading = false;

    confirmDone = false;

    isAgreeCheckboxChecked = false;

    constructor(authStore: AuthStore) {
        this.rootStore = authStore.rootStore;
        this.authStore = authStore;

        makeAutoObservable(this, {
            rootStore: false,
            authStore: false,
        });
    }

    closeDialog(): void {
        this.activeModal = null;
        this.rootStore.alertStore.clearAllMessages();
    }

    setLoginActive(keepMessages = false): void {
        this.activeModal = 'login';
        if (!keepMessages) {
            this.rootStore.alertStore.clearAllMessages();
        }
    }

    setForgottenPassActive(): void {
        this.activeModal = 'forgottenPassword';
        this.rootStore.alertStore.clearAllMessages();
    }

    setSignupActive(lookupKey: LookupKeyEnum | null = null): void {
        this.activeModal = 'signUp';
        this.rootStore.cookieService.setInterestedInSubscription(lookupKey);
        this.rootStore.alertStore.clearAllMessages();
    }

    setEmailConfirmationActive(): void {
        this.activeModal = 'emailConfirmation';
        this.rootStore.alertStore.clearAllMessages();
    }

    setResetPasswordActive(): void {
        this.activeModal = 'resetPassword';
        this.rootStore.alertStore.clearAllMessages();
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    switchIsAgreeCheckboxChecked(): void {
        this.isAgreeCheckboxChecked = !this.isAgreeCheckboxChecked;
    }
}
