/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

export function OrderEnumFromJSON(json: any): OrderEnum {
    return OrderEnumFromJSONTyped(json, false);
}

export function OrderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderEnum {
    return json as OrderEnum;
}

export function OrderEnumToJSON(value?: OrderEnum | null): any {
    return value as any;
}

