import { useCallback, useEffect, useState } from 'react';
import * as CookieConsent from 'vanilla-cookieconsent';
import { cookies } from '../config/cookies';

const useCookies = (isApp: boolean) => {
    const [requiredCookieAccepted, setRequiredCookieAccepted] = useState(false);
    const [optionalCookieAccepted, setOptionalCookieAccepted] = useState(false);

    const handleShowPreferences = useCallback(() => {
        CookieConsent.showPreferences();
    }, [CookieConsent.showPreferences]);

    useEffect(() => {
        CookieConsent.run({
            ...cookies,
            onConsent: ({ cookie }) => {
                if (cookie.categories.includes('necessary')) {
                    setRequiredCookieAccepted(true);
                }
                if (cookie.categories.includes('analytics')) {
                    setOptionalCookieAccepted(true);
                }
            },
            onChange: ({ cookie }) => {
                if (cookie.categories.includes('necessary')) {
                    setRequiredCookieAccepted(true);
                }
                if (cookie.categories.includes('analytics')) {
                    setOptionalCookieAccepted(true);
                }
            },
        }).then(() => {
            if (isApp) {
                CookieConsent.hide();
            }
        });
    }, [isApp]);

    return { optionalCookieAccepted, requiredCookieAccepted, handleShowPreferences };
};

export default useCookies;
