/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PortfolioIntegrationDto,
    PortfolioIntegrationDtoFromJSON,
    PortfolioIntegrationDtoToJSON,
    PortfolioIntegrationEntity,
    PortfolioIntegrationEntityFromJSON,
    PortfolioIntegrationEntityToJSON,
    Trading212IntegrationDto,
    Trading212IntegrationDtoFromJSON,
    Trading212IntegrationDtoToJSON,
} from '../models';

export interface PortfolioIntegrationControllerGetIntegrationRequest {
    id: string;
}

export interface PortfolioIntegrationControllerUpdateTrading212IntegrationRequest {
    id: string;
    trading212IntegrationDto: Trading212IntegrationDto;
}

/**
 * 
 */
export class PortfoliosIntegrationsApi extends runtime.BaseAPI {

    /**
     * Get portfolio integration
     */
    async portfolioIntegrationControllerGetIntegrationRaw(requestParameters: PortfolioIntegrationControllerGetIntegrationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PortfolioIntegrationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling portfolioIntegrationControllerGetIntegration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/portfolios/{id}/integration`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioIntegrationDtoFromJSON(jsonValue));
    }

    /**
     * Get portfolio integration
     */
    async portfolioIntegrationControllerGetIntegration(requestParameters: PortfolioIntegrationControllerGetIntegrationRequest, initOverrides?: RequestInit): Promise<PortfolioIntegrationDto> {
        const response = await this.portfolioIntegrationControllerGetIntegrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update portfolio integration
     */
    async portfolioIntegrationControllerUpdateTrading212IntegrationRaw(requestParameters: PortfolioIntegrationControllerUpdateTrading212IntegrationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PortfolioIntegrationEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling portfolioIntegrationControllerUpdateTrading212Integration.');
        }

        if (requestParameters.trading212IntegrationDto === null || requestParameters.trading212IntegrationDto === undefined) {
            throw new runtime.RequiredError('trading212IntegrationDto','Required parameter requestParameters.trading212IntegrationDto was null or undefined when calling portfolioIntegrationControllerUpdateTrading212Integration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/portfolios/{id}/trading212-integration`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: Trading212IntegrationDtoToJSON(requestParameters.trading212IntegrationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortfolioIntegrationEntityFromJSON(jsonValue));
    }

    /**
     * Update portfolio integration
     */
    async portfolioIntegrationControllerUpdateTrading212Integration(requestParameters: PortfolioIntegrationControllerUpdateTrading212IntegrationRequest, initOverrides?: RequestInit): Promise<PortfolioIntegrationEntity> {
        const response = await this.portfolioIntegrationControllerUpdateTrading212IntegrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
