/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShareTypeEnum,
    ShareTypeEnumFromJSON,
    ShareTypeEnumFromJSONTyped,
    ShareTypeEnumToJSON,
} from './ShareTypeEnum';

/**
 * 
 * @export
 * @interface PortfolioShareInformationDto
 */
export interface PortfolioShareInformationDto {
    /**
     * 
     * @type {ShareTypeEnum}
     * @memberof PortfolioShareInformationDto
     */
    shareType: ShareTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PortfolioShareInformationDto
     */
    shareSlug: string;
}

export function PortfolioShareInformationDtoFromJSON(json: any): PortfolioShareInformationDto {
    return PortfolioShareInformationDtoFromJSONTyped(json, false);
}

export function PortfolioShareInformationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioShareInformationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shareType': ShareTypeEnumFromJSON(json['shareType']),
        'shareSlug': json['shareSlug'],
    };
}

export function PortfolioShareInformationDtoToJSON(value?: PortfolioShareInformationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shareType': ShareTypeEnumToJSON(value.shareType),
        'shareSlug': value.shareSlug,
    };
}

