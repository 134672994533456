import { makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';
import { StatusMessageEnum } from '@app/common';
import type { RootStore } from '../root-store';
import { OverviewStore, OverviewStoreHydration } from './overview-store';
import { ProfitStore } from './profit-store';
import { RatiosStore, RatiosStoreHydration } from './ratios-store';
import { StatisticsIntervalTypeEnum } from '../../enums/statistics-interval-type-enum';
import { StatisticsIntervalInterface } from '../../interfaces/statistics-interval-interface';

export type StatisticStoreHydration = OverviewStoreHydration &
    RatiosStoreHydration & {
        type: StatisticsIntervalTypeEnum;
    };

export const STATISTIC_INTERVALS: StatisticsIntervalInterface[] = [
    {
        title: 'Za celou dobu',
        dateFrom: null,
        type: StatisticsIntervalTypeEnum.AllTime,
    },
    {
        title: 'Posledních 12 měsíců',
        dateFrom: moment().subtract(1, 'year').startOf('day'),
        type: StatisticsIntervalTypeEnum.LastYearBeginning,
    },
    {
        title: 'Od začátku roku',
        dateFrom: moment().startOf('year'),
        type: StatisticsIntervalTypeEnum.ThisYearBeginning,
    },
    {
        title: 'Posledních 30 dní',
        dateFrom: moment().subtract(30, 'day').startOf('day'),
        type: StatisticsIntervalTypeEnum.LastThirtyDays,
    },
    {
        title: 'Posledních 7 dní',
        dateFrom: moment().subtract(7, 'day').startOf('day'),
        type: StatisticsIntervalTypeEnum.LastSevenDays,
    },
    {
        title: 'Dnes',
        dateFrom: moment().subtract(1, 'day').startOf('day'),
        dateTo: moment().startOf('day'),
        type: StatisticsIntervalTypeEnum.Today,
    },
];

export class StatisticStore {
    rootStore: RootStore;

    overviewStore: OverviewStore;

    profitStore: ProfitStore;

    ratiosStore: RatiosStore;

    type: StatisticsIntervalTypeEnum = StatisticsIntervalTypeEnum.AllTime;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.overviewStore = new OverviewStore(this);
        this.profitStore = new ProfitStore(this);
        this.ratiosStore = new RatiosStore(this);

        makeAutoObservable(this);
    }

    startLoading(): void {
        this.overviewStore.startLoading();
        this.profitStore.startLoading();
        this.ratiosStore.startLoading();
    }

    stopLoading(): void {
        this.overviewStore.stopLoading();
        this.profitStore.stopLoading();
        this.ratiosStore.stopLoading();
    }

    get loading() {
        // TODO: speed
        return this.overviewStore.loading || this.ratiosStore.loading || this.profitStore.loading;
        // return this.overviewStore.loading || this.ratiosStore.loading;
    }

    get dateFrom(): Moment | null {
        const interval = STATISTIC_INTERVALS.find((si) => si.type === this.type);
        if (!interval) {
            throw new Error(StatusMessageEnum.SelectedIntervalNotFound);
        }
        return interval.dateFrom;
    }

    get dateTo(): Moment | null | undefined {
        const interval = STATISTIC_INTERVALS.find((si) => si.type === this.type);
        if (!interval) {
            throw new Error(StatusMessageEnum.SelectedIntervalNotFound);
        }
        return interval.dateTo;
    }

    get chartDateFormat(): string {
        if (
            this.type === StatisticsIntervalTypeEnum.LastSevenDays ||
            this.type === StatisticsIntervalTypeEnum.LastThirtyDays
        ) {
            return process.env.NEXT_PUBLIC_DATE_FORMAT as string;
        }
        return process.env.NEXT_PUBLIC_DATE_YEAR_MONTH_FORMAT as string;
    }

    async fetchIndexData(): Promise<void> {
        await Promise.all([this.overviewStore.fetchOverview(), this.ratiosStore.fetchRatios()]);
    }

    init(): void {
        if (this.rootStore.authStore.userHasFreeTariff) {
            this.type = StatisticsIntervalTypeEnum.LastThirtyDays;
        }
    }

    async setType(type: StatisticsIntervalTypeEnum): Promise<void> {
        if (this.rootStore.authStore.userHasFreeTariff && type !== StatisticsIntervalTypeEnum.LastThirtyDays) {
            this.rootStore.premiumDialogStore.open();
        } else {
            this.type = type;
            await Promise.all([this.fetchIndexData(), this.profitStore.fetchProfitCharts()]);
        }
    }
}
