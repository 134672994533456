/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TradeOverviewDto
 */
export interface TradeOverviewDto {
    /**
     * 
     * @type {number}
     * @memberof TradeOverviewDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOverviewDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOverviewDto
     */
    fees: number;
    /**
     * 
     * @type {string}
     * @memberof TradeOverviewDto
     */
    currency: string;
}

export function TradeOverviewDtoFromJSON(json: any): TradeOverviewDto {
    return TradeOverviewDtoFromJSONTyped(json, false);
}

export function TradeOverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TradeOverviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'value': json['value'],
        'fees': json['fees'],
        'currency': json['currency'],
    };
}

export function TradeOverviewDtoToJSON(value?: TradeOverviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'value': value.value,
        'fees': value.fees,
        'currency': value.currency,
    };
}

