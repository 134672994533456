/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateTradeDto,
    CreateTradeDtoFromJSON,
    CreateTradeDtoToJSON,
    CreatedTradeDto,
    CreatedTradeDtoFromJSON,
    CreatedTradeDtoToJSON,
    OrderEnum,
    OrderEnumFromJSON,
    OrderEnumToJSON,
    TradeDto,
    TradeDtoFromJSON,
    TradeDtoToJSON,
    TradeEntity,
    TradeEntityFromJSON,
    TradeEntityToJSON,
    TradeListOverviewDto,
    TradeListOverviewDtoFromJSON,
    TradeListOverviewDtoToJSON,
    TradeListSortDto,
    TradeListSortDtoFromJSON,
    TradeListSortDtoToJSON,
    UpdatedTradeDto,
    UpdatedTradeDtoFromJSON,
    UpdatedTradeDtoToJSON,
} from '../models';

export interface TradeControllerCreateTradeRequest {
    createTradeDto: CreateTradeDto;
}

export interface TradeControllerDeleteTradeRequest {
    id: string;
}

export interface TradeControllerGetTradeRequest {
    id: string;
}

export interface TradeControllerListTradesRequest {
    limit: number;
    offset: number;
    sort?: TradeListSortDto;
    type?: string;
    symbolTypes?: Array<string>;
    dateFrom?: string;
    dateTo?: string;
    phrase?: string;
}

export interface TradeControllerListTradesBySymbolIdRequest {
    symbolId: string;
    type?: OrderEnum;
    symbolName?: OrderEnum;
    date?: OrderEnum;
    exchangeName?: OrderEnum;
    unitPrice?: OrderEnum;
    value?: OrderEnum;
    fees?: OrderEnum;
}

export interface TradeControllerUpdateTradeRequest {
    id: string;
    tradeDto: TradeDto;
}

/**
 * 
 */
export class TradesApi extends runtime.BaseAPI {

    /**
     * Create trade
     */
    async tradeControllerCreateTradeRaw(requestParameters: TradeControllerCreateTradeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreatedTradeDto>> {
        if (requestParameters.createTradeDto === null || requestParameters.createTradeDto === undefined) {
            throw new runtime.RequiredError('createTradeDto','Required parameter requestParameters.createTradeDto was null or undefined when calling tradeControllerCreateTrade.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trades`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTradeDtoToJSON(requestParameters.createTradeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatedTradeDtoFromJSON(jsonValue));
    }

    /**
     * Create trade
     */
    async tradeControllerCreateTrade(requestParameters: TradeControllerCreateTradeRequest, initOverrides?: RequestInit): Promise<CreatedTradeDto> {
        const response = await this.tradeControllerCreateTradeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove trade
     */
    async tradeControllerDeleteTradeRaw(requestParameters: TradeControllerDeleteTradeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tradeControllerDeleteTrade.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trades/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Remove trade
     */
    async tradeControllerDeleteTrade(requestParameters: TradeControllerDeleteTradeRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.tradeControllerDeleteTradeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get trade
     */
    async tradeControllerGetTradeRaw(requestParameters: TradeControllerGetTradeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TradeEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tradeControllerGetTrade.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trades/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeEntityFromJSON(jsonValue));
    }

    /**
     * Get trade
     */
    async tradeControllerGetTrade(requestParameters: TradeControllerGetTradeRequest, initOverrides?: RequestInit): Promise<TradeEntity> {
        const response = await this.tradeControllerGetTradeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get paginated trades
     */
    async tradeControllerListTradesRaw(requestParameters: TradeControllerListTradesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TradeListOverviewDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling tradeControllerListTrades.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling tradeControllerListTrades.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.symbolTypes) {
            queryParameters['symbolTypes'] = requestParameters.symbolTypes;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo;
        }

        if (requestParameters.phrase !== undefined) {
            queryParameters['phrase'] = requestParameters.phrase;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trades`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeListOverviewDtoFromJSON(jsonValue));
    }

    /**
     * Get paginated trades
     */
    async tradeControllerListTrades(requestParameters: TradeControllerListTradesRequest, initOverrides?: RequestInit): Promise<TradeListOverviewDto> {
        const response = await this.tradeControllerListTradesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get trades by symbolId
     */
    async tradeControllerListTradesBySymbolIdRaw(requestParameters: TradeControllerListTradesBySymbolIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TradeDto>>> {
        if (requestParameters.symbolId === null || requestParameters.symbolId === undefined) {
            throw new runtime.RequiredError('symbolId','Required parameter requestParameters.symbolId was null or undefined when calling tradeControllerListTradesBySymbolId.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.symbolName !== undefined) {
            queryParameters['symbolName'] = requestParameters.symbolName;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        if (requestParameters.exchangeName !== undefined) {
            queryParameters['exchangeName'] = requestParameters.exchangeName;
        }

        if (requestParameters.unitPrice !== undefined) {
            queryParameters['unitPrice'] = requestParameters.unitPrice;
        }

        if (requestParameters.value !== undefined) {
            queryParameters['value'] = requestParameters.value;
        }

        if (requestParameters.fees !== undefined) {
            queryParameters['fees'] = requestParameters.fees;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trades/by-symbol/{symbolId}`.replace(`{${"symbolId"}}`, encodeURIComponent(String(requestParameters.symbolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TradeDtoFromJSON));
    }

    /**
     * Get trades by symbolId
     */
    async tradeControllerListTradesBySymbolId(requestParameters: TradeControllerListTradesBySymbolIdRequest, initOverrides?: RequestInit): Promise<Array<TradeDto>> {
        const response = await this.tradeControllerListTradesBySymbolIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update trade
     */
    async tradeControllerUpdateTradeRaw(requestParameters: TradeControllerUpdateTradeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdatedTradeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tradeControllerUpdateTrade.');
        }

        if (requestParameters.tradeDto === null || requestParameters.tradeDto === undefined) {
            throw new runtime.RequiredError('tradeDto','Required parameter requestParameters.tradeDto was null or undefined when calling tradeControllerUpdateTrade.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trades/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TradeDtoToJSON(requestParameters.tradeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdatedTradeDtoFromJSON(jsonValue));
    }

    /**
     * Update trade
     */
    async tradeControllerUpdateTrade(requestParameters: TradeControllerUpdateTradeRequest, initOverrides?: RequestInit): Promise<UpdatedTradeDto> {
        const response = await this.tradeControllerUpdateTradeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
