/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortfolioIntegrationDto
 */
export interface PortfolioIntegrationDto {
    /**
     * 
     * @type {string}
     * @memberof PortfolioIntegrationDto
     */
    trading212Token: string | null;
    /**
     * 
     * @type {string}
     * @memberof PortfolioIntegrationDto
     */
    trading212Error: string | null;
}

export function PortfolioIntegrationDtoFromJSON(json: any): PortfolioIntegrationDto {
    return PortfolioIntegrationDtoFromJSONTyped(json, false);
}

export function PortfolioIntegrationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioIntegrationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trading212Token': json['trading212Token'],
        'trading212Error': json['trading212Error'],
    };
}

export function PortfolioIntegrationDtoToJSON(value?: PortfolioIntegrationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trading212Token': value.trading212Token,
        'trading212Error': value.trading212Error,
    };
}

