/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WaitingUserDto,
    WaitingUserDtoFromJSON,
    WaitingUserDtoFromJSONTyped,
    WaitingUserDtoToJSON,
} from './WaitingUserDto';

/**
 * 
 * @export
 * @interface PaginatedWaitingUserDto
 */
export interface PaginatedWaitingUserDto {
    /**
     * 
     * @type {number}
     * @memberof PaginatedWaitingUserDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedWaitingUserDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedWaitingUserDto
     */
    offset: number;
    /**
     * 
     * @type {Array<WaitingUserDto>}
     * @memberof PaginatedWaitingUserDto
     */
    items: Array<WaitingUserDto>;
}

export function PaginatedWaitingUserDtoFromJSON(json: any): PaginatedWaitingUserDto {
    return PaginatedWaitingUserDtoFromJSONTyped(json, false);
}

export function PaginatedWaitingUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedWaitingUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'limit': json['limit'],
        'offset': json['offset'],
        'items': ((json['items'] as Array<any>).map(WaitingUserDtoFromJSON)),
    };
}

export function PaginatedWaitingUserDtoToJSON(value?: PaginatedWaitingUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'limit': value.limit,
        'offset': value.offset,
        'items': ((value.items as Array<any>).map(WaitingUserDtoToJSON)),
    };
}

