export enum StatisticsIntervalTypeEnum {
    AllTime = 'allTime',
    LastYearBeginning = 'lastYearBeginning',
    ThisYearBeginning = 'thisYearBeginning',
    ThisMonthBeginning = 'thisMonthBeginning',
    LastThirtyDays = 'lastThirtyDays',
    LastSevenDays = 'lastSevenDays',
    LastQuarter = 'lastQuarter',
    Today = 'today',
}
