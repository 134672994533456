import { makeAutoObservable } from 'mobx';
import { DividendCalendarMonthMobxDto } from './dividend-calendar-month-mobx-dto';
import { DividendCalendarDto } from '../../../../defs/api';

export class DividendCalendarMobxDto implements DividendCalendarDto {
    public year: string = '';

    public months: DividendCalendarMonthMobxDto[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: DividendCalendarDto): DividendCalendarMobxDto {
        const mobxDto = new DividendCalendarMobxDto();
        return Object.assign(mobxDto, dto, {
            months: DividendCalendarMonthMobxDto.createFromArray(dto.months),
        });
    }

    static createFromArray(dtos: DividendCalendarDto[]): DividendCalendarMobxDto[] {
        return dtos.map((dto) => DividendCalendarMobxDto.create(dto));
    }

    get totalAmount(): number {
        return this.months.reduce((p1, p2) => p1 + p2.amount, 0);
    }
}
