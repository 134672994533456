import { makeAutoObservable } from 'mobx';
import { ExchangeEntity, SymbolDto, SymbolEntity, SymbolTypeEnum } from '../../../../defs/api';
import { SymbolWithExchangeMobxDto } from './symbol-with-exchange-mobx-dto';

export class SymbolMobxDto implements SymbolDto {
    id: string = '';

    symbol: string = '';

    isin?: string = '';

    name: string = '';

    exchange: ExchangeEntity;

    currency: string = '';

    type: SymbolTypeEnum = SymbolTypeEnum.Cs;

    close: number = 0;

    exchangeId: string = '';

    exchangeName: string = '';

    marketCapitalization: number = 0;

    figi: string | null = null;

    iexId: string | null = null;

    constructor() {
        makeAutoObservable(this, {});
    }

    static create(dto: SymbolDto | SymbolEntity): SymbolMobxDto {
        const mobxDto = new SymbolMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: SymbolDto[] | SymbolEntity[]): SymbolMobxDto[] {
        return dtos.map((dto) => SymbolMobxDto.create(dto));
    }

    get marketCapitalizationBn() {
        if (this.marketCapitalization) {
            return this.marketCapitalization / 1000;
        }
        return null;
    }

    static createFromSymbolWithExchangeDto(symbol: SymbolWithExchangeMobxDto): SymbolMobxDto {
        const symbolDto = new SymbolMobxDto();
        Object.assign(symbolDto, symbol);
        symbolDto.exchangeId = symbol.exchange.id;
        symbolDto.exchangeName = symbol.exchange.name;
        return symbolDto;
    }
}
