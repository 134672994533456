/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolWithExchangeDto,
    SymbolWithExchangeDtoFromJSON,
    SymbolWithExchangeDtoFromJSONTyped,
    SymbolWithExchangeDtoToJSON,
} from './SymbolWithExchangeDto';

/**
 * 
 * @export
 * @interface CreateSymbolAlternativeDto
 */
export interface CreateSymbolAlternativeDto {
    /**
     * 
     * @type {SymbolWithExchangeDto}
     * @memberof CreateSymbolAlternativeDto
     */
    symbol: SymbolWithExchangeDto;
    /**
     * 
     * @type {string}
     * @memberof CreateSymbolAlternativeDto
     */
    alternativeSymbol: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSymbolAlternativeDto
     */
    alternativeName: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateSymbolAlternativeDto
     */
    validFrom: Date;
}

export function CreateSymbolAlternativeDtoFromJSON(json: any): CreateSymbolAlternativeDto {
    return CreateSymbolAlternativeDtoFromJSONTyped(json, false);
}

export function CreateSymbolAlternativeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSymbolAlternativeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'symbol': SymbolWithExchangeDtoFromJSON(json['symbol']),
        'alternativeSymbol': json['alternativeSymbol'],
        'alternativeName': json['alternativeName'],
        'validFrom': (new Date(json['validFrom'])),
    };
}

export function CreateSymbolAlternativeDtoToJSON(value?: CreateSymbolAlternativeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'symbol': SymbolWithExchangeDtoToJSON(value.symbol),
        'alternativeSymbol': value.alternativeSymbol,
        'alternativeName': value.alternativeName,
        'validFrom': (value.validFrom.toISOString()),
    };
}

