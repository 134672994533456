/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InvestmentBySymbolDto,
    InvestmentBySymbolDtoFromJSON,
    InvestmentBySymbolDtoToJSON,
    InvestmentsDto,
    InvestmentsDtoFromJSON,
    InvestmentsDtoToJSON,
} from '../models';

export interface InvestmentControllerGetInvestmentBySymbolIdRequest {
    exchangeId: string;
    symbolCode: string;
}

/**
 * 
 */
export class InvestmentsApi extends runtime.BaseAPI {

    /**
     * Get investment data for specific symbol
     */
    async investmentControllerGetInvestmentBySymbolIdRaw(requestParameters: InvestmentControllerGetInvestmentBySymbolIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InvestmentBySymbolDto>> {
        if (requestParameters.exchangeId === null || requestParameters.exchangeId === undefined) {
            throw new runtime.RequiredError('exchangeId','Required parameter requestParameters.exchangeId was null or undefined when calling investmentControllerGetInvestmentBySymbolId.');
        }

        if (requestParameters.symbolCode === null || requestParameters.symbolCode === undefined) {
            throw new runtime.RequiredError('symbolCode','Required parameter requestParameters.symbolCode was null or undefined when calling investmentControllerGetInvestmentBySymbolId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments/by-symbol/{exchangeId}/{symbolCode}`.replace(`{${"exchangeId"}}`, encodeURIComponent(String(requestParameters.exchangeId))).replace(`{${"symbolCode"}}`, encodeURIComponent(String(requestParameters.symbolCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestmentBySymbolDtoFromJSON(jsonValue));
    }

    /**
     * Get investment data for specific symbol
     */
    async investmentControllerGetInvestmentBySymbolId(requestParameters: InvestmentControllerGetInvestmentBySymbolIdRequest, initOverrides?: RequestInit): Promise<InvestmentBySymbolDto> {
        const response = await this.investmentControllerGetInvestmentBySymbolIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get absolute investment by months
     */
    async investmentControllerGetInvestmentsByMonthsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InvestmentsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/investments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvestmentsDtoFromJSON(jsonValue));
    }

    /**
     * Get absolute investment by months
     */
    async investmentControllerGetInvestmentsByMonths(initOverrides?: RequestInit): Promise<InvestmentsDto> {
        const response = await this.investmentControllerGetInvestmentsByMonthsRaw(initOverrides);
        return await response.value();
    }

}
