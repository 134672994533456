/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolFundamentalDataCashFlowDto,
    SymbolFundamentalDataCashFlowDtoFromJSON,
    SymbolFundamentalDataCashFlowDtoFromJSONTyped,
    SymbolFundamentalDataCashFlowDtoToJSON,
} from './SymbolFundamentalDataCashFlowDto';
import {
    SymbolFundamentalDataGeneralDto,
    SymbolFundamentalDataGeneralDtoFromJSON,
    SymbolFundamentalDataGeneralDtoFromJSONTyped,
    SymbolFundamentalDataGeneralDtoToJSON,
} from './SymbolFundamentalDataGeneralDto';
import {
    SymbolFundamentalDataHighlightsDto,
    SymbolFundamentalDataHighlightsDtoFromJSON,
    SymbolFundamentalDataHighlightsDtoFromJSONTyped,
    SymbolFundamentalDataHighlightsDtoToJSON,
} from './SymbolFundamentalDataHighlightsDto';
import {
    SymbolFundamentalDataTechnicalsDto,
    SymbolFundamentalDataTechnicalsDtoFromJSON,
    SymbolFundamentalDataTechnicalsDtoFromJSONTyped,
    SymbolFundamentalDataTechnicalsDtoToJSON,
} from './SymbolFundamentalDataTechnicalsDto';

/**
 * 
 * @export
 * @interface SymbolFundamentalDataDto
 */
export interface SymbolFundamentalDataDto {
    /**
     * 
     * @type {SymbolFundamentalDataGeneralDto}
     * @memberof SymbolFundamentalDataDto
     */
    general: SymbolFundamentalDataGeneralDto | null;
    /**
     * 
     * @type {SymbolFundamentalDataHighlightsDto}
     * @memberof SymbolFundamentalDataDto
     */
    highlights: SymbolFundamentalDataHighlightsDto | null;
    /**
     * 
     * @type {SymbolFundamentalDataTechnicalsDto}
     * @memberof SymbolFundamentalDataDto
     */
    technicals: SymbolFundamentalDataTechnicalsDto | null;
    /**
     * 
     * @type {Array<SymbolFundamentalDataCashFlowDto>}
     * @memberof SymbolFundamentalDataDto
     */
    cashFlow: Array<SymbolFundamentalDataCashFlowDto> | null;
}

export function SymbolFundamentalDataDtoFromJSON(json: any): SymbolFundamentalDataDto {
    return SymbolFundamentalDataDtoFromJSONTyped(json, false);
}

export function SymbolFundamentalDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolFundamentalDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'general': SymbolFundamentalDataGeneralDtoFromJSON(json['general']),
        'highlights': SymbolFundamentalDataHighlightsDtoFromJSON(json['highlights']),
        'technicals': SymbolFundamentalDataTechnicalsDtoFromJSON(json['technicals']),
        'cashFlow': (json['cashFlow'] === null ? null : (json['cashFlow'] as Array<any>).map(SymbolFundamentalDataCashFlowDtoFromJSON)),
    };
}

export function SymbolFundamentalDataDtoToJSON(value?: SymbolFundamentalDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'general': SymbolFundamentalDataGeneralDtoToJSON(value.general),
        'highlights': SymbolFundamentalDataHighlightsDtoToJSON(value.highlights),
        'technicals': SymbolFundamentalDataTechnicalsDtoToJSON(value.technicals),
        'cashFlow': (value.cashFlow === null ? null : (value.cashFlow as Array<any>).map(SymbolFundamentalDataCashFlowDtoToJSON)),
    };
}

