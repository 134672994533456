import { makeAutoObservable } from 'mobx';
import { ProfitYearDto } from '../../../../defs/api';
import { ProfitMonthMobxDto } from './profit-month-mobx-dto';

export class ProfitYearMobxDto implements ProfitYearDto {
    year: number = 0;

    months: ProfitMonthMobxDto[] = [];

    total: ProfitMonthMobxDto = new ProfitMonthMobxDto();

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: ProfitYearDto): ProfitYearMobxDto {
        const mobxDto = new ProfitYearMobxDto();
        return Object.assign(mobxDto, dto, {
            months: ProfitMonthMobxDto.createFromArray(dto.months),
            total: ProfitMonthMobxDto.create(dto.total),
        });
    }

    static createFromArray(dtos: ProfitYearDto[]): ProfitYearMobxDto[] {
        return dtos.map((dto) => ProfitYearMobxDto.create(dto));
    }
}
