import { makeAutoObservable, runInAction } from 'mobx';
import { ErrorStatusEnum } from '@app/common';
import type { RootStore } from './root-store';

export class MailStore {
    rootStore: RootStore;

    loading = false;

    currentTemplate: string | null = null;

    currentTemplateContent: string | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async fetchTemplateContent(
        templateName: string,
        variables: { [key: string]: string | number | object | boolean },
    ): Promise<void> {
        this.startLoading();
        try {
            const { templateContent } = await this.rootStore.apiClient.mailController.mailControllerGetTemplateContent({
                templateName,
                variables: JSON.stringify(variables),
            });

            runInAction(() => {
                this.currentTemplate = templateName;
                this.currentTemplateContent = templateContent;
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async synchronizeUsersToEcomail(): Promise<void> {
        this.startLoading();
        this.rootStore.alertStore.setWarningMessage('Users are synchronizing..');
        try {
            const response = await this.rootStore.apiClient.mailController.mailControllerSynchronizeUsers();
            if (!response) {
                throw new Error(ErrorStatusEnum.SynchronizationFailed);
            }
            this.rootStore.alertStore.clearAllMessages();
            this.rootStore.alertStore.setSuccessMessage('Users are synchronized successfully');
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.clearAllMessages();
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
