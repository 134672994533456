import { makeAutoObservable } from 'mobx';
import { NotConfirmedUserDto } from '../../../../../defs/api';

export class NotConfirmedUserMobxDto implements NotConfirmedUserDto {
    public id: string = '';

    public email: string = '';

    public fullName: string = '';

    public registrationDate: Date = new Date();

    constructor() {
        makeAutoObservable(this, {});
    }

    static create(dto: NotConfirmedUserDto): NotConfirmedUserMobxDto {
        const mobxDto = new NotConfirmedUserMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: NotConfirmedUserDto[]): NotConfirmedUserMobxDto[] {
        return dtos.map((dto) => NotConfirmedUserMobxDto.create(dto));
    }
}
