import * as React from 'react';
import { Icon } from '../../icon/icon';
import { Spinner } from './spinner.styled';

export const IconSpinner: React.FC = () => (
    <Spinner>
        <Icon viewBox="0 0 50 50" fill="none">
            <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
        </Icon>
    </Spinner>
);
