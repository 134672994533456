import { makeAutoObservable } from 'mobx';
import { RealizedTransactionsSummaryMobxDto } from './realized-transaction-summary-mobx-dto';
import { RealizedReportTradeMobxDto } from './realized-report-trade-mobx-dto';
import { UnrealizedReportTradeMobxDto } from './unrealized-report-trade-mobx-dto';
import { RealizedDividendsSummaryMobxDto } from './realized-divideds-summary-mobx-dto';
import { TaxReportDto } from '../../../../../defs/api';
import { DividendMobxDto } from '../../dividend/dividend-mobx-dto';

export class TaxReportMobxDto implements TaxReportDto {
    public year: string = '';

    public realizedReportTrades: RealizedReportTradeMobxDto[] = [];

    public realizedTransactionsSummary?: RealizedTransactionsSummaryMobxDto = undefined;

    public unrealizedReportTrades: UnrealizedReportTradeMobxDto[] = [];

    public dividends: DividendMobxDto[] = [];

    public dividendsSummary?: RealizedDividendsSummaryMobxDto = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: TaxReportDto): TaxReportMobxDto {
        const mobxDto = new TaxReportMobxDto();

        return Object.assign(mobxDto, dto, {
            realizedReportTrades: RealizedReportTradeMobxDto.createFromArray(dto.realizedReportTrades),
            realizedTransactionsSummary: dto.realizedTransactionsSummary
                ? RealizedTransactionsSummaryMobxDto.create(dto.realizedTransactionsSummary)
                : undefined,
            unrealizedReportTrades: UnrealizedReportTradeMobxDto.createFromArray(dto.unrealizedReportTrades),
            dividends: DividendMobxDto.createFromArray(dto.dividends),
            dividendsSummary: dto.dividendsSummary
                ? RealizedDividendsSummaryMobxDto.create(dto.dividendsSummary)
                : undefined,
        });
    }

    static createFromArray(dtos: TaxReportDto[]): TaxReportMobxDto[] {
        return dtos.map((dto) => TaxReportMobxDto.create(dto));
    }
}
