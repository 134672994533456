/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    YearDto,
    YearDtoFromJSON,
    YearDtoFromJSONTyped,
    YearDtoToJSON,
} from './YearDto';

/**
 * 
 * @export
 * @interface InvestmentsDto
 */
export interface InvestmentsDto {
    /**
     * 
     * @type {Array<YearDto>}
     * @memberof InvestmentsDto
     */
    years: Array<YearDto>;
    /**
     * 
     * @type {number}
     * @memberof InvestmentsDto
     */
    numberOfMonths: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentsDto
     */
    totalAmountWithoutSell: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentsDto
     */
    totalAmountWithSell: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentsDto
     */
    averageAmountWithoutSell: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentsDto
     */
    averageAmountWithSell: number;
}

export function InvestmentsDtoFromJSON(json: any): InvestmentsDto {
    return InvestmentsDtoFromJSONTyped(json, false);
}

export function InvestmentsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestmentsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'years': ((json['years'] as Array<any>).map(YearDtoFromJSON)),
        'numberOfMonths': json['numberOfMonths'],
        'totalAmountWithoutSell': json['totalAmountWithoutSell'],
        'totalAmountWithSell': json['totalAmountWithSell'],
        'averageAmountWithoutSell': json['averageAmountWithoutSell'],
        'averageAmountWithSell': json['averageAmountWithSell'],
    };
}

export function InvestmentsDtoToJSON(value?: InvestmentsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'years': ((value.years as Array<any>).map(YearDtoToJSON)),
        'numberOfMonths': value.numberOfMonths,
        'totalAmountWithoutSell': value.totalAmountWithoutSell,
        'totalAmountWithSell': value.totalAmountWithSell,
        'averageAmountWithoutSell': value.averageAmountWithoutSell,
        'averageAmountWithSell': value.averageAmountWithSell,
    };
}

