import { makeAutoObservable } from 'mobx';
import { SymbolDetailRegionMobxDto } from './symbol-detail-region-mobx-dto';
import { SymbolFundamentalDataGeneralDto } from '../../../../../defs/api';
import { SymbolWithExchangeMobxDto } from '../symbol-with-exchange-mobx-dto';

export class SymbolDetailGeneralMobxDto implements SymbolFundamentalDataGeneralDto {
    public id: string = '';

    public type: string = '';

    public description: string = '';

    public sector: string = '';

    public industry: string = '';

    public symbol: SymbolWithExchangeMobxDto = new SymbolWithExchangeMobxDto();

    public regions: SymbolDetailRegionMobxDto[] | null = null;

    public sectors: SymbolDetailRegionMobxDto[] | null = null;

    public isDelisted: boolean = false;

    public delistedDate?: Date;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolFundamentalDataGeneralDto): SymbolDetailGeneralMobxDto {
        const mobxDto = new SymbolDetailGeneralMobxDto();
        return Object.assign(mobxDto, dto, {
            symbol: SymbolWithExchangeMobxDto.create(dto.symbol),
            regions: dto.regions ? SymbolDetailRegionMobxDto.createFromArray(dto.regions) : null,
            sectors: dto.sectors ? SymbolDetailRegionMobxDto.createFromArray(dto.sectors) : null,
        });
    }

    static createFromArray(dtos: SymbolFundamentalDataGeneralDto[]): SymbolDetailGeneralMobxDto[] {
        return dtos.map((dto) => SymbolDetailGeneralMobxDto.create(dto));
    }
}
