import * as React from 'react';
import { Wrapper, Type, Description } from './page-error.styled';
import { Button } from '../../../components/app/form/button/button';

interface PageErrorPropsInterface {
    type: number | string;
    description?: string;
    buttonText?: string;
    buttonUrl?: string;
}

export const PageError: React.FC<PageErrorPropsInterface> = ({ type, description, buttonText, buttonUrl }) => {
    return (
        <Wrapper>
            <Type>{type}</Type>
            {description && <Description>{description}</Description>}
            {buttonText && buttonUrl && (
                <Button size="vlarge" shadow variant="secondary" href={buttonUrl}>
                    {buttonText}
                </Button>
            )}
        </Wrapper>
    );
};
