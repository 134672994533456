import Link from 'next/link';
import React from 'react';
import { useTheme } from 'styled-components';
import { Wrapper } from './logo.styled';

interface LogoPropsInterface {
    href?: string;
    white?: boolean;
    inApp?: boolean;
}

export const Logo: React.FC<LogoPropsInterface> = ({ white = false, inApp = false, href }) => {
    const theme = useTheme();
    const uniqueId = `${Date.now()}-${Math.random().toString(36)}`;

    const SvgLogoWhite = () => (
        <svg viewBox="0 0 344 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.6346 21.4423V12.375L29.9515 2.85669L30.2404 2.94229L36.6442 11.125L38.4231 21.4423H26.3269V20.375C26.3269 18.9996 25.212 17.8846 23.8366 17.8846C22.4612 17.8846 21.3462 18.9996 21.3462 20.375V21.4423H20.6346Z"
                fill={theme.palette.color.white.main}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46.9615 21.4423V12.375L56.2784 2.85669L56.5673 2.94229L64.3942 4.00964L64.75 21.4423H52.6539V20.375C52.6539 18.9996 51.5389 17.8846 50.1635 17.8846C48.7881 17.8846 47.6731 18.9996 47.6731 20.375V21.4423H46.9615Z"
                fill={theme.palette.color.white.main}
            />
            <rect
                x="26.3269"
                y="0.0961609"
                width="21.3462"
                height="49.8077"
                rx="10.6731"
                fill={theme.palette.color.white.main}
            />
            <rect
                x="52.6539"
                y="0.0961609"
                width="21.3462"
                height="49.8077"
                rx="10.6731"
                fill={theme.palette.color.white.main}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.03964 29.3287C1.45015 31.9742 0 35.5288 0 39.2307C0 45.1253 4.7785 49.9038 10.6731 49.9038C16.5677 49.9038 21.3462 45.1253 21.3462 39.2307V11.648L4.03964 29.3287Z"
                fill={theme.palette.color.white.main}
            />
            <path
                d="M91 43.5093H101.747V22.9173C101.747 18.9653 104.104 15.984 107.64 15.984C111.315 15.984 113.325 18.6187 113.325 22.3627V43.5093H124.072V22.9867C124.072 18.896 126.429 15.984 129.965 15.984C133.709 15.984 135.512 18.6187 135.512 22.64V43.5093H146.259V18.896C146.259 11.2693 140.92 6 132.947 6C128.371 6 124.904 7.59467 122.339 10.992H121.853C119.635 7.872 116.029 6 111.245 6C106.392 6 103.203 8.42667 101.677 10.8533H101.123V6.90133H91V43.5093Z"
                fill={theme.palette.color.white.main}
            />
            <path
                d="M170.464 44.4107C182.25 44.4107 190.224 36.0213 190.224 25.2053C190.224 14.3893 182.25 6 170.533 6C158.677 6 150.773 14.3893 150.773 25.2053C150.773 36.0213 158.746 44.4107 170.464 44.4107ZM170.464 34.704C165.402 34.704 161.658 30.8213 161.658 25.2053C161.658 19.6587 165.402 15.7067 170.464 15.7067C175.594 15.7067 179.338 19.6587 179.338 25.2053C179.338 30.752 175.594 34.704 170.464 34.704Z"
                fill={theme.palette.color.white.main}
            />
            <path
                d="M195.883 43.5093H206.63V22.9867C206.63 19.104 209.126 15.984 212.87 15.984C216.544 15.984 218.763 18.688 218.763 22.432V43.5093H229.51V19.104C229.51 11.4773 224.518 6 216.406 6C211.483 6 208.016 8.42667 206.56 10.9227H206.006V6.90133H195.883V43.5093Z"
                fill={theme.palette.color.white.main}
            />
            <path
                d="M252.571 44.4107C262.485 44.4107 269.141 39.488 270.805 31.792H261.099C260.059 34.08 257.216 36.16 252.987 36.16C247.925 36.16 244.528 32.832 244.181 28.1867H271.152V25.0667C271.152 14.8747 264.843 6 252.779 6C241.616 6 233.989 13.9733 233.989 25.4133C233.989 36.4373 241.963 44.4107 252.571 44.4107ZM244.389 21.0453C244.875 17.44 248.203 14.1813 252.779 14.1813C257.563 14.1813 260.752 17.1627 261.168 21.0453H244.389Z"
                fill={theme.palette.color.white.main}
            />
            <path
                d="M276.865 43.5093H287.611V26.8693C287.611 20.56 291.009 16.7467 296.278 16.7467H300.507V6.624H296.971C292.049 6.624 288.79 9.744 287.542 12.448H286.987V6.90133H276.865V43.5093Z"
                fill={theme.palette.color.white.main}
            />
            <path
                d="M324.07 58C324.07 58 339.654 16.106 343.067 6.90133H331.697L323.762 29.5238L315.473 6.90133H304.241L318.19 43.5093C317.077 46.6023 312.619 58 312.619 58H324.07Z"
                fill={theme.palette.color.white.main}
            />
        </svg>
    );

    const SvgLogo = () => (
        <svg viewBox="0 0 344 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.6346 21.4423V12.375L29.9515 2.85669L30.2404 2.94229L36.6442 11.125L38.4231 21.4423H26.3269V20.375C26.3269 18.9996 25.212 17.8846 23.8366 17.8846C22.4612 17.8846 21.3462 18.9996 21.3462 20.375V21.4423H20.6346Z"
                fill={`url(#gradient-1-${uniqueId})`}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46.9615 21.4423V12.375L56.2784 2.85669L56.5673 2.94229L64.3942 4.00964L64.75 21.4423H52.6539V20.375C52.6539 18.9996 51.5389 17.8846 50.1635 17.8846C48.7881 17.8846 47.6731 18.9996 47.6731 20.375V21.4423H46.9615Z"
                fill={`url(#gradient-2-${uniqueId})`}
            />
            <rect
                x="26.3269"
                y="0.0961609"
                width="21.3462"
                height="49.8077"
                rx="10.6731"
                fill={`url(#gradient-3-${uniqueId})`}
            />
            <rect
                x="52.6539"
                y="0.0961609"
                width="21.3462"
                height="49.8077"
                rx="10.6731"
                fill={`url(#gradient-4-${uniqueId})`}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.03964 29.3287C1.45015 31.9742 0 35.5288 0 39.2307C0 45.1253 4.7785 49.9038 10.6731 49.9038C16.5677 49.9038 21.3462 45.1253 21.3462 39.2307V11.648L4.03964 29.3287Z"
                fill={`url(#gradient-5-${uniqueId})`}
            />
            <path
                d="M91 43.5093H101.747V22.9173C101.747 18.9653 104.104 15.984 107.64 15.984C111.315 15.984 113.325 18.6187 113.325 22.3627V43.5093H124.072V22.9867C124.072 18.896 126.429 15.984 129.965 15.984C133.709 15.984 135.512 18.6187 135.512 22.64V43.5093H146.259V18.896C146.259 11.2693 140.92 6 132.947 6C128.371 6 124.904 7.59467 122.339 10.992H121.853C119.635 7.872 116.029 6 111.245 6C106.392 6 103.203 8.42667 101.677 10.8533H101.123V6.90133H91V43.5093Z"
                fill={theme.palette.common.text}
            />
            <path
                d="M170.464 44.4107C182.25 44.4107 190.224 36.0213 190.224 25.2053C190.224 14.3893 182.25 6 170.533 6C158.677 6 150.773 14.3893 150.773 25.2053C150.773 36.0213 158.746 44.4107 170.464 44.4107ZM170.464 34.704C165.402 34.704 161.658 30.8213 161.658 25.2053C161.658 19.6587 165.402 15.7067 170.464 15.7067C175.594 15.7067 179.338 19.6587 179.338 25.2053C179.338 30.752 175.594 34.704 170.464 34.704Z"
                fill={theme.palette.common.text}
            />
            <path
                d="M195.883 43.5093H206.63V22.9867C206.63 19.104 209.126 15.984 212.87 15.984C216.544 15.984 218.763 18.688 218.763 22.432V43.5093H229.51V19.104C229.51 11.4773 224.518 6 216.406 6C211.483 6 208.016 8.42667 206.56 10.9227H206.006V6.90133H195.883V43.5093Z"
                fill={theme.palette.common.text}
            />
            <path
                d="M252.571 44.4107C262.485 44.4107 269.141 39.488 270.805 31.792H261.099C260.059 34.08 257.216 36.16 252.987 36.16C247.925 36.16 244.528 32.832 244.181 28.1867H271.152V25.0667C271.152 14.8747 264.843 6 252.779 6C241.616 6 233.989 13.9733 233.989 25.4133C233.989 36.4373 241.963 44.4107 252.571 44.4107ZM244.389 21.0453C244.875 17.44 248.203 14.1813 252.779 14.1813C257.563 14.1813 260.752 17.1627 261.168 21.0453H244.389Z"
                fill={theme.palette.common.text}
            />
            <path
                d="M276.865 43.5093H287.611V26.8693C287.611 20.56 291.009 16.7467 296.278 16.7467H300.507V6.624H296.971C292.049 6.624 288.79 9.744 287.542 12.448H286.987V6.90133H276.865V43.5093Z"
                fill={theme.palette.common.text}
            />
            <path
                d="M324.07 58C324.07 58 339.654 16.106 343.067 6.90133H331.697L323.762 29.5238L315.473 6.90133H304.241L318.19 43.5093C317.077 46.6023 312.619 58 312.619 58H324.07Z"
                fill={theme.palette.common.text}
            />
            <defs>
                <filter
                    x="0"
                    y="11.648"
                    width="21.3462"
                    height="38.8417"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.585973" />
                    <feGaussianBlur stdDeviation="0.292987" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_608_6007" />
                </filter>
                <linearGradient
                    id={`gradient-1-${uniqueId}`}
                    x1="18.8558"
                    y1="16.1058"
                    x2="29.8846"
                    y2="12.1923"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0188FB" />
                    <stop offset="1" stopColor="#0660AD" />
                </linearGradient>
                <linearGradient
                    id={`gradient-2-${uniqueId}`}
                    x1="45.1827"
                    y1="16.1058"
                    x2="56.2115"
                    y2="12.1923"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0188FB" />
                    <stop offset="1" stopColor="#0660AD" />
                </linearGradient>
                <linearGradient
                    id={`gradient-3-${uniqueId}`}
                    x1="26.9091"
                    y1="-41.5907"
                    x2="69.5504"
                    y2="-8.55671"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7FE8FF" />
                    <stop offset="1" stopColor="#0087FA" />
                </linearGradient>
                <linearGradient
                    id={`gradient-4-${uniqueId}`}
                    x1="53.236"
                    y1="-41.5907"
                    x2="95.8773"
                    y2="-8.55671"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7B7DFD" />
                    <stop offset="1" stopColor="#0087FA" />
                </linearGradient>
                <linearGradient
                    id={`gradient-5-${uniqueId}`}
                    x1="9.17218"
                    y1="11.648"
                    x2="36.6711"
                    y2="59.3817"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7FE8FF" />
                    <stop offset="1" stopColor="#00CDFA" />
                </linearGradient>
            </defs>
        </svg>
    );

    if (href) {
        return (
            <Link href={href}>
                <Wrapper isLink inApp={inApp}>
                    {white ? <SvgLogoWhite /> : <SvgLogo />}
                </Wrapper>
            </Link>
        );
    }

    return <Wrapper inApp={inApp}>{white ? <SvgLogoWhite /> : <SvgLogo />}</Wrapper>;
};
