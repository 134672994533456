import { makeAutoObservable } from 'mobx';
import { SymbolDividendCalendarDto, SymbolTypeEnum } from '../../../../../defs/api';
import { DividendCalendarMonthMobxDto } from '../../dividend/dividend-calendar-month-mobx-dto';

export class SymbolDividendCalendarMobxDto implements SymbolDividendCalendarDto {
    public symbol: string = '';

    public symbolId: string = '';

    public symbolName: string = '';

    public symbolType: SymbolTypeEnum = SymbolTypeEnum.Cs;

    public symbolCurrency?: string = '';

    public exchangeId: string;

    public exchangeName: string;

    public months: DividendCalendarMonthMobxDto[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolDividendCalendarDto): SymbolDividendCalendarMobxDto {
        const mobxDto = new SymbolDividendCalendarMobxDto();
        return Object.assign(mobxDto, dto, { months: DividendCalendarMonthMobxDto.createFromArray(dto.months) });
    }

    static createFromArray(dtos: SymbolDividendCalendarDto[]): SymbolDividendCalendarMobxDto[] {
        return dtos.map((dto) => SymbolDividendCalendarMobxDto.create(dto));
    }

    get totalAmount(): number {
        return this.months.reduce((p1, p2) => p1 + p2.amount, 0);
    }
}
