import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { color } from '../colors';
import { getOpacity } from '../utils/get-rgb';

export const createPagination = (t: ThemeEnum) => {
    const avatar: DefaultTheme['pagination'] = {
        page: {
            base: {
                background: 'transparent',
                color: color[t].base.textMuted,
            },
            active: {
                background: color[t].base.text,
                color: color[t].base.light,
            },
            hover: {
                background: getOpacity(color[t].base.text),
                color: color[t].base.text,
            },
        },
        arrow: {
            base: {
                background: getOpacity(color[t].base.text),
                color: color[t].base.text,
            },
            disabled: {
                background: getOpacity(color[t].base.text, 0.04),
                color: getOpacity(color[t].base.text, 0.34),
            },
            hover: {
                background: color[t].base.text,
                color: color[t].base.light,
            },
        },
    };
    return avatar;
};
