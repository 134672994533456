import { makeAutoObservable } from 'mobx';
import { DividendCalendarMonthDto } from '../../../../defs/api';

export class DividendCalendarMonthMobxDto implements DividendCalendarMonthDto {
    public month: string = '';

    public amount: number = 0;

    public isFutureDividend: boolean = false;

    constructor() {
        makeAutoObservable(this, {});
    }

    static create(dto: DividendCalendarMonthDto): DividendCalendarMonthMobxDto {
        const mobxDto = new DividendCalendarMonthMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: DividendCalendarMonthDto[]): DividendCalendarMonthMobxDto[] {
        return dtos.map((dto) => DividendCalendarMonthMobxDto.create(dto));
    }
}
