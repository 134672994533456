import { makeAutoObservable } from 'mobx';
import { TradeOverviewDto } from '../../../../defs/api';

export class TradeOverviewMobxDto implements TradeOverviewDto {
    public amount: number = 0;

    public value: number = 0;

    public fees: number = 0;

    public currency: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: TradeOverviewDto): TradeOverviewMobxDto {
        const mobxDto = new TradeOverviewMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: TradeOverviewDto[]): TradeOverviewMobxDto[] {
        return dtos.map((dto) => TradeOverviewMobxDto.create(dto));
    }
}
