/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StornoReasonEnum,
    StornoReasonEnumFromJSON,
    StornoReasonEnumFromJSONTyped,
    StornoReasonEnumToJSON,
} from './StornoReasonEnum';

/**
 * 
 * @export
 * @interface UpdateStornoReasonDto
 */
export interface UpdateStornoReasonDto {
    /**
     * 
     * @type {StornoReasonEnum}
     * @memberof UpdateStornoReasonDto
     */
    stornoReason?: StornoReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateStornoReasonDto
     */
    stornoReasonDescription: string;
}

export function UpdateStornoReasonDtoFromJSON(json: any): UpdateStornoReasonDto {
    return UpdateStornoReasonDtoFromJSONTyped(json, false);
}

export function UpdateStornoReasonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateStornoReasonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stornoReason': !exists(json, 'stornoReason') ? undefined : StornoReasonEnumFromJSON(json['stornoReason']),
        'stornoReasonDescription': json['stornoReasonDescription'],
    };
}

export function UpdateStornoReasonDtoToJSON(value?: UpdateStornoReasonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stornoReason': StornoReasonEnumToJSON(value.stornoReason),
        'stornoReasonDescription': value.stornoReasonDescription,
    };
}

