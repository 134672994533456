/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSymbolGrowthRateDto
 */
export interface UpdateSymbolGrowthRateDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSymbolGrowthRateDto
     */
    symbolId: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSymbolGrowthRateDto
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSymbolGrowthRateDto
     */
    expectedProfitGrowthRate: number;
}

export function UpdateSymbolGrowthRateDtoFromJSON(json: any): UpdateSymbolGrowthRateDto {
    return UpdateSymbolGrowthRateDtoFromJSONTyped(json, false);
}

export function UpdateSymbolGrowthRateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSymbolGrowthRateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'symbolId': json['symbolId'],
        'year': json['year'],
        'expectedProfitGrowthRate': json['expectedProfitGrowthRate'],
    };
}

export function UpdateSymbolGrowthRateDtoToJSON(value?: UpdateSymbolGrowthRateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'symbolId': value.symbolId,
        'year': value.year,
        'expectedProfitGrowthRate': value.expectedProfitGrowthRate,
    };
}

