/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PieChartDto,
    PieChartDtoFromJSON,
    PieChartDtoFromJSONTyped,
    PieChartDtoToJSON,
} from './PieChartDto';
import {
    RealizedAllocationDto,
    RealizedAllocationDtoFromJSON,
    RealizedAllocationDtoFromJSONTyped,
    RealizedAllocationDtoToJSON,
} from './RealizedAllocationDto';
import {
    UnrealizedAllocationDto,
    UnrealizedAllocationDtoFromJSON,
    UnrealizedAllocationDtoFromJSONTyped,
    UnrealizedAllocationDtoToJSON,
} from './UnrealizedAllocationDto';
import {
    UnrealizedAllocationsOverviewDto,
    UnrealizedAllocationsOverviewDtoFromJSON,
    UnrealizedAllocationsOverviewDtoFromJSONTyped,
    UnrealizedAllocationsOverviewDtoToJSON,
} from './UnrealizedAllocationsOverviewDto';

/**
 * 
 * @export
 * @interface RatiosDto
 */
export interface RatiosDto {
    /**
     * 
     * @type {PieChartDto}
     * @memberof RatiosDto
     */
    symbolTypeRatio: PieChartDto;
    /**
     * 
     * @type {PieChartDto}
     * @memberof RatiosDto
     */
    allocationTypeRatio: PieChartDto;
    /**
     * 
     * @type {PieChartDto}
     * @memberof RatiosDto
     */
    currencyRatio: PieChartDto;
    /**
     * 
     * @type {PieChartDto}
     * @memberof RatiosDto
     */
    sectorRatio: PieChartDto;
    /**
     * 
     * @type {PieChartDto}
     * @memberof RatiosDto
     */
    countryRatio: PieChartDto;
    /**
     * 
     * @type {Array<UnrealizedAllocationDto>}
     * @memberof RatiosDto
     */
    allocations: Array<UnrealizedAllocationDto>;
    /**
     * 
     * @type {Array<RealizedAllocationDto>}
     * @memberof RatiosDto
     */
    realizedAllocations: Array<RealizedAllocationDto>;
    /**
     * 
     * @type {Array<UnrealizedAllocationDto>}
     * @memberof RatiosDto
     */
    gainersRanking: Array<UnrealizedAllocationDto>;
    /**
     * 
     * @type {Array<UnrealizedAllocationDto>}
     * @memberof RatiosDto
     */
    losersRanking: Array<UnrealizedAllocationDto>;
    /**
     * 
     * @type {UnrealizedAllocationsOverviewDto}
     * @memberof RatiosDto
     */
    unrealizedAllocationsOverview: UnrealizedAllocationsOverviewDto;
}

export function RatiosDtoFromJSON(json: any): RatiosDto {
    return RatiosDtoFromJSONTyped(json, false);
}

export function RatiosDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatiosDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'symbolTypeRatio': PieChartDtoFromJSON(json['symbolTypeRatio']),
        'allocationTypeRatio': PieChartDtoFromJSON(json['allocationTypeRatio']),
        'currencyRatio': PieChartDtoFromJSON(json['currencyRatio']),
        'sectorRatio': PieChartDtoFromJSON(json['sectorRatio']),
        'countryRatio': PieChartDtoFromJSON(json['countryRatio']),
        'allocations': ((json['allocations'] as Array<any>).map(UnrealizedAllocationDtoFromJSON)),
        'realizedAllocations': ((json['realizedAllocations'] as Array<any>).map(RealizedAllocationDtoFromJSON)),
        'gainersRanking': ((json['gainersRanking'] as Array<any>).map(UnrealizedAllocationDtoFromJSON)),
        'losersRanking': ((json['losersRanking'] as Array<any>).map(UnrealizedAllocationDtoFromJSON)),
        'unrealizedAllocationsOverview': UnrealizedAllocationsOverviewDtoFromJSON(json['unrealizedAllocationsOverview']),
    };
}

export function RatiosDtoToJSON(value?: RatiosDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'symbolTypeRatio': PieChartDtoToJSON(value.symbolTypeRatio),
        'allocationTypeRatio': PieChartDtoToJSON(value.allocationTypeRatio),
        'currencyRatio': PieChartDtoToJSON(value.currencyRatio),
        'sectorRatio': PieChartDtoToJSON(value.sectorRatio),
        'countryRatio': PieChartDtoToJSON(value.countryRatio),
        'allocations': ((value.allocations as Array<any>).map(UnrealizedAllocationDtoToJSON)),
        'realizedAllocations': ((value.realizedAllocations as Array<any>).map(RealizedAllocationDtoToJSON)),
        'gainersRanking': ((value.gainersRanking as Array<any>).map(UnrealizedAllocationDtoToJSON)),
        'losersRanking': ((value.losersRanking as Array<any>).map(UnrealizedAllocationDtoToJSON)),
        'unrealizedAllocationsOverview': UnrealizedAllocationsOverviewDtoToJSON(value.unrealizedAllocationsOverview),
    };
}

