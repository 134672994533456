/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateSymbolPriceAlertDto,
    CreateSymbolPriceAlertDtoFromJSON,
    CreateSymbolPriceAlertDtoToJSON,
    CreatedSymbolPriceAlertDto,
    CreatedSymbolPriceAlertDtoFromJSON,
    CreatedSymbolPriceAlertDtoToJSON,
    SymbolPriceAlertDto,
    SymbolPriceAlertDtoFromJSON,
    SymbolPriceAlertDtoToJSON,
} from '../models';

export interface SymbolPriceAlertControllerCreateRequest {
    createSymbolPriceAlertDto: CreateSymbolPriceAlertDto;
}

export interface SymbolPriceAlertControllerDeleteRequest {
    id: string;
}

export interface SymbolPriceAlertControllerGetBySymbolIdRequest {
    symbolId: string;
}

/**
 * 
 */
export class SymbolPriceAlertsApi extends runtime.BaseAPI {

    /**
     * Create symbol price alert
     */
    async symbolPriceAlertControllerCreateRaw(requestParameters: SymbolPriceAlertControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreatedSymbolPriceAlertDto>> {
        if (requestParameters.createSymbolPriceAlertDto === null || requestParameters.createSymbolPriceAlertDto === undefined) {
            throw new runtime.RequiredError('createSymbolPriceAlertDto','Required parameter requestParameters.createSymbolPriceAlertDto was null or undefined when calling symbolPriceAlertControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbol-price-alerts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSymbolPriceAlertDtoToJSON(requestParameters.createSymbolPriceAlertDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatedSymbolPriceAlertDtoFromJSON(jsonValue));
    }

    /**
     * Create symbol price alert
     */
    async symbolPriceAlertControllerCreate(requestParameters: SymbolPriceAlertControllerCreateRequest, initOverrides?: RequestInit): Promise<CreatedSymbolPriceAlertDto> {
        const response = await this.symbolPriceAlertControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete symbol price alert
     */
    async symbolPriceAlertControllerDeleteRaw(requestParameters: SymbolPriceAlertControllerDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling symbolPriceAlertControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbol-price-alerts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete symbol price alert
     */
    async symbolPriceAlertControllerDelete(requestParameters: SymbolPriceAlertControllerDeleteRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.symbolPriceAlertControllerDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get symbol price alert by symbol id
     */
    async symbolPriceAlertControllerGetBySymbolIdRaw(requestParameters: SymbolPriceAlertControllerGetBySymbolIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SymbolPriceAlertDto>> {
        if (requestParameters.symbolId === null || requestParameters.symbolId === undefined) {
            throw new runtime.RequiredError('symbolId','Required parameter requestParameters.symbolId was null or undefined when calling symbolPriceAlertControllerGetBySymbolId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbol-price-alerts/{symbolId}`.replace(`{${"symbolId"}}`, encodeURIComponent(String(requestParameters.symbolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SymbolPriceAlertDtoFromJSON(jsonValue));
    }

    /**
     * Get symbol price alert by symbol id
     */
    async symbolPriceAlertControllerGetBySymbolId(requestParameters: SymbolPriceAlertControllerGetBySymbolIdRequest, initOverrides?: RequestInit): Promise<SymbolPriceAlertDto> {
        const response = await this.symbolPriceAlertControllerGetBySymbolIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
