import { makeAutoObservable } from 'mobx';
import type { RootStore } from './root-store';

export type HomepageStoreHydration = {
    roundedCount: number;
};

export class HomepageStore {
    rootStore: RootStore;

    loading = false;

    roundedCount = 0;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    hydrate(): HomepageStoreHydration {
        return {
            roundedCount: this.roundedCount,
        };
    }

    rehydrate(data: HomepageStoreHydration): void {
        this.roundedCount = data.roundedCount;
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async fetchRoundedCount(): Promise<void> {
        this.startLoading();
        try {
            const count = await this.rootStore.apiClient.userController.userControllerGetRoundedCount();
            if (count) {
                this.roundedCount = count;
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }
}
