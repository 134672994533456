/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotConfirmedUserDto,
    NotConfirmedUserDtoFromJSON,
    NotConfirmedUserDtoFromJSONTyped,
    NotConfirmedUserDtoToJSON,
} from './NotConfirmedUserDto';

/**
 * 
 * @export
 * @interface PaginatedNotConfirmedUserDto
 */
export interface PaginatedNotConfirmedUserDto {
    /**
     * 
     * @type {number}
     * @memberof PaginatedNotConfirmedUserDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedNotConfirmedUserDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedNotConfirmedUserDto
     */
    offset: number;
    /**
     * 
     * @type {Array<NotConfirmedUserDto>}
     * @memberof PaginatedNotConfirmedUserDto
     */
    items: Array<NotConfirmedUserDto>;
}

export function PaginatedNotConfirmedUserDtoFromJSON(json: any): PaginatedNotConfirmedUserDto {
    return PaginatedNotConfirmedUserDtoFromJSONTyped(json, false);
}

export function PaginatedNotConfirmedUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedNotConfirmedUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'limit': json['limit'],
        'offset': json['offset'],
        'items': ((json['items'] as Array<any>).map(NotConfirmedUserDtoFromJSON)),
    };
}

export function PaginatedNotConfirmedUserDtoToJSON(value?: PaginatedNotConfirmedUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'limit': value.limit,
        'offset': value.offset,
        'items': ((value.items as Array<any>).map(NotConfirmedUserDtoToJSON)),
    };
}

