import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { color } from '../colors';
import { getOpacity } from '../utils/get-rgb';

export const createProgressBar = (t: ThemeEnum) => {
    const progressbar: DefaultTheme['progressBar'] = {
        variant: {
            default: {
                background: getOpacity(color[t].base.gray, 0.2),
                bar: color[t].base.gray,
            },
            primary: {
                background: getOpacity(color[t].base.gray, 0.2),
                bar: color[t].light.primary,
            },
            positive: {
                background: getOpacity(color[t].base.gray, 0.2),
                bar: color[t].base.positive,
            },
            negative: {
                background: getOpacity(color[t].base.gray, 0.2),
                bar: color[t].base.negative,
            },
        },
    };
    return progressbar;
};
