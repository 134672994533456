/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SymbolTypeEnum {
    Ad = 'ad',
    Re = 're',
    Ce = 'ce',
    Si = 'si',
    Lp = 'lp',
    Cs = 'cs',
    Et = 'et',
    Etc = 'etc',
    Wt = 'wt',
    Oef = 'oef',
    Cef = 'cef',
    Mf = 'mf',
    Ps = 'ps',
    Ct = 'ct',
    Futures = 'futures',
    Commodity = 'commodity',
    Bond = 'bond',
    Index = 'index',
    Note = 'note',
    Ot = 'ot'
}

export function SymbolTypeEnumFromJSON(json: any): SymbolTypeEnum {
    return SymbolTypeEnumFromJSONTyped(json, false);
}

export function SymbolTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolTypeEnum {
    return json as SymbolTypeEnum;
}

export function SymbolTypeEnumToJSON(value?: SymbolTypeEnum | null): any {
    return value as any;
}

