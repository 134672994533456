/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorEnum,
    ColorEnumFromJSON,
    ColorEnumFromJSONTyped,
    ColorEnumToJSON,
} from './ColorEnum';
import {
    IconNameEnum,
    IconNameEnumFromJSON,
    IconNameEnumFromJSONTyped,
    IconNameEnumToJSON,
} from './IconNameEnum';

/**
 * 
 * @export
 * @interface CreateWatchlistDto
 */
export interface CreateWatchlistDto {
    /**
     * 
     * @type {IconNameEnum}
     * @memberof CreateWatchlistDto
     */
    iconName: IconNameEnum;
    /**
     * 
     * @type {ColorEnum}
     * @memberof CreateWatchlistDto
     */
    colorName: ColorEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateWatchlistDto
     */
    name: string;
}

export function CreateWatchlistDtoFromJSON(json: any): CreateWatchlistDto {
    return CreateWatchlistDtoFromJSONTyped(json, false);
}

export function CreateWatchlistDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWatchlistDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iconName': IconNameEnumFromJSON(json['iconName']),
        'colorName': ColorEnumFromJSON(json['colorName']),
        'name': json['name'],
    };
}

export function CreateWatchlistDtoToJSON(value?: CreateWatchlistDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iconName': IconNameEnumToJSON(value.iconName),
        'colorName': ColorEnumToJSON(value.colorName),
        'name': value.name,
    };
}

