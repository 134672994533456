/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExchangeSourceEnum,
    ExchangeSourceEnumFromJSON,
    ExchangeSourceEnumFromJSONTyped,
    ExchangeSourceEnumToJSON,
} from './ExchangeSourceEnum';

/**
 * 
 * @export
 * @interface ExchangeEntity
 */
export interface ExchangeEntity {
    /**
     * 
     * @type {ExchangeSourceEnum}
     * @memberof ExchangeEntity
     */
    source: ExchangeSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof ExchangeEntity
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangeEntity
     */
    importSymbols: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExchangeEntity
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeEntity
     */
    longName: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeEntity
     */
    suffix: string;
    /**
     * 
     * @type {number}
     * @memberof ExchangeEntity
     */
    priority: number;
}

export function ExchangeEntityFromJSON(json: any): ExchangeEntity {
    return ExchangeEntityFromJSONTyped(json, false);
}

export function ExchangeEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': ExchangeSourceEnumFromJSON(json['source']),
        'id': json['id'],
        'importSymbols': json['importSymbols'],
        'region': json['region'],
        'name': json['name'],
        'longName': json['longName'],
        'suffix': json['suffix'],
        'priority': json['priority'],
    };
}

export function ExchangeEntityToJSON(value?: ExchangeEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': ExchangeSourceEnumToJSON(value.source),
        'id': value.id,
        'importSymbols': value.importSymbols,
        'region': value.region,
        'name': value.name,
        'longName': value.longName,
        'suffix': value.suffix,
        'priority': value.priority,
    };
}

