/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExchangeEntity,
    ExchangeEntityFromJSON,
    ExchangeEntityFromJSONTyped,
    ExchangeEntityToJSON,
} from './ExchangeEntity';
import {
    SymbolTypeEnum,
    SymbolTypeEnumFromJSON,
    SymbolTypeEnumFromJSONTyped,
    SymbolTypeEnumToJSON,
} from './SymbolTypeEnum';

/**
 * 
 * @export
 * @interface SymbolDto
 */
export interface SymbolDto {
    /**
     * 
     * @type {SymbolTypeEnum}
     * @memberof SymbolDto
     */
    type: SymbolTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SymbolDto
     */
    close: number;
    /**
     * 
     * @type {string}
     * @memberof SymbolDto
     */
    exchangeId: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDto
     */
    exchangeName: string;
    /**
     * 
     * @type {number}
     * @memberof SymbolDto
     */
    marketCapitalization: number;
    /**
     * 
     * @type {string}
     * @memberof SymbolDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDto
     */
    symbol: string;
    /**
     * 
     * @type {ExchangeEntity}
     * @memberof SymbolDto
     */
    exchange: ExchangeEntity;
    /**
     * 
     * @type {string}
     * @memberof SymbolDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDto
     */
    iexId: string | null;
    /**
     * 
     * @type {string}
     * @memberof SymbolDto
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDto
     */
    figi: string | null;
}

export function SymbolDtoFromJSON(json: any): SymbolDto {
    return SymbolDtoFromJSONTyped(json, false);
}

export function SymbolDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': SymbolTypeEnumFromJSON(json['type']),
        'close': json['close'],
        'exchangeId': json['exchangeId'],
        'exchangeName': json['exchangeName'],
        'marketCapitalization': json['marketCapitalization'],
        'id': json['id'],
        'symbol': json['symbol'],
        'exchange': ExchangeEntityFromJSON(json['exchange']),
        'name': json['name'],
        'iexId': json['iexId'],
        'currency': json['currency'],
        'figi': json['figi'],
    };
}

export function SymbolDtoToJSON(value?: SymbolDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': SymbolTypeEnumToJSON(value.type),
        'close': value.close,
        'exchangeId': value.exchangeId,
        'exchangeName': value.exchangeName,
        'marketCapitalization': value.marketCapitalization,
        'id': value.id,
        'symbol': value.symbol,
        'exchange': ExchangeEntityToJSON(value.exchange),
        'name': value.name,
        'iexId': value.iexId,
        'currency': value.currency,
        'figi': value.figi,
    };
}

