import { makeAutoObservable } from 'mobx';
import { YearMobxDto } from './year-mobx-dto';
import { InvestmentsDto } from '../../../../defs/api';

export class InvestmentsMobxDto implements InvestmentsDto {
    years: YearMobxDto[] = [];

    numberOfMonths: number = 0;

    totalAmountWithoutSell: number = 0;

    totalAmountWithSell: number = 0;

    averageAmountWithoutSell: number = 0;

    averageAmountWithSell: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: InvestmentsDto): InvestmentsMobxDto {
        const mobxDto = new InvestmentsMobxDto();
        return Object.assign(mobxDto, dto, { years: YearMobxDto.createFromArray(dto.years) });
    }

    static createFromArray(dtos: InvestmentsDto[]): InvestmentsMobxDto[] {
        return dtos.map((dto) => InvestmentsMobxDto.create(dto));
    }
}
