import { makeAutoObservable } from 'mobx';
import { SymbolDividendDto, SymbolTypeEnum } from '../../../../../defs/api';

export class SymbolDividendMobxDto implements SymbolDividendDto {
    public recordDate: Date = new Date();

    public paymentDate: Date = new Date();

    public date: Date = new Date();

    public currency: string = '';

    public quantity: number = 0;

    public amount: number = 0;

    public amountInSymbolCurrency: number = 0;

    public amountPerUnit: number = 0;

    public amountPerUnitInSymbolCurrency: number = 0;

    public exchangeId: string = '';

    public exchangeName: string = '';

    public symbolId: string = '';

    public symbolCode: string = '';

    public symbolName: string = '';

    public symbolType: SymbolTypeEnum = SymbolTypeEnum.Cs;

    public symbolCurrency: string = '';

    public region: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolDividendDto): SymbolDividendMobxDto {
        const mobxDto = new SymbolDividendMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: SymbolDividendDto[]): SymbolDividendMobxDto[] {
        return dtos.map((dto) => SymbolDividendMobxDto.create(dto));
    }
}
