import { DefaultTheme } from 'styled-components';

export const createIconSymbol = () => {
    const iconSymbol: DefaultTheme['iconSymbol'] = {
        size: {
            xsmall: {
                image: '2.0rem',
                wrapper: '3.0rem',
            },
            vsmall: {
                image: '2.2rem',
                wrapper: '3.2rem',
            },
            small: {
                image: '2.6rem',
                wrapper: '3.6rem',
            },
            medium: {
                image: '3.0rem',
                wrapper: '4.0rem',
            },
            large: {
                image: '3.8rem',
                wrapper: '4.8rem',
            },
            vlarge: {
                image: '4.2rem',
                wrapper: '5.2rem',
            },
            xlarge: {
                image: '5.6rem',
                wrapper: '8.0rem',
            },
        },
    };
    return iconSymbol;
};
