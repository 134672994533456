/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CurrencyDto,
    CurrencyDtoFromJSON,
    CurrencyDtoToJSON,
} from '../models';

export interface CurrencyControllerGetListRequest {
    phrase?: string;
}

/**
 * 
 */
export class CurrenciesApi extends runtime.BaseAPI {

    /**
     * Get currencies
     */
    async currencyControllerGetListRaw(requestParameters: CurrencyControllerGetListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CurrencyDto>>> {
        const queryParameters: any = {};

        if (requestParameters.phrase !== undefined) {
            queryParameters['phrase'] = requestParameters.phrase;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/currencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CurrencyDtoFromJSON));
    }

    /**
     * Get currencies
     */
    async currencyControllerGetList(requestParameters: CurrencyControllerGetListRequest = {}, initOverrides?: RequestInit): Promise<Array<CurrencyDto>> {
        const response = await this.currencyControllerGetListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
