import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { getOpacity } from '../utils/get-rgb';
import { color } from '../colors';

export const createIconPortfolio = (t: ThemeEnum) => {
    const iconPortfolio: DefaultTheme['iconPortfolio'] = {
        size: {
            xsmall: {
                icon: '1.2rem',
                wrapper: '3.0rem',
            },
            vsmall: {
                icon: '1.4rem',
                wrapper: '3.2rem',
            },
            small: {
                icon: '1.6rem',
                wrapper: '3.6rem',
            },
            medium: {
                icon: '1.8rem',
                wrapper: '4.0rem',
            },
            large: {
                icon: '2.4rem',
                wrapper: '4.8rem',
            },
            vlarge: {
                icon: '2.8rem',
                wrapper: '5.2rem',
            },
            xlarge: {
                icon: '3.8rem',
                wrapper: '6.2rem',
            },
        },
        variant: {
            blue: {
                base: {
                    background: color[t].base.blue,
                    border: color[t].base.blue,
                    color: color[t].base.white,
                },
                hover: {
                    background: color[t].darken.blue,
                    border: color[t].darken.blue,
                    color: color[t].base.white,
                },
                active: {
                    background: color[t].dark.blue,
                    border: color[t].dark.blue,
                    color: color[t].base.white,
                },
                disabled: {
                    background: getOpacity(color[t].light.blue, 0.4),
                    border: getOpacity(color[t].light.blue, 0.4),
                    color: getOpacity(color[t].base.text, 0.4),
                },
            },
            purple: {
                base: {
                    background: color[t].base.purple,
                    border: color[t].base.purple,
                    color: color[t].base.white,
                },
                hover: {
                    background: color[t].darken.purple,
                    border: color[t].darken.purple,
                    color: color[t].base.white,
                },
                active: {
                    background: color[t].dark.purple,
                    border: color[t].dark.purple,
                    color: color[t].base.white,
                },
                disabled: {
                    background: getOpacity(color[t].light.purple, 0.4),
                    border: getOpacity(color[t].light.purple, 0.4),
                    color: getOpacity(color[t].base.text, 0.4),
                },
            },
            green: {
                base: {
                    background: color[t].base.green,
                    border: color[t].base.green,
                    color: color[t].base.white,
                },
                hover: {
                    background: color[t].darken.green,
                    border: color[t].darken.green,
                    color: color[t].base.white,
                },
                active: {
                    background: color[t].dark.green,
                    border: color[t].dark.green,
                    color: color[t].base.white,
                },
                disabled: {
                    background: getOpacity(color[t].light.green, 0.4),
                    border: getOpacity(color[t].light.green, 0.4),
                    color: getOpacity(color[t].base.text, 0.4),
                },
            },
            primary: {
                base: {
                    background: color[t].base.primary,
                    border: color[t].base.primary,
                    color: color[t].base.white,
                },
                hover: {
                    background: color[t].darken.primary,
                    border: color[t].darken.primary,
                    color: color[t].base.white,
                },
                active: {
                    background: color[t].dark.primary,
                    border: color[t].dark.primary,
                    color: color[t].base.white,
                },
                disabled: {
                    background: getOpacity(color[t].light.primary, 0.4),
                    border: getOpacity(color[t].light.primary, 0.4),
                    color: getOpacity(color[t].base.text, 0.4),
                },
            },
            secondary: {
                base: {
                    background: color[t].base.secondary,
                    border: color[t].base.secondary,
                    color: color[t].base.textInverse,
                },
                hover: {
                    background: color[t].darken.secondary,
                    border: color[t].darken.secondary,
                    color: color[t].base.white,
                },
                active: {
                    background: color[t].dark.secondary,
                    border: color[t].dark.secondary,
                    color: color[t].base.white,
                },
                disabled: {
                    background: getOpacity(color[t].light.secondary, 0.4),
                    border: getOpacity(color[t].light.secondary, 0.4),
                    color: getOpacity(color[t].base.text, 0.4),
                },
            },
            gray: {
                base: {
                    background: color[t].base.gray,
                    border: color[t].base.gray,
                    color: color[t].base.white,
                },
                hover: {
                    background: color[t].darken.gray,
                    border: color[t].darken.gray,
                    color: color[t].base.white,
                },
                active: {
                    background: color[t].dark.gray,
                    border: color[t].dark.gray,
                    color: color[t].base.white,
                },
                disabled: {
                    background: getOpacity(color[t].light.gray, 0.4),
                    border: getOpacity(color[t].light.gray, 0.4),
                    color: getOpacity(color[t].base.text, 0.4),
                },
            },
        },
    };
    return iconPortfolio;
};
