/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminStatisticsDto
 */
export interface AdminStatisticsDto {
    /**
     * 
     * @type {Date}
     * @memberof AdminStatisticsDto
     */
    enteredDate: Date;
    /**
     * 
     * @type {number}
     * @memberof AdminStatisticsDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof AdminStatisticsDto
     */
    logged: number;
    /**
     * 
     * @type {number}
     * @memberof AdminStatisticsDto
     */
    canceled: number;
    /**
     * 
     * @type {number}
     * @memberof AdminStatisticsDto
     */
    premium: number;
}

export function AdminStatisticsDtoFromJSON(json: any): AdminStatisticsDto {
    return AdminStatisticsDtoFromJSONTyped(json, false);
}

export function AdminStatisticsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminStatisticsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enteredDate': (new Date(json['enteredDate'])),
        'count': json['count'],
        'logged': json['logged'],
        'canceled': json['canceled'],
        'premium': json['premium'],
    };
}

export function AdminStatisticsDtoToJSON(value?: AdminStatisticsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enteredDate': (value.enteredDate.toISOString()),
        'count': value.count,
        'logged': value.logged,
        'canceled': value.canceled,
        'premium': value.premium,
    };
}

