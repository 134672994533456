/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PortfolioListItemDto,
    PortfolioListItemDtoFromJSON,
    PortfolioListItemDtoFromJSONTyped,
    PortfolioListItemDtoToJSON,
} from './PortfolioListItemDto';

/**
 * 
 * @export
 * @interface PaginatedPortfolioListDto
 */
export interface PaginatedPortfolioListDto {
    /**
     * 
     * @type {number}
     * @memberof PaginatedPortfolioListDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedPortfolioListDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedPortfolioListDto
     */
    offset: number;
    /**
     * 
     * @type {Array<PortfolioListItemDto>}
     * @memberof PaginatedPortfolioListDto
     */
    items: Array<PortfolioListItemDto>;
}

export function PaginatedPortfolioListDtoFromJSON(json: any): PaginatedPortfolioListDto {
    return PaginatedPortfolioListDtoFromJSONTyped(json, false);
}

export function PaginatedPortfolioListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedPortfolioListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'limit': json['limit'],
        'offset': json['offset'],
        'items': ((json['items'] as Array<any>).map(PortfolioListItemDtoFromJSON)),
    };
}

export function PaginatedPortfolioListDtoToJSON(value?: PaginatedPortfolioListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'limit': value.limit,
        'offset': value.offset,
        'items': ((value.items as Array<any>).map(PortfolioListItemDtoToJSON)),
    };
}

