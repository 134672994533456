import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['inApp', 'isLink'].includes(prop),
})<{
    inApp: boolean;
    isLink?: boolean;
}>(
    ({ theme, inApp, isLink }) => css`
        display: flex;
        align-items: center;
        cursor: ${isLink ? 'pointer' : 'default'};

        svg {
            width: 10.2rem;
            height: 1.7rem;
        }

        @media ${theme.breakpoints.md.min} {
            svg {
                width: 13.6rem;
                height: 2.6rem;
            }
        }

        @media ${theme.breakpoints.sm.max} {
            width: 3rem;
            overflow: hidden;

            svg {
                min-width: 13.6rem;
                min-height: 2.6rem;
            }
        }

        ${inApp &&
        css`
            svg {
                width: 10.2rem;
                height: 1.7rem;
            }

            @media ${theme.breakpoints.xl.min} {
                svg {
                    width: 13.6rem;
                    height: 2.6rem;
                }
            }
        `}
    `,
);
