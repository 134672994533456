import { makeAutoObservable } from 'mobx';
import { MonthDto } from '../../../../defs/api';

export class MonthMobxDto implements MonthDto {
    month: number = 0;

    amountWithoutSell: number = 0;

    amountWithSell: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: MonthDto): MonthMobxDto {
        const mobxDto = new MonthMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: MonthDto[]): MonthMobxDto[] {
        return dtos.map((dto) => MonthMobxDto.create(dto));
    }
}
