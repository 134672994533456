import { makeAutoObservable } from 'mobx';
import { EcomailUsersCountDto } from '../../../../defs/api';

export class EcomailUsersCountMobxDto implements EcomailUsersCountDto {
    total = 0;

    deleted = 0;

    premium = 0;

    free = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: EcomailUsersCountDto): EcomailUsersCountMobxDto {
        const mobxDto = new EcomailUsersCountMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: EcomailUsersCountDto[]): EcomailUsersCountMobxDto[] {
        return dtos.map((dto) => EcomailUsersCountMobxDto.create(dto));
    }
}
