/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DistributionTypeEnum,
    DistributionTypeEnumFromJSON,
    DistributionTypeEnumFromJSONTyped,
    DistributionTypeEnumToJSON,
} from './DistributionTypeEnum';
import {
    ExchangeEntity,
    ExchangeEntityFromJSON,
    ExchangeEntityFromJSONTyped,
    ExchangeEntityToJSON,
} from './ExchangeEntity';
import {
    SymbolTypeEnum,
    SymbolTypeEnumFromJSON,
    SymbolTypeEnumFromJSONTyped,
    SymbolTypeEnumToJSON,
} from './SymbolTypeEnum';

/**
 * 
 * @export
 * @interface SymbolWithExchangeDto
 */
export interface SymbolWithExchangeDto {
    /**
     * 
     * @type {SymbolTypeEnum}
     * @memberof SymbolWithExchangeDto
     */
    type: SymbolTypeEnum;
    /**
     * 
     * @type {DistributionTypeEnum}
     * @memberof SymbolWithExchangeDto
     */
    distributionType: DistributionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SymbolWithExchangeDto
     */
    id: string;
    /**
     * 
     * @type {ExchangeEntity}
     * @memberof SymbolWithExchangeDto
     */
    exchange: ExchangeEntity;
    /**
     * 
     * @type {string}
     * @memberof SymbolWithExchangeDto
     */
    symbol: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolWithExchangeDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolWithExchangeDto
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolWithExchangeDto
     */
    quote: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolWithExchangeDto
     */
    iexId: string | null;
    /**
     * 
     * @type {string}
     * @memberof SymbolWithExchangeDto
     */
    isin: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolWithExchangeDto
     */
    figi: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SymbolWithExchangeDto
     */
    hasDividendHistory: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SymbolWithExchangeDto
     */
    latestHistoryUpdateDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SymbolWithExchangeDto
     */
    latestDividendsUpdateDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SymbolWithExchangeDto
     */
    latestSplitsUpdateDate?: Date;
}

export function SymbolWithExchangeDtoFromJSON(json: any): SymbolWithExchangeDto {
    return SymbolWithExchangeDtoFromJSONTyped(json, false);
}

export function SymbolWithExchangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolWithExchangeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': SymbolTypeEnumFromJSON(json['type']),
        'distributionType': DistributionTypeEnumFromJSON(json['distributionType']),
        'id': json['id'],
        'exchange': ExchangeEntityFromJSON(json['exchange']),
        'symbol': json['symbol'],
        'name': json['name'],
        'currency': json['currency'],
        'quote': json['quote'],
        'iexId': json['iexId'],
        'isin': json['isin'],
        'figi': json['figi'],
        'hasDividendHistory': json['hasDividendHistory'],
        'latestHistoryUpdateDate': !exists(json, 'latestHistoryUpdateDate') ? undefined : (new Date(json['latestHistoryUpdateDate'])),
        'latestDividendsUpdateDate': !exists(json, 'latestDividendsUpdateDate') ? undefined : (new Date(json['latestDividendsUpdateDate'])),
        'latestSplitsUpdateDate': !exists(json, 'latestSplitsUpdateDate') ? undefined : (new Date(json['latestSplitsUpdateDate'])),
    };
}

export function SymbolWithExchangeDtoToJSON(value?: SymbolWithExchangeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': SymbolTypeEnumToJSON(value.type),
        'distributionType': DistributionTypeEnumToJSON(value.distributionType),
        'id': value.id,
        'exchange': ExchangeEntityToJSON(value.exchange),
        'symbol': value.symbol,
        'name': value.name,
        'currency': value.currency,
        'quote': value.quote,
        'iexId': value.iexId,
        'isin': value.isin,
        'figi': value.figi,
        'hasDividendHistory': value.hasDividendHistory,
        'latestHistoryUpdateDate': value.latestHistoryUpdateDate === undefined ? undefined : (value.latestHistoryUpdateDate.toISOString()),
        'latestDividendsUpdateDate': value.latestDividendsUpdateDate === undefined ? undefined : (value.latestDividendsUpdateDate.toISOString()),
        'latestSplitsUpdateDate': value.latestSplitsUpdateDate === undefined ? undefined : (value.latestSplitsUpdateDate.toISOString()),
    };
}

