import { makeAutoObservable } from 'mobx';
import { EquityDto } from '../../../../../defs/api';

export class SymbolDetailRegionMobxDto implements EquityDto {
    public name: string = '';

    public equity: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: EquityDto): SymbolDetailRegionMobxDto {
        const mobxDto = new SymbolDetailRegionMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: EquityDto[]): SymbolDetailRegionMobxDto[] {
        return dtos.map((dto) => SymbolDetailRegionMobxDto.create(dto));
    }
}
