import { makeAutoObservable } from 'mobx';
import type { RootStore } from './root-store';

export class BannerYearlyPremiumStore {
    rootStore: RootStore;

    showBanner: boolean = true;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    get canShowBanner() {
        return this.showBanner && this.rootStore.stripeStore.canShowYearlyButton;
    }

    closeBanner(): void {
        if (!this.rootStore.cookieService.hasBannerYearlyOpenProtection()) {
            this.showBanner = false;
            this.rootStore.cookieService.setBannerYearlyOpenProtection();
        }
    }

    loadBannerYearlyOpenProtectionFromCookies(): void {
        this.showBanner = !this.rootStore.cookieService.hasBannerYearlyOpenProtection();
    }
}
