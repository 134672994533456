import { makeAutoObservable } from 'mobx';
import { SymbolEntity, SymbolSplitDto } from '../../../../defs/api';

export class SymbolSplitMobxDto implements SymbolSplitDto {
    public date: Date = new Date();

    public ratio: string = '';

    public ratioValue: number = 0;

    symbol: SymbolEntity;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolSplitDto): SymbolSplitMobxDto {
        const mobxDto = new SymbolSplitMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: SymbolSplitDto[]): SymbolSplitMobxDto[] {
        return dtos.map((dto) => SymbolSplitMobxDto.create(dto));
    }
}
