/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolValueDto,
    SymbolValueDtoFromJSON,
    SymbolValueDtoFromJSONTyped,
    SymbolValueDtoToJSON,
} from './SymbolValueDto';

/**
 * 
 * @export
 * @interface SymbolDividendChartDto
 */
export interface SymbolDividendChartDto {
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendChartDto
     */
    timePeriod: string;
    /**
     * 
     * @type {Array<SymbolValueDto>}
     * @memberof SymbolDividendChartDto
     */
    symbols: Array<SymbolValueDto>;
}

export function SymbolDividendChartDtoFromJSON(json: any): SymbolDividendChartDto {
    return SymbolDividendChartDtoFromJSONTyped(json, false);
}

export function SymbolDividendChartDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolDividendChartDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timePeriod': json['timePeriod'],
        'symbols': ((json['symbols'] as Array<any>).map(SymbolValueDtoFromJSON)),
    };
}

export function SymbolDividendChartDtoToJSON(value?: SymbolDividendChartDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timePeriod': value.timePeriod,
        'symbols': ((value.symbols as Array<any>).map(SymbolValueDtoToJSON)),
    };
}

