import { DefaultTheme } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { createForm } from './create/form-create';
import { createCard } from './create/card-create';
import { createButton } from './create/button-create';
import { createBase, createGap, createRadius, createShadow } from './create/main-create';
import { createHeadline } from './create/headline-create';
import { createBreakpoints } from './create/breakpoints-create';
import { createPalette } from './create/palette-create';
import { createAvatar } from './create/avatar-create';
import { createPagination } from './create/pagination-create';
import { createModal } from './create/modal-create';
import { createSpinner } from './create/spinner-create';
import { createAlert } from './create/alert-create';
import { createProgressBar } from './create/progress-bar-create';
import { createAllocationsProgressBar } from './create/allocations-progress-bar-create';
import { createIconButton } from './create/icon-button-create';
import { createIconPortfolio } from './create/icon-portfolio-create';
import { createIconSymbol } from './create/icon-symbol-create';
import { createText } from './create/text-create';
import { createDatepicker } from './create/datepicker-create';
import { createSelect } from './create/select-create';
import { createAutocomplete } from './create/autocomplete-create';
import { createTextfield } from './create/textfield-create';
import { createSwitch } from './create/switch-create';

export const createTheme = (t: ThemeEnum) => {
    const palette = createPalette(t);
    const base = createBase();
    const shadow = createShadow();
    const radius = createRadius();
    const gap = createGap();
    const button = createButton(t);
    const form = createForm(t);
    const card = createCard(t);
    const headline = createHeadline(t);
    const text = createText(t);
    const avatar = createAvatar(t);
    const iconButton = createIconButton(t);
    const iconPortfolio = createIconPortfolio(t);
    const pagination = createPagination(t);
    const modal = createModal(t);
    const breakpoints = createBreakpoints();
    const spinner = createSpinner(t);
    const alert = createAlert(t);
    const progressBar = createProgressBar(t);
    const allocationsProgressBar = createAllocationsProgressBar(t);
    const iconSymbol = createIconSymbol();
    const datepicker = createDatepicker(t);
    const select = createSelect(t);
    const autocomplete = createAutocomplete(t);
    const textfield = createTextfield(t);
    const switchCheckbox = createSwitch(t);

    const theme: DefaultTheme = {
        base,
        palette,
        shadow,
        radius,
        gap,
        headline,
        card,
        text,
        form,
        button,
        avatar,
        alert,
        iconButton,
        iconPortfolio,
        iconSymbol,
        pagination,
        modal,
        spinner,
        progressBar,
        allocationsProgressBar,
        breakpoints,
        datepicker,
        select,
        autocomplete,
        textfield,
        switchCheckbox,
    };

    return theme;
};
