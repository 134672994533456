import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from '../../root-store';
import { PortfolioListItemMobxDto } from '../../../mobx/dtos/admin/portfolio/portfolio-list-item-mobx-dto';
import { PortfolioControllerGetAllRequest } from '../../../../defs/api';

export class PortfolioListStore {
    rootStore: RootStore;

    loading = false;

    portfolios: PortfolioListItemMobxDto[] = [];

    page = 1;

    portfoliosCount = 0;

    limit = Number(process.env.NEXT_PUBLIC_PAGE_LIMIT);

    withProblemFilter = false;

    filterPhrase = '';

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    async fetchPortfolioList(): Promise<void> {
        try {
            this.setIsLoading(true);
            const query: PortfolioControllerGetAllRequest = {
                limit: this.limit,
                offset: (this.page - 1) * this.limit,
                withProblem: this.withProblemFilter,
                phrase: this.filterPhrase,
            };
            const fetchedPortfolio = await this.rootStore.apiClient.portfolioController.portfolioControllerGetAll(
                query,
            );
            if (fetchedPortfolio) {
                runInAction(() => {
                    this.portfolios = PortfolioListItemMobxDto.createFromArray(fetchedPortfolio.items);
                    this.portfoliosCount = fetchedPortfolio.total;
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.setIsLoading(false);
        }
    }

    setIsLoading(loading: boolean): void {
        this.loading = loading;
    }

    async setActivePage(page: number): Promise<void> {
        this.page = page;
        await this.fetchPortfolioList();
    }

    get activePagesCount(): number {
        return Math.ceil(this.portfoliosCount / this.limit);
    }

    get hasPortfolios(): boolean {
        return this.portfolios.length > 0;
    }

    get isPageSelected(): boolean {
        return this.portfolios.filter((portfolio) => !portfolio.isSelected).length <= 0;
    }

    get selectedPortfoliosCount(): number {
        return this.portfolios.filter((p) => p.isSelected).length;
    }

    get selectedPortfolioIds(): string[] {
        return this.portfolios.filter((p) => p.isSelected).map((p) => p.portfolioId);
    }

    toggleWithProblemFilter() {
        this.withProblemFilter = !this.withProblemFilter;
        this.fetchPortfolioList();
    }

    setPhraseFilter(phrase: string) {
        this.page = 1;
        this.filterPhrase = phrase;
        this.fetchPortfolioList();
    }

    setAllPortfolioSelection(checked: boolean): void {
        this.portfolios.forEach((portfolio) => {
            portfolio.setSelected(checked);
        });
    }

    switchPortfolioSelected(portfolioId: string) {
        this.portfolios.find((portfolio) => portfolio.portfolioId === portfolioId)?.switchSelected();
    }
}
