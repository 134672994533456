import { makeAutoObservable } from 'mobx';
import { ExchangeEntity, SymbolTypeEnum, DistributionTypeEnum, SymbolWithExchangeDto } from '../../../../defs/api';

export class SymbolWithExchangeMobxDto implements SymbolWithExchangeDto {
    id: string = '';

    exchange: ExchangeEntity;

    symbol: string = '';

    name: string = '';

    currency: string = '';

    quote: string = '';

    iexId: string | null = null;

    isin: string = '';

    figi: string | null = null;

    hasDividendHistory: boolean = false;

    latestHistoryUpdateDate?: Date;

    latestDividendsUpdateDate?: Date;

    latestSplitsUpdateDate?: Date;

    distributionType: DistributionTypeEnum = DistributionTypeEnum.Acc;

    type: SymbolTypeEnum = SymbolTypeEnum.Cs;

    constructor() {
        makeAutoObservable(this, {});
    }

    static create(dto: SymbolWithExchangeDto): SymbolWithExchangeMobxDto {
        const mobxDto = new SymbolWithExchangeMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: SymbolWithExchangeDto[]): SymbolWithExchangeMobxDto[] {
        return dtos.map((dto) => SymbolWithExchangeMobxDto.create(dto));
    }
}
