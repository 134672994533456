import { makeAutoObservable } from 'mobx';
import type { RootStore } from './root-store';
import type { SymbolsStore } from './symbols-store';

export class SymbolDialogStore {
    rootStore: RootStore;

    symbolsStore: SymbolsStore;

    searchDialogOpened = false;

    loading = false;

    constructor(symbolsStore: SymbolsStore) {
        this.rootStore = symbolsStore.rootStore;
        this.symbolsStore = symbolsStore;

        makeAutoObservable(this, {
            rootStore: false,
            symbolsStore: false,
        });
    }

    openSearchDialog(): void {
        this.rootStore.symbolsStore.setIgnoreSymbolsFromWatchlistId(null);
        this.searchDialogOpened = true;
    }

    closeSearchDialog(): void {
        this.searchDialogOpened = false;
    }
}
