import { makeAutoObservable } from 'mobx';
import { OverviewDto } from '../../../../defs/api';

export class OverviewMobxDto implements OverviewDto {
    dateFrom: string = '';

    lastUpdated: string = '';

    portfolioValue: number = 0;

    invested: number = 0;

    investmentPeriod: number = 0;

    averageInvestment: number = 0;

    unrealizedProfit: number = 0;

    unrealizedProfitPercentage: number = 0;

    todayUnrealizedProfit: number = 0;

    todayUnrealizedProfitPercentage: number = 0;

    realizedProfit: number = 0;

    realizedProfitPercentage: number = 0;

    dividends: number = 0;

    dividendsNextYearPrediction: number = 0;

    dividendsYield: number = 0;

    dividendsYieldOnCost: number = 0;

    fees: number = 0;

    feesPercentage: number = 0;

    rateImpact: number = 0;

    totalPortfolioValue: number = 0;

    isRecalculating: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: OverviewDto): OverviewMobxDto {
        const mobxDto = new OverviewMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: OverviewDto[]): OverviewMobxDto[] {
        return dtos.map((dto) => OverviewMobxDto.create(dto));
    }
}
