/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IsRecalculatingDto
 */
export interface IsRecalculatingDto {
    /**
     * 
     * @type {boolean}
     * @memberof IsRecalculatingDto
     */
    isRecalculating: boolean;
}

export function IsRecalculatingDtoFromJSON(json: any): IsRecalculatingDto {
    return IsRecalculatingDtoFromJSONTyped(json, false);
}

export function IsRecalculatingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IsRecalculatingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isRecalculating': json['isRecalculating'],
    };
}

export function IsRecalculatingDtoToJSON(value?: IsRecalculatingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isRecalculating': value.isRecalculating,
    };
}

