/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DividendCalendarMonthDto,
    DividendCalendarMonthDtoFromJSON,
    DividendCalendarMonthDtoFromJSONTyped,
    DividendCalendarMonthDtoToJSON,
} from './DividendCalendarMonthDto';

/**
 * 
 * @export
 * @interface DividendCalendarDto
 */
export interface DividendCalendarDto {
    /**
     * 
     * @type {string}
     * @memberof DividendCalendarDto
     */
    year: string;
    /**
     * 
     * @type {Array<DividendCalendarMonthDto>}
     * @memberof DividendCalendarDto
     */
    months: Array<DividendCalendarMonthDto>;
}

export function DividendCalendarDtoFromJSON(json: any): DividendCalendarDto {
    return DividendCalendarDtoFromJSONTyped(json, false);
}

export function DividendCalendarDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DividendCalendarDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'months': ((json['months'] as Array<any>).map(DividendCalendarMonthDtoFromJSON)),
    };
}

export function DividendCalendarDtoToJSON(value?: DividendCalendarDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'months': ((value.months as Array<any>).map(DividendCalendarMonthDtoToJSON)),
    };
}

