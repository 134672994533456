/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolTypeEnum,
    SymbolTypeEnumFromJSON,
    SymbolTypeEnumFromJSONTyped,
    SymbolTypeEnumToJSON,
} from './SymbolTypeEnum';
import {
    TradeTypeEnum,
    TradeTypeEnumFromJSON,
    TradeTypeEnumFromJSONTyped,
    TradeTypeEnumToJSON,
} from './TradeTypeEnum';

/**
 * 
 * @export
 * @interface PortfolioTradeDto
 */
export interface PortfolioTradeDto {
    /**
     * 
     * @type {SymbolTypeEnum}
     * @memberof PortfolioTradeDto
     */
    symbolType: SymbolTypeEnum;
    /**
     * 
     * @type {TradeTypeEnum}
     * @memberof PortfolioTradeDto
     */
    type: TradeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PortfolioTradeDto
     */
    unitPrice: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioTradeDto
     */
    symbolId: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioTradeDto
     */
    symbol: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioTradeDto
     */
    symbolName: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioTradeDto
     */
    symbolCurrency: string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioTradeDto
     */
    symbolRate: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioTradeDto
     */
    tradeCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioTradeDto
     */
    portfolioCurrency: string;
    /**
     * 
     * @type {Date}
     * @memberof PortfolioTradeDto
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof PortfolioTradeDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioTradeDto
     */
    totalPricePortfolioCurrency: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioTradeDto
     */
    tradeSymbolId: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioTradeDto
     */
    exchange: string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioTradeDto
     */
    fees: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioTradeDto
     */
    feesPortfolioCurrency: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioTradeDto
     */
    rawAmount: number;
    /**
     * 
     * @type {string}
     * @memberof PortfolioTradeDto
     */
    note: string;
}

export function PortfolioTradeDtoFromJSON(json: any): PortfolioTradeDto {
    return PortfolioTradeDtoFromJSONTyped(json, false);
}

export function PortfolioTradeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioTradeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'symbolType': SymbolTypeEnumFromJSON(json['symbolType']),
        'type': TradeTypeEnumFromJSON(json['type']),
        'unitPrice': json['unitPrice'],
        'symbolId': json['symbolId'],
        'symbol': json['symbol'],
        'symbolName': json['symbolName'],
        'symbolCurrency': json['symbolCurrency'],
        'symbolRate': json['symbolRate'],
        'tradeCurrency': json['tradeCurrency'],
        'portfolioCurrency': json['portfolioCurrency'],
        'date': (new Date(json['date'])),
        'amount': json['amount'],
        'totalPricePortfolioCurrency': json['totalPricePortfolioCurrency'],
        'tradeSymbolId': json['tradeSymbolId'],
        'exchange': json['exchange'],
        'fees': json['fees'],
        'feesPortfolioCurrency': json['feesPortfolioCurrency'],
        'rawAmount': json['rawAmount'],
        'note': json['note'],
    };
}

export function PortfolioTradeDtoToJSON(value?: PortfolioTradeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'symbolType': SymbolTypeEnumToJSON(value.symbolType),
        'type': TradeTypeEnumToJSON(value.type),
        'unitPrice': value.unitPrice,
        'symbolId': value.symbolId,
        'symbol': value.symbol,
        'symbolName': value.symbolName,
        'symbolCurrency': value.symbolCurrency,
        'symbolRate': value.symbolRate,
        'tradeCurrency': value.tradeCurrency,
        'portfolioCurrency': value.portfolioCurrency,
        'date': (value.date.toISOString()),
        'amount': value.amount,
        'totalPricePortfolioCurrency': value.totalPricePortfolioCurrency,
        'tradeSymbolId': value.tradeSymbolId,
        'exchange': value.exchange,
        'fees': value.fees,
        'feesPortfolioCurrency': value.feesPortfolioCurrency,
        'rawAmount': value.rawAmount,
        'note': value.note,
    };
}

