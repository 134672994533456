/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RealizedDividendsSummaryDto,
    RealizedDividendsSummaryDtoFromJSON,
    RealizedDividendsSummaryDtoFromJSONTyped,
    RealizedDividendsSummaryDtoToJSON,
} from './RealizedDividendsSummaryDto';
import {
    RealizedReportTradeDto,
    RealizedReportTradeDtoFromJSON,
    RealizedReportTradeDtoFromJSONTyped,
    RealizedReportTradeDtoToJSON,
} from './RealizedReportTradeDto';
import {
    RealizedTransactionsSummaryDto,
    RealizedTransactionsSummaryDtoFromJSON,
    RealizedTransactionsSummaryDtoFromJSONTyped,
    RealizedTransactionsSummaryDtoToJSON,
} from './RealizedTransactionsSummaryDto';
import {
    SymbolDividendDto,
    SymbolDividendDtoFromJSON,
    SymbolDividendDtoFromJSONTyped,
    SymbolDividendDtoToJSON,
} from './SymbolDividendDto';
import {
    UnrealizedReportTradeDto,
    UnrealizedReportTradeDtoFromJSON,
    UnrealizedReportTradeDtoFromJSONTyped,
    UnrealizedReportTradeDtoToJSON,
} from './UnrealizedReportTradeDto';

/**
 * 
 * @export
 * @interface TaxReportDto
 */
export interface TaxReportDto {
    /**
     * 
     * @type {string}
     * @memberof TaxReportDto
     */
    year: string;
    /**
     * 
     * @type {Array<RealizedReportTradeDto>}
     * @memberof TaxReportDto
     */
    realizedReportTrades: Array<RealizedReportTradeDto>;
    /**
     * 
     * @type {RealizedTransactionsSummaryDto}
     * @memberof TaxReportDto
     */
    realizedTransactionsSummary?: RealizedTransactionsSummaryDto;
    /**
     * 
     * @type {Array<UnrealizedReportTradeDto>}
     * @memberof TaxReportDto
     */
    unrealizedReportTrades: Array<UnrealizedReportTradeDto>;
    /**
     * 
     * @type {Array<SymbolDividendDto>}
     * @memberof TaxReportDto
     */
    dividends: Array<SymbolDividendDto>;
    /**
     * 
     * @type {RealizedDividendsSummaryDto}
     * @memberof TaxReportDto
     */
    dividendsSummary?: RealizedDividendsSummaryDto;
}

export function TaxReportDtoFromJSON(json: any): TaxReportDto {
    return TaxReportDtoFromJSONTyped(json, false);
}

export function TaxReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxReportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'realizedReportTrades': ((json['realizedReportTrades'] as Array<any>).map(RealizedReportTradeDtoFromJSON)),
        'realizedTransactionsSummary': !exists(json, 'realizedTransactionsSummary') ? undefined : RealizedTransactionsSummaryDtoFromJSON(json['realizedTransactionsSummary']),
        'unrealizedReportTrades': ((json['unrealizedReportTrades'] as Array<any>).map(UnrealizedReportTradeDtoFromJSON)),
        'dividends': ((json['dividends'] as Array<any>).map(SymbolDividendDtoFromJSON)),
        'dividendsSummary': !exists(json, 'dividendsSummary') ? undefined : RealizedDividendsSummaryDtoFromJSON(json['dividendsSummary']),
    };
}

export function TaxReportDtoToJSON(value?: TaxReportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'realizedReportTrades': ((value.realizedReportTrades as Array<any>).map(RealizedReportTradeDtoToJSON)),
        'realizedTransactionsSummary': RealizedTransactionsSummaryDtoToJSON(value.realizedTransactionsSummary),
        'unrealizedReportTrades': ((value.unrealizedReportTrades as Array<any>).map(UnrealizedReportTradeDtoToJSON)),
        'dividends': ((value.dividends as Array<any>).map(SymbolDividendDtoToJSON)),
        'dividendsSummary': RealizedDividendsSummaryDtoToJSON(value.dividendsSummary),
    };
}

