/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DividendCalendarMonthDto,
    DividendCalendarMonthDtoFromJSON,
    DividendCalendarMonthDtoFromJSONTyped,
    DividendCalendarMonthDtoToJSON,
} from './DividendCalendarMonthDto';
import {
    SymbolTypeEnum,
    SymbolTypeEnumFromJSON,
    SymbolTypeEnumFromJSONTyped,
    SymbolTypeEnumToJSON,
} from './SymbolTypeEnum';

/**
 * 
 * @export
 * @interface SymbolDividendCalendarDto
 */
export interface SymbolDividendCalendarDto {
    /**
     * 
     * @type {SymbolTypeEnum}
     * @memberof SymbolDividendCalendarDto
     */
    symbolType: SymbolTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendCalendarDto
     */
    symbolId: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendCalendarDto
     */
    symbol: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendCalendarDto
     */
    symbolName: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendCalendarDto
     */
    symbolCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendCalendarDto
     */
    exchangeId?: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolDividendCalendarDto
     */
    exchangeName?: string;
    /**
     * 
     * @type {Array<DividendCalendarMonthDto>}
     * @memberof SymbolDividendCalendarDto
     */
    months: Array<DividendCalendarMonthDto>;
}

export function SymbolDividendCalendarDtoFromJSON(json: any): SymbolDividendCalendarDto {
    return SymbolDividendCalendarDtoFromJSONTyped(json, false);
}

export function SymbolDividendCalendarDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolDividendCalendarDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'symbolType': SymbolTypeEnumFromJSON(json['symbolType']),
        'symbolId': json['symbolId'],
        'symbol': json['symbol'],
        'symbolName': json['symbolName'],
        'symbolCurrency': !exists(json, 'symbolCurrency') ? undefined : json['symbolCurrency'],
        'exchangeId': !exists(json, 'exchangeId') ? undefined : json['exchangeId'],
        'exchangeName': !exists(json, 'exchangeName') ? undefined : json['exchangeName'],
        'months': ((json['months'] as Array<any>).map(DividendCalendarMonthDtoFromJSON)),
    };
}

export function SymbolDividendCalendarDtoToJSON(value?: SymbolDividendCalendarDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'symbolType': SymbolTypeEnumToJSON(value.symbolType),
        'symbolId': value.symbolId,
        'symbol': value.symbol,
        'symbolName': value.symbolName,
        'symbolCurrency': value.symbolCurrency,
        'exchangeId': value.exchangeId,
        'exchangeName': value.exchangeName,
        'months': ((value.months as Array<any>).map(DividendCalendarMonthDtoToJSON)),
    };
}

