import { makeAutoObservable } from 'mobx';
import { RealizedBuyTradeMobxDto } from '../realized-buy-trade-mobx-dto';
import { RealizedReportTradeDto } from '../../../../../defs/api';

export class RealizedReportTradeMobxDto implements RealizedReportTradeDto {
    public trade: RealizedBuyTradeMobxDto = new RealizedBuyTradeMobxDto();

    public holdingPeriodDays: number = 0;

    public profitLoss: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: RealizedReportTradeDto): RealizedReportTradeMobxDto {
        const mobxDto = new RealizedReportTradeMobxDto();
        return Object.assign(mobxDto, dto, { trade: RealizedBuyTradeMobxDto.create(dto.trade) });
    }

    static createFromArray(dtos: RealizedReportTradeDto[]): RealizedReportTradeMobxDto[] {
        return dtos.map((dto) => RealizedReportTradeMobxDto.create(dto));
    }
}
