import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
import { SymbolGrowthRateMobxDto } from '../mobx/dtos/symbol/symbol-growth-rate-mobx-dto';

export class SymbolGrowthRateStore {
    rootStore: RootStore;

    loading = true;

    symbol: string = '';

    growthRates: SymbolGrowthRateMobxDto[] = [];

    fairPrice: number = 0;

    inputGrowthRate: number | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    setInputGrowthRate(value: number | null): void {
        this.inputGrowthRate = value;
    }

    private calculateFairPrice(peRatio: number, eps: number, expectedProfitGrowthRate: number): number {
        return eps * (1 + expectedProfitGrowthRate / 100) * peRatio;
    }

    async fetchGrowthRates(symbolId: string): Promise<void> {
        try {
            this.startLoading();

            const fetchedRates =
                await this.rootStore.apiClient.symbolGrowthRateController.symbolGrowthRateControllerGetBySymbolId({
                    symbolId,
                });

            if (fetchedRates) {
                const currentGrowthRate = fetchedRates.growthRates.find((r) => r.current);
                runInAction(() => {
                    if (currentGrowthRate?.expectedProfitGrowthRate && fetchedRates.peRatio && fetchedRates.eps) {
                        this.fairPrice = this.calculateFairPrice(
                            fetchedRates.peRatio,
                            fetchedRates.eps,
                            currentGrowthRate.expectedProfitGrowthRate,
                        );
                        this.setInputGrowthRate(currentGrowthRate.expectedProfitGrowthRate);
                    } else {
                        this.inputGrowthRate = null;
                        this.fairPrice = 0;
                    }

                    this.growthRates = SymbolGrowthRateMobxDto.createFromArray(fetchedRates.growthRates);
                    this.symbol = symbolId;
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async updateGrowthRate(): Promise<void> {
        if (this.inputGrowthRate === null || !this.symbol) {
            return;
        }

        try {
            this.startLoading();
            const res = await this.rootStore.apiClient.symbolGrowthRateController.symbolGrowthRateControllerUpdate({
                updateSymbolGrowthRateDto: {
                    symbolId: this.symbol,
                    year: new Date().getFullYear(),
                    expectedProfitGrowthRate: this.inputGrowthRate,
                },
            });

            if (res) {
                await this.fetchGrowthRates(this.symbol);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
