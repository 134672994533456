import { makeAutoObservable } from 'mobx';
import { LeaderboardSymbolDto } from '../../../../defs/api';

export class LeaderboardSymbolMobxDto implements LeaderboardSymbolDto {
    id = '';

    symbol = '';

    exchange = '';

    name = '';

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: LeaderboardSymbolDto): LeaderboardSymbolMobxDto {
        const mobxDto = new LeaderboardSymbolMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: LeaderboardSymbolDto[]): LeaderboardSymbolMobxDto[] {
        return dtos.map((dto) => LeaderboardSymbolMobxDto.create(dto));
    }
}
