import { makeAutoObservable } from 'mobx';
import type { RootStore } from './root-store';
import { LayoutEnum, SubscriptionEnum, ThemeEnum } from '../../defs/api';

export type ThemeStoreHydration = {
    layout: LayoutEnum;

    theme: ThemeEnum;

    mobileNavigationDisplay: boolean;

    mobileAdminNavigationDisplay: boolean;

    mobilePortfolioNavigationDisplay: boolean;

    showBar: boolean;
};

export class ThemeStore {
    rootStore: RootStore;

    layout: LayoutEnum = LayoutEnum.Horizontal;

    theme: ThemeEnum = ThemeEnum.Light;

    mobileNavigationDisplay: boolean = false;

    mobileAdminNavigationDisplay: boolean = false;

    mobilePortfolioNavigationDisplay: boolean = false;

    showBar: boolean = false;

    loading = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    hydrate(): ThemeStoreHydration {
        return {
            theme: this.theme,
            layout: this.layout,
            mobileNavigationDisplay: this.mobileNavigationDisplay,
            mobileAdminNavigationDisplay: this.mobileAdminNavigationDisplay,
            mobilePortfolioNavigationDisplay: this.mobilePortfolioNavigationDisplay,
            showBar: this.showBar,
        };
    }

    rehydrate(data: ThemeStoreHydration): void {
        this.theme = data.theme;
        this.layout = data.layout;
        this.showBar = data.showBar;
    }

    init(): void {
        this.layout = this.rootStore.authStore.userProfile?.layout || LayoutEnum.Horizontal;
        this.theme = this.rootStore.authStore.userProfile?.theme || ThemeEnum.Light;
        this.mobileNavigationDisplay = false;
        this.mobileAdminNavigationDisplay = false;
        this.mobilePortfolioNavigationDisplay = false;
        this.showBar =
            this.rootStore.authStore.userProfile?.subscription === SubscriptionEnum.Free ||
            !!this.rootStore.authStore.userProfile?.subscriptionEndDate;
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    setDefaultTheme(): void {
        this.theme = ThemeEnum.Light;
    }

    async toggleTheme() {
        this.theme = this.theme === ThemeEnum.Light ? ThemeEnum.Dark : ThemeEnum.Light;
        try {
            this.startLoading();
            const res = await this.rootStore.apiClient.userController.userControllerSetTheme({
                setThemeDto: { theme: this.theme },
            });
            if (!res) {
                throw new Error();
            }

            const themeColor = this.theme === ThemeEnum.Dark ? '#1A1A29' : '#ffffff';
            const metaThemeColor = document.querySelector('meta[name="theme-color"]');
            if (metaThemeColor) {
                metaThemeColor.setAttribute('content', themeColor);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async toggleLayout() {
        this.layout = this.layout === LayoutEnum.Horizontal ? LayoutEnum.Vertical : LayoutEnum.Horizontal;
        try {
            this.startLoading();
            const res = await this.rootStore.apiClient.userController.userControllerSetLayout({
                setLayoutDto: { layout: this.layout },
            });
            if (!res) {
                throw new Error();
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    toggleMobileNavigationDisplay() {
        this.mobileNavigationDisplay = this.mobileNavigationDisplay !== true;
    }

    toggleMobileAdminNavigationDisplay() {
        this.mobileAdminNavigationDisplay = this.mobileAdminNavigationDisplay !== true;
    }

    toggleMobilePortfolioNavigationDisplay() {
        this.mobilePortfolioNavigationDisplay = this.mobilePortfolioNavigationDisplay !== true;
    }

    hideMobileNavigation() {
        this.mobileNavigationDisplay = false;
    }

    hideMobileAdminNavigation() {
        this.mobileAdminNavigationDisplay = false;
    }

    hideMobilePortfolioNavigation() {
        this.mobilePortfolioNavigationDisplay = false;
    }

    toggleShowBar() {
        this.showBar = this.showBar !== true;
    }
}
