import { makeAutoObservable } from 'mobx';
import { SymbolDetailDto } from '../../../../defs/api';

export class SymbolDetailMobxDto implements SymbolDetailDto {
    symbol: string = '';

    valueChangePercentage: number = 0;

    valueChangeAbsolute: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolDetailDto): SymbolDetailMobxDto {
        const mobxDto = new SymbolDetailMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: SymbolDetailDto[]): SymbolDetailMobxDto[] {
        return dtos.map((dto) => SymbolDetailMobxDto.create(dto));
    }
}
