/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TradeEntity,
    TradeEntityFromJSON,
    TradeEntityFromJSONTyped,
    TradeEntityToJSON,
} from './TradeEntity';

/**
 * 
 * @export
 * @interface CurrencyEntity
 */
export interface CurrencyEntity {
    /**
     * 
     * @type {TradeEntity}
     * @memberof CurrencyEntity
     */
    trades: TradeEntity;
    /**
     * 
     * @type {string}
     * @memberof CurrencyEntity
     */
    code: string;
}

export function CurrencyEntityFromJSON(json: any): CurrencyEntity {
    return CurrencyEntityFromJSONTyped(json, false);
}

export function CurrencyEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trades': TradeEntityFromJSON(json['trades']),
        'code': json['code'],
    };
}

export function CurrencyEntityToJSON(value?: CurrencyEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trades': TradeEntityToJSON(value.trades),
        'code': value.code,
    };
}

