/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotConfirmedUserDto
 */
export interface NotConfirmedUserDto {
    /**
     * 
     * @type {string}
     * @memberof NotConfirmedUserDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NotConfirmedUserDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof NotConfirmedUserDto
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof NotConfirmedUserDto
     */
    registrationDate: Date;
}

export function NotConfirmedUserDtoFromJSON(json: any): NotConfirmedUserDto {
    return NotConfirmedUserDtoFromJSONTyped(json, false);
}

export function NotConfirmedUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotConfirmedUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fullName': json['fullName'],
        'email': json['email'],
        'registrationDate': (new Date(json['registrationDate'])),
    };
}

export function NotConfirmedUserDtoToJSON(value?: NotConfirmedUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fullName': value.fullName,
        'email': value.email,
        'registrationDate': (value.registrationDate.toISOString()),
    };
}

