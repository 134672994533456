/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AreaChartDto,
    AreaChartDtoFromJSON,
    AreaChartDtoFromJSONTyped,
    AreaChartDtoToJSON,
} from './AreaChartDto';

/**
 * 
 * @export
 * @interface SymbolCloseChartDto
 */
export interface SymbolCloseChartDto {
    /**
     * 
     * @type {AreaChartDto}
     * @memberof SymbolCloseChartDto
     */
    absoluteClose: AreaChartDto;
    /**
     * 
     * @type {AreaChartDto}
     * @memberof SymbolCloseChartDto
     */
    percentageClose: AreaChartDto;
}

export function SymbolCloseChartDtoFromJSON(json: any): SymbolCloseChartDto {
    return SymbolCloseChartDtoFromJSONTyped(json, false);
}

export function SymbolCloseChartDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolCloseChartDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'absoluteClose': AreaChartDtoFromJSON(json['absoluteClose']),
        'percentageClose': AreaChartDtoFromJSON(json['percentageClose']),
    };
}

export function SymbolCloseChartDtoToJSON(value?: SymbolCloseChartDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'absoluteClose': AreaChartDtoToJSON(value.absoluteClose),
        'percentageClose': AreaChartDtoToJSON(value.percentageClose),
    };
}

